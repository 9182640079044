import React, { FC, Fragment } from 'react';
import {
  Avatar,
  Button,
  Flex,
  Link,
  Skeleton,
  Text,
  Tooltip,
  useTheme,
} from '@chakra-ui/react';
import { WorkLogDetailsType } from '../../../../helpers/ActivityLog.types';
import {
  AuthorIdFilter,
  setQueryParams,
  SlideBar,
  TeamFilter,
  useDashboardStore,
  useDetailsPageStore,
} from '@devd-client/devd/components';
import { Divider, NoDataFoundText, SidebarCard } from './SlideDrawer.styled';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

interface SlideDrawerProps {
  openDrawer: boolean;
  workLogDetails?: any;
  handleDrawerClose: () => void;
  workLogDetailsFetching: boolean;
  heading: string;
  tag: string;
  subHeading: string;
  date: string;
}

interface NavigationState {
  filterBy: TeamFilter;
  currentChartMetaData: any;
  payload: any;
  tableType: 'PR' | 'ISSUE';
  metricHeading: string;
}
const SlideDrawer: FC<SlideDrawerProps> = ({
  openDrawer,
  workLogDetails,
  handleDrawerClose,
  workLogDetailsFetching,
  heading,
  tag,
  subHeading,
  date,
}) => {
  const { setSelected, setActive } = useDetailsPageStore();
  const navigate = useNavigate();

  let chartKey = '';

  const PRHeadings = ['PR Opened', 'Merged PRs', 'PR reviewed'];

  switch (heading) {
    case 'PR Opened':
      chartKey = 'PR_OPEN';
      break;
    case 'Merged PRs':
      chartKey = 'PR_MERGE';
      break;
    case 'PR reviewed':
      chartKey = 'PR_REVIEWED';
      break;
    default:
      chartKey = '';
      break;
  }

  return (
    <SlideBar
      width="460px"
      openDrawer={openDrawer}
      handleDrawerClose={handleDrawerClose}
      sideBarHeading={heading}
      SideDrawerBodyHeight={false}
      sideBarSubHeading={
        <Flex direction={'column'}>
          <Text color="text.secondary" fontWeight="400" fontSize="13px">
            {tag === 'contributors'
              ? heading === 'PR Merged'
                ? 'of'
                : 'by'
              : 'on'}{' '}
            {tag === 'contributors'
              ? 'user'
              : tag === 'git'
              ? 'repo'
              : 'project'}{' '}
            <RouterLink to={`/contributors/${subHeading}`}>
              <Text display={'inline'} textDecoration={'underline'}>
                {subHeading}
              </Text>
            </RouterLink>{' '}
            on {moment(date).format('dddd, MMMM Do YYYY').split(' ')[2]}{' '}
            {moment(date).format('dddd, MMMM Do YYYY').split(' ')[1]}
          </Text>
          {PRHeadings.includes(heading) && (
            <Button
              onClick={() => {
                const state: NavigationState = {
                  tableType: 'PR',
                  metricHeading: heading,
                  currentChartMetaData: { chartKey: chartKey },
                  filterBy: { author: subHeading, teamId: '' },
                  payload: null,
                };

                setSelected({
                  id: 6,
                  startDate: date as string,
                  endDate: date as string,
                  duration: 'Custom',
                  sprintId: '',
                });
                setActive('Custom');
                navigate(setQueryParams(state));
              }}
              variant={'primary'}
              maxW={'fit-content'}
              mt={4}
            >
              More Details
            </Button>
          )}
        </Flex>
      }
      content={
        <>
          {!workLogDetails ||
            (workLogDetails.length === 0 && !workLogDetailsFetching && (
              <NoDataFoundText>No Data Found.</NoDataFoundText>
            ))}

          {(workLogDetailsFetching
            ? Array.from(new Array(7))
            : workLogDetails
          )?.map((detail: WorkLogDetailsType, idx: number) => (
            <Fragment key={idx}>
              {detail ? (
                <SidebarCard>
                  <Flex gap={1}>
                    {detail?.activityDate && (
                      <Tooltip label={'Activity time'} hasArrow>
                        <Text
                          fontFamily="heading"
                          fontSize="xs"
                          color="text.secondary"
                          fontWeight="semibold"
                        >
                          {moment(detail?.activityDate).format('lll')}
                        </Text>
                      </Tooltip>
                    )}
                  </Flex>

                  {detail?.description && (
                    <Link
                      href={detail?.link}
                      target="_blank"
                      _hover={{ textDecoration: 'none' }}
                      my={1}
                    >
                      <Text
                        fontSize="md"
                        fontFamily="heading"
                        textDecoration={detail?.link ? 'underline' : ''}
                        color="gray.700"
                        fontWeight="medium"
                      >
                        {detail?.description}
                      </Text>
                    </Link>
                  )}

                  {detail?.assignee && (
                    <Flex my={1} align={'center'}>
                      <Avatar
                        size="xs"
                        bg="text.secondary"
                        opacity={0.8}
                        name={detail?.assignee?.toUpperCase()?.charAt(0)}
                      />
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        color="text.secondary"
                        fontWeight="medium"
                        ml={1}
                      >
                        {detail?.assignee}
                      </Text>
                    </Flex>
                  )}

                  {detail?.project && (
                    <Flex mr={3} align="flex-start">
                      <Text
                        fontFamily="heading"
                        fontSize="sm"
                        fontWeight="semibold"
                        color="gray.600"
                        whiteSpace={'nowrap'}
                        mr={1}
                      >
                        Project
                      </Text>{' '}
                      <Text mt={'-3px'}>:</Text>
                      <Text
                        ml={1}
                        fontFamily="heading"
                        fontSize="sm"
                        fontWeight="medium"
                        color="text.secondary"
                        wordBreak={'break-all'}
                      >
                        {detail?.project}
                      </Text>
                    </Flex>
                  )}

                  {detail?.branch && (
                    <Flex mr={3} align="flex-start">
                      <Text
                        fontFamily="heading"
                        fontSize="sm"
                        fontWeight="semibold"
                        color="gray.600"
                        whiteSpace={'nowrap'}
                        mr={1}
                      >
                        Branch
                      </Text>{' '}
                      <Text mt={'-3px'}>:</Text>
                      <Text
                        ml={1}
                        fontFamily="heading"
                        fontSize="sm"
                        fontWeight="medium"
                        color="text.secondary"
                        wordBreak={'break-all'}
                      >
                        {detail?.branch}
                      </Text>
                    </Flex>
                  )}
                </SidebarCard>
              ) : (
                <Skeleton height="80px" />
              )}

              <Divider />
            </Fragment>
          ))}
        </>
      }
    />
  );
};

export default React.memo(SlideDrawer);
