import { generateTeamAvatar } from '../shared/SharedComponents';
import {
  Flex,
  Icon,
  Skeleton,
  Text,
  TextProps,
  useTheme,
} from '@chakra-ui/react';
import AppTooltip from '../appTooltip';
import { WrongIcon } from '../icons/WrongIcon';
import { NotAllowedIcon } from '@chakra-ui/icons';

type Props = TextProps & {
  teams: string[];
  loading: boolean;
  hideMore?: boolean;
};

export function ContributorTeams({
  teams,
  loading,
  hideMore = false,
  ...props
}: Props) {
  const { colors } = useTheme();
  return loading ? (
    <Flex gap={1}>
      <Skeleton height={4} w={4} rounded={'full'} />
      <Skeleton height={4} w={16} />
    </Flex>
  ) : teams?.length > 0 ? (
    <Text {...props} color="text.primary" fontWeight={'medium'}>
      {generateTeamAvatar(teams[0])}
      {teams[0]}{' '}
      {teams?.length > 1 && (
        <AppTooltip
          content={
            <Flex
              fontFamily={'heading'}
              direction={'column'}
              maxW={'360px'}
              gap={0.5}
              fontWeight={500}
            >
              {teams?.slice(1)?.map((team: string, index: number) => (
                <Text
                  key={`${team}-${index}`}
                  {...props}
                  color="text.primary"
                  fontWeight={'medium'}
                >
                  {generateTeamAvatar(team)} {team}
                </Text>
              ))}
            </Flex>
          }
          placement="bottom"
          trigger="hover"
        >
          <Text {...props} textDecoration={'underline'} display={'inline'}>
            +{teams?.length - 1} {!hideMore ? 'more' : ''}
          </Text>
        </AppTooltip>
      )}
    </Text>
  ) : (
    <Flex align={'center'} gap={1}>
      <Icon>
        <NotAllowedIcon
          stroke={colors.error}
          h={17}
          w={17}
          boxSizing="border-box"
        />
      </Icon>
      <Text {...props} color="text.primary" fontWeight={'medium'}>
        No Team Assigned
      </Text>
    </Flex>
  );
}
