import {
  Box,
  Button,
  Flex,
  Grid,
  Skeleton,
  Text,
  useTheme,
  GridItem,
  Image,
  Tooltip as ChakraTooltip,
  Spinner,
  IconButton,
} from '@chakra-ui/react';
import {
  AlertTriangle,
  AppTooltip,
  convertHexToRGBA,
  CustomTooltip,
  DownArrowIcon,
  EliteIcon,
  RightIcon,
  SlideInfoDrawer,
  StarIcon,
  Thumsup,
  UpArrowIcon,
  useDashboardStore,
} from '@devd-client/devd/components';
import { useMetricsOverviewGql } from '../../../graphql';
import { useNavigate } from 'react-router';
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import StackBarChart from './StackBarChart';
import AILogo from '../../shared/assets/img/ai-icon-circle.svg';
import AITooltipLogo from '../../shared/assets/img/ai-tooltip-logo.svg';

interface OverviewCardProps {
  category: string;
}

type ValueType = {
  color?: string;
};

export const Value = styled.p<ValueType>`
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ color }) => color || '#777'};
  min-width: max-content;
  line-height: 16px;
  margin-left: 4px;
`;

const OverviewCard = ({ category }: OverviewCardProps) => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const { selected, selectedTeam } = useDashboardStore();

  const { data: metricsOverviewData, isLoading: metricsOverviewLoading } =
    useMetricsOverviewGql(
      selectedTeam.value,
      selected.startDate,
      selected.endDate,
      category
    );

  const [openDrawer, setOpenDrawer] = useState(false);
  const [title, setTitle] = useState('');
  const [chartKey, setChartKey] = useState('');
  const [item, setItem] = useState<any>();
  const handleDrawerOpen = useCallback(
    (item: any) => {
      setOpenDrawer(true);
      setTitle(item?.threshold?.title);
      setChartKey(item?.chartMetadata?.chartKey);
      setItem(item);
    },
    [openDrawer]
  );

  const handleDrawerClose = useCallback(
    () => setOpenDrawer(false),
    [openDrawer]
  );

  const modifiedMetricOverviewList =
    metricsOverviewData?.metricsOverviewList?.map((el: any) => ({
      ...el,
      data: JSON.parse(el.data)?.map((item: any) => ({
        ...item,
        y: Number(item.y),
      })),
    }));

  return metricsOverviewLoading ? (
    <Box position={'relative'}>
      <Skeleton height="220px" minWidth="500px" />
      <Spinner position={'absolute'} top={'45%'} left={'45%'} />
    </Box>
  ) : metricsOverviewData?.overviewError?.message &&
    metricsOverviewData?.overviewError?.link ? (
    <Flex
      p={4}
      border="1px solid #E0E0E0"
      borderRadius="8px"
      boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
      direction="column"
      minHeight="220px"
      justify="space-between"
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Image
        height="100%"
        objectFit="contain"
        src={metricsOverviewData.zsImage || ''}
        style={{ position: 'relative' }}
      />
      {isHovered && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          backgroundColor="rgba(0, 0, 0, 0.4)"
          borderRadius="4px"
          transition="background-color 0.3s"
        >
          <Button
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            onClick={() =>
              metricsOverviewData?.overviewError?.link &&
              navigate(metricsOverviewData?.overviewError?.link as string)
            }
          >
            {metricsOverviewData?.overviewError?.message}
          </Button>
        </Box>
      )}
    </Flex>
  ) : (
    <Flex
      p={4}
      border="1px solid #E0E0E0"
      borderRadius="8px"
      boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
      direction="column"
      minHeight="220px"
      justify="space-between"
      _hover={{
        boxShadow: 'lg',
        transition: '0.2s all',
      }}
    >
      <Flex mb={4} align="center">
        <Text color="text.primary" fontWeight="semibold">
          {metricsOverviewData?.title}
        </Text>
      </Flex>

      {metricsOverviewData?.title === 'Investment Distribution' ? (
        <Grid
          templateColumns="repeat(1, 1fr)"
          gap={4}
          justifyItems={'center'}
          w="100%"
        >
          <Flex flexWrap="wrap" gap={6} justifyContent={'center'} w="100%">
            {metricsOverviewData?.investmentRows?.map((row: any) => (
              <Flex direction="column">
                <Flex align="center">
                  <Box
                    width="3px"
                    bg={
                      modifiedMetricOverviewList[0]?.keys?.find(
                        (item: any) => item.key === row.name
                      )?.color
                    }
                    height="13px"
                    borderRadius={'10px'}
                    mr={1}
                  />
                  <Text
                    fontFamily="heading"
                    fontSize="sm"
                    fontWeight="normal"
                    color="text.secondary3"
                  >
                    {row.name}
                  </Text>
                </Flex>
                <Text
                  ml={2}
                  fontFamily="heading"
                  fontWeight="semibold"
                  color="text.primary"
                >
                  {row.dist}%
                </Text>
              </Flex>
            ))}
          </Flex>
          <GridItem
            height="200px"
            width={'100%'}
            position={'relative'}
            minWidth={'300px'}
          >
            <Box
              position={'absolute'}
              left={'0'}
              top={'0'}
              right={'0'}
              bottom={'0'}
            >
              <StackBarChart
                barChartData={
                  (modifiedMetricOverviewList[0]?.data &&
                    modifiedMetricOverviewList[0]?.data) ||
                  []
                }
                keys={
                  modifiedMetricOverviewList[0]?.keys?.map((item: any) => ({
                    label: item.key,
                    fillColor: item.color,
                  })) || []
                }
              />
            </Box>
          </GridItem>
        </Grid>
      ) : (
        <Grid templateColumns="repeat(2, 1fr)" gap={4} w="full">
          {modifiedMetricOverviewList?.map((item: any, index: number) => (
            <GridItem key={`grid-${index}`}>
              <Flex
                p={2}
                height="124px"
                border="1px solid #e0e0e0"
                borderRadius="4px"
                direction="column"
              >
                <Flex>
                  <Text
                    color="text.secondary2"
                    fontSize="sm"
                    fontFamily="heading"
                    fontWeight="medium"
                  >
                    {item?.threshold?.title}
                  </Text>
                  <Box ml="auto">
                    <AppTooltip
                      placement="top"
                      content={
                        <Text
                          fontSize="sm"
                          color="text.secondary"
                          fontWeight="normal"
                          fontFamily="heading"
                        >
                          {item?.chartMetadata?.info}{' '}
                          <Box
                            as="span"
                            display="inline-flex"
                            gap={1}
                            alignItems="center"
                          >
                            <Text
                              as="span"
                              color="blue"
                              bg="none"
                              fontSize="14"
                              display="inline"
                              borderBottom="1px solid blue"
                              fontWeight={400}
                              onClick={() => handleDrawerOpen(item)}
                              cursor={'pointer'}
                            >
                              Learn More by
                            </Text>
                            <Image src={AILogo} h={5} w={5} />
                          </Box>
                        </Text>
                      }
                      trigger="hover"
                    >
                      <IconButton
                        aria-label="ticket/open-ai-assistant"
                        icon={<Image src={AITooltipLogo} w={10} />}
                        _hover={{
                          transform: 'scale(1.1)',
                          transition: 'transform 0.2s ease-in-out',
                        }}
                        variant="unstyled"
                        size={'xs'}
                        ml={1}
                      />
                    </AppTooltip>
                  </Box>
                </Flex>

                <Flex justify="space-between" h="full">
                  <Flex direction="column" h="full" justify="space-between">
                    <Text
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="text.primary"
                    >
                      {/^(null|NaN)%?$/.test(item?.threshold?.value)
                        ? '0'
                        : item?.threshold?.value}
                    </Text>
                    {metricsOverviewData?.title === 'DORA Performance' ? (
                      <Flex>
                        {item?.threshold?.tag === 'Low' && (
                          <Flex
                            p={'4px 6px'}
                            bg={convertHexToRGBA(item?.threshold?.color, 0.1)}
                            align="center"
                          >
                            <Box>
                              <AlertTriangle
                                height="12px"
                                width="12px"
                                color={item?.threshold?.color}
                              />
                            </Box>
                            <Text
                              ml={1}
                              color={item?.threshold?.color}
                              fontSize="xs"
                              fontWeight="medium"
                              fontFamily="heading"
                            >
                              Low
                            </Text>
                          </Flex>
                        )}

                        {item?.threshold?.tag === 'Medium' && (
                          <Flex
                            p={'4px 6px'}
                            bg={convertHexToRGBA(item?.threshold?.color, 0.1)}
                            align="center"
                          >
                            <Box>
                              <Thumsup
                                height="12px"
                                width="12px"
                                color={item?.threshold?.color}
                              />
                            </Box>
                            <Text
                              ml={1}
                              color={item?.threshold?.color}
                              fontSize="xs"
                              fontWeight="medium"
                              fontFamily="heading"
                            >
                              Medium
                            </Text>
                          </Flex>
                        )}

                        {item?.threshold?.tag === 'High' && (
                          <Flex
                            p={'4px 6px'}
                            bg={convertHexToRGBA(item?.threshold?.color, 0.1)}
                            align="center"
                          >
                            <Box>
                              <StarIcon
                                height="12px"
                                width="12px"
                                color={item?.threshold?.color}
                              />
                            </Box>
                            <Text
                              ml={1}
                              color={item?.threshold?.color}
                              fontSize="xs"
                              fontWeight="medium"
                              fontFamily="heading"
                            >
                              High
                            </Text>
                          </Flex>
                        )}

                        {item?.threshold?.tag === 'Elite' && (
                          <Flex
                            p={'4px 6px'}
                            bg={convertHexToRGBA(item?.threshold?.color, 0.1)}
                            align="center"
                          >
                            <Box>
                              <EliteIcon
                                height="16px"
                                width="16px"
                                color={item?.threshold?.color}
                              />
                            </Box>
                            <Text
                              ml={1}
                              color={item?.threshold?.color}
                              fontSize="xs"
                              fontWeight="medium"
                              fontFamily="heading"
                            >
                              Elite
                            </Text>
                          </Flex>
                        )}
                      </Flex>
                    ) : (
                      <>
                        {typeof item?.previousPeriodStat?.percentChange !==
                          'undefined' &&
                        item?.previousPeriodStat?.percentChange !== null ? (
                          <ChakraTooltip
                            hasArrow
                            label={
                              item?.previousPeriodStat?.reason
                                ? `Reason: ${item?.previousPeriodStat?.reason}`
                                : 'Current average compared to average of last period'
                            }
                            placement="bottom"
                            background={'gray.700'}
                            color={'white'}
                          >
                            <Flex align="center" p={1} position="relative">
                              <Box
                                position="absolute"
                                top={0}
                                left={0}
                                bg={
                                  item?.previousPeriodStat?.percentChange === 0
                                    ? 'text.tertiary'
                                    : item?.previousPeriodStat?.improved
                                    ? colors.success
                                    : colors.error
                                }
                                opacity={0.1}
                                p={1}
                                h="full"
                                w="full"
                                zIndex={-1}
                              />
                              {item?.previousPeriodStat?.percentChange !== 0 ? (
                                <>
                                  {item?.previousPeriodStat?.percentChange >
                                  0 ? (
                                    <UpArrowIcon
                                      color={
                                        item?.previousPeriodStat?.improved
                                          ? colors.success
                                          : colors.error
                                      }
                                      fontSize={'24px'}
                                    />
                                  ) : (
                                    <DownArrowIcon
                                      color={
                                        item?.previousPeriodStat?.improved
                                          ? colors.success
                                          : colors.error
                                      }
                                      fontSize={'24px'}
                                    />
                                  )}
                                  <Value
                                    color={
                                      item?.previousPeriodStat?.improved
                                        ? colors.success
                                        : colors.error
                                    }
                                  >
                                    {/* {item?.previousPeriodStat?.percentChange > 0
                            ? '+'
                            : ''} */}
                                    {Math.abs(
                                      item?.previousPeriodStat?.percentChange
                                    )}
                                    %
                                  </Value>
                                </>
                              ) : (
                                <Value color={colors.text.secondary}>
                                  {item?.previousPeriodStat?.percentChange}%
                                </Value>
                              )}
                            </Flex>
                          </ChakraTooltip>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Flex>

                  <Flex width="80px" h="70px" mt={'auto'}>
                    <ResponsiveContainer height="100%" width="100%">
                      <BarChart
                        margin={{
                          top: 5,
                          bottom: -30,
                        }}
                        data={(item?.data && item?.data) || []}
                        barSize={10}
                      >
                        <Tooltip
                          cursor={{ fill: 'transparent' }}
                          content={
                            <CustomTooltip
                              minW="180px"
                              hideBtn={true}
                              keyName={item?.threshold?.title}
                            />
                          }
                          wrapperStyle={{ outline: 'none', zIndex: 1000 }}
                          position={{ y: 75, x: -50 }}
                        />

                        <XAxis
                          dataKey="xLabelName"
                          tick={false}
                          axisLine={false}
                        />
                        {item?.keys?.map((el: any) => (
                          <Bar dataKey={el.key} name={el.name}>
                            {item?.data?.map((el: any) => (
                              <Cell
                                fill={
                                  metricsOverviewData?.title ===
                                  'DORA Performance'
                                    ? item?.threshold?.color
                                    : el.color
                                }
                              />
                            ))}
                          </Bar>
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                  </Flex>
                </Flex>
              </Flex>
            </GridItem>
          ))}
        </Grid>
      )}

      <Box mt={4}>
        <Button
          variant="unstyled"
          color="text.secondary2"
          fontWeight="normal"
          size="sm"
          fontSize="sm"
          onClick={() => navigate(metricsOverviewData?.link)}
          _hover={{ textDecoration: 'underline' }}
          rightIcon={
            <RightIcon
              height="11px"
              width="11px"
              color={colors.text.secondary2}
            />
          }
        >
          View Details
        </Button>
      </Box>
      {openDrawer && (
        <SlideInfoDrawer
          handelDrawerClose={handleDrawerClose}
          openDrawer={openDrawer}
          title={title}
          chartKey={chartKey}
          item={item}
          metricOverview
        />
      )}
    </Flex>
  );
};

export default OverviewCard;
