export const ContributorTableCompareIcon = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="Flip-Horizontal-Arrow-1--Streamline-Core"
        clip-path="url(#clip0_8948_142)"
      >
        <g id="flip-horizontal-arrow-1--arrow-design-flip-reflect">
          <path
            id="Vector"
            d="M1 3.5L4.5 7L1 10.5V3.5Z"
            stroke={props.color || '#2A2A2F'}
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_2"
            d="M13 3.5L9.5 7L13 10.5V3.5Z"
            stroke={props.color || '#2A2A2F'}
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_3"
            d="M7 1V2.5"
            stroke={props.color || '#2A2A2F'}
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_4"
            d="M7 4.5V6"
            stroke={props.color || '#2A2A2F'}
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_5"
            d="M7 8V9.5"
            stroke={props.color || '#2A2A2F'}
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_6"
            d="M7 11.5V13"
            stroke={props.color || '#2A2A2F'}
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8948_142">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
