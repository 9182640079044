import { createColumnHelper } from '@tanstack/react-table';
import { Fragment, useCallback, useMemo, useState } from 'react';
import {
  AppTooltip,
  DataGrid,
  DonutChart,
  Paginate,
  splitTime,
} from '@devd-client/devd/components';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import RecentRuns from './RecentRuns';
import { RiArrowRightSLine } from 'react-icons/ri';
import TableBarChart from './TableBarChart';
import TableBarChartModal from './TableBarChartModal';
import { FaExpand } from 'react-icons/fa';

interface SprintListProps {
  data: any;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  loading: boolean;
}

const GetPipelineDetails = ({
  keyOfPipeline,
  value,
}: {
  keyOfPipeline: string;
  value: any;
}) => (
  <Flex align="center" gap={2}>
    <Text fontFamily={'heading'} fontSize="sm" color="text.secondary">
      {keyOfPipeline} :{' '}
    </Text>
    <Text fontFamily={'heading'} fontSize="sm" color="text.primary">
      {value}
    </Text>
  </Flex>
);

const CicdList: React.FC<SprintListProps> = ({
  data,
  currentPage,
  setCurrentPage,
  loading,
}) => {
  const navigate = useNavigate();
  const barChartModal = useDisclosure();
  const [currentData, setCurrentData] = useState<any>();
  const rows: any[] = useMemo(
    () => data?.pipelineReport || [],
    [data?.pipelineReport]
  );

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('Details', {
        cell: (info) => (
          <Flex direction="column" gap={1}>
            <Text
              onClick={() =>
                navigate(
                  `/dashboard/cicd/${encodeURIComponent(
                    info.row.original?.pipelineDetail?.name
                  )}`
                )
              }
              as="span"
              color="primary"
              textDecoration="underline"
              fontFamily="heading"
              fontSize="md"
              cursor="pointer"
              wordBreak={'break-all'}
            >
              {info.row.original?.pipelineDetail?.name}
            </Text>
            <GetPipelineDetails
              keyOfPipeline="Repo"
              value={info.row.original?.pipelineDetail?.repo}
            />
            <GetPipelineDetails
              keyOfPipeline="Type"
              value={info.row.original?.pipelineDetail?.type}
            />
            <GetPipelineDetails
              keyOfPipeline="Env"
              value={info.row.original?.pipelineDetail?.env || 'NA'}
            />
          </Flex>
        ),

        header: 'Pipeline Details',
        size: 200,
      }),
      columnHelper.accessor('totalRuns', {
        cell: (info) => info.getValue(),
        header: 'Total Runs',
        size: 80,
      }),
      columnHelper.accessor('successRate', {
        cell: (info) => (
          <Flex height="140px" width="120px" align="center" justify="center">
            <DonutChart percentage={parseFloat(info.getValue()) || 0} />
          </Flex>
        ),
        header: 'Success Rate',
        size: 80,
      }),
      columnHelper.accessor('avgTimeInSecs', {
        cell: (info) => splitTime(info.getValue() / 3600),
        header: 'Avg Time',
        size: 80,
      }),
      columnHelper.accessor('recentRuns', {
        cell: (info) => <RecentRuns info={info} />,
        header: 'Recent Runs',
        size: 180,
      }),
      columnHelper.accessor('stagesAvgDuration', {
        cell: (info) => (
          <Flex height="140px" width="200px" position="relative">
            {info.row.original?.stagesAvgDuration &&
              info.row.original?.stagesAvgDuration?.length && (
                <Box
                  position="absolute"
                  pointerEvents={'auto'}
                  zIndex={1}
                  top={-1}
                  right={-10}
                >
                  <AppTooltip label="Expand" trigger="hover">
                    <IconButton
                      aria-label="expand"
                      size="sm"
                      bg="transparent"
                      onClick={() => {
                        barChartModal.onOpen();
                        setCurrentData({
                          data:
                            info.row.original?.stagesAvgDuration?.map(
                              (item: any) => ({
                                ...item,
                                value: item.value
                                  ? parseFloat(
                                      parseFloat(item.value).toFixed(2)
                                    )
                                  : 0,
                              })
                            ) || [],
                          pipeline: info.row.original?.pipelineDetail?.name,
                        });
                      }}
                      icon={<FaExpand size={18} />}
                    />
                  </AppTooltip>
                </Box>
              )}
            {info.row.original?.stagesAvgDuration &&
              info.row.original?.stagesAvgDuration?.length && (
                <TableBarChart
                  showLabel={false}
                  data={
                    info.row.original?.stagesAvgDuration?.map((item: any) => ({
                      ...item,
                      value: item.value
                        ? parseFloat(parseFloat(item.value).toFixed(2))
                        : 0,
                    })) || []
                  }
                />
              )}
          </Flex>
        ),

        header: 'Stages Avg Duration',
        size: 180,
      }),
    ],
    [data?.pipelineReport]
  );

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );

  return (
    <Box mt={4}>
      <DataGrid
        data={rows}
        columns={columns}
        showLoader={loading}
        maxH="550px"
        sticky="firstCol"
      />

      <Box marginLeft="-20px" my={'16px'}>
        <Paginate
          currentPage={data?.currentPage + 1}
          totalPageCount={data?.totalPages}
          pageSize={20}
          onPageChange={(page) => handlePageClick(page as number)}
        />
      </Box>
      {barChartModal.isOpen && (
        <TableBarChartModal
          isOpen={barChartModal.isOpen}
          onClose={barChartModal.onClose}
          data={currentData.data}
          pipeline={currentData.pipeline}
        />
      )}
    </Box>
  );
};

export default CicdList;
