import styled from 'styled-components';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';

import { Button, Text, useFocusEffect } from '@chakra-ui/react';

import {
  TeamFilter,
  PR_CHART_KEYS,
  PR_AGEING_CHARTS,
  PR_HEALTH_INDEX_CHARTS,
  QUALITY_CHART_KEYS,
  ISSUE_CHART_KEYS,
  LOC_VS_PR_CYCLE_TIME_CHARTS,
  useChartTableGql,
  durationData,
  ISelect,
  Loader,
  PageHeader,
  PageToolbar,
  PeriodType,
  removeEmptyQueryParams,
  useQueryState,
  useDashboardStore,
  REVIEW_RESPONSIVENESS_INDEX_CHARTS,
  useToastHook,
  useMetricsTableStore,
  ISSUE_AGE_CHART_KEYS,
  ISSUE_LIFECYCLE_DIST_CHARTS,
  REQUIREMENT_STABILITY_CHARTS,
  useDetailsPageStore,
} from '@devd-client/devd/components';
import moment from 'moment';

import { PRDetails } from '../components/PR-table';
import { useAuth } from '@devd-client/devd/auth';
import IssueDetails from '../components/Issue-table';
import useMetricTableHelpers from '../components/Filters/hooks/useMetricTableHelpers';
import {
  FilterByType,
  MetricTableProps,
  NavigationState,
} from '../utils/metricTableTypes';
import { useContributorDetailGql, useFetchRepo } from '../apis';

/* eslint-disable-next-line */

export function MetricDetails(metricTableProps: MetricTableProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { pathname, state } = useLocation();
  const passedState = state as NavigationState;

  const dashboardStore = useDashboardStore();
  const detailsPageStore = useDetailsPageStore();
  const metricFilterBy = useMetricsTableStore();
  const { handleMetricFilterBy } = useMetricTableHelpers();

  const { selected, setSelected } =
    pathname.includes('issue-details') || pathname.includes('pr-details')
      ? detailsPageStore
      : dashboardStore;

  const [appState, dispatch] = useAuth();
  const { selectedTeam, setSelectedTeam } = useDashboardStore();
  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [periodOptions, setPeriodOptions] = useState(durationData);

  const [chartKey, setChartKey] = useQueryState('chartKey');
  const [metricKey, setMetricKey] = useQueryState('metricKey');
  const [metric, setMetric] = useQueryState('metric');
  const [heading, setHeading] = useQueryState('heading');
  const [firstOption, setFirstOption] = useQueryState('firstOption');
  const [secondOption, setSecondOption] = useQueryState('secondOption');
  const [teamId, setTeamId] = useQueryState('teamId');
  const [status, setStatus] = useQueryState('status');
  const [ticketType, setTicketType] = useQueryState('ticketType');
  const [preDefinedOptions, setPredefinedOptions] =
    useQueryState('preDefinedOptions');
  const [persistPassedState, setPersistPassedState] =
    useQueryState('persistPassedState');

  // below useeffect is for setting inital values for zustand from component props.
  useEffect(() => {
    if (metricTableProps.isSeparateComponent) {
      setChartKey(metricTableProps.chartKey);
      setMetricKey(metricTableProps.metricKey);
      setMetric(metricTableProps.metric);
      setHeading(metricTableProps.heading);
      setFirstOption(metricTableProps.firstOption);
      setSecondOption(metricTableProps.secondOption);
      setTeamId(metricTableProps.teamId);
      setStatus(metricTableProps.status);
      setTicketType(metricTableProps.ticketType);
      metricFilterBy.setComponentOrigin(
        metricTableProps.componentOrigin ?? null
      );
      if (metricTableProps.tableType === 'PR') {
        handleMetricFilterBy(
          'prFilters',
          metricTableProps.firstOption ?? '',
          metricTableProps.secondOption
            ? metricTableProps.secondOption
            : metricTableProps.firstOption ?? null,
          null,
          metricTableProps.chartKey ?? '',
          metricTableProps.metricKey ?? '',
          metricTableProps.metric ?? '',
          metricTableProps.heading ?? ''
        );
        handleMetricFilterBy(
          'status',
          '',
          metricTableProps.status ?? null,
          null
        );
      } else if (metricTableProps.tableType === 'ISSUE') {
        handleMetricFilterBy(
          'issueFilters',
          metricTableProps.firstOption ?? '',
          metricTableProps.secondOption
            ? metricTableProps.secondOption
            : metricTableProps.firstOption ?? null,
          metricTableProps.ticketType ?? null,
          metricTableProps.chartKey ?? '',
          '',
          metricTableProps.metric ?? '',
          metricTableProps.heading ?? ''
        );
        handleMetricFilterBy(
          'issueStatusType',
          '',
          metricTableProps.status ?? null,
          null
        );
      }
      handleMetricFilterBy('teams', '', metricTableProps.teamId ?? null, null);
    }
  }, [metricTableProps]);

  // below useeffect is for setting inital values for zustand from URL params.
  useEffect(() => {
    if (preDefinedOptions === 'true') {
      metricFilterBy.setComponentOrigin(null);
      if (pathname === '/pr-details') {
        handleMetricFilterBy(
          'prFilters',
          firstOption ?? '',
          secondOption ? secondOption : firstOption ?? null,
          null,
          chartKey,
          metricKey,
          metric,
          heading
        );
        handleMetricFilterBy('status', '', status ?? null, null);
        metricFilterBy.setPrIds(
          passedState?.prIds
            ? passedState?.prIds
            : persistPassedState === 'true'
            ? metricFilterBy.prIds
            : null
        );
        metricFilterBy.setShowFilters(
          passedState?.showFilters === false
            ? passedState?.showFilters
            : persistPassedState === 'true'
            ? metricFilterBy.showFilters
            : true
        );
      } else if (pathname === '/issue-details') {
        handleMetricFilterBy(
          'issueFilters',
          firstOption ?? '',
          secondOption ? secondOption : firstOption ?? null,
          ticketType ?? null,
          chartKey,
          '',
          metric,
          heading
        );
        handleMetricFilterBy('issueStatusType', '', status ?? null, null);
      }
      handleMetricFilterBy('teams', '', teamId ?? null, null);
    }
  }, []);

  // Below 2 useeffects are for Date filter only.
  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? setSprintId('')
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }
  }, [selected, selectedTeam.value]);

  useEffect(() => {
    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  //below useEffect is for when componnent unmounts.
  useEffect(() => {
    return () => {
      metricFilterBy.setIsQueryReady(false);
    };
  }, []);

  return (
    <>
      {[
        'contributor-profile-pr',
        'contributor-profile-issue',
        'initiatives-issue',
      ].includes(metricTableProps.componentOrigin as string) ? (
        <></>
      ) : searchParams.get('back') === 'hidden' ? (
        <></>
      ) : (
        <Button
          onClick={() => navigate(-1)}
          size="sm"
          variant="link"
          w={'fit-content'}
          mr={'auto'}
          mb={2}
        >
          Back
        </Button>
      )}

      {[
        ...PR_CHART_KEYS,
        ...PR_AGEING_CHARTS,
        ...PR_HEALTH_INDEX_CHARTS,
        ...LOC_VS_PR_CYCLE_TIME_CHARTS,
        ...REVIEW_RESPONSIVENESS_INDEX_CHARTS,
      ].includes(chartKey) ? (
        <PRDetails
          sprintId={sprintId ? sprintId : selected.sprintId}
          startDate={startDate ? startDate : selected.startDate}
          endDate={endDate ? endDate : selected.endDate}
          heading={
            metricTableProps.isSeparateComponent
              ? metricTableProps.heading
              : heading
          }
          chartKey={
            metricTableProps.isSeparateComponent
              ? metricTableProps.chartKey
              : chartKey
          }
          metric={
            metricTableProps.isSeparateComponent
              ? metricTableProps.metric
              : metric
          }
          metricKey={
            metricTableProps.isSeparateComponent
              ? metricTableProps.metricKey
              : metricKey
          }
          teamId={
            metricTableProps.isSeparateComponent
              ? metricTableProps.teamId
              : teamId
          }
          status={
            metricTableProps.isSeparateComponent
              ? metricTableProps.status
              : status ?? 'all'
          }
          firstOption={
            metricTableProps.isSeparateComponent
              ? metricTableProps.firstOption
              : firstOption
          }
          secondOption={
            metricTableProps.isSeparateComponent
              ? metricTableProps.secondOption
              : secondOption
          }
          setFirstOption={setFirstOption}
          setSecondOption={setSecondOption}
          setTeamId={setTeamId}
          setStatus={setStatus}
          setChartKey={setChartKey}
          setMetric={setMetric}
          setHeading={setHeading}
          setMetricKey={setMetricKey}
        />
      ) : [
          ...ISSUE_CHART_KEYS,
          ...ISSUE_AGE_CHART_KEYS,
          ...ISSUE_LIFECYCLE_DIST_CHARTS,
          ...REQUIREMENT_STABILITY_CHARTS,
        ].includes(chartKey) ? (
        <IssueDetails
          sprintId={sprintId ? sprintId : selected.sprintId}
          startDate={startDate ? startDate : selected.startDate}
          endDate={endDate ? endDate : selected.endDate}
          heading={
            metricTableProps.isSeparateComponent
              ? metricTableProps.heading
              : heading
          }
          chartKey={
            metricTableProps.isSeparateComponent
              ? metricTableProps.chartKey
              : chartKey
          }
          metric={
            metricTableProps.isSeparateComponent
              ? metricTableProps.metric
              : metric
          }
          metricKey={
            metricTableProps.isSeparateComponent
              ? metricTableProps.metricKey
              : metricKey
          }
          teamId={
            metricTableProps.isSeparateComponent
              ? metricTableProps.teamId
              : teamId
          }
          status={
            metricTableProps.isSeparateComponent
              ? metricTableProps.status
              : status ?? 'all'
          }
          firstOption={
            metricTableProps.isSeparateComponent
              ? metricTableProps.firstOption
              : firstOption
          }
          secondOption={
            metricTableProps.isSeparateComponent
              ? metricTableProps.secondOption
              : secondOption
          }
          setFirstOption={setFirstOption}
          setSecondOption={setSecondOption}
          setTeamId={setTeamId}
          setStatus={setStatus}
          setChartKey={setChartKey}
          setMetric={setMetric}
          setHeading={setHeading}
          setMetricKey={setMetricKey}
          setTicketType={setTicketType}
        />
      ) : (
        // <Navigate to={'/git'} />
        <></>
      )}
    </>
  );
}

export default MetricDetails;
