export const CheckCircleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      {...props}
    >
      <path
        d="M15.1663 7.39046V8.0038C15.1655 9.44141 14.7 10.8402 13.8392 11.9917C12.9785 13.1431 11.7685 13.9854 10.3899 14.3931C9.0113 14.8007 7.53785 14.7517 6.18932 14.2535C4.8408 13.7553 3.68944 12.8345 2.90698 11.6285C2.12452 10.4225 1.75287 8.99584 1.84746 7.56134C1.94205 6.12684 2.49781 4.76135 3.43186 3.66851C4.36591 2.57568 5.6282 1.81405 7.03047 1.49723C8.43274 1.1804 9.89985 1.32535 11.213 1.91046M15.1663 2.66665L8.49967 9.33998L6.49967 7.33998"
        stroke={props.color || '#4D4DFF'}
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
