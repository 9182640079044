import {
  Box,
  Divider,
  Tooltip as ChakraToolTip,
  Flex,
  Text,
  Icon,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useLocation } from 'react-router';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';

import moment from 'moment';
import {
  COMMIT_CHART_KEYS,
  WORK_BREAKDOWN_CHARTS,
  WORK_BREAKDOWN_STATS_CHARTS,
} from '../shared/utils';
import { MetricSidebar } from '../charts/components/MetricSidebar';

interface barChartProps {
  barChartStats: any;
  selected: any;
  queryKey: string;
  fitHeight?: boolean;
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box bg="white" p={2} boxShadow="md" borderRadius="md">
        <Flex gap={0.5} alignItems={'center'}>
          <Box
            w={'14px'}
            h={'14px'}
            rounded={'full'}
            bg={payload[0]?.payload.color}
          />
          <Text>
            {label} : {payload[0]?.value}%
          </Text>
        </Flex>
      </Box>
    );
  }
  return null;
};

export const CodeWorkBreakdownBarChart: React.FC<barChartProps> = ({
  barChartStats,
  selected,
  queryKey,
  fitHeight = false,
}) => {
  const { colors } = useTheme();
  const { pathname } = useLocation();
  const authormodal = useDisclosure();

  const [metricsName, setMetricsName] = useState<string>('');
  const [metricType, setMetricType] = useState<string>('');
  const [heading, setHeading] = useState<string>('');
  const [count, setCount] = useState<string>('');

  const sortedbarChartStats = [...barChartStats];
  sortedbarChartStats.sort((a, b) => {
    return parseInt(b.currentData) - parseInt(a.currentData);
  });

  const workBreakdownStats = sortedbarChartStats.filter(
    (item) =>
      item.metric !== 'WORK_BREAKDOWN_CODE_IMPACT' &&
      item.metric !== 'COMMIT_COUNT'
  );

  const sortedBarChartStats = workBreakdownStats
    .map((item) => ({
      name: item.chartMetadata.chartTitle,
      value: parseInt(item.currentData),
      color:
        item.metric === 'WORK_BREAKDOWN_NEW_WORK'
          ? '#71E03A'
          : item.metric === 'WORK_BREAKDOWN_RE_WORK'
          ? '#FF5230'
          : item.metric === 'WORK_BREAKDOWN_REFACTOR'
          ? '#0095E6'
          : item.metric === 'WORK_BREAKDOWN_HELP_OTHERS'
          ? '#F1B813'
          : '#000000',
    }))
    .filter((item) => item.value > 0)
    .sort((a, b) => b.value - a.value);

  const commitStatsTabularData = sortedbarChartStats?.filter((item: any) =>
    [WORK_BREAKDOWN_CHARTS[1], ...COMMIT_CHART_KEYS].includes(item.metric)
  );

  const codeWorkDownStats = sortedbarChartStats
    ?.filter((item: any) =>
      [...WORK_BREAKDOWN_STATS_CHARTS].includes(item.metric)
    )
    ?.map((item: any) => {
      return {
        ...item,
        color:
          item.metric === 'WORK_BREAKDOWN_NEW_WORK'
            ? '#71E03A'
            : item.metric === 'WORK_BREAKDOWN_RE_WORK'
            ? '#FF5230'
            : item.metric === 'WORK_BREAKDOWN_REFACTOR'
            ? '#0095E6'
            : item.metric === 'WORK_BREAKDOWN_HELP_OTHERS' && '#F1B813',
      };
    });

  const metricTypeMap: { [key: string]: string } = {
    'New Work': 'newwork',
    Rework: 'rework',
    'Helped Other': 'helpOthers',
    Refactor: 'refactor',
  };

  return (
    <Box
      w={'100%'}
      borderWidth={1}
      borderColor="gray.300"
      borderRadius="12px"
      py={'20px'}
      bg="#fff"
      minHeight={fitHeight ? '248px' : '320px'}
      border="1px solid #E3E6EA"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
      transition="all 0.2s"
      _hover={{
        boxShadow: '0 2px 7px 0 rgba(0,0,0,.06)',
      }}
    >
      <Text
        fontWeight="semibold"
        fontFamily="heading"
        color="text.primary"
        lineHeight="24px"
        fontSize={'md'}
        ml={6}
      >
        Code Work Breakdown
      </Text>

      <Flex
        direction="row"
        justify={'space-around'}
        align="stretch"
        mt={'1rem'}
        px={4}
      >
        <Box maxH="300px" w={'30%'} pl={2}>
          <Flex direction="column" gap={0.5} justifyContent={'space-around'}>
            {codeWorkDownStats?.map((data: any, index: number) => (
              <React.Fragment key={index}>
                <Divider orientation="horizontal" borderColor="gray.300" />

                <Flex
                  align="center"
                  justifyContent="space-between"
                  fontSize="sm"
                >
                  <Text
                    fontFamily="heading"
                    fontWeight="semibold"
                    color="gray.500"
                    mr={2}
                  >
                    {data?.chartMetadata?.chartTitle}
                  </Text>

                  <Flex direction="column" align="flex-end">
                    <Flex align={'center'}>
                      <ChakraToolTip
                        label={data?.currentData > 0 ? 'View details' : ''}
                        aria-label="View details"
                      >
                        <Text
                          cursor={data?.currentData > 0 ? 'pointer' : 'default'}
                          onClick={(e) => {
                            if (data?.currentData > 0) {
                              e.stopPropagation();
                              e.preventDefault();
                              setMetricsName(data?.metric);
                              setMetricType(data?.chartMetadata?.chartTitle);
                              setHeading(data?.chartMetadata?.chartTitle);
                              setCount(data?.currentData);
                              authormodal.onOpen();
                            }
                          }}
                          fontFamily="inter"
                          fontWeight="semibold"
                          fontSize="md"
                          color={data?.color}
                          textDecoration="none"
                          transition="text-decoration 0.2s ease-in-out"
                          _hover={{
                            textDecoration:
                              data?.currentData > 0 ? 'underline' : '',
                          }}
                          _focus={{
                            outline: 'none',
                            boxShadow: 'outline',
                          }}
                        >
                          {data?.currentData}%
                        </Text>
                      </ChakraToolTip>

                      <Box minW={5} h={4}>
                        {typeof data?.previousData?.percentChange !==
                          'undefined' &&
                          data?.previousData?.percentChange !== 0 &&
                          data?.previousData?.percentChange !== null && (
                            <Flex>
                              <ChakraToolTip
                                label={`${Math.abs(
                                  data?.previousData?.percentChange
                                )}%
                                  ${
                                    data?.previousData?.improved > 0
                                      ? 'improved'
                                      : 'deteriorated'
                                  } compared to the last period `}
                                placement="right"
                                hasArrow
                              >
                                <Box>
                                  <Icon
                                    as={
                                      data?.previousData?.percentChange > 0
                                        ? IoMdArrowDropup
                                        : IoMdArrowDropdown
                                    }
                                    fontSize={'20px'}
                                    color={
                                      data?.previousData?.improved
                                        ? colors?.success
                                        : colors?.error
                                    }
                                  />
                                </Box>
                              </ChakraToolTip>
                            </Flex>
                          )}
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </React.Fragment>
            ))}
          </Flex>
          <Flex direction="column" gap={0.5} justifyContent={'space-around'}>
            {commitStatsTabularData?.map((data: any, index: number) => (
              <React.Fragment key={index}>
                <Divider orientation="horizontal" borderColor="gray.300" />

                <Flex
                  align="center"
                  justifyContent="space-between"
                  fontSize="sm"
                >
                  <Text
                    fontFamily="heading"
                    fontWeight="semibold"
                    color="gray.500"
                    mr={2}
                  >
                    {data?.chartMetadata?.chartTitle}
                  </Text>

                  <Flex direction="column" align="flex-end">
                    <Flex align={'center'}>
                      <ChakraToolTip
                        label="View details"
                        aria-label="View details"
                      >
                        <Text
                          cursor="pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setMetricsName(data?.metric);
                            setMetricType(data?.chartMetadata?.chartTitle);
                            setHeading(data?.chartMetadata?.chartTitle);
                            setCount(data?.currentData);
                            authormodal.onOpen();
                          }}
                          fontFamily="heading"
                          fontWeight="semibold"
                          fontSize="md"
                          color="text.primary"
                          textDecoration="none"
                          transition="text-decoration 0.2s ease-in-out"
                          _hover={{
                            textDecoration: 'underline',
                          }}
                          _focus={{
                            outline: 'none',
                            boxShadow: 'outline',
                          }}
                        >
                          {data?.currentData}
                        </Text>
                      </ChakraToolTip>

                      <Box minW={5} h={4}>
                        {typeof data?.previousData?.percentChange !==
                          'undefined' &&
                          data?.previousData?.percentChange !== 0 &&
                          data?.previousData?.percentChange !== null && (
                            <Flex>
                              <ChakraToolTip
                                label={`${Math.abs(
                                  data?.previousData?.percentChange
                                )}%
                                  ${
                                    data?.previousData?.improved > 0
                                      ? 'improved'
                                      : 'deteriorated'
                                  } compared to the last period `}
                                placement="right"
                                hasArrow
                              >
                                <Box>
                                  <Icon
                                    as={
                                      data?.previousData?.percentChange > 0
                                        ? IoMdArrowDropup
                                        : IoMdArrowDropdown
                                    }
                                    fontSize={'20px'}
                                    color={
                                      data?.previousData?.improved
                                        ? colors?.success
                                        : colors?.error
                                    }
                                  />
                                </Box>
                              </ChakraToolTip>
                            </Flex>
                          )}
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </React.Fragment>
            ))}
          </Flex>
        </Box>

        <Box w={'80%'} maxH={'300px'} overflowY="auto" overflowX={'hidden'}>
          <ResponsiveContainer
            width="100%"
            height={sortedBarChartStats.length * 35 + 40}
          >
            <BarChart
              layout="vertical"
              data={sortedBarChartStats}
              margin={{ top: 0, right: 5, left: 5, bottom: 5 }}
            >
              <XAxis
                type="number"
                tick={{ fill: colors.gray[600], fontSize: 12 }}
              />
              <YAxis
                dataKey="name"
                type="category"
                axisLine={false}
                tickLine={false}
                tick={{ fill: colors.gray[600], fontSize: 12 }}
                width={100}
              />
              <Tooltip cursor={{ fill: 'none' }} content={<CustomTooltip />} />
              <Bar dataKey="value" radius={[0, 4, 4, 0]} barSize={25}>
                {sortedBarChartStats.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>

        {[
          ...WORK_BREAKDOWN_CHARTS,
          ...WORK_BREAKDOWN_STATS_CHARTS,
          ...COMMIT_CHART_KEYS,
        ].includes(metricsName) &&
          authormodal?.isOpen && (
            <MetricSidebar
              querykey={queryKey}
              openDrawer={authormodal?.isOpen}
              handleDrawerClose={authormodal?.onClose}
              heading={heading}
              subHeading={`${moment(selected.startDate).format(
                'DD MMM'
              )} - ${moment(selected.endDate).format(
                'DD MMM'
              )}, ${heading} (${count}${
                heading !== 'Code Impact' && heading !== 'Commit Count'
                  ? ' %'
                  : ''
              })
             `}
              payload={{
                payload: {
                  startDate: selected.startDate,
                  endDate: selected.endDate,
                },
              }}
              currentChartMeta={{ chartKey: 'WORK_BREAKDOWN' }}
              filterBy={{
                type: metricTypeMap[metricType] || metricType,
                sprintId: selected.sprintId,
                ...(pathname?.split('/')[1] === 'teams'
                  ? { teamId: decodeURIComponent(pathname?.split('/')[2]) }
                  : { author: decodeURIComponent(pathname?.split('/')[2]) }),
              }}
              selectedTeam={''}
            />
          )}
      </Flex>
    </Box>
  );
};

export default CodeWorkBreakdownBarChart;
