import { ViewSwitcher, DownloadButton } from '@devd-client/devd/components';
import { useContext, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { InvestmentsContext } from '../../../context/investmentContext';
import { Loader } from '@devd-client/devd/components';
import { apiClient } from '@devd-client/api';

const InfoTopBar = ({
  setViewToggle,
  viewSwitchObj,
  viewToggle,
  selectedTeam,
}: any) => {
  const {
    dispatch,
    state: { selectedChartData },
  } = useContext(InvestmentsContext);
  const [pending, setPending] = useState(false);

  const download = function (data: any) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute(
      'download',
      `${localStorage.getItem('orgId')} - ${selectedChartData.endDate}`
    );
    a.click();
  };
  const downloadCSV = async () => {
    setPending(true);
    apiClient(
      `/v2/org/${localStorage.getItem('orgId')}/investment/export?weekEndDate=${
        selectedChartData.endDate
      }&teamId=${selectedTeam}`,
      {
        method: 'GET',
        headers: {
          authorization: `bearer ${localStorage.getItem('token')}`,
        },
      }
    )
      .then((res: any) => {
        setPending(false);
        download(res);
      })
      .catch((err) => {
        setPending(false);
      });
  };

  return (
    <>
      <Flex gap={3}>
        <DownloadButton downloadCSV={downloadCSV} />

        <ViewSwitcher
          tabList={viewSwitchObj}
          currentTab={viewToggle}
          handleSwitch={setViewToggle}
        />
      </Flex>
      {pending && <Loader />}
    </>
  );
};

export default InfoTopBar;
