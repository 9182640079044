import {
  Box,
  Button,
  Flex,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import {
  Breadcrumbs,
  Loader,
  PageHeader,
  useToastHook,
} from '@devd-client/devd/components';
import { Select } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { BsAsterisk } from 'react-icons/bs';
import TeamDetails from './TeamDetails';
import Members from './Members';
import Workspace from './Workspace';
import Communication from './Communication';
import {
  useFetchPipelineProjects,
  useFetchTestSuites,
  useTeamMembers,
} from '../../apis';

import {
  QUERY_KEYS,
  useAddMembers,
  useDeleteMembers,
  useFetchAllBoardNames,
  useFetchAllGitProjects,
  useFetchAllIncidentManagement,
  useFetchAllIssueEpic,
  useFetchAllJiraProjects,
  useFetchDoraSettings,
  useFetchParentTeams,
  useFetchQualityProjects,
  useFetchTeamData,
  usePostTeam,
  useUpdateTeam,
} from '../../apis';
import { useLocation, useNavigate } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import AddMemberModal from '../AddMemberModal';
import BulkUpload from '../AddMemberModal/BulkUpload';

export const TeamConfiguration = () => {
  const [activeTab, setActiveTab] = useState(0);

  const { colors } = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [newToast] = useToastHook();
  const location = useLocation();
  const state = location.state;

  const team = pathname.split('/')[3];

  const { mutate: handleAddTeam, isLoading: handleAddTeamLoading } =
    usePostTeam();

  const { mutate: handleUpdateTeam, isLoading: handleUpdateTeamLoading } =
    useUpdateTeam();

  const { data: parentTeams, isLoading: parentTeamLoading } =
    useFetchParentTeams(team);

  const { data: gitRepo, isLoading: gitRepoLoading } = useFetchAllGitProjects();
  const { data: jiraRepo, isLoading: jiraRepoLoading } =
    useFetchAllJiraProjects();
  const { data: issueEpic, isLoading: issueEpicLoading } =
    useFetchAllIssueEpic();
  const { data: qualityRepo, isLoading: qualityRepoLoading } =
    useFetchQualityProjects();

  const { data: incidentManagement, isLoading: incidentManagementLoading } =
    useFetchAllIncidentManagement();

  const { data: doraSettings, isLoading: doraSettingsLoading } =
    useFetchDoraSettings();

  const { data: response, isFetching: teamDetailsLoading } =
    useFetchTeamData(team);
  const { data: boardNames, isLoading: boardNamesLoading } =
    useFetchAllBoardNames();

  const { data: pipelines, isLoading: pipePipelinesLoading } =
    useFetchPipelineProjects();

  const { data: testSuite, isLoading: testSuiteLoading } = useFetchTestSuites();

  const defaultData = response?.dto;

  const [days, setDays] = useState([
    false,
    true,
    true,
    true,
    true,
    true,
    false,
  ]);

  const [parentTeam, setParentTeam] = useState({ value: '', label: '' });

  const [teamName, setTeamName] = useState<string>('');
  const [issueDone, setIssueDone] = useState<boolean>(false);
  const [issueInProgress, setIssueInProgress] = useState<boolean>(false);
  const [prMerged, setPrMerged] = useState<boolean>(false);
  const [goalExceptions, setGoalExceptions] = useState<boolean>(false);

  const [teamDescription, setTeamDescription] = useState<string>('');
  const [slackChannel, setSlackChannel] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [digest, setDigest] = useState<boolean>(false);

  const [tz, setTz] = useState(
    defaultData?.workTiming?.timeZone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [startTime, setStartTime] = useState('09:00');
  const [endTime, setEndTime] = useState('18:00');
  const [notificationTime, setNotificationTime] = useState('');
  const [selectedBoardNames, setSelectedBoardNames] = useState<any>([]);
  const [issueFilterConditions, setIssueFilterConditions] = useState<any>({
    conditions: null,
  });

  const [cfTeams, setCfTeams] = useState<any>('');
  const [cfLabels, setCfLabels] = useState<any>('');
  const [cfProjects, setCfProjects] = useState<any>([]);
  const [cfEpics, setCfEpics] = useState<any>([]);
  const [cfServicesList, setCfServicesList] = useState<any>([]);
  const [cfCustomField, setCfCustomField] = useState<any>('');
  const [cfRepos, setCfRepos] = useState<any>([]);

  const [deploymentLabels, setDeploymentLabels] = useState<any>('');
  const [deploymentPattern, setDeploymentPattern] = useState<any>('');
  const [deploymentProjects, setDeploymentProjects] = useState<any>([]);
  const [deploymentRepos, setDeploymentRepos] = useState<any>([]);
  const [deploymentCustomField, setDeploymentCustomField] = useState<any>('');
  const [workspaceRepo, setWorkspaceRepo] = useState<any>([]);
  const [workspaceIssue, setWorkspaceIssue] = useState<any>([]);
  const [qualityProjects, setQualityProjects] = useState<any>([]);
  const [cicdPipelines, setCicdPipelines] = useState<any>([]);
  const [testSuiteProjects, setTestSuiteProjects] = useState<any>([]);

  const [filterDataByRepo, setFilterDataByRepo] = useState<boolean>(false);
  const [filterDataByIssueTracking, setFilterDataByIssueTracking] =
    useState<boolean>(false);

  const addMemberModal = useDisclosure();
  const [addMemberModalOpenedFor, setAddMemberModalOpenedFor] = useState('');
  const [initialRender, setInitialRender] = useState(false);
  const bulkUploadModal = useDisclosure();
  const queryClient = useQueryClient();
  const [selectedMember, setSelectedMember] = useState<string[]>([]);
  const { mutate: handleDeleteMember, isLoading: deleteMemberLoading } =
    useDeleteMembers();

  const { mutate: handleAddMember, isLoading: addMemberLoading } =
    useAddMembers();

  const handleFormSubmit = () => {
    handleAddMember(
      {
        teamName: decodeURIComponent(team),
        members: selectedMember.map((member: any) => {
          return {
            employeeId: member.employeeId,
            email: member.email,
            role: addMemberModalOpenedFor || member.teamRole,
          };
        }),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEYS.teamMembers]);
          newToast({
            message: `${
              selectedMember.length > 1
                ? addMemberModalOpenedFor === 'MANAGER'
                  ? 'Managers'
                  : 'Members'
                : addMemberModalOpenedFor === 'MANAGER'
                ? 'Manager'
                : 'Member'
            } added successfully!`,
            status: 'success',
          });
          handleTabChange(1);
          setInitialRender(false);
          addMemberModal.onClose();
        },
      }
    );
  };

  useEffect(() => {
    if (state?.tab !== undefined && !defaultData?.key?.name) return;
    handleTabChange(state?.tab || 0);
    if (state?.tab === 1 && state?.initialState) {
      addMemberModal.onClose();
    } else if (state?.tab === 1) {
      addMemberModal.onOpen();
    }
  }, [state, defaultData?.key?.name]);

  useEffect(() => {
    setCfLabels(defaultData?.toolsDefinition?.changeFailure?.labels);
    setCfCustomField(
      defaultData?.toolsDefinition?.changeFailure?.customField?.value
    );
    setDeploymentCustomField(
      defaultData?.toolsDefinition?.deployment?.customField?.value
    );
    setCfProjects(
      defaultData?.toolsDefinition?.changeFailure?.projects?.map(
        (repo: any) => ({
          value: repo.name,
          label: repo.name,
        })
      )
    );
    setCfEpics(
      defaultData?.toolsDefinition?.changeFailure?.terms?.map((repo: any) => ({
        value: repo,
        label: repo,
      }))
    );
    setCfRepos(
      defaultData?.toolsDefinition?.changeFailure?.repos?.map((repo: any) => ({
        value: repo.name,
        label: repo.name,
      }))
    );

    setCfServicesList(
      defaultData?.toolsDefinition?.changeFailure?.servicesList?.map(
        (repo: any) => ({
          value: repo.name,
          label: repo.name,
        })
      )
    );

    setDeploymentLabels(defaultData?.toolsDefinition?.deployment?.labels);
    setDeploymentPattern(defaultData?.toolsDefinition?.deployment?.pattern);
    setDeploymentProjects(
      defaultData?.toolsDefinition?.deployment?.projects?.map((repo: any) => ({
        value: repo.name,
        label: repo.name,
      }))
    );

    setDeploymentRepos(
      defaultData?.toolsDefinition?.deployment?.repos?.map((repo: any) => ({
        value: repo.name,
        label: repo.name,
        url: repo.url,
        key: repo.key,
      }))
    );

    setSelectedBoardNames(
      defaultData?.workspaces?.issueBoards?.map((repo: any) => ({
        value: repo.name,
        label: repo.name,
      }))
    );

    setWorkspaceRepo(
      defaultData?.workspaces?.repository?.map((repo: any) => ({
        value: repo.name,
        label: repo.name,
        url: repo.url,
        key: repo.key,
      }))
    );

    setQualityProjects(
      defaultData?.workspaces?.qualityProjects?.map((repo: any) => ({
        value: repo.name,
        label: repo.name,
      }))
    );

    setCicdPipelines(
      defaultData?.workspaces?.pipelines?.map((pipeline: any) => ({
        value: pipeline.name,
        label: pipeline.name,
        url: pipeline.url,
        key: pipeline.key,
      }))
    );

    setTestSuiteProjects(
      defaultData?.workspaces?.testSuites?.map((testSuite: any) => ({
        value: testSuite.name,
        label: testSuite.name,
        url: testSuite.url,
        key: testSuite.key,
      }))
    );

    setWorkspaceIssue(
      defaultData?.workspaces?.issueTracking?.map((repo: any) => ({
        value: repo.name,
        label: repo.name,
      }))
    );

    setIssueFilterConditions(defaultData?.workspaces?.issueFilterConditions);
  }, [defaultData]);

  useEffect(() => {
    if (workspaceRepo?.length === 0) setFilterDataByRepo(false);

    if (workspaceIssue?.length === 0) setFilterDataByIssueTracking(false);
  }, [workspaceRepo, workspaceIssue]);

  useEffect(() => {
    if (!defaultData) return;

    setParentTeam({
      value: defaultData?.parentTeam,
      label: defaultData?.parentTeam,
    });

    setDays(
      defaultData?.workTiming?.days || [
        false,
        true,
        true,
        true,
        true,
        true,
        false,
      ]
    );
    setTeamName(defaultData?.key?.name || '');
    setTeamDescription(defaultData?.description || '');
    setIssueDone(defaultData?.notification?.digest?.issueDone || false);
    setIssueInProgress(
      defaultData?.notification?.digest?.issueInProgress || false
    );
    setPrMerged(defaultData?.notification?.digest?.prMerged || false);
    setGoalExceptions(
      defaultData?.notification?.digest?.goalExceptions || false
    );
    setEmail(defaultData?.notification?.email || '');
    setSlackChannel(defaultData?.notification?.slack || '');

    setDigest(defaultData?.notification?.digest?.enable || false);
    setTz(
      defaultData?.workTiming?.timeZone ||
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    setStartTime(defaultData?.workTiming?.startTime || '09:00');
    setEndTime(defaultData?.workTiming?.endTime || '18:00');
    setNotificationTime(defaultData?.notification?.time || '');

    setFilterDataByRepo(defaultData?.workspaces?.filterDataByRepo || false);
    setFilterDataByIssueTracking(
      defaultData?.workspaces?.filterDataByIssueTracking || false
    );
  }, [defaultData]);

  const [triggerSubmit, setTriggerSubmit] = useState<boolean>(false);
  const { data, isFetching } = useTeamMembers(defaultData?.key?.name);
  const [isNewTeam, setIsNewTeam] = useState<boolean | null>(false);

  useEffect(() => {
    if (!isFetching && defaultData && data?.dto?.length === 0) {
      handleTabChange(1);
    }
  }, [isFetching]);

  useEffect(() => {
    if (triggerSubmit) {
      handleSubmit();
    }
  }, [triggerSubmit]);

  const handleSubmit = () => {
    const payload = {
      key: {
        orgId: localStorage.getItem('orgId'),
        name: teamName.trim(),
      },
      parentTeam: parentTeam.value,
      contacts: {
        slack: slackChannel,
        email: email,
      },
      owners: null,
      notification: {
        slack: slackChannel,
        email: email,
        time: notificationTime,
        digest: {
          enable: digest,
          issueDone: issueDone,
          bugsOpen: null,
          bugsDone: null,
          prMerged: prMerged,
          issueInProgress: issueInProgress,
          issueInQa: null,
          goalExceptions: goalExceptions,
        },
      },
      toolsDefinition: {
        changeFailure: {
          teams: '',
          customField: {
            value: cfCustomField,
          },
          labels: cfLabels,
          projects: cfProjects
            ? jiraRepo?.dto?.filter((item: any) =>
                cfProjects.some((el: any) => el.value === item.name)
              )
            : [],
          terms: cfEpics
            ? issueEpic?.dto?.filter((item: any) =>
                cfEpics.some((el: any) => el.value === item)
              )
            : [],
          servicesList: cfServicesList
            ? incidentManagement?.dto?.filter((item: any) =>
                cfServicesList.some((el: any) => el.value === item.name)
              )
            : [],
          repos: cfRepos
            ? gitRepo?.dto?.filter((item: any) =>
                cfRepos.some((el: any) => el.value === item.name)
              )
            : [],
        },
        deployment: {
          labels: deploymentLabels,
          pattern: deploymentPattern,
          projects: deploymentProjects
            ? jiraRepo?.dto?.filter((item: any) =>
                deploymentProjects.some((el: any) => el.value === item.name)
              )
            : [],
          repos: deploymentRepos
            ? gitRepo?.dto?.filter((item: any) =>
                deploymentRepos.some((el: any) => el.key === item.key)
              )
            : [],
          customField: {
            value: deploymentCustomField,
          },
        },
      },

      workspaces: {
        issueBoards: selectedBoardNames
          ? boardNames?.dto
              ?.filter((item: any) =>
                selectedBoardNames.some((el: any) => el.value === item.name)
              )
              ?.map((board: any) => ({
                name: board.name,
                key: board.id,
                url: null,
                project: board.key,
              }))
          : [],

        repository: workspaceRepo
          ? gitRepo?.dto?.filter((item: any) =>
              workspaceRepo.some((el: any) => el.key === item.key)
            )
          : [],

        issueTracking: workspaceIssue
          ? jiraRepo?.dto?.filter((item: any) =>
              workspaceIssue.some((el: any) => el.value === item.name)
            )
          : [],

        qualityProjects: qualityProjects
          ? qualityRepo?.dto?.filter((item: any) =>
              qualityProjects.some((el: any) => el.value === item.name)
            )
          : [],
        pipelines: cicdPipelines
          ? pipelines?.dto?.filter((item: any) =>
              cicdPipelines.some((el: any) => el.value === item.name)
            )
          : [],

        testSuites: testSuiteProjects
          ? testSuite?.dto?.filter((item: any) =>
              testSuiteProjects.some((el: any) => el.value === item.name)
            )
          : [],

        filterDataByRepo: workspaceRepo?.length > 0 ? true : filterDataByRepo,
        filterDataByIssueTracking,
        issueFilterConditions: issueFilterConditions,
      },
      workTiming: {
        startTime: startTime,
        endTime: endTime,
        timeZone: tz,
        days: days,
      },
      description: teamDescription,
      contributors: 0,
      digestEnabled: digest,
    };
    if (!teamName) {
      setTriggerSubmit(false);
      newToast({
        message: 'Team name is mendatory',
        status: 'error',
      });
    } else {
      if (isNewTeam) {
        handleAddTeam(payload, {
          onSuccess: () => {
            queryClient.invalidateQueries([QUERY_KEYS.teams]);
            setTriggerSubmit(false);
            newToast({
              message: defaultData
                ? 'Team has been updated successfully'
                : 'Team has been added successfully',
              status: 'success',
            });
            setTimeout(() => {
              navigate(`/settings/teams/${teamName.trim()}`);
            }, 1000);
          },

          onError: (err: any) => {
            setTriggerSubmit(false);
            newToast({
              message: err.message ? err.message : 'Something went wrong',
              status: 'error',
            });
          },
        });
      } else {
        handleUpdateTeam(payload, {
          onSuccess: () => {
            setTriggerSubmit(false);
            newToast({
              message: defaultData
                ? 'Team has been updated successfully'
                : 'Team has been added successfully',
              status: 'success',
            });
            queryClient.invalidateQueries([QUERY_KEYS.teamDetails]);
            setTimeout(() => {
              navigate(`/settings/teams/${teamName.trim()}`);
            }, 1000);
          },

          onError: (err: any) => {
            setTriggerSubmit(false);
            newToast({
              message: err.message ? err.message : 'Something went wrong',
              status: 'error',
            });
          },
        });
      }
    }
  };

  const tabList = [
    {
      tabIndex: 0,
      name: 'Team Details',
      panel: (
        <TeamDetails
          setIsNewTeam={setIsNewTeam}
          teamName={teamName}
          setTeamName={setTeamName}
          teamDescription={teamDescription}
          setTeamDescription={setTeamDescription}
          parentTeam={parentTeam}
          setParentTeam={setParentTeam}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          tz={tz}
          setTz={setTz}
          days={days}
          setDays={setDays}
          defaultData={defaultData}
          parentTeams={parentTeams}
          handleSubmit={handleSubmit}
        />
      ),
    },
    {
      tabIndex: 1,
      name: 'Members',
      panel: (
        <Members
          team={defaultData?.key?.name}
          addMemberModal={addMemberModal}
          setAddMemberModalOpenedFor={setAddMemberModalOpenedFor}
          setInitialRender={setInitialRender}
        />
      ),
    },
    {
      tabIndex: 2,
      name: 'Additional Filters',
      panel: (
        <Workspace
          setTriggerSubmit={setTriggerSubmit}
          gitRepo={gitRepo}
          jiraRepo={jiraRepo}
          issueEpic={issueEpic}
          qualityRepo={qualityRepo}
          pipelines={pipelines}
          testSuite={testSuite}
          incidentManagement={incidentManagement}
          doraSettings={doraSettings}
          cfLabels={cfLabels}
          setCfLabels={setCfLabels}
          cfProjects={cfProjects}
          setCfProjects={setCfProjects}
          cfEpics={cfEpics}
          setCfEpics={setCfEpics}
          cfServicesList={cfServicesList}
          setCfServicesList={setCfServicesList}
          cfCustomField={cfCustomField}
          setCfCustomField={setCfCustomField}
          cfRepos={cfRepos}
          setCfRepos={setCfRepos}
          deploymentLabels={deploymentLabels}
          setDeploymentLabels={setDeploymentLabels}
          deploymentPattern={deploymentPattern}
          setDeploymentPattern={setDeploymentPattern}
          deploymentProjects={deploymentProjects}
          setDeploymentProjects={setDeploymentProjects}
          deploymentRepos={deploymentRepos}
          setDeploymentRepos={setDeploymentRepos}
          deploymentCustomField={deploymentCustomField}
          setDeploymentCustomField={setDeploymentCustomField}
          workspaceRepo={workspaceRepo}
          setWorkspaceRepo={setWorkspaceRepo}
          workspaceIssue={workspaceIssue}
          setWorkspaceIssue={setWorkspaceIssue}
          qualityProjects={qualityProjects}
          setQualityProjects={setQualityProjects}
          cicdPipelines={cicdPipelines}
          setCicdPipelines={setCicdPipelines}
          testSuiteProjects={testSuiteProjects}
          setTestSuiteProjects={setTestSuiteProjects}
          filterDataByRepo={filterDataByRepo}
          setFilterDataByRepo={setFilterDataByRepo}
          filterDataByIssueTracking={filterDataByIssueTracking}
          setFilterDataByIssueTracking={setFilterDataByIssueTracking}
          handleSubmit={handleSubmit}
          defaultData={defaultData}
          loading={
            gitRepoLoading ||
            jiraRepoLoading ||
            issueEpicLoading ||
            qualityRepoLoading ||
            incidentManagementLoading ||
            doraSettingsLoading ||
            boardNamesLoading ||
            pipePipelinesLoading ||
            testSuiteLoading
          }
        />
      ),
    },
    {
      tabIndex: 3,
      name: 'Communication',
      panel: (
        <Communication
          defaultData={defaultData}
          email={email}
          setEmail={setEmail}
          slackChannel={slackChannel}
          setSlackChannel={setSlackChannel}
          digest={digest}
          setDigest={setDigest}
          notificationTime={notificationTime}
          setNotificationTime={setNotificationTime}
          issueDone={issueDone}
          setIssueDone={setIssueDone}
          prMerged={prMerged}
          setPrMerged={setPrMerged}
          issueInProgress={issueInProgress}
          setIssueInProgress={setIssueInProgress}
          goalExceptions={goalExceptions}
          setGoalExceptions={setGoalExceptions}
          handleSubmit={handleSubmit}
        />
      ),
    },
  ];

  const handleTabChange = (index: number) => setActiveTab(index);

  return (
    <>
      <Stack w="full" spacing={2}>
        <Flex align="center" justify="space-between">
          <PageHeader
            headingText={
              defaultData?.key?.name
                ? `Update Team: ${defaultData.key.name}`
                : 'Create Team'
            }
          />
          {defaultData?.key?.name && (
            <Button
              size="sm"
              variant="primary"
              onClick={() => {
                addMemberModal.onOpen();
                setAddMemberModalOpenedFor('');
              }}
            >
              +Add Members
            </Button>
          )}
        </Flex>

        <Box pb={'35px'}>
          <Breadcrumbs
            parentTeam={defaultData?.parentTeam}
            originUrl="settings/teams"
          />
        </Box>

        <Tabs onChange={handleTabChange} index={activeTab}>
          {!parentTeamLoading && !teamDetailsLoading && (
            <>
              <TabList>
                {tabList.map((tab) => (
                  <Tab
                    isDisabled={tab.tabIndex !== 0 && !defaultData?.key?.name}
                    key={tab.tabIndex}
                    _selected={{
                      color: 'primary',
                      borderBottom: '2px solid',
                      borderBottomColor: 'primary',
                      fontWeight: 'medium',
                    }}
                    color="text.secondary"
                  >
                    <Text fontFamily="heading" fontWeight="normal">
                      {tab.name}
                    </Text>
                  </Tab>
                ))}
              </TabList>

              <TabPanels>
                {tabList.map((tab, index) => (
                  <TabPanel key={tab.tabIndex}>{tab.panel}</TabPanel>
                ))}
              </TabPanels>
            </>
          )}
        </Tabs>
      </Stack>
      {(parentTeamLoading || teamDetailsLoading) && <Loader />}
      {addMemberModal.isOpen && (
        <AddMemberModal
          isOpen={addMemberModal.isOpen}
          onClose={addMemberModal.onClose}
          bulkUploadModal={bulkUploadModal}
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
          handleFormSubmit={handleFormSubmit}
          addMemberLoading={addMemberLoading}
          addMemberModalOpenedFor={addMemberModalOpenedFor}
          team={team}
          initialRender={initialRender}
        />
      )}
      {bulkUploadModal.isOpen && (
        <BulkUpload
          isOpen={bulkUploadModal.isOpen}
          onClose={bulkUploadModal.onClose}
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
          handleFormSubmit={handleFormSubmit}
          addMemberLoading={addMemberLoading}
          team={team}
        />
      )}
    </>
  );
};

export default TeamConfiguration;
