import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import {
  PageHeader,
  MetaTags,
  PageToolbar,
  TeamMenu,
  DateFilter,
  AppTooltip,
} from '@devd-client/devd/components';
import { FC, useCallback, useState } from 'react';
import { AuthActionTypes, getUser } from '@devd-client/devd/auth';
import { useTestSuiteDashboard } from '../hooks/useTestSuiteDashboard';
import TestSuiteList from '../components/TestSuiteList';
import { useTestSuiteGql } from '../graphql';
import { Link } from 'react-router-dom';

export const TestSuite: FC = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const { selectedTeam, selected, setSelectedTeam, appState, dispatch } =
    useTestSuiteDashboard();

  const { data: testSuiteData, isFetching } = useTestSuiteGql(
    selectedTeam.value,
    selected.startDate,
    selected.endDate,
    selected.sprintId,
    currentPage,
    10,
    ''
  );

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );
  return (
    <>
      <MetaTags title="Test Suite | Dashboard | Devdynamics" />

      <Flex direction={'column'} width={'100%'}>
        {testSuiteData?.chartDataState === 'NO_INTEGRATION' ? (
          <Box>
            <PageHeader
              headingText={'Test Suite'}
              helpDocLink={
                'https://docs.devdynamics.ai/integrations/test-suite'
              }
              mb={'10px'}
            />
            <Flex mb={2} width="100%" justify="flex-end">
              <AppTooltip
                trigger="hover"
                content={
                  <Flex>
                    <Link
                      to={`${testSuiteData?.chartError?.link}`}
                      target="_blank"
                      rel="noopener"
                    >
                      <Text
                        color="primary"
                        fontFamily="heading"
                        fontSize="sm"
                        textDecoration={'underline'}
                      >
                        Configure
                      </Text>
                    </Link>
                    <Text
                      fontFamily="heading"
                      fontSize="sm"
                      color="text.primary"
                      ml={2}
                    >
                      {testSuiteData?.chartError?.message}
                    </Text>
                  </Flex>
                }
              >
                <Box>
                  <Button
                    size={'sm'}
                    color={'error'}
                    border="1px"
                    borderColor={'error'}
                    backgroundColor={'white'}
                    fontFamily="heading"
                  >
                    {'why no data?'}
                  </Button>
                </Box>
              </AppTooltip>
            </Flex>

            <Box
              w="full"
              height="400px"
              display="flex"
              justifyContent={'center'}
              alignItems="center"
              position="relative"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Image
                height="100%"
                src={testSuiteData?.zs || ''}
                style={{ position: 'relative' }}
              />

              {isHovered && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  backgroundColor="rgba(0, 0, 0, 0.4)"
                  borderRadius="4px"
                >
                  {testSuiteData?.chartError?.message && (
                    <Button
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      onClick={() =>
                        window.open(
                          'https://docs.devdynamics.ai/integrations/test-suite',
                          '_blank'
                        )
                      }
                    >
                      Connect{' '}
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box>
            <PageHeader
              headingText={'Test Suite'}
              helpDocLink={
                'https://docs.devdynamics.ai/integrations/test-suite'
              }
              mb={'10px'}
            />
            <PageToolbar
              leftComp={
                <Flex align="flex-end">
                  <TeamMenu
                    selectedTeam={selectedTeam}
                    handleSelectedTeam={setSelectedTeam}
                    AuthActionTypes={AuthActionTypes}
                    getUser={getUser}
                    appState={appState}
                    dispatch={dispatch}
                  />
                </Flex>
              }
              rightComp={
                <DateFilter
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                  AuthActionTypes={AuthActionTypes}
                />
              }
            />

            <TestSuiteList
              testSuiteData={testSuiteData}
              loading={isFetching}
              handlePageClick={handlePageClick}
            />
          </Box>
        )}
      </Flex>
    </>
  );
};

export default TestSuite;
