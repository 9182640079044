import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListIcon,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import {
  PageHeader,
  MetaTags,
  PageToolbar,
  TeamMenu,
  ViewSwitcher,
  Loader,
  AppTooltip,
  SlideBar,
  DateFilter,
} from '@devd-client/devd/components';
import { FC } from 'react';
import SprintList from '../components/SprintList';
import { AuthActionTypes, getUser } from '@devd-client/devd/auth';
import { Link, useNavigate } from 'react-router-dom';
import { MdCheckCircle } from 'react-icons/md';
import { ChakraStylesConfig, Select } from 'chakra-react-select';
import { ticketTypeFilterOptions } from '../helpers/home.utils';
import { SearchIcon } from '@chakra-ui/icons';
import { useSprintDashboard } from '../hooks/useSprintDashboard';

const filterOptions = [
  { value: 'team', label: 'Team' },
  { value: 'project', label: 'Project' },
];

const chakraStyles: ChakraStylesConfig = {
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 9999,
    };
  },
};

export const Sprint: FC = () => {
  const navigate = useNavigate();
  const {
    data,
    isHovered,
    setIsHovered,
    ticketType,
    setTicketType,
    filterBy,
    setFilterBy,
    selectedTeam,
    setSelectedTeam,
    appState,
    dispatch,
    repos,
    setRepos,
    repoList,
    popoverRef,
    isPopoverOpen,
    searchTerm,
    filteredSprints,
    handleSearchChange,
    setIsPopoverOpen,
    handleSprintSelect,
    formatDate,
    sprintIds,
    setSprintIds,
    viewToggle,
    viewSwitchObj,
    setViewToggle,
    status,
    setStatus,
    currentPage,
    setCurrentPage,
    sprintListLoading,
    slideDrawer,
    blockedIssues,
    setBlockedIssues,
  } = useSprintDashboard();

  return (
    <>
      <MetaTags title="Sprint | Dashboard | Devdynamics" />

      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        {data?.chartDataState === 'NO_INTEGRATION' ? (
          <Box>
            <PageHeader headingText={'Sprint'} mb={'10px'} />
            <Flex mb={2} width="100%" justify="flex-end">
              <AppTooltip
                trigger="hover"
                content={
                  <Flex>
                    <Link to={`${data?.chartError?.link}`}>
                      <Text
                        color="primary"
                        fontFamily="heading"
                        fontSize="sm"
                        textDecoration={'underline'}
                      >
                        Configure
                      </Text>
                    </Link>
                    <Text
                      fontFamily="heading"
                      fontSize="sm"
                      color="text.primary"
                      ml={2}
                    >
                      {data?.chartError?.message}
                    </Text>
                  </Flex>
                }
              >
                <Box>
                  <Button
                    size={'sm'}
                    color={'error'}
                    border="1px"
                    borderColor={'error'}
                    backgroundColor={'white'}
                    fontFamily="heading"
                  >
                    {'why no data?'}
                  </Button>
                </Box>
              </AppTooltip>
            </Flex>

            <Box
              w="full"
              height="400px"
              display="flex"
              justifyContent={'center'}
              alignItems="center"
              position="relative"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Image
                height="100%"
                src={data?.zs || ''}
                style={{ position: 'relative' }}
              />

              {isHovered && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  backgroundColor="rgba(0, 0, 0, 0.4)"
                  borderRadius="4px"
                >
                  {data?.chartError?.message && (
                    <Button
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      onClick={() => navigate('/settings/integrations')}
                    >
                      Connect{' '}
                      {data?.chartError?.message === 'ISSUE TRACKING'
                        ? 'Jira'
                        : data?.chartError?.message === 'REPOSITORY'
                        ? 'Github'
                        : ''}
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box>
            <PageHeader headingText={'Sprint'} mb={'10px'} />
            <PageToolbar
              leftComp={
                <>
                  <Box mr={3}>
                    <Text
                      fontSize="sm"
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="text.secondary"
                      lineHeight="17px"
                      mb={2}
                    >
                      Ticket Type
                    </Text>
                    <Select
                      placeholder="Select Ticket Type"
                      isMulti={true}
                      useBasicStyles
                      chakraStyles={chakraStyles}
                      onChange={(e: any) => {
                        setTicketType(e);
                      }}
                      size="sm"
                      value={ticketType}
                      options={ticketTypeFilterOptions}
                    />
                  </Box>

                  <Box mr={3}>
                    <Text
                      fontSize="sm"
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="text.secondary"
                      lineHeight="17px"
                      mb={2}
                    >
                      Filter By
                    </Text>
                    <Select
                      placeholder="Select"
                      useBasicStyles
                      chakraStyles={chakraStyles}
                      onChange={(e: any) => {
                        setFilterBy(e);
                      }}
                      size="sm"
                      value={filterBy}
                      options={filterOptions}
                    />
                  </Box>
                  {filterBy.value === 'team' && (
                    <TeamMenu
                      selectedTeam={selectedTeam}
                      handleSelectedTeam={setSelectedTeam}
                      AuthActionTypes={AuthActionTypes}
                      getUser={getUser}
                      appState={appState}
                      dispatch={dispatch}
                    />
                  )}

                  {filterBy.value === 'project' && (
                    <Box mr={3} maxWidth="800px">
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                        mb={2}
                      >
                        Project
                      </Text>
                      <Select
                        placeholder="Select Projects"
                        isMulti={true}
                        useBasicStyles
                        chakraStyles={{
                          ...chakraStyles,
                          container: (provided) => ({
                            ...provided,
                            maxWidth: '400px',
                          }),
                        }}
                        onChange={(e: any) => {
                          setRepos(e);
                        }}
                        size="sm"
                        value={repos}
                        options={repoList?.dto?.map((repo: any) => ({
                          value: repo.name,
                          label: repo.name,
                        }))}
                      />
                    </Box>
                  )}
                </>
              }
              rightComp={
                <Flex>
                  <Box mr={3} minW={'150px'} ref={popoverRef}>
                    <Popover
                      isOpen={
                        isPopoverOpen &&
                        searchTerm?.length > 0 &&
                        filteredSprints?.length > 0
                      }
                      autoFocus={false}
                      matchWidth
                    >
                      <PopoverTrigger>
                        <InputGroup size="sm">
                          <InputLeftElement
                            pointerEvents="none"
                            height={'32px'}
                          >
                            <SearchIcon color="gray.300" />
                          </InputLeftElement>
                          <Input
                            placeholder="Search sprint"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            onFocus={() => setIsPopoverOpen(true)}
                            pl="30px"
                            height="32px"
                          />
                        </InputGroup>
                      </PopoverTrigger>
                      <PopoverContent
                        maxHeight={'300px'}
                        maxWidth={'300px'}
                        w="238px"
                        overflow={'auto'}
                      >
                        <PopoverBody>
                          <Flex flexDirection={'column'}>
                            {filteredSprints.map((sprint: any) => (
                              <Box
                                key={sprint.id}
                                p={2}
                                _hover={{ bg: 'gray.100', cursor: 'pointer' }}
                                onClick={() => handleSprintSelect(sprint)}
                              >
                                <Text
                                  fontSize="14px"
                                  color={'#2A2A2F'}
                                  fontFamily={'inter'}
                                >
                                  {sprint.sprintName}
                                </Text>
                                <Text fontSize="10px" color="#626266">
                                  {formatDate(sprint.startDate)} -{' '}
                                  {formatDate(sprint.endDate)}
                                </Text>
                              </Box>
                            ))}
                          </Flex>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Box>
                  <DateFilter
                    getUser={getUser}
                    appState={appState}
                    dispatch={dispatch}
                    AuthActionTypes={AuthActionTypes}
                    sprintIds={sprintIds}
                    setSprintIds={setSprintIds}
                  />
                </Flex>
              }
            />

            <PageToolbar
              leftComp={
                <Flex align="flex-end" my={3}>
                  <Box>
                    <ViewSwitcher
                      tabList={viewSwitchObj}
                      currentTab={viewToggle}
                      handleSwitch={setViewToggle}
                    />
                  </Box>

                  <Box ml={2} border="1px solid #E2E8F0" borderRadius={'2px'}>
                    <Checkbox
                      style={{ padding: '7px' }}
                      size="md"
                      isChecked={status}
                      onChange={(e) => setStatus(e.target.checked)}
                    >
                      Completed
                    </Checkbox>
                  </Box>
                </Flex>
              }
            />

            <SprintList
              data={data}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              loading={sprintListLoading}
              team={selectedTeam.value}
              slideDrawer={slideDrawer}
              setBlockedIssues={setBlockedIssues}
            />

            {slideDrawer.isOpen && (
              <SlideBar
                openDrawer={slideDrawer.isOpen}
                handleDrawerClose={slideDrawer.onClose}
                width={'440px'}
                sideBarHeading={'Blocked Issues'}
                sideBarSubHeading=""
                content={
                  <>
                    <Box p="4">
                      <List spacing={3}>
                        {blockedIssues.map((item: any) => (
                          <ListItem key={item}>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            {item}
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </>
                }
              />
            )}
          </Box>
        )}
      </Box>
      {sprintListLoading && <Loader />}
    </>
  );
};

export default Sprint;
