import { Box, Button, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import ListSelect from '../../ListSelect';
import { useFetchAllJiraProjects } from '../api';
import { InfoIcon } from '../../icons';

interface ProjectNameSelectProps {
  criteriaField: any;
  issueIndex: number;
  criteriaIndex: number;
  handleChangeCriteriaField: any;
}

const ProjectNameSelect = ({
  criteriaField,
  issueIndex,
  criteriaIndex,
  handleChangeCriteriaField,
}: ProjectNameSelectProps) => {
  const listSelectModal = useDisclosure();
  const { data: issueProjects } = useFetchAllJiraProjects();
  const [triggerSubmit, setTriggerSubmit] = useState(false);

  const getLengthOfItem = (el: any) => {
    const selectedValues = el?.split(',') || [];
    const matchedItems = issueProjects?.dto?.filter((item: any) =>
      selectedValues.includes(item.name)
    );
    return matchedItems?.length || 0;
  };
  return (
    <>
      <Box mt={'-20px'} ml={'10px'} minWidth="150px">
        <Box display="flex" alignItems="center" gap={1}>
          <Text
            fontFamily={'heading'}
            fontSize="sm"
            fontWeight="semibold"
            color="text.secondary"
          >
            {getLengthOfItem(criteriaField.value)} Projects Linked
          </Text>
          <Tooltip
            label={criteriaField?.value?.split(',').filter(Boolean).join(', ')}
            hasArrow
            placement="top"
          >
            <Box as="span" display="inline-block">
              <InfoIcon />
            </Box>
          </Tooltip>
        </Box>
        <Box>
          <Button variant="primary" size="md" onClick={listSelectModal.onOpen}>
            Select Projects
          </Button>
        </Box>
      </Box>

      {listSelectModal.isOpen && (
        <ListSelect
          isOpen={listSelectModal.isOpen}
          onClose={listSelectModal.onClose}
          loading={false}
          initiallySelectedRows={criteriaField.value
            ?.split(',')
            .map((item: any) => ({
              label: item,
              value: item,
            }))}
          onSelectedRowsChange={(e: any) =>
            handleChangeCriteriaField(
              issueIndex,
              criteriaIndex,
              'value',
              e?.map((el: any) => el.label)?.join(',')
            )
          }
          data={issueProjects?.dto?.map((item: any) => ({
            value: item.name,
            label: item.name,
          }))}
          label={'Issue Projects'}
          setTriggerSubmit={setTriggerSubmit}
        />
      )}
    </>
  );
};

export default ProjectNameSelect;
