import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  Checkbox,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useTheme,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import {
  eliminateHtmlTags,
  Loader,
  useToastHook,
} from '@devd-client/devd/components';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import styled from 'styled-components';
import { QUERY_KEYS, useGoalSettingsMutation } from '../../../../../../api';
import { FieldType } from '../../../../../../helpers/team-details.types';

interface SettingsModalProps {
  handleModalClose: () => void;
  isOpen: boolean;
  selectedTeam: string;
  clickedGoal: any;
}

const TopContainer = styled.div``;

const BottomContainer = styled.div``;

const Divider = styled.div`
  height: 0.5px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-bottom: 20px;
`;

const InputLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 15px;
  margin-bottom: 4px;
`;

const BottomText = styled.div`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 17px;
  margin-bottom: 16px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const SettingsModal = ({
  handleModalClose,
  isOpen,
  selectedTeam,
  clickedGoal,
}: SettingsModalProps) => {
  const { colors } = useTheme();

  const [newToast] = useToastHook();
  const queryClient = useQueryClient();
  const { mutate: handleGoalUpdate, isLoading } = useGoalSettingsMutation();

  const [dataToSend, setDataToSend] = useState<any>({});

  const [emailCheckbox, setEmailCheckbox] = useState<boolean>(
    clickedGoal?.alertConfig?.email
  );

  const [slackCheckbox, setSlackCheckbox] = useState<boolean>(
    clickedGoal?.alertConfig?.slack
  );

  useEffect(() => {
    setDataToSend({ ...clickedGoal, team: selectedTeam });
  }, [clickedGoal, selectedTeam]);

  const handleEmailCheckbox = () => {
    setEmailCheckbox((prev) => !prev);
    setDataToSend({
      ...dataToSend,
      alertConfig: {
        ...dataToSend.alertConfig,
        email: !emailCheckbox,
      },
    });
  };

  const handleSlackCheckbox = () => {
    setSlackCheckbox((prev) => !prev);
    setDataToSend({
      ...dataToSend,
      alertConfig: {
        ...dataToSend.alertConfig,
        slack: !slackCheckbox,
      },
    });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          handleModalClose();
        }}
        isCentered
        motionPreset="slideInBottom"
        size="lg"
      >
        <ModalOverlay />
        <ModalContent rounded="md">
          <ModalHeader>
            <Box
              as="h2"
              color="text.primary"
              fontFamily="heading"
              fontSize="md"
              lineHeight="20px"
              fontWeight="bold"
            >
              {'Settings'}
            </Box>
          </ModalHeader>
          <ModalCloseButton color="text.secondary" fontSize="xs" />

          <ModalBody display="flex" flexDirection="column">
            <TopContainer>
              {clickedGoal?.fields?.map((fieldItem: FieldType, idx: number) => (
                <InputGroup key={`field-${idx}`}>
                  <InputLabel>{fieldItem.field}</InputLabel>

                  {fieldItem.type === 'Number' ? (
                    <NumberInput
                      defaultValue={
                        Object.entries(clickedGoal.fieldsValue)
                          .filter(([k, v]) => k === fieldItem.key)
                          .map(([k, v]) => v)[0] as string
                      }
                      min={0}
                      onChange={(numberValue: string) =>
                        setDataToSend({
                          ...dataToSend,
                          fieldsValue: {
                            ...dataToSend.fieldsValue,
                            [fieldItem.key]: eliminateHtmlTags(numberValue),
                          },
                        })
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  ) : fieldItem.type === 'Text' ? (
                    <Input
                      defaultValue={
                        Object.entries(clickedGoal.fieldsValue)
                          .filter(([k, v]) => k === fieldItem.key)
                          .map(([k, v]) => v)[0] as string
                      }
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        const validKeys = /^[a-zA-Z_-]$/;
                        const specialKeys = [
                          'Backspace',
                          'Tab',
                          'ArrowLeft',
                          'ArrowRight',
                          'Delete',
                        ];

                        if (
                          !validKeys.test(e.key) &&
                          !specialKeys.includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e: any) =>
                        setDataToSend({
                          ...dataToSend,
                          fieldsValue: {
                            ...dataToSend.fieldsValue,
                            [fieldItem.key]: eliminateHtmlTags(e.target.value),
                          },
                        })
                      }
                    />
                  ) : (
                    <></>
                  )}
                </InputGroup>
              ))}
            </TopContainer>

            <Divider />

            <BottomContainer>
              <BottomText>Send me alerts on</BottomText>

              <CheckboxWrapper>
                <Checkbox
                  defaultChecked={emailCheckbox}
                  onChange={handleEmailCheckbox}
                  marginRight="9px"
                >
                  Email
                </Checkbox>
                <AiOutlineInfoCircle
                  color={colors.text.secondary}
                  fontSize="16px"
                />
              </CheckboxWrapper>

              <CheckboxWrapper>
                <Checkbox
                  defaultChecked={slackCheckbox}
                  onChange={handleSlackCheckbox}
                  marginRight="9px"
                >
                  Slack
                </Checkbox>
                <AiOutlineInfoCircle
                  color={colors.text.secondary}
                  fontSize="16px"
                />
              </CheckboxWrapper>
            </BottomContainer>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              variant="filled"
              onClick={() => {
                handleGoalUpdate(dataToSend, {
                  onSuccess: () => {
                    queryClient.invalidateQueries([QUERY_KEYS.goals]);
                    newToast({
                      message: `Agreement settings updated successfully.`,
                      status: 'success',
                    });

                    setTimeout(() => {
                      handleModalClose();
                    }, 500);
                  },
                });
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isLoading && <Loader />}
    </>
  );
};

export default React.memo(SettingsModal);
