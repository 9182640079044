import { useMetricsTableStore } from '@devd-client/devd/components';
import {
  useContributorDetailGql,
  useContributorDetails,
  useFetchRepo,
  useGetIssueTypePriorityOptions,
  useTeammenu,
} from '../../../apis';
import { useEffect, useMemo, useState } from 'react';
import { OptionBase } from 'chakra-react-select';
import { useLocation } from 'react-router';
import { GetOptionsType } from '../../../utils/metricTableTypes';

interface Option extends OptionBase {
  label: string;
  value: any;
}

// This hook helps in passing all types of options.
export function useOptions({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) {
  const metricFilterBy = useMetricsTableStore();
  const { pathname } = useLocation();
  const { data: teamData, isFetching: teamsLoading } = useTeammenu();
  const { data: issueOptions, isFetching: issueOptionsLoading } =
    useGetIssueTypePriorityOptions();
  const { data: repoList, isFetching: repoLoading } = useFetchRepo('git');
  const { data: contributorData, isLoading: contributorLoading } =
    useContributorDetailGql(
      startDate,
      endDate,
      '',
      metricFilterBy.teamId,
      null
    );

  const { data: contributorTeams } = useContributorDetails(
    ['contributor-profile-pr', 'contributor-profile-issue'].includes(
      metricFilterBy.componentOrigin ?? ''
    )
      ? pathname.split('/')[2]
      : ''
  );

  const [teamOption, setTeamOption] = useState<Option[]>([
    { label: '', value: '' },
  ]);

  // PR-TABLE OPTIONS BELOW

  const prTableOptions: Option[] = useMemo(() => {
    return [
      { value: '', label: 'All' },
      { value: 'repos', label: 'Repo' },
      { value: 'authors', label: 'Author' },
      { value: 'reviewers', label: 'Reviewer' },
      { value: 'pr_age', label: 'PR Age' },
      { value: 'prs_linked', label: 'PRs Linked To Issues' },
      { value: 'prs_unlinked', label: 'PRs Not Linked To Issues' },
      { value: 'prs_wo_comments', label: 'PRs w/o Comment' },
      { value: 'prIterations', label: 'PR Iterations' },
      { value: 'pr_size', label: 'PR Size' },
      { value: 'merged_with_review', label: 'Merged With Review' },
      { value: 'merged_wo_review', label: 'Merged w/o Review' },
      { value: 'long_pr_cycle', label: 'Long PR Cycle Time' },
      { value: 'prReviewResponsivenessType', label: 'Review Response' },
      { value: 'review_feedback', label: 'Review Feedback' },
      { value: 'prFeedbackResponsivenessType', label: 'Feedback Response' },
    ];
  }, []);

  useEffect(() => {
    if (teamData) {
      const option: Option[] = [{ label: 'Organisation', value: '' }];
      teamData?.dto?.forEach((team: any) => {
        option.push({
          value: team.name,
          label: team.name,
        });

        if (team.subTeams.length >= 1) {
          team.subTeams.forEach((item: any) => {
            option.push({
              value: `subTeam ${item.name}`,
              label: item.name,
            });
          });
        }
      });

      setTeamOption(option);
    }
  }, [teamData]);

  const repoOption: Option[] = useMemo(() => {
    return (
      repoList?.dto?.map((repo: any) => ({
        value: repo.id,
        label: repo.name,
      })) ?? [{ label: '', value: '' }]
    );
  }, [repoList?.dto]);

  const authorOption: Option[] = useMemo(() => {
    return (
      contributorData?.data?.map((person: any) => ({
        value: person.email,
        label: person.name,
      })) ?? [{ label: '', value: '' }]
    );
  }, [contributorData?.data]);

  const prSizeOption: Option[] = useMemo(() => {
    return [
      { value: '0-200', label: '0-200' },
      { value: '200-400', label: '200-400' },
      { value: '400-600', label: '400-600' },
      { value: '600-800', label: '600-800' },
      { value: '800', label: '800+' },
    ];
  }, []);
  const prIterationsOption: Option[] = useMemo(() => {
    return [
      { value: 'GOOD', label: 'Low' },
      { value: 'MODERATE', label: 'Moderate' },
      { value: 'HIGH', label: 'High' },
    ];
  }, []);
  const prAgeOption: Option[] = useMemo(() => {
    return [
      { value: 'MORE_THAN_1D', label: 'Age > 1D & < 3D' },
      { value: 'MORE_THAN_3D', label: 'Age > 3D & < 7D' },
      { value: 'MORE_THAN_7D', label: 'Age > 7D & < 14D' },
      { value: 'MORE_THAN_14D', label: 'Age > 14D & < 1M' },
      { value: 'ABOVE_7D', label: 'Age > 7D' },
      { value: 'ABOVE_1M', label: 'Age > 1M' },
    ];
  }, []);
  const responseOption: Option[] = useMemo(() => {
    return [
      { value: 'MORE_THAN_24HRS', label: 'More than 24HRS' },
      { value: 'LESS_THAN_24HRS', label: 'Less than 24HRS' },
    ];
  }, []);
  const feedbackOption: Option[] = useMemo(() => {
    return [
      { value: 'review_with_comment', label: 'With Comment' },
      { value: 'review_without_comment', label: 'Without Comment' },
    ];
  }, []);

  // ISSUE TABLE OPTIONS BELOW

  const issueTableOptions: Option[] = useMemo(() => {
    return [
      { value: '', label: 'All' },
      { value: 'issueType', label: 'Issue Type' },
      { value: 'issuePriority', label: 'Priority' },
      { value: 'issueDueDate', label: 'Due Date' },
      { value: 'isLongCycleTime', label: 'Issues With Long Cycle Time' },
      { value: 'isDelayed', label: 'Delayed Issues' },
      { value: 'inflow_outflow', label: 'Issue Throughput' },
      { value: 'inactiveIssues', label: 'Inactive Issues' },
      { value: 'backtrackIssues', label: 'Backtrack Issues' },
      { value: 'reopenedIssues', label: 'Reopened Issues' },
      { value: 'cycleTimeSpread', label: 'Cycle Time Spread ' },
      { value: 'issueStatus', label: 'Issue Status ' },
      { value: 'issueAge', label: 'Open Issue Age' },
      { value: 'changingRequirements', label: 'Changing Requirements' },
      { value: 'stage', label: 'Issue Stage' },
      { value: 'assignees', label: 'Assignees' },
      { value: 'currentAssignee', label: 'Current Assignee' },
    ];
  }, []);
  const issueTypeOptions: Option[] = useMemo(() => {
    return (
      issueOptions?.dto?.types?.map((type: any) => ({
        value: type,
        label: type,
      })) ?? [{ label: '', value: '' }]
    );
  }, [issueOptions?.dto?.types]);
  const priorityOptions: Option[] = useMemo(() => {
    const defaultOption = { value: 'false', label: 'No Priority Assigned' };

    const mappedOptions = issueOptions?.dto?.priorities?.map((type: any) => ({
      value: type,
      label: type,
    })) ?? [{ label: '', value: '' }];
    return [defaultOption, ...mappedOptions];
  }, [issueOptions?.dto?.priorities]);

  const issueStatusOptions: Option[] = useMemo(() => {
    return (
      issueOptions?.dto?.statuses?.map((type: any) => ({
        value: type,
        label: type,
      })) ?? [{ label: '', value: '' }]
    );
  }, [issueOptions?.dto?.priorities]);
  const dueDateOptions: Option[] = useMemo(() => {
    return [
      { value: 'false', label: 'No Due Date Assigned' },
      { value: 'Less than 3D', label: 'In 3 Days' },
      { value: 'Less than 7D', label: 'In 7 Days' },
      { value: 'Less than 15D', label: 'In 15 Days' },
      { value: 'Less than 1M', label: 'In 1 Month' },
      { value: 'More than 1 Month', label: 'More than 1 Month' },
    ];
  }, []);
  const inactiveOptions: Option[] = useMemo(() => {
    return [
      { value: 'More than 3 Days', label: 'Age > 3D & < 7D' },
      { value: 'More than 7 Days', label: 'Age > 7D & < 14D' },
      { value: 'More than 15 Days', label: 'Age > 15D & < 1M' },
      { value: 'Above 15D', label: 'Age > 15D' },
      { value: 'More than 1 Month', label: 'Age > 1M' },
    ];
  }, []);
  const reOpenIssuesOptions: Option[] = useMemo(() => {
    return [
      { value: 'all', label: 'All' },
      { value: 'task', label: 'Work Item Reopened' },
      { value: 'bug', label: 'Bugs Reopened' },
    ];
  }, []);
  const throughputOptions: Option[] = useMemo(() => {
    return [
      { value: 'inflow', label: 'Inflow' },
      { value: 'outflow', label: 'Outflow' },
    ];
  }, []);
  const cycleTimeSpreadOptions: Option[] = useMemo(() => {
    return [
      { value: 'Less than 7D', label: 'CT < 7D' },
      { value: 'More than 7 Days', label: 'CT > 7D & < 15D' },
      { value: 'More than 15 Days', label: 'CT > 15D & < 1M' },
      { value: 'Above 15D', label: 'CT > 15D' },
      { value: 'More than 1 Month', label: 'CT > 1M & < 3M' },
      { value: 'More than 3 Month', label: 'CT > 3M' },
    ];
  }, []);
  const openIssueAgeOptions: Option[] = useMemo(() => {
    return [
      { value: 'week', label: 'Less than 1 Week' },
      { value: 'month', label: 'Less than 1 Month' },
      { value: 'threeMonth', label: 'Less than 3 Months' },
      { value: 'moreThreeMonth', label: 'More than 3 Months' },
    ];
  }, []);

  const changingRequirementsOptions: Option[] = useMemo(() => {
    return [
      { value: 'false', label: 'Stable Issues' },
      { value: 'true', label: 'Unstable Issues' },
    ];
  }, []);

  const issueStageOptions: Option[] = useMemo(() => {
    return [
      { value: 'ideation', label: 'Ideation' },
      { value: 'prd', label: 'Product Requirement Document' },
      { value: 'uxDesign', label: 'UX Design' },
      { value: 'techDesign', label: 'Tech Design' },
      { value: 'development', label: 'Development' },
      { value: 'qa', label: 'Quality Assurance' },
      { value: 'blocked', label: 'Blocked' },
      { value: 'deployment', label: 'Deployment' },
    ];
  }, []);

  const getOptions = (type: GetOptionsType) => {
    switch (type) {
      // PR related

      case 'prTable':
        return metricFilterBy.componentOrigin === 'contributor-profile-pr'
          ? prTableOptions.filter((option: Option) =>
              ['Repo', 'Author', 'Reviewer'].includes(option.label)
            )
          : prTableOptions;
      case 'teams':
        return ['contributor-profile-pr', 'contributor-profile-issue'].includes(
          metricFilterBy.componentOrigin ?? ''
        )
          ? teamOption.filter(
              (option: Option) =>
                [...(contributorTeams?.dto?.teams ?? '')].includes(
                  option.label
                ) || option.label === 'Organisation'
            )
          : teamOption;
      case 'repos':
        return repoOption;
      case 'authors':
        return ['contributor-profile-pr', 'contributor-profile-issue'].includes(
          metricFilterBy.componentOrigin ?? ''
        )
          ? authorOption.filter(
              (person: any) =>
                person.value === metricFilterBy.author ||
                person.value === metricFilterBy.reviewer
            )
          : authorOption;
      case 'reviewers':
        return metricFilterBy.componentOrigin === 'contributor-profile-pr'
          ? authorOption.filter(
              (person: any) =>
                person.value === metricFilterBy.author ||
                person.value === metricFilterBy.reviewer
            )
          : authorOption;
      case 'pr_size':
        return prSizeOption;
      case 'prIterations':
        return prIterationsOption;
      case 'pr_age':
        return prAgeOption;
      case 'prReviewResponsivenessType':
        return responseOption;
      case 'prFeedbackResponsivenessType':
        return responseOption;
      case 'review_feedback':
        return feedbackOption;

      // issue related

      case 'issueTable':
        return metricFilterBy.componentOrigin === 'contributor-profile-issue'
          ? issueTableOptions.filter((option: Option) =>
              [
                'Issue Type',
                'Priority',
                'Due Date',
                'Issues Without due date',
                'Issue Stage',
                'Assignees',
                'Current Assignee',
              ].includes(option.label)
            )
          : issueTableOptions;
      case 'issueType':
        return issueTypeOptions;
      case 'issuePriority':
        return priorityOptions;
      case 'issueDueDate':
        return dueDateOptions;
      case 'inactiveIssues':
        return inactiveOptions;
      case 'reopenedIssues':
        return reOpenIssuesOptions;
      case 'inflow_outflow':
        return throughputOptions;
      case 'cycleTimeSpread':
        return cycleTimeSpreadOptions;
      case 'issueAge':
        return openIssueAgeOptions;
      case 'changingRequirements':
        return changingRequirementsOptions;
      case 'stage':
        return issueStageOptions;
      case 'issueStatus':
        return issueStatusOptions;
      case 'assignees':
        return ['contributor-profile-pr', 'contributor-profile-issue'].includes(
          metricFilterBy.componentOrigin ?? ''
        )
          ? authorOption.filter(
              (person: any) =>
                person.value === metricFilterBy.assignees?.[0] ||
                person.value === metricFilterBy.currentAssignee
            )
          : authorOption;
      case 'currentAssignee':
        return ['contributor-profile-pr', 'contributor-profile-issue'].includes(
          metricFilterBy.componentOrigin ?? ''
        )
          ? authorOption.filter(
              (person: any) =>
                person.value === metricFilterBy.assignees?.[0] ||
                person.value === metricFilterBy.currentAssignee
            )
          : authorOption;
      default:
        return [];
    }
  };
  return { getOptions, teamData, issueOptions, repoList, contributorData };
}

export default useOptions;
