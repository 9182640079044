import { Box, Button, SimpleGrid, Text } from '@chakra-ui/react';
import { AuthActionTypes, getUser } from '@devd-client/devd/auth';
import {
  MetricDetails,
  MetricsCard,
  PageHeader,
  PageToolbar,
  TeamMenu,
  DateFilter,
  MetaTags,
} from '@devd-client/devd/components';

import { FC } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTestSuiteDashboard } from '../hooks/useTestSuiteDashboard';

export const TestSuiteDetails: FC = () => {
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const metricType = searchParams.get('type');

  const { selectedTeam, selected, setSelectedTeam, appState, dispatch } =
    useTestSuiteDashboard();
  const navigate = useNavigate();

  return (
    <>
      <MetaTags title="Test Suite Details | Dashboard | Devdynamics" />
      {!metricType ? (
        <Box display={'flex'} flexDirection={'column'} width={'100%'}>
          <Box>
            <Button
              onClick={() => {
                if (window.history.length > 2) {
                  navigate(-1);
                } else {
                  navigate('/test-suite');
                }
              }}
              size="sm"
              variant="link"
            >
              Back
            </Button>
            <PageHeader headingText={'Test Suite Details'} mb={'4px'} />
            <Text
              my={2}
              fontFamily="heading"
              fontSize="lg"
              color="text.secondary"
              fontWeight={'semibold'}
            >
              Test Suite:{' '}
              {decodeURIComponent(location.pathname.split('/').pop() as string)}
            </Text>
            <PageToolbar
              leftComp={
                <TeamMenu
                  selectedTeam={selectedTeam}
                  handleSelectedTeam={setSelectedTeam}
                  AuthActionTypes={AuthActionTypes}
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                />
              }
              rightComp={
                <DateFilter
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                  AuthActionTypes={AuthActionTypes}
                />
              }
            />
          </Box>

          <SimpleGrid columns={{ base: 2, '2xl': 2 }} spacing={6} mt={6}>
            {[
              'TEST_RUN_COUNT',
              'TEST_RUN_TIME',
              'TEST_COUNT',
              'TEST_SUCCESS_RATE',
            ].map((type: string) => (
              <MetricsCard
                key={type}
                metricType={type}
                selected={selected}
                selectedTeam={selectedTeam.value}
                ticketType={decodeURIComponent(
                  searchParams.get('id') as string
                )}
                hideBtn={true}
                // queryKey={QUERY_KEYS.doraPage}
              />
            ))}
          </SimpleGrid>
        </Box>
      ) : (
        <MetricDetails />
      )}
    </>
  );
};

export default TestSuiteDetails;
