import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { getIcon } from '../shared/SharedComponents';

type Props = {
  accounts: any;
};

export const ContributorsAccounts = ({ accounts }: Props) => {
  return (
    <Flex gap={2}>
      {accounts
        ?.reduce((acc: any, item: any) => {
          const existingEntry = acc.find(
            (entry: any) => entry?.type === item?.type
          );

          if (existingEntry) {
            existingEntry?.accountIds?.push(item?.accountId);
          } else {
            acc.push({
              type: item?.type,
              accountIds: [item?.accountId],
            });
          }

          return acc;
        }, [])
        ?.map((entry: any, index: number) => (
          <Tooltip
            key={index}
            label={
              <div>
                <strong>
                  {entry?.type?.charAt(0)?.toUpperCase() +
                    entry?.type?.slice(1)}
                </strong>{' '}
                Account IDs:
                <br />
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                  {entry?.accountIds?.map(
                    (accountId: any, accountIdIndex: any) => (
                      <li key={accountIdIndex} style={{ margin: '4px 0' }}>
                        {accountId}
                      </li>
                    )
                  )}
                </ul>
              </div>
            }
            aria-label="Account IDs"
            hasArrow
            borderRadius="md"
            px={2}
            py={1}
            maxW="600px"
          >
            <Box height={'14px'} width={'14px'}>
              {getIcon(entry?.type)}
            </Box>
          </Tooltip>
        ))}
    </Flex>
  );
};
