export const EraserIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9998 8.66352L7.33311 3.99685M13.9998 13.9968H5.33311M7.29128 13.372L13.0704 7.59292C13.8624 6.80089 14.2584 6.40487 14.4068 5.94822C14.5373 5.54653 14.5373 5.11384 14.4068 4.71215C14.2584 4.25549 13.8624 3.85948 13.0704 3.06744L12.9292 2.92626C12.1372 2.13422 11.7411 1.73821 11.2845 1.58983C10.8828 1.45931 10.4501 1.45931 10.0484 1.58983C9.59175 1.73821 9.19574 2.13422 8.4037 2.92626L2.92919 8.40077C2.13715 9.19281 1.74114 9.58883 1.59276 10.0455C1.46224 10.4472 1.46224 10.8799 1.59276 11.2816C1.74114 11.7382 2.13715 12.1342 2.92919 12.9263L3.37494 13.372C3.60554 13.6026 3.72084 13.7179 3.8554 13.8004C3.9747 13.8735 4.10475 13.9273 4.2408 13.96C4.39425 13.9968 4.55731 13.9968 4.88343 13.9968H5.78279C6.10891 13.9968 6.27197 13.9968 6.42542 13.96C6.56147 13.9273 6.69153 13.8735 6.81083 13.8004C6.94538 13.7179 7.06068 13.6026 7.29128 13.372Z"
        stroke="#626266"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default EraserIcon;
