import { Flex, useTheme } from '@chakra-ui/react';
import { singularizeWord, splitTime } from '../shared/utils';

import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  LineChart,
  Tooltip,
  ReferenceLine,
} from 'recharts';
import styled from 'styled-components';
import { useMemo } from 'react';

const TooltipContainer = styled.div`
  padding: 10px;
  width: fit-content;
  background-color: #fff;
  outline: none;
  border: none;
  border-radius: 4px;
  pointer-events: all;
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1),
    0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
  transform: translate(0px, 35px);
`;

const StyledTooltipHeading = styled.p`
  font-size: 10px;
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
`;

const StyledToopTipText = styled.p`
  color: ${({ theme }) => theme.colors.text.primary};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: 14px;
  /* margin-bottom: 2px; */
`;

const Unit = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 11px;
`;

interface TrendLineChartProps {
  data: any;
  color: string;
  unit?: string;
  moreLeft?: boolean;
  average?: string;
  toolTipCord?: { x?: number; y?: number };
}

const CustomTooltip = ({
  active,
  payload,
  label,
  hideTooltip = false,
  unit,
}: any) => {
  if (active && payload && payload.length && !hideTooltip) {
    return (
      <TooltipContainer>
        <StyledTooltipHeading>{label}</StyledTooltipHeading>

        {payload.map((item: any, index: number) => (
          <Flex key={index} align="center" justify="space-between">
            <Flex align="center">
              <StyledToopTipText>
                {unit === 'Minutes' ? splitTime(item.value / 60) : item.value}{' '}
                {unit === 'Count'
                  ? ''
                  : unit === 'Minutes'
                  ? ''
                  : singularizeWord(Number(item.value), unit)}
              </StyledToopTipText>
            </Flex>
          </Flex>
        ))}
      </TooltipContainer>
    );
  }

  return null;
};

export const TrendLineChart: React.FC<TrendLineChartProps> = ({
  data,
  color,
  unit,
  moreLeft,
  average,
  toolTipCord,
}) => {
  const { colors } = useTheme();
  const noData = useMemo(() => {
    return [
      {
        endDate: '2024-12-08',
        xLabelName: '7 Dec-8 Dec',
        x: '2024-12-08',
        y: 0,
        startDate: '2024-12-07',
      },
      {
        endDate: '2024-12-15',
        xLabelName: '9 Dec-15 Dec',
        x: '2024-12-15',
        y: 2,
        startDate: '2024-12-09',
      },
      {
        endDate: '2024-12-22',
        xLabelName: '16 Dec-22 Dec',
        x: '2024-12-22',
        y: 1,
        startDate: '2024-12-16',
      },
      {
        endDate: '2024-12-29',
        xLabelName: '23 Dec-29 Dec',
        x: '2024-12-29',
        y: 0,
        startDate: '2024-12-23',
      },
      {
        endDate: '2025-01-05',
        xLabelName: '1 Jan-7 Jan',
        x: '2025-01-05',
        y: 1,
        startDate: '2024-12-30',
      },
      {
        endDate: '2025-01-06',
        xLabelName: '6 Jan-12 Jan',
        x: '2025-01-06',
        y: 0,
        startDate: '2025-01-06',
      },
    ];
  }, []);
  return (
    <ResponsiveContainer height="100%" width="100%">
      <LineChart
        data={data?.length > 0 ? data : noData}
        margin={{ top: 10, right: 5, left: moreLeft ? -50 : -30, bottom: -22 }}
      >
        <XAxis
          dataKey="xLabelName"
          tickLine={false}
          tick={false}
          axisLine={false}
        />
        <YAxis tickLine={false} tick={false} axisLine={false} />
        {data?.length > 0 && (
          <Tooltip
            content={<CustomTooltip hideBtn unit={unit} />}
            wrapperStyle={{ outline: 'none', zIndex: 2500 }}
            position={toolTipCord}
          />
        )}
        {average && (
          <ReferenceLine
            y={average}
            stroke={colors.text.secondary}
            strokeWidth={1}
          />
        )}
        <Line
          dataKey={'y'}
          stroke={data?.length > 0 ? color || '#1AAEFF' : '#D3D3D3'}
          strokeWidth={2}
          dot={false}
          fillOpacity={1}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TrendLineChart;
