import { FC } from 'react';
import {
  Button,
  Card,
  Center,
  Flex,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router';
import { LockIcon } from '../../icons';
import AppSidebar from '../AppSidebar';

interface AppBodyProps {
  children: React.ReactNode;
  appState: any;
}

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 89;
`;

const AppBody: FC<AppBodyProps> = ({ children, appState }) => {
  const displayUpgrade = appState?.user?.dto?.displayUpgrade;
  const metrics = appState?.user?.dto?.modules?.metrics;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Flex h="calc(100vh - 64px)" w="100%">
      <AppSidebar appState={appState} />

      <VStack
        position="relative"
        w="100%"
        h="100%"
        spacing={0}
        p={5}
        pb={pathname === '/contributors' ? 0 : 5}
        overflowY={
          (displayUpgrade && pathname !== '/settings/subscription-billing') ||
          (metrics === false &&
            decodeURIComponent(pathname.split('/')[1]) !== 'settings' &&
            decodeURIComponent(pathname.split('/')[1]) !== 'feedback')
            ? 'hidden'
            : 'auto'
        }
      >
        {((displayUpgrade && pathname !== '/settings/subscription-billing') ||
          (metrics === false &&
            decodeURIComponent(pathname.split('/')[1]) !== 'settings' &&
            decodeURIComponent(pathname.split('/')[1]) !== 'feedback')) && (
          <Overlay>
            <Center height="100%">
              <Card p={6}>
                <Stack spacing={4}>
                  <Center>
                    <LockIcon height={'80px'} width="300px" />
                  </Center>

                  <Text
                    fontFamily="heading"
                    fontSize="xl"
                    fontWeight="medium"
                    color="text.secondary"
                    alignSelf="center"
                  >
                    Upgrade to access feature
                  </Text>

                  <Button
                    variant="tertiary"
                    width="80%"
                    alignSelf="center"
                    onClick={() => navigate('/settings/subscription-billing')}
                  >
                    Upgrade
                  </Button>
                </Stack>
              </Card>
            </Center>
          </Overlay>
        )}
        {children}
      </VStack>
    </Flex>
  );
};

export default AppBody;
