import moment from 'moment';
import { formatDistanceToNowStrict } from 'date-fns';

export const getDaysAgo = (dateString: string, flag: string) => {
  if (!dateString) {
    return flag === 'exactDate' ? '' : 'Not Yet Merged';
  }

  if (flag === 'DueDate') {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };

    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(
      date
    );

    return formattedDate.replace(/\s/g, ', ');
  }
  if (flag === 'prCycleTime') {
    const dayMatch = dateString?.match(/(\d+)d/);
    const hourMatch = dateString?.match(/(\d+)hr/);
    const minuteMatch = dateString?.match(/(\d+)m/);

    const days = dayMatch ? parseInt(dayMatch[1]) : 0;
    const hours = hourMatch ? parseInt(hourMatch[1]) : 0;
    const minutes = minuteMatch ? parseInt(minuteMatch[1]) : 0;

    return days * 24 + hours + minutes / 60;
  }

  if (flag === 'exactDate') {
    const date = new Date(dateString);

    const day = String(date?.getDate()).padStart(2, '0');
    const month = date?.toLocaleString('default', { month: 'short' });
    const year = date?.getFullYear();

    let hours = date?.getHours();
    const minutes = String(date?.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;

    const formattedDate = `${day} ${month}, ${year} ${hours}:${minutes} ${ampm}`;
    return formattedDate;
  }

  return formatDistanceToNowStrict(new Date(dateString), {
    addSuffix: true,
    roundingMethod: 'floor',
  });
};

export const truncateText = (text: string, maxLength = 15) =>
  text?.length > maxLength ? `${text?.slice(0, maxLength)}...` : text;
