import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { DownloadIcon } from '../icons';
import styled from 'styled-components';
import { apiClient } from '@devd-client/api';
import { DownloadDataButton } from '../DownloadDataButton';
import {
  ACTIVE_DAYS_CHARTS,
  AuthorIdFilter,
  CFR_CHART_KEYS,
  DEPLOY_CHART_KEYS,
  ISSUE_AGE_CHART_KEYS,
  ISSUE_CHART_KEYS,
  ISSUE_LIFECYCLE_DIST_CHARTS,
  PR_AGEING_CHARTS,
  PR_CHART_KEYS,
  PR_HEALTH_INDEX_CHARTS,
  REQUIREMENT_STABILITY_CHARTS,
  TeamFilter,
  DEPENDENCY_VULNERABILITIES_CHART_KEYS,
  WORK_BREAKDOWN_CHARTS,
} from '../shared/utils';
import moment from 'moment';

interface SlideDrawerProps {
  sideBarHeading: string;
  sideBarSubHeading: React.ReactNode;
  openDrawer: boolean;
  content?: React.ReactNode;
  handleDrawerClose: () => void;
  width?: string;
  currentChartMeta?: any;
  startDate?: string;
  SideDrawerBodyHeight?: boolean;
  endDate?: string;
  filterBy?: AuthorIdFilter | TeamFilter;
  hideDownload?: boolean;
  specialCase?: string;
  isLoading?: boolean;
}

type SideDrawerProps = {
  show: boolean;
  width?: string;
  SideDrawerBodyHeight?: boolean;
  specialCase?: string;
};

const SideDrawerContainer = styled.div<SideDrawerProps>`
  position: fixed;
  height: 100%;
  background: #fff;
  padding: 12px;
  top: 0;
  right: 0;
  width: ${({ width }) => width || '350px'};
  z-index: 200;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: ${({ show }) =>
    show ? 'transform: translateX(0%)' : 'translateX(120%)'};
  transition: transform 0.3s ease-out;
  z-index: 999;
  overflow-x: ${({ specialCase }) =>
    specialCase === 'prSlideBar' ? 'none' : 'hidden'};
  margin-top: 0 !important;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 5px;
  margin-top: 5px;
`;

const Heading = styled.h1`
  font-size: 18px;
  font-family: 'Inter';
  color: '#000';
  font-weight: 700;
`;

const SideDrawerBody = styled.div<SideDrawerProps>`
  margin-top: 10px;
  width: 100%;
  height: ${({ SideDrawerBodyHeight }) =>
    SideDrawerBodyHeight ? '75%' : '85%'};
  @media screen and (min-width: 1600px) {
    height: ${({ SideDrawerBodyHeight }) =>
      SideDrawerBodyHeight ? '80vh' : '85%'};
  }
  overflow-y: auto;
`;

export const SlideBar: FC<SlideDrawerProps> = ({
  openDrawer,
  content,
  handleDrawerClose,
  width,
  currentChartMeta,
  startDate,
  endDate,
  filterBy,
  sideBarHeading,
  sideBarSubHeading,
  SideDrawerBodyHeight = true,
  hideDownload,
  specialCase,
  isLoading,
}) => {
  const myRef = useRef<HTMLDivElement>(null);
  const [pending, setPending] = useState(false);

  const download = function (data: any) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute(
      'download',
      `${
        currentChartMeta.chartTitle === 'Change Failure Rate'
          ? 'CHANGE_FAILURE_RATE'
          : currentChartMeta?.chartKey === 'CUSTOM'
          ? currentChartMeta?.chartTitle
          : currentChartMeta?.chartKey
      }: ${moment(startDate).format('DD MMM')} - ${moment(endDate).format(
        'DD MMM'
      )}`
    );
    a.click();
  };

  const downloadCSV = async () => {
    setPending(true);
    apiClient(
      `/v1/csv/${
        [
          ...PR_CHART_KEYS,
          ...PR_AGEING_CHARTS,
          ...ACTIVE_DAYS_CHARTS,
          ...PR_HEALTH_INDEX_CHARTS,
        ].includes(currentChartMeta?.chartKey)
          ? 'pr'
          : [...DEPLOY_CHART_KEYS].includes(currentChartMeta?.chartKey)
          ? 'deployment'
          : [...CFR_CHART_KEYS].includes(currentChartMeta?.chartKey)
          ? 'cfr'
          : [
              ...ISSUE_CHART_KEYS,
              ...ISSUE_AGE_CHART_KEYS,
              ...REQUIREMENT_STABILITY_CHARTS,
              ...ISSUE_LIFECYCLE_DIST_CHARTS,
            ].includes(currentChartMeta?.chartKey)
          ? 'issue'
          : [...WORK_BREAKDOWN_CHARTS].includes(currentChartMeta?.chartKey)
          ? 'commit'
          : ''
      }/metric/${
        currentChartMeta.chartTitle === 'Change Failure Rate'
          ? 'CHANGE_FAILURE_RATE'
          : currentChartMeta?.chartKey
      }`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          orgId: `${localStorage.getItem('orgId')}`,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          startDate: startDate,
          endDate: endDate,
          ...filterBy,
          vsmAtrributes: { age: filterBy?.age },
        }),
      }
    )
      .then((res: any) => {
        setPending(false);
        download(res);
      })
      .catch((err) => {
        setPending(false);
      });
  };
  const downloadDependencyVulnerabilitiesCSV = async () => {
    setPending(true);
    apiClient(`/v1/csv/dependency-vulnerabilities-details`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        orgId: `${localStorage.getItem('orgId')}`,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        startDate: startDate,
        endDate: endDate,
        ...filterBy,
        vsmAtrributes: { age: filterBy?.age },
      }),
    })
      .then((res: any) => {
        setPending(false);
        download(res);
      })
      .catch((err) => {
        setPending(false);
      });
  };

  const downloadCustomCSV = async () => {
    setPending(true);
    const chartTitle = encodeURIComponent(currentChartMeta?.chartTitle);
    apiClient(`/v1/csv/custom/metric/${chartTitle}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        orgId: `${localStorage.getItem('orgId')}`,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        startDate: startDate,
        endDate: endDate,
        ...filterBy,
        vsmAtrributes: { age: filterBy?.age },
      }),
    })
      .then((res: any) => {
        setPending(false);
        download(res);
      })
      .catch((err) => {
        setPending(false);
      });
  };

  const handleClickOutside = (e: any) => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      handleDrawerClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <SideDrawerContainer
      ref={myRef}
      show={openDrawer}
      width={width}
      specialCase={specialCase}
    >
      <CloseButtonContainer>
        <Flex flexDir={'column'}>
          <Heading>{sideBarHeading}</Heading>
          {sideBarSubHeading && sideBarSubHeading}
        </Flex>
        {specialCase !== 'prSlideBar' && (
          <IconButton
            isRound
            bg="transparent"
            onClick={handleDrawerClose}
            aria-label="close"
            size="sm"
            icon={<AiOutlineClose fontSize="18" />}
          />
        )}

        {specialCase === 'prSlideBar' && (
          <Tooltip label="Close Sidebar" hasArrow>
            <Box
              bg={'white'}
              top={4}
              left={'-32px'}
              boxShadow={'-2px 0px 7px rgba(0, 0, 0, 0.3)'}
              borderLeftRadius={4}
              zIndex={999999999999999}
              overflow={'hidden'}
              position={'absolute'}
            >
              <IconButton
                bg="transparent"
                onClick={handleDrawerClose}
                aria-label="close"
                size="sm"
                icon={<AiOutlineClose fontSize="18" />}
              />
            </Box>
          </Tooltip>
        )}
      </CloseButtonContainer>
      <SideDrawerBody SideDrawerBodyHeight={SideDrawerBodyHeight} show={true}>
        {[
          ...PR_CHART_KEYS,
          ...DEPLOY_CHART_KEYS,
          ...ISSUE_CHART_KEYS,
          ...CFR_CHART_KEYS,
          ...PR_AGEING_CHARTS,
          ...ISSUE_AGE_CHART_KEYS,
          ...REQUIREMENT_STABILITY_CHARTS,
          ...ACTIVE_DAYS_CHARTS,
        ].includes(currentChartMeta?.chartKey) &&
          specialCase === 'prSlideBar' &&
          !isLoading && (
            <Tooltip label="Download Data" hasArrow>
              <IconButton
                bg="transparent"
                position={'absolute'}
                zIndex={99999999999999}
                right={0}
                mr={10}
                mt={2}
                cursor={'pointer'}
                p={1}
                border={'1px'}
                onClick={downloadCSV}
                aria-label="download"
                size="xs"
                icon={<DownloadIcon color="black" />}
              ></IconButton>
            </Tooltip>
          )}
        {content}
      </SideDrawerBody>

      {[
        ...PR_CHART_KEYS,
        ...DEPLOY_CHART_KEYS,
        ...ISSUE_CHART_KEYS,
        ...CFR_CHART_KEYS,
        ...PR_AGEING_CHARTS,
        ...ISSUE_AGE_CHART_KEYS,
        ...REQUIREMENT_STABILITY_CHARTS,
        ...ACTIVE_DAYS_CHARTS,
        ...PR_HEALTH_INDEX_CHARTS,
        ...WORK_BREAKDOWN_CHARTS,
        ...ISSUE_LIFECYCLE_DIST_CHARTS,
      ].includes(currentChartMeta?.chartKey)
        ? !hideDownload && (
            <DownloadDataButton downloadCSV={downloadCSV} pending={pending} />
          )
        : [...DEPENDENCY_VULNERABILITIES_CHART_KEYS].includes(
            currentChartMeta?.chartKey
          )
        ? !hideDownload && (
            <DownloadDataButton
              downloadCSV={downloadDependencyVulnerabilitiesCSV}
              pending={pending}
            />
          )
        : currentChartMeta?.chartKey === 'CUSTOM' && (
            <DownloadDataButton
              downloadCSV={downloadCustomCSV}
              pending={pending}
            />
          )}
    </SideDrawerContainer>
  );
};
