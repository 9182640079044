import {
  ChartContainer,
  Container,
  Date,
  Divider,
  TableBody,
  TableBodyLeft,
  TableBodyMiddle,
  TableBodyRight,
  TableHeader,
  TableLeft,
  TableLeftHeading,
  TableMiddle,
  TableRight,
  TableRightItem,
} from './ActivityLogTable.styled';
import {
  DataItem,
  DataProps,
  RecordsItemProps,
} from '../../../helpers/ActivityLog.types';
import { FC, Fragment, useCallback, useContext, useState } from 'react';

import { ActivityLogContext } from '../../../contexts/activityLogContext';
import BubbleChart from './BubbleChart';
import DetailBox from './DetailBox';
import moment from 'moment';
import SlideDrawer from './SlideDrawer';
import { useWorkLogDetailsGql } from '../../../graphql';

import { Loader } from '@devd-client/devd/components';
import { Box, useTheme } from '@chakra-ui/react';
import PieChartData from '../../PieChart';
import { convertHeading } from '../../../helpers/ActivityLog.utils';

interface ActivityLogTableProps {
  weekArray: string[];
  workLogType: string;
  teamId: string;
  activityLogData: any;
}

const ActivityLogTable: FC<ActivityLogTableProps> = ({
  weekArray,
  workLogType,
  teamId,
  activityLogData,
}) => {
  const {
    state: { workLogDetails },
    dispatch,
  } = useContext(ActivityLogContext);
  const { colors } = useTheme();

  const [bubbleData, setBubbleData] = useState<any>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [rowName, setRowName] = useState<string>('');

  const handleDrawerOpen = useCallback(() => setOpenDrawer(true), [openDrawer]);
  const handleDrawerClose = useCallback(
    () => setOpenDrawer(false),
    [openDrawer]
  );

  const {
    isLoading: workLogDetailsLoading,
    isFetching: workLogDetailsFetching,
  } = useWorkLogDetailsGql(
    bubbleData?.clickedData?.label,
    bubbleData?.date,
    rowName,
    workLogType.toLowerCase() === 'contributors'
      ? 'author'
      : workLogType.toLowerCase(),
    teamId,
    dispatch
  );

  return (
    <>
      <Container>
        <TableHeader>
          <TableLeft>
            <TableLeftHeading style={{ marginRight: '10px' }}>
              {workLogType}
            </TableLeftHeading>
          </TableLeft>

          {workLogType === 'Git' && (
            <TableMiddle>
              <TableLeftHeading color={'primary'}>
                Work Breakdown
              </TableLeftHeading>
            </TableMiddle>
          )}

          <TableRight>
            {weekArray.map((item: string, idx: number) => (
              <TableRightItem
                color={
                  moment(item).format('DD ddd').split(' ')[1] === 'Sun' ||
                  moment(item).format('DD ddd').split(' ')[1] === 'Sat'
                    ? colors.error
                    : '#333'
                }
                key={`week-${idx}`}
              >
                <Date>{moment(item).format('DD ddd').split(' ')[0]}</Date>
                {moment(item).format('DD ddd').split(' ')[1]}
              </TableRightItem>
            ))}
          </TableRight>
        </TableHeader>

        {activityLogData?.rows?.map((row: DataProps, idx: number) => (
          <Fragment key={`row-${idx}`}>
            <TableBody onClick={() => setRowName(row.name)}>
              <TableBodyLeft>
                <DetailBox
                  name={row.name}
                  properties={row.properties}
                  activeDays={row.activeDays}
                  workLogType={workLogType}
                />
              </TableBodyLeft>
              {workLogType === 'Git' && (
                <TableBodyMiddle>
                  <Box mr={4} width="40px" height="40px">
                    {row.breakdownList && (
                      <PieChartData properties={row.breakdownList} />
                    )}
                  </Box>
                </TableBodyMiddle>
              )}

              <TableBodyRight>
                {row.columns.map((col: RecordsItemProps, idx: number) => {
                  const checkZero = col.items.children.every(
                    (item: DataItem) => item.count === '0'
                  );
                  const dateToCheck = moment(col.date);
                  return !checkZero ? (
                    <ChartContainer key={`bubble-${idx}`}>
                      <BubbleChart
                        chartData={col.items}
                        row={row}
                        setRowName={setRowName}
                        data={col}
                        handleDrawerOpen={handleDrawerOpen}
                        setBubbleData={setBubbleData}
                      />
                    </ChartContainer>
                  ) : (
                    <ChartContainer
                      key={`bubble-${idx}`}
                      bgColor={!dateToCheck.isBefore(moment())}
                    ></ChartContainer>
                  );
                })}
              </TableBodyRight>
            </TableBody>
            <Divider />
          </Fragment>
        ))}
      </Container>
      <SlideDrawer
        workLogDetails={workLogDetails}
        workLogDetailsFetching={workLogDetailsFetching}
        openDrawer={openDrawer}
        handleDrawerClose={handleDrawerClose}
        heading={`${convertHeading(bubbleData?.clickedData?.label)} (${
          bubbleData?.clickedData?.count
        })`}
        tag={workLogType?.toLowerCase()}
        subHeading={rowName}
        date={bubbleData?.date}
      />
      {/* {workLogDetailsLoading && <Loader />} */}
    </>
  );
};

export default ActivityLogTable;
