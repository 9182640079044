import { FC, useState } from 'react';
import styled from 'styled-components';
import DateFilter from '../../../../date-filter';
import { TeamMenu } from '../../../../teammenu';
import { Box, Text } from '@chakra-ui/react';
import { CustomSelect, ISelect } from '../../../../customSelect/index';
import { PeriodType } from '../../../../shared/typings';
import {
  durationData,
  eliminateHtmlTags,
  ISSUE_CHART_KEYS,
} from '../../../../shared/utils';
import { ChakraStylesConfig, Select } from 'chakra-react-select';
import { allMetricsList } from '../../../graphql';
import { useQueryState } from '../../../../hooks/useQueryState';
import { useFetchRepo } from '../../../apis';
import { filterOptions } from '../../../helpers/metricCard.utils';

const ToolbarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const CalendarContainer = styled.div``;

const chakraStyles: ChakraStylesConfig = {
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 9999,
    };
  },
};

interface ToolbarProps {
  breakdown: ISelect;
  setBreakdown: (br: ISelect) => void;
  selected: PeriodType;
  setSelected: (selected: PeriodType) => void;
  selectedTeam: ISelect;
  setSelectedTeam: (team: ISelect) => void;
  data: any;
  repos: string[];
  setRepos: (repos: string[]) => void;
  filterBy: { value: string; label: string };
  setFilterBy: (filterBy: { value: string; label: string }) => void;
}

const filterOptions2 = [
  { value: 'team', label: 'Team' },
  { value: 'repo', label: 'Repo' },
];

const Toolbar: FC<ToolbarProps> = ({
  breakdown,
  setBreakdown,
  selected,
  setSelected,
  selectedTeam,
  setSelectedTeam,
  data,
  repos,
  setRepos,
  filterBy,
  setFilterBy,
}) => {
  const [periodOptions, setPeriodOptions] = useState<any>(durationData);
  const [metricType] = useQueryState('type');

  const { data: allRepos, isLoading: allReposLoading } = useFetchRepo();

  return (
    <ToolbarContainer>
      <DropdownContainer>
        {allMetricsList
          .filter((item: any) => item.type === metricType)
          .map((el: any) => el?.repoFilter || false)[0] ? (
          <>
            <Box mr={3}>
              <Text
                fontSize="sm"
                fontFamily="heading"
                fontWeight="semibold"
                color="text.secondary"
                lineHeight="17px"
                mb={2}
              >
                Filter By
              </Text>
              <Select
                placeholder="Select Filter By"
                useBasicStyles
                onChange={(e: any) => {
                  setFilterBy(e);
                }}
                size="sm"
                value={filterBy}
                options={filterOptions2}
              />
            </Box>

            {filterBy.value === 'team' && (
              <TeamMenu
                selectedTeam={selectedTeam}
                handleSelectedTeam={setSelectedTeam}
                hideShowDetails
              />
            )}

            {filterBy.value === 'repo' && (
              <Box mr={1} maxWidth="800px">
                <Text
                  fontSize="sm"
                  fontFamily="heading"
                  fontWeight="semibold"
                  color="text.secondary"
                  lineHeight="17px"
                  mb={2}
                >
                  Repository
                </Text>
                <Select
                  placeholder="Select Repository"
                  isMulti={true}
                  useBasicStyles
                  onChange={(e: any) => {
                    setRepos(e);
                  }}
                  size="sm"
                  value={repos}
                  options={allRepos?.dto?.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                />
              </Box>
            )}
          </>
        ) : (
          <TeamMenu
            selectedTeam={selectedTeam}
            handleSelectedTeam={setSelectedTeam}
            hideShowDetails
          />
        )}

        {data?.chartMetadata?.showBreakdown && (
          <>
            <Box height="32px" width="1.5px" bg="gray.300" ml={2} />
            <Box ml={2}>
              <CustomSelect
                label="Breakdown by"
                size="sm"
                chakraStyles={chakraStyles}
                defaultItem={breakdown}
                onChange={(selected: string) =>
                  setBreakdown({
                    value: eliminateHtmlTags(selected),
                    label:
                      eliminateHtmlTags(selected) === ''
                        ? '-'
                        : eliminateHtmlTags(selected),
                  })
                }
                options={
                  ISSUE_CHART_KEYS?.includes(metricType)
                    ? filterOptions
                    : filterOptions.filter((item) => item.value !== 'PRIORITY')
                }
              />
            </Box>
          </>
        )}
      </DropdownContainer>
      <CalendarContainer>
        <DateFilter />
      </CalendarContainer>
    </ToolbarContainer>
  );
};

export default Toolbar;
