import { useTheme } from '@chakra-ui/react';
import styled from 'styled-components';

const CustomTooltipContainer = styled.div`
  width: 200px;
  background-color: #fff;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.15);
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 5px;
  font-family: 'Inter';
  font-size: '10px';
`;

type CustomTooltipLabelProps = {
  fontSize?: string;
  color?: string;
  mb?: string;
};

const CustomTooltipLabel = styled.p<CustomTooltipLabelProps>`
  font-size: ${({ fontSize }) => fontSize || '10px'};
  font-weight: 700;
  color: ${({ color }) => color || '#777'};
  margin-bottom: ${({ mb }) => mb || '0px'};
`;

export interface CustomTooltipProps {
  active: boolean;
  payload: PayloadType;
  label: string;
}

type PayloadType = {
  chartType: undefined | string;
  color: string;
  datakey: string;
  fill: string;
  formatter?: string | undefined;
  name: string;
  payload: any;
  type: undefined | string;
  unit: undefined | string;
  value: string;
};

const ColorDot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
  background-color: ${({ color }) => color};
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const CustomTooltip = ({ active, payload, label }: any) => {
  const { colors } = useTheme();

  if (active && payload && payload.length) {
    return (
      <CustomTooltipContainer>
        <CustomTooltipLabel
          color={colors.text.primary}
          fontSize="10px"
          mb="8px"
        >{`${label}`}</CustomTooltipLabel>
        {payload?.map((item: PayloadType) => (
          <LabelWrapper key={item?.datakey}>
            <ColorDot color={item?.color} />
            <CustomTooltipLabel
              color={colors.text.secondary}
              fontSize="12px"
            >{`${item?.value}%  ${item?.name}`}</CustomTooltipLabel>
          </LabelWrapper>
        ))}
      </CustomTooltipContainer>
    );
  }

  return null;
};

export default CustomTooltip;
