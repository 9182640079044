import { Flex, TextProps, useTheme } from '@chakra-ui/react';
import { getLanguageIcon } from '../shared/SharedComponents';
import { QuestionIcon } from '../icons';

type Props = TextProps & {
  language: string;
};

export const CodingLanguage = ({ language, ...props }: Props) => {
  const { colors } = useTheme();
  return (
    <Flex {...props} alignItems={'center'} gap={1} fontWeight={'medium'}>
      {getLanguageIcon(language) ? (
        getLanguageIcon(language)
      ) : (
        <QuestionIcon
          height="18px"
          width="18px"
          color={colors.text.secondary}
        />
      )}{' '}
      {language}
    </Flex>
  );
};
