import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import {
  JavaScriptIcon,
  PythonIcon,
  TypeScriptIcon,
  TypeScriptJSXIcon,
  JavaIcon,
  GoIcon,
  RubyIcon,
  PHPIcon,
  HTMLIcon,
  XHTMLIcon,
  CSSIcon,
  SwiftIcon,
  RustIcon,
  ScalaIcon,
  DartIcon,
  XMLIcon,
  PerlIcon,
  RIcon,
  SQLIcon,
  PrologIcon,
  ClojureIcon,
  MatlabIcon,
  ErlangIcon,
  LuaIcon,
  FortranIcon,
  KotlinIcon,
  JSONIcon,
  MarkdownIcon,
  BitbucketIcon,
  CloudIcon,
  ConfluenceIcon,
  GitHubIcon,
  GitlabIcon,
  JiraIcon,
  LinearIcon,
  OpsgenieIcon,
  OutlookIcon,
  PDIcon,
} from '../../icons';

export const generateTeamAvatar = (teamName: string) => {
  const colorOptions = [
    { bg: '#EC48991A', text: '#EC4899' },
    { bg: '#10B9811A', text: '#10B981' },
    { bg: '#2684FF1A', text: '#2684FF' },
  ];

  const index = teamName?.charCodeAt(0) % colorOptions.length;
  const selectedColor = colorOptions[index];

  const initial = teamName?.charAt(0)?.toUpperCase();

  return (
    <Text
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      bg={selectedColor?.bg}
      color={selectedColor?.text}
      fontSize={'12px'}
      width={'20px'}
      height={'20px'}
      borderRadius={'50%'}
      mr={'6px'}
      fontWeight={'500'}
    >
      {initial}
    </Text>
  );
};

export const getLanguageIcon = (language: string) => {
  switch (language) {
    case 'JavaScript':
      return <JavaScriptIcon height="18px" width="18px" />;
    case 'Python':
      return <PythonIcon height="18px" width="18px" />;
    case 'TypeScript':
      return <TypeScriptIcon height="18px" width="18px" />;
    case 'TypeScript JSX':
      return <TypeScriptJSXIcon height="18px" width="18px" />;
    case 'Java':
      return <JavaIcon height="18px" width="18px" />;
    case 'Go':
      return <GoIcon height="18px" width="18px" />;
    case 'Ruby':
      return <RubyIcon height="18px" width="18px" />;
    case 'PHP':
      return <PHPIcon height="18px" width="18px" />;
    case 'HTML':
      return <HTMLIcon height="18px" width="18px" />;
    case 'XHTML':
      return <XHTMLIcon height="18px" width="18px" />;
    case 'CSS':
      return <CSSIcon height="18px" width="18px" />;
    case 'Swift':
      return <SwiftIcon height="18px" width="18px" />;
    case 'Rust':
      return <RustIcon height="18px" width="18px" />;
    case 'Scala':
      return <ScalaIcon height="18px" width="18px" />;
    case 'Dart':
      return <DartIcon height="18px" width="18px" />;
    case 'XML':
      return <XMLIcon height="18px" width="18px" />;
    case 'Perl':
      return <PerlIcon height="18px" width="18px" />;
    case 'R':
      return <RIcon height="18px" width="18px" />;
    case 'SQL':
      return <SQLIcon height="18px" width="18px" />;
    case 'Prolog':
      return <PrologIcon height="18px" width="18px" />;
    case 'Clojure':
      return <ClojureIcon height="18px" width="18px" />;
    case 'MATLAB':
      return <MatlabIcon height="18px" width="18px" />;
    case 'Erlang':
      return <ErlangIcon height="18px" width="18px" />;
    case 'Lua':
      return <LuaIcon height="18px" width="18px" />;
    case 'Fortran':
      return <FortranIcon height="18px" width="18px" />;
    case 'Kotlin':
      return <KotlinIcon height="18px" width="18px" />;
    case 'JSON':
      return <JSONIcon height="18px" width="18px" />;
    case 'Markdown':
      return <MarkdownIcon height="18px" width="18px" />;
    default:
      return undefined;
  }
};

export const getIcon = (imgType: string) => {
  switch (imgType) {
    case 'github':
      return <GitHubIcon height="14px" width="14px" />;
    case 'jira':
      return <JiraIcon height="14px" width="14px" />;
    case 'gitlab':
      return <GitlabIcon height="14px" width="14px" />;
    case 'linear':
      return <LinearIcon height="14px" width="14px" />;
    case 'pd':
      return <PDIcon height="14px" width="14px" />;
    case 'opsgenie':
      return <OpsgenieIcon height="14px" width="14px" />;
    case 'cloud':
      return <CloudIcon height="14px" width="14px" />;
    case 'bitbucket':
      return <BitbucketIcon height="14px" width="14px" />;
    case 'outlook':
      return <OutlookIcon height="14px" width="14px" />;
    case 'confluence':
      return <ConfluenceIcon height="14px" width="14px" />;
    default:
      return undefined;
  }
};

export const AccountName = ({
  title,
  imgType,
}: {
  title: string;
  imgType: string;
}) => (
  <Tooltip label={title}>
    <Flex align="center" maxW={'100%'} isTruncated>
      {imgType && (
        <Box height={'14px'} width={'14px'}>
          {getIcon(imgType)}
        </Box>
      )}
      <Text ml={1} fontSize="14px" fontWeight="400" isTruncated>
        {title}
      </Text>
    </Flex>
  </Tooltip>
);
