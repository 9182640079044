import moment from 'moment';
import { PeriodType } from '../typings';

export const durationData: PeriodType[] = [
  {
    id: 1,
    duration: '7D',
    startDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    sprintId: '',
  },
  {
    id: 2,
    duration: '14D',
    startDate: moment().subtract(14, 'day').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    sprintId: '',
  },
  {
    id: 3,
    duration: '21D',
    startDate: moment().subtract(21, 'day').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    sprintId: '',
  },
  {
    id: 4,
    duration: '1M',
    startDate: moment().subtract(30, 'day').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    sprintId: '',
  },
  {
    id: 5,
    duration: '3M',
    startDate: moment().subtract(90, 'day').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    sprintId: '',
  },
  {
    id: 6,
    duration: 'Custom',
    startDate: '',
    endDate: '',
    sprintId: '',
  },
  {
    id: 7,
    duration: 'Sprint',
    startDate: '',
    endDate: '',
    sprintId: '',
  },
];

export const eliminateHtmlTags = (tag: string) => {
  return tag.replace(/<\/?[^>]+(>|$)/g, '');
};

export const convertHexToRGBA = (hexCode: string, opacity: number) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

export function isHTML(str: string) {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

export const splitTime = (numberOfHours: number) => {
  if (numberOfHours === 0) return '0';

  const Days = Math.floor(numberOfHours / 24);
  const Remainder = numberOfHours % 24;
  const Hours = Math.floor(Remainder);
  const MinutesDecimal = 60 * (Remainder - Hours);
  const Minutes = Math.floor(MinutesDecimal);
  const Seconds = Math.floor((MinutesDecimal - Minutes) * 60);

  const showSeconds = numberOfHours < 1 && numberOfHours * 60 < 1;

  return Days !== 0
    ? `${isNaN(Days) ? '0d' : Days !== 0 ? Days + 'd' : ''} ${
        isNaN(Hours) ? '0h' : Hours ? Hours + 'h' : ''
      } ${isNaN(Minutes) ? '0m' : Minutes !== 0 ? Minutes + 'm' : ''} ${
        showSeconds ? `${Seconds}s` : ''
      }`
    : `${isNaN(Hours) ? '0h' : Hours ? Hours + 'h' : ''} ${
        isNaN(Minutes) ? '0m' : Minutes !== 0 ? Minutes + 'm' : ''
      } ${showSeconds ? `${Seconds}s` : ''}`;
};

export enum NumberStatus {
  positive = 'Positive',
  negative = 'Negative',
  zero = 'Zero',
}

export const checkNumber = (number: number | null) => {
  if (number === null) {
    return NumberStatus.zero;
  } else {
    return number > 0
      ? NumberStatus.positive
      : number < 0
      ? NumberStatus.negative
      : NumberStatus.zero;
  }
};

export const eliminateSpecialCharacters = (input: string) => {
  return input.replace(/[^\w\s]/gi, '');
};

export const eliminateSpecialCharactersNotHyphen = (input: string) => {
  return input.replace(/[^\w\s-]/gi, '');
};

//Query-> PrReportDetail
export const PR_CHART_KEYS = [
  'PR_UPDATED',
  'PR_OPEN',
  'PR_MERGE',
  'PR_REVIEW',
  'PR_REVIEWED',
  'PR_CHANGES',
  'MERGE_WITHOUT_REVIEW',
  'COMMENTS_PER_PR',
  'PR_CYCLE_TIME',
  'PICKUP_CYCLE_TIME',
  'MERGE_CYCLE_TIME',
  'CODING_CYCLE_TIME',
  'COMMIT_WITHOUT_REVIEW',
  'PR_COMMENT',
  'PR_CHANGES_REQUESTED',
  'PR_WITHOUT_ISSUE',
  'TOP_REVIEWER_DISTRIBUTION',
  'PR_CYCLE_TIME_BREAKDOWN',
  'PR_REVIEW_TIME',
  'LOC_VS_PR_CYCLE_TIME',
  'APPROVED_WITHOUT_COMMENT',
];

// Query-> ChangeFailureDetail
export const CFR_CHART_KEYS = [
  'CHANGE_FAILURE',
  'CHANGE_FAILURE_RATE',
  'MEAN_TIME_TO_RECOVERY',
  'MEAN_TIME_TO_RECOVERY_DISTRIBUTION',
  'CHANGE_HEALTH',
];

// Query-> DeploymentDetails
export const DEPLOY_CHART_KEYS = [
  'DEPLOYMENT_FREQUENCY',
  'LEAD_TIME_FOR_CHANGE',
  'DEPLOYMENT_FREQUENCY_DISTRIBUTION',
  'LEAD_TIME_BREAKDOWN',
];

// Query-> ContributorDetails
export const CONTRIBUTOR_CHART_KEYS = ['CONTRIBUTOR_COUNT'];

// Query-> issueReport
export const ISSUE_CHART_KEYS = [
  'ISSUE_UPDATED',
  'TASK_CLOSE',
  'TASK_OPEN',
  'BUG_CLOSE',
  'BUG_OPEN',
  'PROD_BUG_OPEN',
  'STORY_POINT_COMPLETED',
  'ISSUE_CYCLE_TIME',
  'BACKWARD_MOMENTUM',
  'ISSUE_THROUGHPUT',
  'BUG_THROUGHPUT',
  'ISSUE_CYCLE_TIME_VS_COUNT',
  'BUG_REOPENED',
  'ISSUE_REOPENED',
  'BUG_LEAD_TIME',
  'ISSUE_WITHOUT_STORY_POINT',
  'ISSUE_PROGRESSION_RATE',
  'TASK_IN_PROGRESS',
  'BUG_IN_PROGRESS',
];

// Query-> GoalMetricDetailsGit
export const GOAL_METRIC_GIT_KEYS = [
  'MERGE_WITHOUT_REVIEW',
  'PR_REVIEW_TIME',
  'PR_SIZE',
  'PR_WITHOUT_ISSUE',
];

// Query-> GoalMetricDetailsJira
export const GOAL_METRIC_JIRA_KEYS = [
  'ISSUE_WITHOUT_INVESTMENT',
  'ISSUE_WITHOUT_STORY_POINT',
  'ISSUE_CYCLE_TIME',
];

// Query-> WellBeingDetailsByType
export const WELLBEING_CHART_KEYS = [
  'BURN_OUT',
  'NON_WORKING_DAY',
  'OUT_OF_HOUR',
];

// Query-> TeamStatsWorkingAgreementDetails
export const WORKING_AGREEMENT_KEYS = ['WORKING_AGREEMENT_EXCEPTIONS'];

// Query-> CommitReportDetail
export const COMMIT_CHART_KEYS = ['COMMIT_COUNT', 'NEW_LINE_OF_CODE'];

// Query-> GetTeamMembers
export const MEMBER_CHART_KEYS = ['MEMBER_COUNT'];

// Query-> QualityBranchDetailByType
export const QUALITY_CHART_KEYS = [
  'QUALITY_CODE_SMELLS',
  'QUALITY_BUGS',
  'QUALITY_VULNERABILITIES',
  'QUALITY_SECURITY_HOTSPOTS',
  'QUALITY_COVERAGE',
  'QUALITY_DUPLICATED_LINES',

  'NEW_QUALITY_CODE_SMELLS',
  'NEW_QUALITY_BUGS',
  'NEW_QUALITY_VULNERABILITIES',
  'NEW_QUALITY_SECURITY_HOTSPOTS',
  'NEW_QUALITY_COVERAGE',
  'NEW_QUALITY_DUPLICATED_LINES',
];

export const DEPENDENCY_VULNERABILITIES_CHART_KEYS = [
  'DEPENDENCY_VULNERABILITIES',
];

export const CUSTOM_CHART_KEYS = ['CUSTOM'];

export const ISSUE_AGE_CHART_KEYS = [
  'CHANGE_AGING_DISTRIBUTION',
  'ISSUE_CYCLE_TIME_DIST',
];

export const REQUIREMENT_STABILITY_CHARTS = ['REQUIREMENT_STABILITY'];

export const PR_AGEING_CHARTS = ['PR_AGEING_DISTRIBUTION'];

export const REVIEW_RESPONSIVENESS_INDEX_CHARTS = [
  'REVIEW_RESPONSIVENESS_INDEX',
];

export const PR_HEALTH_INDEX_CHARTS = ['PR_HEALTH_INDEXES'];

export const LOC_VS_PR_CYCLE_TIME_CHARTS = ['LOC_VS_PR_CYCLE_TIME'];

export const ACTIVE_DAYS_CHARTS = ['ACTIVE_DAYS'];

export const WORK_BREAKDOWN_CHARTS = [
  'WORK_BREAKDOWN',
  'WORK_BREAKDOWN_CODE_IMPACT',
];

export const WORK_BREAKDOWN_STATS_CHARTS = [
  'WORK_BREAKDOWN_NEW_WORK',
  'WORK_BREAKDOWN_REFACTOR',
  'WORK_BREAKDOWN_HELP_OTHERS',
  'WORK_BREAKDOWN_RE_WORK',
];

export const ISSUE_LIFECYCLE_DIST_CHARTS = [
  'ISSUE_LIFECYCLE_DIST',
  'ISSUE_PROGRESSION_RATE',
];

export const MEETING_CHARTS = ['MEETING_COUNT', 'MEETING_TIME'];

export const ISSUE_REPORT_DETAIL_FOR_RECRUIT_CRM_CHARTS = [
  'OUTCOME_POINTS',
  'ACHIEVED_OUTCOME_POINTS',
];

export const DOCUMENT_UPDATED_CHARTS = ['DOCUMENT_UPDATED'];

export const countCategory = (metrics: any, category: any) => {
  return metrics.reduce((acc: number, tag: any) => {
    return acc + (tag?.tabs?.includes(category) ? 1 : 0);
  }, 0);
};

export const truncateText = (text: string, maxLength = 15) =>
  text?.length > maxLength ? `${text?.slice(0, maxLength)}...` : text;

export const ISSUE_REPORT_DETAIL_FOR_RECRUIT_CRM_PRIORITY_DISTRIBUTED_DATA_CHARTS =
  [
    'SUB_PAR_REVIEWER_DATA_CRITICAL',
    'SUB_PAR_REVIEWER_DATA_OTHER',
    'BUG_CAUSED_BY_DATA_CRITICAL',
    'BUG_CAUSED_BY_DATA_OTHER',
  ];

export const graphColorArr = [
  '#0B698B',
  '#C2528B',
  '#FF8D70',
  '#5F50A9',
  '#0396A6',
  '#F1B813',
  '#490B3E',
];

export type AuthorIdFilter = {
  author: string;
  type?: string;
  status?: string;
  age?: string;
  name?: string;
  sprintId?: string;
  projectsFilter?: string[];
  lowerBoundIncl?: any;
  upperBound?: any;
  sdlcStageId?: string;
};

export type TeamFilter = {
  author?: string;
  teamId: string;
  type?: string;
  status?: string;
  age?: string;
  name?: string;
  sprintId?: string;
  projectsFilter?: string[];
  lowerBoundIncl?: any;
  upperBound?: any;
  sdlcStageId?: string;
};

export const statusLabels = {
  ideation: 'Ideation',
  prd: 'Product Requirement Document',
  uxDesign: 'UX Design',
  techDesign: 'Tech Design',
  development: 'Development',
  qa: 'Quality Assurance',
  blocked: 'Blocked',
  deployment: 'Deployment',
} as any;

export function getKeyByValue(
  value: string
): keyof typeof statusLabels | undefined {
  return (Object.keys(statusLabels) as Array<keyof typeof statusLabels>).find(
    (key) => statusLabels[key] === value
  );
}

export function extractPercentageAndRemaining(message: string) {
  const regex = /([+-]?\d+(\.\d+)?)%/;
  const match = message.match(regex);
  let percentage = '';
  let remaining = '';

  if (match) {
    percentage = match[0];
    remaining = message.replace(percentage, '').trim();
  } else {
    remaining = message;
  }

  return { percentage, remaining };
}

export const NAME_MAX_LENGTH = 30;
export const ROLE_MAX_LENGTH = 50;

export const validateNameInput = (value: string) => {
  return /^[a-zA-Z-_]*$/.test(value);
};

export const validateRoleInput = (value: string) => {
  return /^[a-zA-Z0-9-_ ]*$/.test(value);
};

export const getOrdinalSuffix = (num: number) => {
  if (!num) return '';
  const remainderTen = num % 10;
  const remainderHundred = num % 100;

  if (remainderTen === 1 && remainderHundred !== 11) {
    return 'st';
  } else if (remainderTen === 2 && remainderHundred !== 12) {
    return 'nd';
  } else if (remainderTen === 3 && remainderHundred !== 13) {
    return 'rd';
  } else {
    return 'th';
  }
};

export function singularizeWord(number: number, word: string) {
  if (number === 1.0) {
    return word?.replace(/s$/, '');
  }
  return word;
}
