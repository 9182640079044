import { Flex, Text, Skeleton, Stack } from '@chakra-ui/react';
import { PieChartShared } from '@devd-client/devd/components';

type Props = {
  data: any;
  showLegendOnBottom?: boolean;
};

const PieSection = ({ data, showLegendOnBottom = true }: Props) => {
  const isAllZero = data?.every((entry: any) => entry?.value === 0);
  const fallbackData = [{ value: 1 }];

  return (
    <PieChartShared
      data={
        isAllZero
          ? fallbackData
          : data?.filter((entry: any) => entry?.value !== 0)
      }
      pieSize={40}
      showPercent
      showLegendOnBottom={showLegendOnBottom}
      isAllZero={isAllZero}
    />
  );
};

export default PieSection;
