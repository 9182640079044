import { Button, Flex, IconButton, Text } from '@chakra-ui/react';
import { CrossIcon, DragIcon } from '../../icons';

export const PermanentItem = ({ column }: { column: any }) => {
  return (
    <Flex
      px={2}
      py={1}
      bg={'#EAEAFB'}
      borderRadius={4}
      justify={'space-between'}
      outline={'1px solid #E2E2E7'}
      align={'center'}
    >
      <Flex gap={1} align={'center'}>
        <IconButton
          icon={<DragIcon />}
          aria-label="drag-button"
          cursor={'grab'}
          size={'sm'}
          opacity={0.25}
          p={0}
          isDisabled
        />
        <Text fontSize={'sm'}>{column}</Text>
      </Flex>
      <IconButton
        icon={<CrossIcon fill={'grey'} color="grey" height={20} width={14} />}
        aria-label="delete-metric"
        size={'sm'}
        p={0}
        opacity={0.75}
        background={'transparent'}
        color={'blue'}
        isDisabled
        maxW={250}
        overflow={'hidden'}
      />
    </Flex>
  );
};
