import { Button, Tooltip } from '@chakra-ui/react';
import { useGlobalState } from '../hooks/useGlobalState';

interface DownloadDataButtonProps {
  downloadCSV: () => void;
  pending?: boolean;
}

export const DownloadDataButton = ({
  downloadCSV,
  pending,
}: DownloadDataButtonProps) => {
  const { globalState } = useGlobalState();
  const downloadAllowed = globalState?.dto?.downloadAllowed;

  const ButtonComponent = (
    <Button
      w={'full'}
      variant="tertiary"
      onClick={downloadCSV}
      mt={2}
      isLoading={pending}
      isDisabled={!downloadAllowed}
    >
      Download data
    </Button>
  );

  return downloadAllowed ? (
    ButtonComponent
  ) : (
    <Tooltip hasArrow label="Upgrade plan to download data">
      {ButtonComponent}
    </Tooltip>
  );
};

export default DownloadDataButton;
