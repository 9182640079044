import { gql } from 'graphql-request';
import { graphQLClient } from '@devd-client/api';

export const getPrChartsGraphql = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  metric: string,
  teamId: string,
  project: string,
  searchTerm: string,
  author: string,
  reviewer: string,
  pageNumber: number,
  pageSize: number,
  timeZone?: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetIndividualCollaborationDetail(
        $orgId: String
        $startDate: String!
        $endDate: String
        $sprintId: String!
        $metric: String!
        $teamId: String!
        $granularity: String!
        $author: String!
        $reviewer: String!
        $pageNumber: Int!
        $pageSize: Int!
        $timeZone: String
      ) {
        IndividualCollaborationDetail(
          metric: $metric
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
            orgId: $orgId
            teamId: $teamId
            project: ""
            author: $author
            reviewer: $reviewer
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            id
            link
            author
            summary
            repository
            addition
            deletions
            files
            commentCount
            prCycleTime
            codingTime
            pickupTime
            mergeTime
            status
            issueId
            createdAt
            updatedAt
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      sprintId,
      metric,
      teamId,
      project,
      searchTerm,
      granularity: '',
      author,
      reviewer,
      pageNumber,
      pageSize,
      timeZone,
    }
  );

export const getTeamListGraphql = async (
  metric: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  pageNumber: number,
  pageSize: number,
  searchTerm: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetTeamDetails(
        $metric: String!
        $granularity: String!
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $teamId: String!
        $pageNumber: Int!
        $pageSize: Int!
        $project: String!
        $searchTerm: String!
      ) {
        TeamDetails(
          metric: $metric
          granularity: $granularity
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
            teamId: $teamId
            project: $project
            searchTerm: $searchTerm
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            name
            members
            contributors
            codeImpact
            gitActivity {
              count
              fillColor
              label
            }
            issueActivity {
              count
              fillColor
              label
            }
            qualityActivity {
              count
              fillColor
              label
            }

            doraActivity {
              count
              fillColor
              label
            }
            leadTimeForChange {
              codingCycleTime
              pickupCycleTime
              mergeCycleTime
              deployTime
            }
            prCycleTime
            prCycleTimeBreakUp {
              pickupCycleTime
              mergeCycleTime
            }
            velocity
            meetingActivity {
              count
              fillColor
              label
            }
            documentUpdatedCount
          }
          itemMeta {
            label
            measurement
            metric
            key
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      granularity: '',
      metric,
      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      sprintId,
      teamId,
      pageNumber,
      pageSize,
      project: '',
      searchTerm,
    }
  );

export const getContributorsList = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  pageNumber: number,
  pageSize: number,
  project: string,
  searchTerm: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetContributorsForDate(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $teamId: String!
        $pageNumber: Int!
        $pageSize: Int!
        $project: String!
        $searchTerm: String!
      ) {
        ContributorsForDate(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
            teamId: $teamId
            project: $project
            searchTerm: $searchTerm
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            contributorAccount
            codeImpact
            storyPointsCompleted
            gitActivity {
              count
              fillColor
              label
            }
            issueActivity {
              count
              fillColor
              label
            }

            prCycleTime
            prCycleTimeBreakUp {
              mergeCycleTime
              pickupCycleTime
            }
            activeDays
            meetingActivity {
              count
              fillColor
              label
            }
          }
          itemMeta {
            label
            measurement
            metric
            key
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      sprintId,
      teamId,
      pageNumber,
      pageSize,
      project: '',
      searchTerm: '',
    }
  );

export const getInProgress = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  project: string,
  searchTerm: string,
  reviewer: string,
  author: string,
  type: string,
  status: string,
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetInProgressItems(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $project: String!
        $searchTerm: String!
        $reviewer: String!
        $author: String!
        $sprintId: String!
        $type: String!
        $status: String!
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        InProgressItems(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            project: $project
            searchTerm: $searchTerm
            reviewer: $reviewer
            author: $author
            sprintId: $sprintId
            type: $type
            status: $status
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            id
            assignee
            title
            link
            type
            openDays
          }
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      teamId,
      project: '',
      searchTerm: '',
      reviewer: '',
      author: '',
      sprintId,
      type: '',
      status: '',
      pageNumber,
      pageSize,
    }
  );

export const getSprintVelocityList = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetSprintActivityByOrg(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $teamId: String!
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        SprintActivityByOrg(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
            teamId: $teamId
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            sprintName
            sprintId
            project
            startDate
            endDate
            commitedTasks
            completedTasks
            unplannedTasks
            commitedSP
            completedSP
          }
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      sprintId,
      teamId,
      pageNumber,
      pageSize,
    }
  );

export const getTeamCollaborationList = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetTeamCollaborationDetails(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $teamId: String!
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        TeamCollaborationDetails(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
            teamId: $teamId
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          collaborations {
            reviewer {
              name
              value
            }
            submitters {
              name
              value
            }
          }
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      sprintId,
      teamId,
      pageNumber,
      pageSize,
    }
  );

export const getCodeVelocityList = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  pageNumber: number,
  pageSize: number,
  project: string,
  searchTerm: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetRepositoryActivitiesForDate(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $teamId: String!
        $pageNumber: Int!
        $pageSize: Int!
        $project: String!
        $searchTerm: String!
      ) {
        RepositoryActivitiesForDate(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
            teamId: $teamId
            project: $project
            searchTerm: $searchTerm
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            repositoryName
            prMerge
            prOpen
            prReview
            prCycleTime
            prChanges
            prComments
            commits
            dependencyAlert
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      sprintId,
      teamId,
      pageNumber,
      pageSize,
      project: '',
      searchTerm: '',
    }
  );

export const goalsGenericGraphql = async (
  metric: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetGoalMetricsByType(
        $orgId: String!
        $metric: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $teamId: String!
      ) {
        GoalMetricsByType(
          metric: $metric
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
            teamId: $teamId
          }
        ) {
          chartMetadata {
            chartType
            chartTitle
            info
            xlabel
            ylabel
            xAxis
            yAxis
            chartKey
          }
          data
          legend {
            color
            label
          }
          keys {
            key
            name
            color
          }
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      metric,
      startDate,
      endDate,
      sprintId,
      teamId,
    }
  );

export const teamStatsGraphql = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  type: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query TeamStats(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $project: String!
        $searchTerm: String!
        $reviewer: String!
        $author: String!
        $sprintId: String!
        $type: String!
        $status: String!
      ) {
        TeamStats(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            project: $project
            searchTerm: $searchTerm
            reviewer: $reviewer
            author: $author
            sprintId: $sprintId
            type: $type
            status: $status
          }
        ) {
          metricStats {
            previousData {
              prevAvgValue
              percentChange
              improved
            }
            metric
            currentData
            chartMetadata {
              chartTitle
            }
          }
          chartDataState
          chartError {
            link
            message
          }
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      teamId,
      project: '',
      searchTerm: '',
      reviewer: '',
      author: '',
      sprintId,
      type,
      status: '',
    }
  );

export const doraChartTeamStatsGraphql = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query TeamStats(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $project: String!
        $searchTerm: String!
        $reviewer: String!
        $author: String!
        $sprintId: String!
        $type: String!
        $status: String!
      ) {
        TeamStats(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            project: $project
            searchTerm: $searchTerm
            reviewer: $reviewer
            author: $author
            sprintId: $sprintId
            type: $type
            status: $status
          }
        ) {
          metricStats {
            metric
            currentData
            chartMetadata {
              chartTitle
            }
            previousData {
              prevAvgValue
              percentChange
              improved
            }
            breakdownList {
              breakdowns {
                key
                title
                icon
                subTitle
                value
              }
              title
              subTitle
              unit
            }
            performanceRank
          }
          chartDataState
          chartError {
            link
            message
          }
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      teamId,
      project: '',
      searchTerm: '',
      reviewer: '',
      author: '',
      sprintId,
      type: 'dora',
      status: '',
    }
  );

export const codeStatsGraphql = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query TeamStats(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $project: String!
        $searchTerm: String!
        $reviewer: String!
        $sprintId: String!
        $type: String!
        $status: String!
      ) {
        TeamStats(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            project: $project
            searchTerm: $searchTerm
            reviewer: $reviewer
            sprintId: $sprintId
            type: $type
            status: $status
          }
        ) {
          metricStats {
            metric
            currentData
            previousData {
              prevAvgValue
              percentChange
              improved
              reason
            }
            chartMetadata {
              chartTitle
            }
            breakdownList {
              breakdowns {
                key
                title
                icon
                subTitle
                value
              }
              title
              subTitle
              unit
            }
            performanceRank
          }
          chartDataState
          chartError {
            message
            link
          }
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      teamId,
      project: '',
      searchTerm: '',
      reviewer: '',
      sprintId,
      type: 'code',
      status: '',
    }
  );

export const getTeamComparisonData = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teams: string[],
  metric: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetTeamComparisonData(
        $filter: Filter!
        $metric: String!
        $teams: [String]!
        $metric: String!
      ) {
        TeamComparisonData(filter: $filter, metric: $metric, teams: $teams) {
          metricName
          rows {
            team
            count
            unit
            improved
            percentage
            color
            totalCount
          }
        }
      }
    `,
    {
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate,
        endDate,
        sprintId,
      },
      metric,
      teams,
    }
  );

export const getIssueBySprintGraphql = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  author: string,
  type: string,
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetIssueDetailsBySprint(
        $startDate: String!
        $endDate: String!
        $orgId: String!
        $sprintId: String!
        $teamId: String!
        $author: String!
        $type: String!
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        IssueDetailsBySprint(
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            sprintId: $sprintId
            teamId: $teamId
            author: $author
            type: $type
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            id
            summary
            assignee
            ticketType
            updatedDate
            createdAt
            status
            ticketUrl
            estimate
          }
          chartDataState
          chartError {
            message
            link
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      sprintId,
      teamId,
      author,
      type,
      pageNumber,
      pageSize,
    }
  );
