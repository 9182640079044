import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Select,
  useTheme,
  Flex,
  Box,
  Radio,
  Image,
  Avatar,
  TableContainer,
  Tooltip,
  Input,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { MergeIcon, PlusIcon } from '../icons';
import { QUERY_KEYS, useContributorsMutation } from './api';
import { AddManually } from '../AddManually';
import { useToastHook } from '../appToast';
import { useQueryClient } from '@tanstack/react-query';
import { AccountName, generateTeamAvatar } from '../shared/SharedComponents';

interface MergeContributorModalProps {
  isOpen: boolean;
  onClose: () => void;
  data?: any;
  teams?: any;
  successAction: () => void;
}

export const MergeContributorModal: FC<MergeContributorModalProps> = ({
  isOpen,
  onClose,
  data,
  teams,
  successAction,
}) => {
  const [showManualSelection, setShowManualSelection] = useState(false);
  const { colors } = useTheme();
  const [selectedEmails, setSelectedEmails] = useState<string[]>([
    data?.email,
    data?.suggestedEmail,
  ]);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedAccountsFromSearch, setSelectedAccountsFromSearch] = useState<
    any[]
  >([]);
  const [primaryEmailSelection, setPrimaryEmailSelection] = useState(
    data?.suggestedEmail
  );

  const { mutate: handleMerge, isLoading } = useContributorsMutation();
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();

  const handleCheckboxChange = (email: string) => {
    setSelectedEmails((prev) =>
      prev.includes(email) ? prev.filter((e) => e !== email) : [...prev, email]
    );
  };

  const handleRadioChange = (email: string) => {
    setPrimaryEmailSelection(email);
    if (email !== '' && email !== 'null' && !selectedEmails.includes(email)) {
      setSelectedEmails((prev) => [...prev, email]);
    }
  };

  const [emailError, setEmailError] = useState('');

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (!primaryEmailSelection || primaryEmailSelection === 'null') {
      setEmailError('Official email is required');
    } else if (!isValidEmail(primaryEmailSelection)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  }, [primaryEmailSelection]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedEmails((prev) => {
        const suggestedEmail = data?.suggestedEmail;
        const manualEmails = selectedAccountsFromSearch?.map(
          (account) => account.email
        );
        const suggestedContributorEmails =
          data?.suggestedContributors?.map(
            (contributor: any) => contributor.emailId
          ) || [];

        const allEmails = [...prev];
        if (suggestedEmail && !prev.includes(suggestedEmail)) {
          allEmails.push(suggestedEmail);
        }
        suggestedContributorEmails.forEach((email: string) => {
          if (!prev.includes(email)) {
            allEmails.push(email);
          }
        });
        manualEmails.forEach((email) => {
          if (!prev.includes(email)) {
            allEmails.push(email);
          }
        });

        return allEmails;
      });
    } else {
      setSelectedEmails((prev) => {
        const emailsToKeep = [data?.email];

        return [...new Set(emailsToKeep)];
      });
    }
  };

  const getFilteredAccounts = () => {
    const allAccounts: any[] = [];

    if (
      primaryEmailSelection !== data?.email &&
      selectedEmails.includes(data?.email)
    ) {
      data?.contributorAccountList?.forEach((acc: any) => {
        allAccounts.push(acc.key.account);
      });
    }

    //Need to add this logic when we have contributor accounts for suggested email
    // if (
    //   primaryEmailSelection !== data?.suggestedEmail &&
    //   selectedEmails.includes(data?.suggestedEmail)
    // ) {
    //   data?.suggestedContributors?.forEach((acc: any) => {
    //     allAccounts.push(acc.key.account);
    //   });
    // }

    data?.suggestedContributors?.forEach((acc: any) => {
      if (
        primaryEmailSelection !== acc.emailId &&
        selectedEmails.includes(acc.emailId)
      ) {
        allAccounts.push(acc.key.account);
      }
    });

    selectedAccountsFromSearch.forEach((account) => {
      if (
        primaryEmailSelection !== account.email &&
        selectedEmails.includes(account.email)
      ) {
        account.contributorAccountList?.forEach((acc: any) => {
          allAccounts.push(acc.key.account);
        });
      }
    });

    return allAccounts;
  };

  const [similarityThreshold, setSimilarityThreshold] = useState('90');

  const handleMergeAboveThreshold = () => {
    const threshold = parseInt(similarityThreshold);
    const suggestedEmail = data?.suggestedEmail;
    const suggestedScore = data?.suggestedScore;
    const suggestedContributors = data?.suggestedContributors || [];

    setSelectedEmails((prev) => {
      const newEmails = [...prev];

      if (suggestedEmail && suggestedScore >= threshold) {
        if (!prev.includes(suggestedEmail)) {
          newEmails.push(suggestedEmail);
        }
      }

      suggestedContributors.forEach((contributor: any) => {
        const contributorEmail = contributor.emailId;
        const contributorScore = contributor.suggestionScore;

        if (
          contributorEmail &&
          contributorScore >= threshold &&
          !prev.includes(contributorEmail)
        ) {
          newEmails.push(contributorEmail);
        }
      });

      return newEmails;
    });
  };

  const handleMergeSubmit = () => {
    const primaryEmail = primaryEmailSelection;
    const accounts = getFilteredAccounts();
    if (primaryEmail) {
      handleMerge(
        {
          email: primaryEmail,
          accounts: accounts,
        },
        {
          onError: (err: Error | undefined | unknown) => {
            newToast({
              message: (err as Error)?.message || 'Something went wrong!',
              status: 'error',
            });
          },
          onSuccess: () => {
            newToast({ message: 'Merged successfully!', status: 'success' });
            queryClient.invalidateQueries([QUERY_KEYS.contributors]);
            setTimeout(() => {
              successAction();
            }, 200);
          },
        }
      );
    }
  };

  const isAccountAlreadyPresent = (email: string) => {
    if (data?.email === email) {
      return true;
    }

    if (data?.suggestedEmail === email) {
      return true;
    }

    if (selectedAccountsFromSearch.some((account) => account.email === email)) {
      return true;
    }

    return false;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={'4px'}
        boxShadow="0px 0px 14px rgba(0, 0, 0, 0.1)"
        fontFamily={'heading'}
      >
        <ModalHeader>
          <Text fontSize="21px" color={'text.primary'}>
            Merge Contributors
          </Text>
          <Text
            fontWeight={'normal'}
            fontSize="sm"
            color={'text.secondary2'}
            mt={'4px'}
          >
            Merged Contributors will be combined with the primary account.
          </Text>
        </ModalHeader>

        <ModalCloseButton onClick={onClose} />

        <ModalBody px={6} maxH={'65vh'} overflowY={'auto'}>
          <Box overflowX="auto" width="100%">
            <TableContainer>
              <Table variant="simple" layout="fixed">
                <Thead
                  bg={'#FBFBFB'}
                  border={'1px solid #E0E0E0'}
                  borderRadius={'8px'}
                >
                  <Tr fontSize={'sm'} fontFamily={'heading'}>
                    <Th
                      color={'text.secondary2'}
                      textTransform="none"
                      fontWeight={'medium'}
                      width={'250px'}
                    >
                      Name
                    </Th>
                    <Th
                      color={'text.secondary2'}
                      textTransform="none"
                      fontWeight={'medium'}
                      width={'280px'}
                    >
                      Email
                    </Th>
                    <Th
                      color={'text.secondary2'}
                      textTransform="none"
                      fontWeight={'medium'}
                      width={'280px'}
                    >
                      Contributors Account
                    </Th>
                    <Th
                      color={'text.secondary2'}
                      textTransform="none"
                      fontWeight={'medium'}
                      width={'130px'}
                    >
                      Official Email
                    </Th>
                    <Th
                      color={'text.secondary2'}
                      textTransform="none"
                      fontWeight={'medium'}
                      width={'280px'}
                    >
                      Teams
                    </Th>
                  </Tr>
                </Thead>
                <Tbody
                  fontFamily={'heading'}
                  fontSize={'sm'}
                  color={'text.primary'}
                >
                  <Tr verticalAlign={'top'}>
                    <Td>
                      <Flex gap={2} align="center">
                        <Avatar size="xs" src={data?.cell?.getValue()} />
                        <Text isTruncated>{data?.name}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Tooltip label={data?.email}>
                        <Text isTruncated>{data?.email}</Text>
                      </Tooltip>
                    </Td>
                    <Td>
                      <Flex flexDir={'column'} gap={2} flexWrap="wrap">
                        {data?.contributorAccountList?.map(
                          (el: any, idx: number) => (
                            <AccountName
                              key={idx}
                              title={el?.key?.account}
                              imgType={el?.key?.type}
                            />
                          )
                        )}
                      </Flex>
                    </Td>
                    <Td>
                      <Flex align={'center'} justify={'center'}>
                        <Radio
                          color={colors.primary}
                          isChecked={
                            primaryEmailSelection !== 'null' &&
                            primaryEmailSelection !== '' &&
                            primaryEmailSelection === data?.email
                          }
                          onChange={() => handleRadioChange(data?.email)}
                          borderColor={'#8F8F91'}
                        />
                      </Flex>
                    </Td>
                    <Td>
                      <Flex flexDir={'column'} gap={1.5}>
                        {teams
                          ? teams?.map((team: string) => (
                              <Flex align={'center'} key={team}>
                                <Box>{generateTeamAvatar(team)}</Box>
                                <Text
                                  fontSize={'sm'}
                                  ml={1}
                                  isTruncated
                                  w={'100%'}
                                >
                                  {team}
                                </Text>
                              </Flex>
                            ))
                          : data?.teams?.map((team: string) => (
                              <Flex align={'center'} key={team}>
                                <Box>{generateTeamAvatar(team)}</Box>
                                <Text
                                  fontSize={'sm'}
                                  ml={1}
                                  isTruncated
                                  w={'100%'}
                                >
                                  {team}
                                </Text>
                              </Flex>
                            ))}
                      </Flex>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Box>

          <Flex
            justifyContent={'space-between'}
            align={'center'}
            fontFamily={'heading'}
            fontWeight={'normal'}
            fontSize="md"
            color={'text.secondary2'}
          >
            <Text>Merge Suggestions based on similarity check algorithm</Text>
            <Flex
              justifyContent="space-between"
              gap={2}
              mb={4}
              alignItems="center"
            >
              <Button
                size="sm"
                variant="outline"
                border={'1px solid #E0E0E0'}
                borderRadius={'4px'}
                px={'16px'}
                py={'18px'}
                color={'text.secondary2'}
                fontWeight={'medium'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={2}
                onClick={handleMergeAboveThreshold}
              >
                <MergeIcon />
                Merge All
              </Button>
              above
              <Select
                size="sm"
                w={'fit-content'}
                h={'36px'}
                defaultValue="90"
                border={'1px solid #E0E0E0'}
                borderRadius={'4px'}
                pl={'16px'}
                py={'16px'}
                color={'text.secondary2'}
                fontWeight={'medium'}
                ml={'-14px'}
                bg={'white'}
                onChange={(e) => setSimilarityThreshold(e.target.value)}
              >
                <option value="90">90%</option>
                <option value="80">80%</option>
                <option value="70">70%</option>
              </Select>
            </Flex>
          </Flex>

          <Box overflowX="auto" width="100%" mt={'-14px'}>
            <TableContainer overflowX={'hidden'}>
              <Table variant="simple" layout="fixed">
                <Thead
                  bg={'#FBFBFB'}
                  border={'1px solid #E0E0E0'}
                  borderRadius={'4px'}
                >
                  <Tr fontSize={'sm'} fontFamily={'heading'}>
                    <Th width={'50px'}>
                      <Checkbox
                        isChecked={selectAll}
                        onChange={handleSelectAll}
                        borderColor={'#8F8F91'}
                      />
                    </Th>
                    <Th
                      width={'200px'}
                      color={'text.secondary2'}
                      textTransform="none"
                      fontWeight={'medium'}
                    >
                      Name
                    </Th>
                    <Th
                      width={'280px'}
                      color={'text.secondary2'}
                      textTransform="none"
                      fontWeight={'medium'}
                    >
                      Email
                    </Th>
                    <Th
                      width={'280px'}
                      color={'text.secondary2'}
                      textTransform="none"
                      fontWeight={'medium'}
                    >
                      Contributors Accounts
                    </Th>
                    <Th
                      width={'130px'}
                      color={'text.secondary2'}
                      textTransform="none"
                      fontWeight={'medium'}
                      textAlign={'center'}
                    >
                      Official Email
                    </Th>
                    <Th
                      width={'280px'}
                      color={'text.secondary2'}
                      textTransform="none"
                      fontWeight={'medium'}
                    >
                      Similarity
                    </Th>
                  </Tr>
                </Thead>
                <Tbody fontFamily={'heading'} fontSize={'sm'} color={'#2A2A2F'}>
                  <Tr>
                    <Td>
                      <Checkbox
                        isChecked={selectedEmails.includes(
                          data?.suggestedEmail
                        )}
                        onChange={() =>
                          handleCheckboxChange(data?.suggestedEmail)
                        }
                        color={colors.primary}
                        borderColor={'#8F8F91'}
                      />
                    </Td>
                    <Td>
                      <Text isTruncated>{data?.name}</Text>
                    </Td>
                    <Td>
                      <Tooltip label={data?.suggestedEmail}>
                        <Text isTruncated>{data?.suggestedEmail}</Text>
                      </Tooltip>
                    </Td>
                    <Td>
                      {/*Currently not getting contributors accounts for suggested email*/}
                      {/* <Flex flexDir={'column'} gap={2} flexWrap="wrap">
                            {data?.suggestedContributors?.map(
                              (contributor: any, idx: number) => (
                                <AccountName
                                  key={idx}
                                  title={contributor?.key?.account || ''}
                                  imgType={contributor?.key?.type || ''}
                                />
                              )
                            )}
                          </Flex> */}
                    </Td>
                    <Td>
                      <Flex align={'center'} justify={'center'}>
                        <Radio
                          isChecked={
                            primaryEmailSelection !== 'null' &&
                            primaryEmailSelection !== '' &&
                            primaryEmailSelection === data?.suggestedEmail
                          }
                          onChange={() =>
                            handleRadioChange(data?.suggestedEmail)
                          }
                          borderColor={'#8F8F91'}
                        />
                      </Flex>
                    </Td>
                    <Td>{data?.suggestedScore}%</Td>
                  </Tr>
                  {data?.suggestedContributors?.map(
                    (contributor: any, idx: number) => (
                      <Tr>
                        <Td>
                          <Checkbox
                            isChecked={selectedEmails.includes(
                              contributor?.emailId
                            )}
                            onChange={() =>
                              handleCheckboxChange(contributor?.emailId)
                            }
                            color={colors.primary}
                            borderColor={'#8F8F91'}
                          />
                        </Td>
                        <Td>
                          <Text isTruncated>
                            {contributor?.name ? contributor?.name : data?.name}
                          </Text>
                        </Td>
                        <Td>
                          <Tooltip label={contributor?.emailId}>
                            <Text isTruncated>{contributor?.emailId}</Text>
                          </Tooltip>
                        </Td>
                        <Td>
                          <Flex flexDir={'column'} gap={2} flexWrap="wrap">
                            <AccountName
                              key={idx}
                              title={contributor?.key?.account || ''}
                              imgType={contributor?.key?.type || ''}
                            />
                          </Flex>
                        </Td>
                        <Td>
                          <Flex align={'center'} justify={'center'}>
                            <Radio
                              isChecked={
                                primaryEmailSelection !== 'null' &&
                                primaryEmailSelection !== '' &&
                                primaryEmailSelection === contributor?.emailId
                              }
                              onChange={() =>
                                handleRadioChange(contributor?.emailId)
                              }
                              borderColor={'#8F8F91'}
                            />
                          </Flex>
                        </Td>
                        <Td>{contributor?.suggestionScore}%</Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>

          {selectedAccountsFromSearch?.length > 0 && (
            <Box overflowX="auto" width="100%" mt={0}>
              <TableContainer overflowX={'hidden'}>
                <Table variant="simple" layout="fixed">
                  <Tbody
                    fontFamily={'heading'}
                    fontSize={'sm'}
                    color={'text.primary'}
                  >
                    {selectedAccountsFromSearch?.map((account, index) => (
                      <Tr
                        key={index}
                        borderTop={'1px solid #E0E0E0'}
                        borderBottom={'none'}
                        verticalAlign={'top'}
                      >
                        <Td w={'50px'}>
                          <Checkbox
                            isChecked={selectedEmails.includes(account.email)}
                            onChange={() => handleCheckboxChange(account.email)}
                            color={colors.primary}
                            borderColor={'#8F8F91'}
                          />
                        </Td>
                        <Td width={'200px'}>
                          <Text isTruncated>{account.name}</Text>
                        </Td>
                        <Td width={'280px'}>
                          <Tooltip label={account.email}>
                            <Text isTruncated>{account.email}</Text>
                          </Tooltip>
                        </Td>
                        <Td width={'280px'}>
                          <Flex flexDir={'column'} gap={2} flexWrap="wrap">
                            {account?.contributorAccountList?.map(
                              (el: any, idx: number) => (
                                <AccountName
                                  key={idx}
                                  title={el?.key?.account}
                                  imgType={el?.key?.type}
                                />
                              )
                            )}
                          </Flex>
                        </Td>
                        <Td width={'130px'}>
                          <Flex align={'center'} justify={'center'}>
                            <Radio
                              isChecked={
                                primaryEmailSelection !== 'null' &&
                                primaryEmailSelection !== '' &&
                                primaryEmailSelection === account.email
                              }
                              onChange={() => handleRadioChange(account.email)}
                              borderColor={'#8F8F91'}
                            />
                          </Flex>
                        </Td>
                        <Td width={'280px'}>
                          <Flex flexDir={'column'} gap={1.5}>
                            {/* {account?.teams?.map((team: string) => (
                                  <Flex align={'center'} key={team}>
                                    <Box>{generateTeamAvatar(team)}</Box>
                                    <Text
                                      fontSize={'sm'}
                                      ml={1}
                                      isTruncated
                                      w={'100%'}
                                    >
                                      {team}
                                    </Text>
                                  </Flex>
                                ))} */}
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          )}

          <Box mt={4}>
            <Text mb={2} fontSize="sm" color="text.secondary2">
              Official Email
            </Text>
            <Input
              value={primaryEmailSelection || ''}
              onChange={(e) => {
                const email = e.target.value;
                setPrimaryEmailSelection(email);
              }}
              placeholder="Enter official email"
              size="md"
              borderColor={emailError ? 'red.400' : 'gray.200'}
              maxW="400px"
            />
            {emailError && (
              <Text fontSize="sm" color="red.400" mt={1}>
                {emailError}
              </Text>
            )}
          </Box>

          {!showManualSelection ? (
            <Flex
              gap={0.5}
              color={colors.primary}
              fontSize={'sm'}
              mt={4}
              alignItems={'center'}
              onClick={() => setShowManualSelection(true)}
              cursor={'pointer'}
            >
              <PlusIcon />
              Add more manually
            </Flex>
          ) : (
            <AddManually
              setSelectedAccountsFromSearch={setSelectedAccountsFromSearch}
              selectedEmails={selectedEmails}
              isAccountAlreadyPresent={isAccountAlreadyPresent}
            />
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            color={'text.secondary2'}
            p={'12px 16px'}
            bg={'transparent'}
            border={'1px solid #E0E0E0'}
            borderRadius={'4px'}
            onClick={onClose}
            fontSize={'md'}
            fontWeight={'medium'}
          >
            Cancel
          </Button>
          <Button
            ml={'8px'}
            color={'white'}
            p={'12px 16px'}
            bg={colors.primary}
            border={'1px solid #E0E0E0'}
            borderRadius={'4px'}
            fontSize={'md'}
            fontWeight={'medium'}
            onClick={handleMergeSubmit}
            isDisabled={
              selectedEmails?.length < 2 ||
              primaryEmailSelection === '' ||
              primaryEmailSelection === 'null' ||
              !!emailError ||
              isLoading
            }
            _hover={{ bg: colors.primary }}
          >
            Select & Merge ({selectedEmails?.length})
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MergeContributorModal;
