import { useCallback, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import CustomTooltip from './components/customTooltip/CustomTooltip';
import './chart.css';
import { useNavigate } from 'react-router';
import {
  CFR_CHART_KEYS,
  CONTRIBUTOR_CHART_KEYS,
  CUSTOM_CHART_KEYS,
  DEPENDENCY_VULNERABILITIES_CHART_KEYS,
  DEPLOY_CHART_KEYS,
  DOCUMENT_UPDATED_CHARTS,
  GOAL_METRIC_GIT_KEYS,
  GOAL_METRIC_JIRA_KEYS,
  ISSUE_CHART_KEYS,
  MEETING_CHARTS,
  PR_CHART_KEYS,
  PR_HEALTH_INDEX_CHARTS,
  QUALITY_CHART_KEYS,
  REQUIREMENT_STABILITY_CHARTS,
  splitTime,
} from '../shared/utils';
import MetricSidebar from './components/MetricSidebar';
import moment from 'moment';
import { WELLBEING_CHART_KEYS } from '../shared/utils/index';
import { useTheme, Box } from '@chakra-ui/react';

interface LineChartRechartsProps {
  chartref?: any;
  data: any;
  keys?: any;
  chartMetadata?: any;
  showLabel?: boolean;
  selectedTeam: string;
  selected?: any;
  hideBtn?: boolean;
  slant?: boolean;
  granularSlant?: boolean;
  ticketType?: string;
  forDetails?: boolean;
  queryKey?: string;
  repos?: string[];
  showYLabel?: boolean;
  showCustomLegend?: boolean;
}

export const LineChartRecharts = ({
  chartref,
  data,
  keys,
  chartMetadata,
  showLabel,
  selectedTeam,
  selected,
  hideBtn,
  slant,
  granularSlant,
  ticketType,
  forDetails,
  queryKey,
  repos,
  showYLabel,
  showCustomLegend,
}: LineChartRechartsProps) => {
  const { colors } = useTheme();
  const [hoveredLabel, setHoveredLabel] = useState<any>(null);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [clickedData, setClickedData] = useState<any>(null);
  const [currentChartMeta, setCurrentChartMeta] = useState<any>(null);

  const params = new URLSearchParams(window.location.search);
  const team = params.get('team');

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
  }, [isOpen]);

  const handleClick = (label: any) => {
    const labelId = data.find(
      (el: any) => el.xLabelName === label.value
    ).xLabelId;

    navigate(`sprint/${labelId}${team && `?team=${team}`}`);
  };

  const handleMouseEnter = (label: any) => {
    setHoveredLabel(label);
  };

  const handleMouseLeave = () => {
    setHoveredLabel(null);
  };

  const renderCustomTick = (props: any) => {
    const { x, y, payload } = props;
    const { value } = payload;
    const isHovered = value === hoveredLabel;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={4}
          fill={colors.primary}
          fontSize={7.5}
          fontWeight={isHovered ? 700 : 600}
          cursor="pointer"
          textAnchor="end"
          textDecoration={'underline'}
          onMouseEnter={() => handleMouseEnter(value)}
          onMouseLeave={handleMouseLeave}
          transform="rotate(-20)"
        >
          {value}
        </text>
      </g>
    );
  };

  const [isTooltipHovered, setIsTooltipHovered] = useState(false);
  const [hideTooltip, setHideTooltip] = useState(false);

  const handleTooltipHoverChange = (hovered: boolean) => {
    setIsTooltipHovered(hovered);
  };

  const CustomLegend = ({ keys }: { keys: any[] }) => (
    <div
      style={{
        position: 'absolute',
        right: '-2%',
        top: '10%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0 20px',
      }}
    >
      {keys?.map((entry) => {
        const isDashed = entry?.chartType === 'dashed_line_chart';
        return (
          <div
            key={entry?.key}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
              whiteSpace: 'nowrap',
            }}
          >
            <svg width="20" height="3" style={{ marginRight: '8px' }}>
              <line
                x1="0"
                y1="1.5"
                x2="20"
                y2="1.5"
                stroke={entry?.color}
                strokeWidth="3"
                strokeDasharray={isDashed ? '2 2' : 'none'}
              />
            </svg>
            <span style={{ color: '#666666', fontSize: '11.5px' }}>
              {entry?.name}
            </span>
          </div>
        );
      })}
    </div>
  );

  return (
    <Box style={{ position: 'relative', width: '100%', height: '100%' }}>
      <ResponsiveContainer ref={chartref} width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 10,
            right: showCustomLegend ? 216 : 35,
            left: showLabel || showYLabel ? 0 : slant ? 108 : -5,
            bottom: granularSlant || slant ? 52 : 0,
          }}
          onMouseEnter={() => {
            handleTooltipHoverChange(false);
            setHideTooltip(false);
          }}
          onMouseLeave={() => {
            handleTooltipHoverChange(true);
            setHideTooltip(true);
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey={chartMetadata.xAxis}
            axisLine={false}
            tickLine={false}
            style={{
              fontSize: 7.5,
              fontWeight: 500,
              cursor: slant ? 'pointer' : undefined,
            }}
            angle={slant || granularSlant ? -60 : undefined}
            textAnchor={granularSlant || slant ? 'end' : 'middle'}
            interval={granularSlant || slant ? 0 : undefined}
            onClick={slant ? handleClick : undefined}
            tick={slant ? renderCustomTick : undefined}
          />

          <YAxis
            tickFormatter={(value) => {
              if (chartMetadata.ylabel === 'Hour') {
                return splitTime(value);
              } else if (chartMetadata.dataType === 'TIME') {
                return splitTime(value / 60);
              } else {
                if (value >= 1000000) {
                  return `${(value / 1000000).toFixed(1)}M`;
                } else if (value >= 1000) {
                  return `${(value / 1000).toFixed(1)}K`;
                }
                return value;
              }
            }}
            label={
              !showYLabel
                ? {}
                : {
                    value: chartMetadata.ylabel,
                    angle: -90,
                    position: 'insideLeft',
                    fontSize: 11,
                    fontWeight: 400,
                    dy: 40,
                  }
            }
            axisLine={false}
            tickLine={false}
            style={{ fontSize: 11, fontWeight: 400 }}
          />

          <Tooltip
            content={
              <CustomTooltip
                handleModalOpen={handleModalOpen}
                setClickedData={setClickedData}
                unit={chartMetadata.ylabel}
                onClickAction={() => {
                  setCurrentChartMeta(chartMetadata);
                }}
                chartKey={chartMetadata?.chartKey}
                hideTooltip={hideTooltip}
                hideBtn={hideBtn}
                onHoverChange={handleTooltipHoverChange}
              />
            }
            trigger={isTooltipHovered ? 'click' : 'hover'}
            wrapperStyle={{ outline: 'none' }}
            position={{ y: -30 }}
            allowEscapeViewBox={{ x: false, y: false }}
          />

          {showLabel && !showCustomLegend && (
            <Legend
              verticalAlign="middle"
              layout="vertical"
              iconType="circle"
              iconSize={11}
              align="right"
              wrapperStyle={{
                right: -8,
                fontSize: 11.5,
                top: keys?.length > 15 ? '0%' : '20%',
                transform: keys?.length > 15 ? 'none' : 'translateY(-50%)',
              }}
            />
          )}

          {keys?.map((el: any) =>
            el.key === 'yRollingAvg' || el.chartType === 'dashed_line_chart' ? (
              <Line
                key={el.key}
                type="monotone"
                dataKey={el.key}
                name={el.name}
                stroke={el.color}
                dot={false}
                activeDot={false}
                strokeWidth={2}
                strokeDasharray="3 6"
              />
            ) : (
              <Line
                key={el.key}
                dataKey={el.key}
                name={el.name}
                stroke={el.color}
                strokeWidth={2}
                activeDot={{
                  r: 6,
                  strokeWidth: 3,
                  stroke: '#9DB2BF',
                  cursor: 'pointer',
                  onClick: (event: any, payload: any) => {
                    handleModalOpen();
                    setClickedData({ ...payload, name: el.name });
                    setCurrentChartMeta(chartMetadata);
                  },
                  className: 'active-dot',
                }}
                dot={{
                  fill: el.color,
                  stroke: el.color,
                  strokeWidth: 3,
                  r: 2,
                }}
              />
            )
          )}
        </LineChart>
      </ResponsiveContainer>
      {showLabel && showCustomLegend && <CustomLegend keys={keys} />}
      {[
        ...PR_CHART_KEYS,
        ...CFR_CHART_KEYS,
        ...DEPLOY_CHART_KEYS,
        ...CONTRIBUTOR_CHART_KEYS,
        ...ISSUE_CHART_KEYS,
        ...GOAL_METRIC_GIT_KEYS,
        ...GOAL_METRIC_JIRA_KEYS,
        ...WELLBEING_CHART_KEYS,
        ...QUALITY_CHART_KEYS,
        ...CUSTOM_CHART_KEYS,
        ...REQUIREMENT_STABILITY_CHARTS,
        ...DEPENDENCY_VULNERABILITIES_CHART_KEYS,
        ...MEETING_CHARTS,
        ...PR_HEALTH_INDEX_CHARTS,
        ...DOCUMENT_UPDATED_CHARTS,
      ].includes(currentChartMeta?.chartKey) &&
        isOpen && (
          <MetricSidebar
            querykey={queryKey}
            openDrawer={isOpen}
            handleDrawerClose={handleModalClose}
            heading={chartMetadata.chartTitle}
            subHeading={
              currentChartMeta?.chartKey === REQUIREMENT_STABILITY_CHARTS[0]
                ? `${moment(clickedData[0]?.payload?.xLabelName).format(
                    'DD MMM'
                  )}`
                : `${
                    Array.isArray(clickedData)
                      ? moment(clickedData[0]?.payload?.startDate).format(
                          'DD MMM'
                        )
                      : moment(clickedData?.payload?.startDate).format('DD MMM')
                  } - ${
                    Array.isArray(clickedData)
                      ? moment(clickedData[0]?.payload?.endDate).format(
                          'DD MMM'
                        )
                      : moment(clickedData?.payload?.endDate).format('DD MMM')
                  }${
                    QUALITY_CHART_KEYS?.includes(currentChartMeta?.chartKey)
                      ? `, ${clickedData?.name} ${
                          clickedData?.value ? `(${clickedData?.value})` : ''
                        }`
                      : clickedData || (clickedData && clickedData[0])
                      ? `${
                          clickedData[0]?.name === 'Hours' ||
                          clickedData?.name === 'Hours'
                            ? ''
                            : `, ${clickedData[0]?.name || clickedData?.name}${
                                clickedData[0]?.payload?.y ||
                                clickedData?.payload?.y
                                  ? ` (${
                                      clickedData[0]?.payload?.y ||
                                      clickedData?.payload?.y
                                    })`
                                  : ''
                              }`
                        } `
                      : ''
                  }`
            }
            payload={clickedData}
            currentChartMeta={currentChartMeta}
            filterBy={{
              teamId: selectedTeam,
              type:
                ticketType || chartMetadata.chartKey === 'BUG_THROUGHPUT'
                  ? 'bug'
                  : chartMetadata.chartKey === 'PR_HEALTH_INDEXES'
                  ? 'merged_without_review'
                  : '',
              projectsFilter: repos,
              sprintId: selected?.sprintId,
            }}
            repoTitle={repos && repos?.length >= 1}
            selectedTeam={selectedTeam}
          />
        )}
    </Box>
  );
};
