import {
  Box,
  Button,
  Flex,
  Image,
  Spinner,
  Text,
  useDisclosure,
  Alert,
} from '@chakra-ui/react';
import {
  Banner,
  DateFilter,
  DemoCalendarModal,
  durationData,
  MetaTags,
  PageToolbar,
  removeEmptyQueryParams,
  TeamMenu,
  useDashboardStore,
  useQueryState,
  useToastHook,
} from '@devd-client/devd/components';
import Signals from '../components/Signals';
import MetricsOverview from '../components/MetricsOverview';
import { useSignalsGql } from '../graphql';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  useG2UserReview,
  useGetG2AccessToken,
  useShownTrialEndModal,
} from '../api';
import G2Logo from '../../../components/src/shared/assets/g2-logo.svg';
import { IoCloseOutline } from 'react-icons/io5';
import { ChatButton } from '../components/chatButton';

export const HomeInsights = () => {
  const [appState, dispatch] = useAuth();
  const { selectedTeam, selected, setSelectedTeam, setSelected } =
    useDashboardStore();
  const [periodOptions, setPeriodOptions] = useState<any>(durationData);

  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [team, setTeam] = useQueryState('team');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [tab, setTab] = useQueryState('tab');

  const { data: signalData, isLoading: signalDataLoading } = useSignalsGql(
    selectedTeam.value,
    selected.startDate,
    selected.endDate
  );

  const { data, isLoading, refetch } = useGetG2AccessToken(
    encodeURIComponent(appState?.user?.email),
    localStorage.getItem('orgId') || ''
  );

  const { mutate: handleG2Review } = useG2UserReview();
  const [newToast] = useToastHook();
  const [reviewUrl, setReviewUrl] = useState('');
  const [isOpenReview, setIsOpenReview] = useState(false);
  const [isCheckOpen, setIsCheckOpen] = useState(false);
  const [hideReviewBtn, setHideReviewBtn] = useState(false);
  const [isLoadingFrame, setIsLoadingFrame] = useState(true);
  const { mutate: handleTrialEndModal } = useShownTrialEndModal();
  const [currentTab, setCurrentTab] = useState<string>('All');

  const demoCalendarModal = useDisclosure();
  const trialEndModal = useDisclosure();

  useEffect(() => {
    if (!isLoading && data?.data) {
      try {
        let encodedEmail = encodeURIComponent(appState?.user?.email);
        encodedEmail = encodedEmail.replace(/\./g, '%2E');
        const state = JSON.parse(data?.data)?.state;
        setReviewUrl(
          `https://www.g2.com/partnerships/DevDynamics/users/login.embed?state=${state}&email=${encodedEmail}`
        );
      } catch (error) {
        console.error('Invalid JSON:', error);
        // Handle invalid JSON error (ignore or show a default value)
      }
    }
  }, [appState?.user?.email, data, isLoading, reviewUrl]);

  const handleSubmitReview = async () => {
    setHideReviewBtn(true);
    await refetch();
    setReviewUrl('');
    handelReviewSubmit();
    handleReviewClose();
  };

  const handleReviewClose = () => {
    setIsCheckOpen(false);
    setIsOpenReview(false);
    setIsLoadingFrame(true);
  };

  const handelReviewSubmit = () => {
    newToast({
      message:
        'Thank you for submitting your review! We appreciate your feedback.',
      status: 'success',
    });
    handleG2Review({
      userId: localStorage.getItem('userId'),
      orgId: localStorage.getItem('orgId'),
      email: appState?.user?.email,
    });
  };

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'Organisation'
        ? setTeam('Organisation')
        : setTeam(selectedTeam.value);
    }

    if (typeof currentTab !== 'undefined') {
      setTab(currentTab);
    }
  }, [selected, selectedTeam.value, currentTab]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'Organisation'
          ? { value: '', label: 'Organisation' }
          : { value: team, label: team }
      );

    tab && setCurrentTab(tab);

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  return (
    <Flex direction="column" width="100%" minH="100%">
      <MetaTags
        title="Home | Devdynamics"
        description="Welcome to Devdynamics"
        keywords="home, devdynamics"
      />

      {appState?.user?.dto?.announcement && <Banner appState={appState} />}

      <Flex mb={4}>
        <Text fontFamily="heading" fontSize="xl" fontWeight="semibold">
          {`👋🏻 Welcome to DevDynamics, ${
            appState?.user?.dto?.firstName || appState?.user?.email
          }!`}
        </Text>
        <Flex ml={'auto'} gap={3}>
          <ChatButton salesButtonAction={() => demoCalendarModal.onOpen()} />
          {reviewUrl && !hideReviewBtn && (
            <Button
              backgroundColor={'transparent'}
              border={'1px'}
              borderColor={'#FF492C'}
              borderRadius={'4px'}
              paddingX={'14px'}
              paddingY={'10px'}
              fontSize={'14px'}
              fontFamily={'inter'}
              fontWeight={500}
              color={'#FF492C'}
              ml={'auto'}
              _hover={{ bg: 'transparent' }}
              onClick={() => setIsOpenReview(true)}
            >
              <Image src={G2Logo} height={'20px'} width={'20px'} mr={2} />
              Write a Review
            </Button>
          )}
        </Flex>
      </Flex>
      {reviewUrl && isOpenReview && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          backgroundColor="rgba(0, 0, 0, 0.5)"
          alignItems="center"
          justifyContent="center"
          zIndex="999"
          onClick={() => setIsCheckOpen(true)}
        >
          {isLoadingFrame && (
            <Box position={'absolute'} top={'47%'} left={'49%'}>
              <Spinner />
            </Box>
          )}
          <Box
            as="iframe"
            src={reviewUrl}
            width="55%"
            height="75%"
            backgroundColor="white"
            border="none"
            borderRadius="8px"
            boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
            onLoad={() => setIsLoadingFrame(false)}
          />
          <Box
            position={'absolute'}
            top={4}
            right={4}
            cursor={'pointer'}
            onClick={() => setIsCheckOpen(true)}
          >
            <IoCloseOutline color="white" size={32} />
          </Box>
        </Flex>
      )}
      {isCheckOpen && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          alignItems="center"
          justifyContent="center"
          zIndex="1000"
        >
          <Flex
            h={'20vh'}
            w={'25vw'}
            rounded={'8px'}
            shadow={'lg'}
            gap={4}
            alignItems={'center'}
            justifyContent={'space-between'}
            flexDirection={'column'}
            padding={6}
            bg={'white'}
            fontFamily={'inter'}
          >
            <Text
              fontSize={'18px'}
              fontWeight={'semibold'}
              textAlign={'center'}
            >
              Have you submitted the review?
            </Text>
            <Flex justifyContent={'center'} gap={10} w={'full'}>
              <Button
                bg={'transparent'}
                onClick={handleSubmitReview}
                _hover={{ bg: 'gray.200' }}
                paddingX={2}
                rounded={2}
              >
                Yes
              </Button>
              <Button
                bg={'transparent'}
                onClick={handleReviewClose}
                _hover={{ bg: 'gray.200' }}
                paddingX={2}
                rounded={2}
              >
                No
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}

      <PageToolbar
        leftComp={
          <>
            <TeamMenu
              selectedTeam={selectedTeam}
              handleSelectedTeam={setSelectedTeam}
              AuthActionTypes={AuthActionTypes}
              getUser={getUser}
              appState={appState}
              dispatch={dispatch}
            />
          </>
        }
        rightComp={
          <DateFilter
            getUser={getUser}
            appState={appState}
            dispatch={dispatch}
            AuthActionTypes={AuthActionTypes}
          />
        }
      />

      <Signals
        signalData={signalData}
        setCurrentTab={setCurrentTab}
        signalDataLoading={signalDataLoading}
        currentTab={currentTab}
        selected={selected}
      />

      <MetricsOverview />
      {demoCalendarModal.isOpen && (
        <DemoCalendarModal
          isOpen={demoCalendarModal.isOpen}
          showBackBtn
          onClose={() => {
            handleTrialEndModal({
              id: appState?.user?.dto?.id,
              displayed: false,
            });
            demoCalendarModal.onClose();
          }}
          onBackAction={() => {
            demoCalendarModal.onClose();
            trialEndModal.onOpen();
          }}
        />
      )}
    </Flex>
  );
};

export default HomeInsights;
