import { Box, SimpleGrid } from '@chakra-ui/react';
import {
  Loader,
  PageHeader,
  PageToolbar,
  TeamMenu,
  DateFilter,
} from '@devd-client/devd/components';
import {
  CumulativeFlowAreaChart,
  FlowScatterChart,
  LineChartCard,
} from '@devd-client/devd/ui-shared';

import { FC, useContext } from 'react';
import { FlowContext } from '../../contexts/flowContext';
import {
  useAgingDistributionGql,
  useFlowGql,
  useIssueCycleTimeGql,
} from '../../graphql';
import { useSearchParams } from 'react-router-dom';
import { AuthActionTypes, getUser } from '@devd-client/devd/auth';
import { useCommonMetrics } from '../../hooks/useCommonMetrics';

interface FlowPageProps {
  metricsData: any;
}

export const FlowPage: FC<FlowPageProps> = ({ metricsData }) => {
  const [searchParams] = useSearchParams();
  const metricType = searchParams.get('type');
  const helpDocLink = metricsData?.dto?.helpDoc;

  const { selected, selectedTeam, setSelectedTeam, appState } =
    useCommonMetrics();

  const { dispatch } = useContext(FlowContext);

  const { isFetching, data: AreaChartData } = useFlowGql(
    selected.startDate,
    selected.endDate,
    selectedTeam.value,
    selected.sprintId,
    dispatch
  );

  const { isFetching: isFetchingCycleTime, data: ScatterChartData } =
    useIssueCycleTimeGql(
      selected.startDate,
      selected.endDate,
      selectedTeam.value,
      selected?.sprintId,
      dispatch
    );
  const {
    isFetching: isFetchingAgingDistribution,
    data: AgingDistributionScatterChartData,
  } = useAgingDistributionGql(
    selected.startDate,
    selected.endDate,
    selectedTeam.value,
    selected?.sprintId,
    dispatch
  );

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <Box>
        <PageHeader headingText="Flow" mb={'10px'} helpDocLink={helpDocLink} />
        <PageToolbar
          leftComp={
            <TeamMenu
              selectedTeam={selectedTeam}
              handleSelectedTeam={setSelectedTeam}
              AuthActionTypes={AuthActionTypes}
              getUser={getUser}
              appState={appState}
              dispatch={dispatch}
            />
          }
          rightComp={
            <DateFilter
              getUser={getUser}
              appState={appState}
              dispatch={dispatch}
              AuthActionTypes={AuthActionTypes}
            />
          }
        />
      </Box>

      <SimpleGrid mt={6} columns={{ lg: 2, md: 1, sm: 1 }} spacing={6}>
        {AreaChartData && (
          <LineChartCard
            chart={<CumulativeFlowAreaChart data={AreaChartData?.data} />}
            heading="Issues Distribution By Status"
          />
        )}
        {ScatterChartData && (
          <LineChartCard
            chart={
              <FlowScatterChart
                data={ScatterChartData?.units}
                xLabel={ScatterChartData?.chartMetadata.xlabel}
                yLabel={ScatterChartData?.chartMetadata.ylabel}
              />
            }
            heading="Issues Cycle Time"
          />
        )}
        {/* {AgingDistributionScatterChartData && (
          <LineChartCard
            chart={
              <AgingDistributionScatterChart
                data={AgingDistributionScatterChartData?.units}
                xLabel={AgingDistributionScatterChartData?.chartMetadata.xlabel}
                yLabel={AgingDistributionScatterChartData?.chartMetadata.ylabel}
              />
            }
            heading="Issues Status Aging Distribution"
          />
        )} */}
      </SimpleGrid>

      {(isFetching || isFetchingCycleTime || isFetchingAgingDistribution) && (
        <Loader />
      )}
    </Box>
  );
};

export default FlowPage;
