import { Box, Flex, Text, useTheme, Image, Button } from '@chakra-ui/react';
import {
  NoDataFoundIcon,
  useMetricsTableStore,
} from '@devd-client/devd/components';
import Nodata from './no-data.png';
import { useNavigate } from 'react-router';

const NoData = () => {
  const metricFilterBy = useMetricsTableStore();
  return (
    <Flex w="100%" py={50} justify="center">
      <Flex justify="center" direction={'column'} align={'center'}>
        <Box w={170}>
          <Image src={Nodata} />
        </Box>

        <Text
          fontFamily="heading"
          fontWeight="semibold"
          fontSize={'lg'}
          mt={5}
          mb={2}
        >
          Oops! No {metricFilterBy.tableType === 'PR' ? 'PRs ' : 'Issues '}
          available with current filter.
        </Text>
        {['TOP_REVIEWER_DISTRIBUTION', 'PR_REVIEW', 'PR_REVIEWED'].includes(
          metricFilterBy.chartKey
        ) && metricFilterBy.isMergedWithReview === false ? (
          <Text fontFamily="heading" fontSize={'xs'}>
            This metric only measures PRs merged with Review.
          </Text>
        ) : ['PR_AGEING_DISTRIBUTION'].includes(metricFilterBy.chartKey) &&
          (metricFilterBy.isMergedWithReview !== null ||
            metricFilterBy.isLongPrCycleTime === true) ? (
          <Text fontFamily="heading" fontSize={'xs'}>
            This metric only measures data for not yet merged PRs.
          </Text>
        ) : (
          <></>
        )}
      </Flex>
    </Flex>
  );
};

export default NoData;
