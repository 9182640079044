import { Tooltip, useTheme } from '@chakra-ui/react';
import { IconButtonContainer } from '../appShared';
import { DownloadIcon } from '../icons';
import { useGlobalState } from '../hooks/useGlobalState';

interface DownloadButtonProps {
  downloadCSV: () => void;
}

export const DownloadButton = ({ downloadCSV }: DownloadButtonProps) => {
  const { colors } = useTheme();
  const { globalState } = useGlobalState();
  const downloadAllowed = globalState?.dto?.downloadAllowed;

  return (
    <Tooltip
      hasArrow
      placement="bottom-end"
      label={
        downloadAllowed ? 'Download Data' : 'Upgrade plan to download data'
      }
    >
      <IconButtonContainer onClick={downloadCSV} disabled={!downloadAllowed}>
        <DownloadIcon
          height="16px"
          width="16px"
          color={colors.text.secondary}
        />
      </IconButtonContainer>
    </Tooltip>
  );
};

export default DownloadButton;
