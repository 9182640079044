import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import CustomSelect from '../../customSelect';
import { AddBottomViewProps } from '../helpers/bottomView.types';
import { SaveAndPreviousButton } from './SaveAndPreviousButton';

type Option = {
  name: string;
  value: string;
};

interface AzureBoardFormProps {
  handleSaveClick: (
    params: AddBottomViewProps[],
    integrationId: string
  ) => void;
  data: any;
  integrationId: string;
  onPrevious: () => void;
  isFirstTab: boolean;
  isLastTab: boolean;
}

const formatOptionLabel = ({ value, label }: any) => {
  if (value === '') {
    return <span>&nbsp;</span>;
  }
  return label;
};

const getSelectedValue = (fieldName: string, data: any) => {
  return (
    data?.dto
      ?.filter((elem: any) => elem?.name === fieldName && elem?.value)
      ?.map((item: any) => {
        return item?.options?.find((el: any) => el?.value === item?.value)
          ?.name;
      })[0] || ''
  );
};

const payloadObject = (fieldName: string, selectedValue: string, data: any) => {
  return {
    name: fieldName,
    value: data?.dto
      ?.find((elem: any) => elem?.name === fieldName)
      .options?.map(({ name, value }: Option) => ({
        value: value,
        label: name,
      }))
      .filter((item: any) => item.label === selectedValue)[0]?.value,
  };
};

const getOptions = (fieldName: string, data: any) => {
  return [{ value: '', label: '' }].concat(
    data?.dto
      ?.find((elem: any) => elem?.name === fieldName)
      .options?.map(({ name, value }: Option) => ({
        value: value,
        label: name,
      })) || []
  );
};

const AzureBoardForm: FC<AzureBoardFormProps> = ({
  handleSaveClick,
  data,
  integrationId,
  onPrevious,
  isFirstTab,
  isLastTab,
}) => {
  const [selectedStoryPoint, setSelectedStoryPoint] = useState<string>('');
  useEffect(() => {
    setSelectedStoryPoint(getSelectedValue('StoryPoint', data));
  }, [data?.dto]);

  const onSubmit = () => {
    handleSaveClick(
      [payloadObject('StoryPoint', selectedStoryPoint, data)],
      integrationId
    );
  };

  const onSelectChange = (
    selected: string,
    fieldName: string,
    setFunc: (setItem: string) => void
  ) => {
    setFunc(
      data?.dto
        ?.find((elem: any) => elem?.name === fieldName)
        ?.options?.map(({ name, value }: { name: string; value: string }) => ({
          value: value,
          label: name,
        }))
        ?.filter((item: any) => item.value === selected)[0]?.label
    );
  };

  return (
    <Stack spacing={4}>
      <HStack spacing={6}>
        <Text
          fontSize="sm"
          fontFamily="heading"
          fontWeight="semibold"
          color="text.secondary"
          lineHeight="17px"
          minW="100px"
        >
          Story Point:
        </Text>
        <CustomSelect
          placeholder="Select Story point"
          onChange={(selected: string) =>
            onSelectChange(selected, 'StoryPoint', setSelectedStoryPoint)
          }
          defaultItem={{ value: selectedStoryPoint, label: selectedStoryPoint }}
          formatOptionLabel={formatOptionLabel}
          options={getOptions('StoryPoint', data)}
        />
      </HStack>
      <Box mt={6}>
        <SaveAndPreviousButton
          isFirstTab={isFirstTab}
          onPrevious={onPrevious}
          onSubmit={onSubmit}
          isLastTab={isLastTab}
        />
      </Box>
    </Stack>
  );
};

export default AzureBoardForm;
