import { Box, Grid, GridItem, Skeleton, Spinner } from '@chakra-ui/react';
import { AuthActionTypes, getUser } from '@devd-client/devd/auth';
import {
  PageHeader,
  PageToolbar,
  TeamMenu,
  MetaTags,
  MetricInfoItems,
  MetricDashboard,
  useAiAssistantStore,
  DateFilter,
} from '@devd-client/devd/components';

import { FC } from 'react';
import { Chatbox } from '../components/Chatbox';
import { useDoraDashboard } from '../hooks/useDoraDashboard';

export const Dora: FC = () => {
  const { isOpen } = useAiAssistantStore();
  const {
    selected,
    selectedTeam,
    setSelectedTeam,
    appState,
    dispatch,
    isLargerThan1680,
    QUERY_KEYS,
    data,
    isFetching,
    type,
    setType,
    clickedTab,
    setClickedTab,
  } = useDoraDashboard();

  return (
    <>
      <MetaTags title="Dora | Dashboard | Devdynamics" />

      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        <Box>
          <PageHeader headingText={'DORA'} mb={'10px'} />
          <PageToolbar
            leftComp={
              <>
                <TeamMenu
                  selectedTeam={selectedTeam}
                  handleSelectedTeam={setSelectedTeam}
                  AuthActionTypes={AuthActionTypes}
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                />
              </>
            }
            rightComp={
              <DateFilter
                getUser={getUser}
                appState={appState}
                dispatch={dispatch}
                AuthActionTypes={AuthActionTypes}
              />
            }
          />
        </Box>

        <Grid
          my={4}
          templateColumns={
            isLargerThan1680 ? 'repeat(4, 1fr)' : 'repeat(4, 1fr)'
          }
          gap={4}
        >
          {isFetching
            ? Array(4)
                .fill(null)
                .map((_, index) => (
                  <Box position={'relative'} minWidth="305px" height="109px">
                    <Skeleton minWidth="305px" height="109px" key={index} />
                    <Spinner position={'absolute'} top={'40%'} left={'43%'} />
                  </Box>
                ))
            : data?.map((item: any, idx: number) => (
                <GridItem key={idx}>
                  <MetricInfoItems
                    item={item}
                    type={type}
                    setType={setType}
                    clickedTab={clickedTab}
                    setClickedTab={setClickedTab}
                  />
                </GridItem>
              ))}
        </Grid>

        <MetricDashboard
          selected={selected}
          selectedTeam={selectedTeam.value}
          setType={setType}
          type={type}
          queryKey={QUERY_KEYS.allDoraThreshold}
        />
      </Box>
      {isOpen && <Chatbox />}
    </>
  );
};

export default Dora;
