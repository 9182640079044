import { create } from 'zustand';

interface GlobalState {
  globalState: any;
  setGlobalState: (globalState: any) => void;
}

export const useGlobalState = create<GlobalState>()((set) => ({
  globalState: {},
  setGlobalState: (globalState: any) => set({ globalState }),
}));
