export const CompareIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_8021_28)">
        <path
          d="M1 3.5L4.5 7L1 10.5V3.5Z"
          stroke="#2A2A2F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13 3.5L9.5 7L13 10.5V3.5Z"
          stroke="#2A2A2F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7 1V2.5"
          stroke="#2A2A2F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7 4.5V6"
          stroke="#2A2A2F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7 8V9.5"
          stroke="#2A2A2F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7 11.5V13"
          stroke="#2A2A2F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8021_28">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
