import { Flex, Box, Tooltip, Text } from '@chakra-ui/react';

const TestStatsItem = ({
  icon: Icon,
  label,
  count,
  color,
  percentage,
}: {
  icon: any;
  label: string;
  count: number;
  color: string;
  percentage: number | string;
}) => {
  return (
    <Flex align="center" gap={1}>
      <Tooltip hasArrow label={label}>
        <Box>
          <Icon size={18} color={color} />
        </Box>
      </Tooltip>

      <Text
        fontFamily={'heading'}
        fontSize="sm"
        color="text.primary"
        fontWeight={'normal'}
      >
        {count}
      </Text>

      <Text
        fontFamily={'heading'}
        fontSize="sm"
        color="text.secondary"
        fontWeight={'normal'}
        whiteSpace={'nowrap'}
      >
        {`(${Number(percentage)?.toFixed(1)}%)`}
      </Text>
    </Flex>
  );
};

export default TestStatsItem;
