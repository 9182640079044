import { BsSearch } from 'react-icons/bs';
import styled from 'styled-components';

type ToggleIconProps = {
  mr?: string;
  ml?: string;
  selected?: boolean;
  bgColor?: string;
};

export const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
`;

export const RightElements = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 2px;
  top: 10px;
  font-size: 12px;
  margin-right: 2px;
`;

export const LeftElements = styled.div`
  display: flex;
  align-items: center;
`;

type IconButtonProps = {
  withGradient?: boolean;
  disabled?: boolean;
};

export const IconButtonContainer = styled.button<IconButtonProps>`
  background: 'transparent';
  padding: 5px 6px;
  border-radius: 4px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;

  ${(props) =>
    props.withGradient &&
    `
    background: linear-gradient(to right, white, white),
      linear-gradient(to right bottom, #4d4dff, #c850c0, #ffcc70);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
  `}

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(1px);
  }

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.6; 
    transform: none; 
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0.1); 
    background: rgba(0, 0, 0, 0.05); 
  `}
`;

export const SearchBox = styled.form`
  display: flex;
  align-items: center;
  font-family: 'Inter';
  position: relative;
  width: fit-content;
  border-radius: 4px;
  margin-left: 12px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-right: 12px;
`;

export const SearchInput = styled.input<{ width?: string }>`
  width: ${(props) => props.width || '150px'};
  padding: 5px;
  font-size: 13px;
  font-weight: 400;
  color: #323130;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #718096;
  }
`;

export const StyledSearchIcon = styled(BsSearch)`
  color: #718096;
  font-size: 14px;
`;

export const SearchIconContainer = styled.button`
  width: 30px;
  height: 32px;
  display: grid;
  place-items: center;
  background-color: white;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

export const ToggleIconGroup = styled.div`
  display: flex;
`;

export const ToggleIcon = styled.button<ToggleIconProps>`
  height: 32px;
  width: 32px;
  display: grid;
  place-items: center;
  border-radius: 4px;
  color: ${({ selected }) => selected && '#fff'};
  background-color: ${({ selected, bgColor }) =>
    selected ? bgColor : '#F9F9F9'};
  margin-right: ${({ mr }) => mr};
  margin-left: ${({ ml }) => ml};
  transition: all 0.2s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(1px);
  }
`;
