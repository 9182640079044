import { Box, Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

interface BreadcrumbsProps {
  parentTeam?: string;
  originUrl?: string;
}

export const Breadcrumbs = ({ parentTeam, originUrl }: BreadcrumbsProps) => {
  const breadcrumbs = useBreadcrumbs();
  const modifiedBreadcrumbs = breadcrumbs?.reduce(
    (acc: any[], item: any, index: number) => {
      acc?.push(item);

      if (item?.match?.pathname?.includes('/teams/') && parentTeam) {
        acc?.splice(acc?.length - 1, 0, {
          breadcrumb: {
            props: {
              children:
                parentTeam?.charAt(0)?.toUpperCase() +
                (parentTeam?.length > 1 ? parentTeam?.slice(1) : ''),
            },
          },
          match: { pathname: `/${originUrl}/${parentTeam}` },
        });
      }
      return acc;
    },
    []
  );
  return (
    <Flex>
      {modifiedBreadcrumbs?.map(({ breadcrumb, match }: any, index: number) => (
        <Box key={match?.pathname}>
          <Text
            fontSize="sm"
            fontWeight="medium"
            color="text.secondary"
            as={Link}
            to={match?.pathname || ''}
            _hover={{ textDecoration: 'underline' }}
          >
            {match?.pathname?.endsWith('/cicd')
              ? 'CI/CD'
              : decodeURIComponent(breadcrumb?.props?.children)}
          </Text>
          &nbsp;
          {index < modifiedBreadcrumbs?.length - 1 && '>'}
          &nbsp;
        </Box>
      ))}
    </Flex>
  );
};

export default Breadcrumbs;
