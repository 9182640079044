import {
  Box,
  Button,
  Divider,
  Flex,
  GridItem,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { FieldArray, Form, Formik, FormikErrors, FormikValues } from 'formik';
import { BiChevronDown } from 'react-icons/bi';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { MdDeleteOutline } from 'react-icons/md';
import CustomSelect from '../customSelect';

import { useCustomfield } from './api';
import { FaPlus } from 'react-icons/fa';
import {
  convertConditionsToIssueFilters,
  definitionTypeOptions,
  definitionTypeOptionsForInitiatives,
  formatOptionLabel,
  getFormattedDate,
  propsConfig,
} from './helpers/issueFilterModal.utils';
import { useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { validationSchema } from './helpers/issueFilterModal.validation';
import Loader from '../appLoader';
import ProjectNameSelect from './components/ProjectNameSelect';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  issueFilterConditions: any;
  onSubmitAction: (payload: any) => void;
  loading?: boolean;
  buttonText?: string;
  forInitiatives?: boolean;
};

const initialStateValues: any = {
  issueFilters: [
    {
      criteriaFields: [
        {
          name: '',
          value: '',
          dateRange: {
            startDate: new Date(),
            endDate: new Date(),
          },
          customField: {
            name: '',
            value: '',
          },
        },
      ],
    },
  ],
};

export const IssueFilterModal = ({
  isOpen,
  onClose,
  issueFilterConditions,
  onSubmitAction,
  loading,
  buttonText,
  forInitiatives,
}: Props) => {
  const { data: customFieldData } = useCustomfield();
  const [initialValues, setInitialValues] = useState(initialStateValues);

  useEffect(() => {
    if (
      !issueFilterConditions?.conditions ||
      !issueFilterConditions ||
      issueFilterConditions === 'null' ||
      issueFilterConditions?.conditions === 'null'
    ) {
      setInitialValues({
        issueFilters: [
          {
            criteriaFields: [
              {
                name: '',
                value: '',
                dateRange: {
                  startDate: new Date(),
                  endDate: new Date(),
                },
                customField: {
                  name: '',
                  value: '',
                },
              },
            ],
          },
        ],
      });
    } else {
      setInitialValues({
        issueFilters: convertConditionsToIssueFilters(
          issueFilterConditions?.conditions
        ),
      });
    }
  }, [issueFilterConditions]);

  const onSubmit = (values: any) => {
    const item = values.issueFilters.map((el: any) => {
      return el.criteriaFields
        .filter((item: any) => item.name)
        .map((item: any) => {
          return item.name === 'Custom Field'
            ? {
                field: forInitiatives
                  ? definitionTypeOptionsForInitiatives.find(
                      (el: any) => el.text === item.name
                    )?.value
                  : definitionTypeOptions.find(
                      (el: any) => el.text === item.name
                    )?.value,
                customField: item.customField,
                operator: 'IN',
              }
            : {
                field: forInitiatives
                  ? definitionTypeOptionsForInitiatives.find(
                      (el: any) => el.text === item.name
                    )?.value
                  : definitionTypeOptions.find(
                      (el: any) => el.text === item.name
                    )?.value,
                value:
                  item.name === 'Date Range'
                    ? JSON.stringify({
                        startDate: getFormattedDate(item.dateRange.startDate),
                        endDate: getFormattedDate(item.dateRange.endDate),
                      })
                    : item.value,
                operator: item.name === 'Labels' ? 'LIKE' : 'IN',
              };
        });
    });
    const hasValidCriteria = item.some(
      (innerArray: any) => innerArray.length > 0
    );

    if (!hasValidCriteria) {
      onSubmitAction({ conditions: null });
    } else {
      onSubmitAction({ conditions: item });
    }
  };

  return (
    <Modal size="4xl" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text
            fontFamily="heading"
            fontWeight="semibold"
            color="text.primary"
            mb={1}
          >
            Configure Issue Filter Conditions
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH="480px" overflow="auto">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {(props) => {
              const {
                isSubmitting,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                errors,
                touched,
                values,
              } = props;

              const handleChangeCriteriaField = (
                issueIndex: number,
                criteriaIndex: number,
                name: string,
                value: string
              ) => {
                const issueFilters = [...values.issueFilters];
                issueFilters[issueIndex].criteriaFields[criteriaIndex] = {
                  ...issueFilters[issueIndex].criteriaFields[criteriaIndex],
                  [name]: value,
                };

                setFieldValue('issueFilters', issueFilters);
              };

              const handleAddCriteriaField = (issueIndex: number) => {
                const issueFilters = [...values.issueFilters];
                issueFilters[issueIndex].criteriaFields.push({
                  name: '',
                  value: '',
                  dateRange: {
                    startDate: new Date(),
                    endDate: new Date(),
                  },
                  customField: {
                    name: '',
                    value: '',
                  },
                });
                setFieldValue('issueFilters', issueFilters);
              };
              const handleRemoveCriteriaField = (
                issueIndex: number,
                criteriaIndex: number
              ) => {
                const issueFilters = [...values.issueFilters];

                issueFilters[issueIndex].criteriaFields.splice(
                  criteriaIndex,
                  1
                );

                if (issueFilters[issueIndex].criteriaFields.length === 0) {
                  if (issueFilters.length > 1) {
                    issueFilters.splice(issueIndex, 1);
                  }
                }

                setFieldValue('issueFilters', issueFilters);
              };

              const getFieldError = (
                errors: any,
                touched: any,
                issueIndex: number,
                criteriaIndex: number,
                field: string
              ): string | undefined => {
                const fieldError =
                  errors?.issueFilters?.[issueIndex]?.criteriaFields?.[
                    criteriaIndex
                  ]?.[field];
                const isFieldTouched =
                  touched?.issueFilters?.[issueIndex]?.criteriaFields?.[
                    criteriaIndex
                  ]?.[field];

                return isFieldTouched && fieldError
                  ? 'Field is required'
                  : undefined;
              };

              const getFieldErrorDateRange = (
                errors: any,
                touched: any,
                issueIndex: number,
                criteriaIndex: number,
                field: string
              ): string | undefined => {
                const issueFilterErrors = errors.issueFilters?.[issueIndex];
                const criteriaFieldErrors =
                  issueFilterErrors?.criteriaFields?.[criteriaIndex];
                const fieldError = criteriaFieldErrors?.dateRange?.[field];

                const isFieldTouched =
                  touched.issueFilters?.[issueIndex]?.criteriaFields?.[
                    criteriaIndex
                  ]?.dateRange?.[field];

                return isFieldTouched && fieldError ? fieldError : undefined;
              };

              const getFieldErrorCustomField = (
                errors: any,
                touched: any,
                issueIndex: number,
                criteriaIndex: number,
                field: string
              ): string | undefined => {
                const issueFilterErrors = errors.issueFilters?.[issueIndex];
                const criteriaFieldErrors =
                  issueFilterErrors?.criteriaFields?.[criteriaIndex];
                const fieldError = criteriaFieldErrors?.customField?.[field];

                const isFieldTouched =
                  touched.issueFilters?.[issueIndex]?.criteriaFields?.[
                    criteriaIndex
                  ]?.customField?.[field];

                return isFieldTouched && fieldError ? fieldError : undefined;
              };

              return (
                <Form>
                  <FieldArray
                    name="issueFilters"
                    render={(arrayHelpers) => (
                      <Flex direction="column">
                        {values.issueFilters.map(
                          (filter: any, issueIndex: number) => (
                            <Box key={issueIndex} mb={4}>
                              <FieldArray
                                name={`issueFilters.${issueIndex}.criteriaFields`}
                                render={(criteriaHelpers) => (
                                  <Flex
                                    direction="column"
                                    bg="#e9e9e9"
                                    p={4}
                                    py={6}
                                    borderRadius="4px"
                                    position="relative"
                                  >
                                    {values.issueFilters?.length > 1 &&
                                      issueIndex !== 0 && (
                                        <Box
                                          position="absolute"
                                          right={-2}
                                          top={-2}
                                        >
                                          <Tooltip
                                            hasArrow
                                            label="Remove this section"
                                          >
                                            <IconButton
                                              isRound
                                              onClick={() =>
                                                arrayHelpers.remove(issueIndex)
                                              }
                                              bg="transparent"
                                              aria-label="cross"
                                              icon={<RxCross2 />}
                                            />
                                          </Tooltip>
                                        </Box>
                                      )}

                                    {filter.criteriaFields.map(
                                      (
                                        criteriaField: any,
                                        criteriaIndex: number
                                      ) => {
                                        return (
                                          <GridItem
                                            mb={2}
                                            colSpan={2}
                                            key={criteriaIndex}
                                          >
                                            <Flex
                                              alignItems="center"
                                              alignContent={'center'}
                                              justify={'space-between'}
                                              minH={
                                                criteriaField.name! ===
                                                  'Custom Field' ||
                                                criteriaField.name ===
                                                  'Date Range'
                                                  ? '80px'
                                                  : '60px'
                                              }
                                            >
                                              <Box width="100px">
                                                <Text
                                                  fontSize="sm"
                                                  fontFamily="heading"
                                                  fontWeight="semibold"
                                                  color="text.secondary"
                                                  lineHeight="17px"
                                                  display={'block'}
                                                >
                                                  {criteriaIndex === 0
                                                    ? `Criteria`
                                                    : `AND`}
                                                </Text>
                                              </Box>
                                              <Flex
                                                w="full"
                                                gap={2}
                                                alignItems={'end'}
                                              >
                                                <Box position={'relative'}>
                                                  <Menu>
                                                    <MenuButton
                                                      w="250px"
                                                      fontSize="sm"
                                                      fontFamily="heading"
                                                      fontWeight="normal"
                                                      bg="white"
                                                      border="1px solid #CBD5E0"
                                                      borderRadius="4px"
                                                      as={Button}
                                                      rightIcon={
                                                        <BiChevronDown />
                                                      }
                                                    >
                                                      {criteriaField.name ||
                                                        'Select Criteria'}
                                                    </MenuButton>
                                                    <MenuList>
                                                      {forInitiatives
                                                        ? definitionTypeOptionsForInitiatives
                                                            .filter(
                                                              (item) =>
                                                                !values.issueFilters[
                                                                  issueIndex
                                                                ].criteriaFields
                                                                  .map(
                                                                    (
                                                                      field: any
                                                                    ) =>
                                                                      field.name
                                                                  )
                                                                  .includes(
                                                                    item.text
                                                                  )
                                                            )
                                                            .map(
                                                              ({
                                                                value,
                                                                text,
                                                              }) => (
                                                                <MenuItem
                                                                  key={value}
                                                                  onClick={() =>
                                                                    handleChangeCriteriaField(
                                                                      issueIndex,
                                                                      criteriaIndex,
                                                                      'name',
                                                                      text
                                                                    )
                                                                  }
                                                                  display="flex"
                                                                  flexDirection="row"
                                                                  justifyContent="space-between"
                                                                  alignItems="center"
                                                                >
                                                                  {text}
                                                                </MenuItem>
                                                              )
                                                            )
                                                        : definitionTypeOptions
                                                            .filter(
                                                              (item) =>
                                                                !values.issueFilters[
                                                                  issueIndex
                                                                ].criteriaFields
                                                                  .map(
                                                                    (
                                                                      field: any
                                                                    ) =>
                                                                      field.name
                                                                  )
                                                                  .includes(
                                                                    item.text
                                                                  )
                                                            )
                                                            .map(
                                                              ({
                                                                value,
                                                                text,
                                                              }) => (
                                                                <MenuItem
                                                                  key={value}
                                                                  onClick={() =>
                                                                    handleChangeCriteriaField(
                                                                      issueIndex,
                                                                      criteriaIndex,
                                                                      'name',
                                                                      text
                                                                    )
                                                                  }
                                                                  display="flex"
                                                                  flexDirection="row"
                                                                  justifyContent="space-between"
                                                                  alignItems="center"
                                                                >
                                                                  {text}
                                                                </MenuItem>
                                                              )
                                                            )}
                                                    </MenuList>
                                                  </Menu>

                                                  {getFieldError(
                                                    errors,
                                                    touched,
                                                    issueIndex,
                                                    criteriaIndex,
                                                    'value'
                                                  ) && (
                                                    <Text
                                                      fontFamily="heading"
                                                      fontSize="xs"
                                                      color="error"
                                                      position="absolute"
                                                      bottom={-6}
                                                      left={2}
                                                    >
                                                      {getFieldError(
                                                        errors,
                                                        touched,
                                                        issueIndex,
                                                        criteriaIndex,
                                                        'name'
                                                      )}
                                                    </Text>
                                                  )}
                                                </Box>
                                                {criteriaField.name &&
                                                  criteriaField.name !==
                                                    'Date Range' && (
                                                    <Box
                                                      flex={1}
                                                      position="relative"
                                                    >
                                                      {criteriaField.name ===
                                                      'Project Names' ? (
                                                        <ProjectNameSelect
                                                          criteriaField={
                                                            criteriaField
                                                          }
                                                          issueIndex={
                                                            issueIndex
                                                          }
                                                          criteriaIndex={
                                                            criteriaIndex
                                                          }
                                                          handleChangeCriteriaField={
                                                            handleChangeCriteriaField
                                                          }
                                                        />
                                                      ) : (
                                                        criteriaField.name !==
                                                          'Custom Field' && (
                                                          <Input
                                                            w="full"
                                                            ml={2}
                                                            value={
                                                              criteriaField.value
                                                            }
                                                            onChange={(
                                                              e: React.FormEvent<HTMLInputElement>
                                                            ) =>
                                                              handleChangeCriteriaField(
                                                                issueIndex,
                                                                criteriaIndex,
                                                                'value',
                                                                e.currentTarget
                                                                  .value
                                                              )
                                                            }
                                                            variant="outline"
                                                            placeholder={`Enter values for ${criteriaField.name}`}
                                                            colorScheme={
                                                              'primary'
                                                            }
                                                          />
                                                        )
                                                      )}

                                                      {getFieldError(
                                                        errors,
                                                        touched,
                                                        issueIndex,
                                                        criteriaIndex,
                                                        'value'
                                                      ) && (
                                                        <Text
                                                          fontFamily="heading"
                                                          fontSize="xs"
                                                          color="error"
                                                          position="absolute"
                                                          bottom={-5}
                                                          left={2}
                                                        >
                                                          {getFieldError(
                                                            errors,
                                                            touched,
                                                            issueIndex,
                                                            criteriaIndex,
                                                            'value'
                                                          )}
                                                        </Text>
                                                      )}
                                                    </Box>
                                                  )}
                                                {criteriaField.name ===
                                                  'Date Range' && (
                                                  <Flex
                                                    w="full"
                                                    ml={2}
                                                    mt={-5}
                                                    flexDirection="row"
                                                    alignItems="flex-start"
                                                  >
                                                    <Stack
                                                      spacing={2}
                                                      mr={4}
                                                      flex={1}
                                                      position="relative"
                                                    >
                                                      <Text
                                                        fontSize="sm"
                                                        fontFamily="heading"
                                                        fontWeight="semibold"
                                                        color="text.secondary"
                                                        lineHeight="17px"
                                                      >
                                                        Criteria Start Date
                                                      </Text>
                                                      <SingleDatepicker
                                                        name={`issueFilters.${issueIndex}.criteriaFields.${criteriaIndex}.dateRange.startDate`}
                                                        date={
                                                          new Date(
                                                            criteriaField.dateRange.startDate
                                                          )
                                                        }
                                                        configs={{
                                                          dateFormat:
                                                            'dd-MM-yyyy',
                                                        }}
                                                        onDateChange={(
                                                          date
                                                        ) => {
                                                          setFieldValue(
                                                            `issueFilters.${issueIndex}.criteriaFields.${criteriaIndex}.dateRange.startDate`,
                                                            date
                                                          );
                                                          setFieldTouched(
                                                            `issueFilters.${issueIndex}.criteriaFields.${criteriaIndex}.dateRange.startDate`,
                                                            true
                                                          );
                                                        }}
                                                        propsConfigs={
                                                          propsConfig
                                                        }
                                                      />
                                                      {getFieldErrorDateRange(
                                                        errors,
                                                        touched,
                                                        issueIndex,
                                                        criteriaIndex,
                                                        'startDate'
                                                      ) && (
                                                        <Text
                                                          fontFamily={'heading'}
                                                          color="error"
                                                          fontSize="xs"
                                                        >
                                                          {getFieldErrorDateRange(
                                                            errors,
                                                            touched,
                                                            issueIndex,
                                                            criteriaIndex,
                                                            'startDate'
                                                          )}
                                                        </Text>
                                                      )}
                                                    </Stack>
                                                    <Stack spacing={2} flex={1}>
                                                      <Text
                                                        fontSize="sm"
                                                        fontFamily="heading"
                                                        fontWeight="semibold"
                                                        color="text.secondary"
                                                        lineHeight="17px"
                                                      >
                                                        Criteria End Date
                                                      </Text>
                                                      <SingleDatepicker
                                                        name={`issueFilters.${issueIndex}.criteriaFields.${criteriaIndex}.dateRange.endDate`}
                                                        date={
                                                          criteriaField
                                                            .dateRange.endDate
                                                        }
                                                        configs={{
                                                          dateFormat:
                                                            'dd-MM-yyyy',
                                                        }}
                                                        onDateChange={(
                                                          date
                                                        ) => {
                                                          setFieldValue(
                                                            `issueFilters.${issueIndex}.criteriaFields.${criteriaIndex}.dateRange.endDate`,
                                                            date
                                                          );
                                                          setFieldTouched(
                                                            `issueFilters.${issueIndex}.criteriaFields.${criteriaIndex}.dateRange.endDate`,
                                                            true
                                                          );
                                                        }}
                                                        propsConfigs={
                                                          propsConfig
                                                        }
                                                      />

                                                      {getFieldErrorDateRange(
                                                        errors,
                                                        touched,
                                                        issueIndex,
                                                        criteriaIndex,
                                                        'endDate'
                                                      ) && (
                                                        <Text
                                                          fontFamily={'heading'}
                                                          color="error"
                                                          fontSize="xs"
                                                        >
                                                          {getFieldErrorDateRange(
                                                            errors,
                                                            touched,
                                                            issueIndex,
                                                            criteriaIndex,
                                                            'endDate'
                                                          )}
                                                        </Text>
                                                      )}
                                                    </Stack>
                                                  </Flex>
                                                )}
                                                {criteriaField.name ===
                                                  'Custom Field' && (
                                                  <Flex gap={2}>
                                                    <CustomSelect
                                                      width="200px"
                                                      defaultItem={{
                                                        label:
                                                          customFieldData?.dto?.find(
                                                            (option: any) =>
                                                              option.value ===
                                                              criteriaField
                                                                .customField
                                                                .name
                                                          )?.name || '',
                                                        value:
                                                          criteriaField
                                                            .customField.name,
                                                      }}
                                                      menuPlacement="auto"
                                                      label={
                                                        'Select Custom Field Name'
                                                      }
                                                      placeholder={
                                                        'Select Custom Field Name'
                                                      }
                                                      onChange={(
                                                        selected: string
                                                      ) => {
                                                        setFieldValue(
                                                          `issueFilters.${issueIndex}.criteriaFields.${criteriaIndex}.customField.name`,
                                                          selected
                                                        );

                                                        setFieldTouched(
                                                          `issueFilters.${issueIndex}.criteriaFields.${criteriaIndex}.customField.name`,
                                                          true
                                                        );
                                                      }}
                                                      formatOptionLabel={
                                                        formatOptionLabel
                                                      }
                                                      options={[
                                                        {
                                                          value: '',
                                                          label: '',
                                                        },
                                                      ].concat(
                                                        customFieldData?.dto?.map(
                                                          (e: any) => {
                                                            return {
                                                              label: e.name,
                                                              value: e.value,
                                                            };
                                                          }
                                                        )
                                                      )}
                                                    />
                                                    <Box
                                                      position="relative"
                                                      flex={1}
                                                    >
                                                      <Input
                                                        value={
                                                          criteriaField
                                                            .customField.value
                                                        }
                                                        onChange={(
                                                          e: React.FormEvent<HTMLInputElement>
                                                        ) => {
                                                          setFieldValue(
                                                            `issueFilters.${issueIndex}.criteriaFields.${criteriaIndex}.customField.value`,
                                                            e.currentTarget
                                                              .value
                                                          );

                                                          setFieldTouched(
                                                            `issueFilters.${issueIndex}.criteriaFields.${criteriaIndex}.customField.value`,
                                                            true
                                                          );
                                                        }}
                                                        mt={'25px'}
                                                        placeholder="Type Custom Field Value"
                                                      />
                                                      {getFieldErrorCustomField(
                                                        errors,
                                                        touched,
                                                        issueIndex,
                                                        criteriaIndex,
                                                        'value'
                                                      ) && (
                                                        <Text
                                                          fontFamily={'heading'}
                                                          color="error"
                                                          fontSize="xs"
                                                          position="absolute"
                                                        >
                                                          {getFieldErrorCustomField(
                                                            errors,
                                                            touched,
                                                            issueIndex,
                                                            criteriaIndex,
                                                            'value'
                                                          )}
                                                        </Text>
                                                      )}
                                                    </Box>
                                                  </Flex>
                                                )}
                                                <Tooltip
                                                  label="Delete"
                                                  hasArrow
                                                >
                                                  <IconButton
                                                    bg="transparent"
                                                    onClick={() =>
                                                      handleRemoveCriteriaField(
                                                        issueIndex,
                                                        criteriaIndex
                                                      )
                                                    }
                                                    color="error"
                                                    position={'static'}
                                                    aria-label="delete"
                                                    icon={
                                                      <MdDeleteOutline
                                                        size={16}
                                                      />
                                                    }
                                                  />
                                                </Tooltip>
                                              </Flex>
                                            </Flex>
                                          </GridItem>
                                        );
                                      }
                                    )}
                                    <Flex gap={2} mt={2}>
                                      {filter.criteriaFields.length < 6 && (
                                        <Button
                                          size="sm"
                                          type="button"
                                          leftIcon={<FaPlus />}
                                          onClick={() =>
                                            handleAddCriteriaField(issueIndex)
                                          }
                                        >
                                          {filter.criteriaFields.length < 1
                                            ? 'Add Criteria'
                                            : 'AND'}
                                        </Button>
                                      )}
                                    </Flex>
                                  </Flex>
                                )}
                              />

                              {values.issueFilters.length ===
                                issueIndex + 1 && (
                                <>
                                  <Divider my={3} />
                                  <Button
                                    size="sm"
                                    type="button"
                                    leftIcon={<FaPlus />}
                                    onClick={() =>
                                      arrayHelpers.push({
                                        criteriaFields: [
                                          {
                                            name: '',
                                            value: '',
                                            dateRange: {
                                              startDate: new Date(),
                                              endDate: new Date(),
                                            },
                                            customField: {
                                              name: '',
                                              value: '',
                                            },
                                          },
                                        ],
                                      })
                                    }
                                  >
                                    OR
                                  </Button>
                                </>
                              )}
                              {values.issueFilters.length !==
                                issueIndex + 1 && (
                                <Flex mt={4} align="center">
                                  <Divider />
                                  <Text
                                    fontFamily={'heading'}
                                    color="text.secondary2"
                                    fontWeight="normal"
                                  >
                                    OR
                                  </Text>
                                  <Divider />
                                </Flex>
                              )}
                            </Box>
                          )
                        )}

                        <Flex w="full" justify={'flex-end'}>
                          <Button variant="secondary" mr={3} onClick={onClose}>
                            Close
                          </Button>
                          <Button type="submit" variant="filled">
                            {buttonText ? buttonText : 'Submit'}
                          </Button>
                        </Flex>
                      </Flex>
                    )}
                  />
                </Form>
              );
            }}
          </Formik>
          {loading && <Loader />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default IssueFilterModal;
