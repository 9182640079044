import { createColumnHelper } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo } from 'react';
import {
  DataGrid,
  DonutChart,
  Paginate,
  PieChartShared,
} from '@devd-client/devd/components';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { RiArrowRightSLine } from 'react-icons/ri';
import { fillColors, fillColorsTaskDist } from '../../helpers/home.utils';
import SprintAreaChart from './AreaChart';
import moment from 'moment';
import SprintBarChart from './SprintBarChart';

interface SprintListProps {
  data: any;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  loading: boolean;
  team: string;
  slideDrawer: any;
  setBlockedIssues: any;
}

const SprintList: React.FC<SprintListProps> = ({
  data,
  currentPage,
  setCurrentPage,
  loading,
  team,
  slideDrawer,
  setBlockedIssues,
}) => {
  const navigate = useNavigate();

  const rows: any[] = useMemo(() => data?.reports || [], [data?.reports]);

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('sprint', {
        cell: (info) => (
          <Flex direction="column">
            {info.row.original?.sprintDetail?.sprintName && (
              <Text
                onClick={() =>
                  navigate(
                    `/sprint/${info?.row?.original?.sprintDetail?.sprintId}${
                      team ? `?team=${team}` : `?team=${'Organisation'}`
                    }`
                  )
                }
                as="span"
                color="primary"
                textDecoration="underline"
                fontFamily="heading"
                fontSize="md"
                cursor="pointer"
              >
                {info.row.original?.sprintDetail?.sprintName}
              </Text>
            )}

            {info.row.original?.sprintDetail?.startDate &&
              info.row.original?.sprintDetail?.endDate && (
                <Text fontSize={'xxs'} color="primary" fontFamily="heading">
                  {moment(info.row.original?.sprintDetail?.startDate).format(
                    'DD MMM'
                  )}{' '}
                  -{' '}
                  {moment(info.row.original?.sprintDetail?.endDate).format(
                    'DD MMM'
                  )}
                </Text>
              )}

            <Stack mt={2} spacing={1}>
              {info.row.original?.sprintDetail?.sprintDuration && (
                <Text as="span" fontWeight={'medium'} color="text.primary">
                  Days :{' '}
                  <Text as="span" fontWeight="normal" color="text.secondary">
                    {info.row.original?.sprintDetail?.sprintDuration}
                  </Text>
                </Text>
              )}

              {info.row.original?.sprintDetail?.status && (
                <Text as="span" fontWeight={'medium'} color="text.primary">
                  Status :{' '}
                  <Text as="span" fontWeight="normal" color="text.secondary">
                    {info.row.original?.sprintDetail?.status}
                  </Text>
                </Text>
              )}

              {info.row.original?.sprintDetail?.overCommitmentRate && (
                <Text as="span" fontWeight={'medium'} color="text.primary">
                  Overcommitment :{' '}
                  <Text as="span" fontWeight="normal" color="text.secondary">
                    {Number(
                      info.row.original?.sprintDetail?.overCommitmentRate
                    ) + '%' || 0}
                  </Text>
                </Text>
              )}

              {info.row.original?.sprintDetail?.scopeCreepRate && (
                <Text as="span" fontWeight={'medium'} color="text.primary">
                  Scope Creep :{' '}
                  <Text as="span" fontWeight="normal" color="text.secondary">
                    {Number(info.row.original?.sprintDetail?.scopeCreepRate) +
                      '%' || 0}
                  </Text>
                </Text>
              )}

              {info.row.original?.sprintDetail?.blockedIssues?.length > 0 && (
                <Text as="span" fontWeight={'medium'} color="text.primary">
                  Blockers :{' '}
                  <Text
                    as="span"
                    fontWeight="normal"
                    color="text.secondary"
                    onClick={() => {
                      setBlockedIssues(
                        info.row.original?.sprintDetail?.blockedIssues
                      );
                      slideDrawer.onOpen();
                    }}
                    textDecoration={'underline'}
                    cursor={'pointer'}
                  >
                    {Number(
                      info.row.original?.sprintDetail?.blockedIssues?.length ||
                        0
                    )}
                  </Text>
                </Text>
              )}
            </Stack>
          </Flex>
        ),

        header: 'Sprint Name',
        size: 180,
      }),
      columnHelper.accessor('sprintPlanning', {
        cell: (info) => (
          <>
            <Flex
              direction="row"
              width="full"
              justify="space-between"
              align="center"
            >
              <Flex height="160px" width="240px" direction="column">
                <SprintBarChart
                  data={info.row.original?.sprintPlanning?.tasks?.map(
                    (item: any, idx: number) => ({
                      ...item,
                      value:
                        item.value === 'null'
                          ? 0
                          : item.value === null
                          ? 0
                          : parseFloat(item.value),
                      fill: fillColors[idx],
                    })
                  )}
                />
              </Flex>
              <Flex
                height="140px"
                width="140px"
                direction="column"
                align="center"
              >
                <DonutChart
                  percentage={
                    info.row.original?.sprintPlanning?.planningAccuracy
                  }
                />
                <Text fontSize="xs" fontFamily="heading" color="text.secondary">
                  Planning Accuracy
                </Text>
              </Flex>
            </Flex>
          </>
        ),

        header: 'Sprint Planning',
        size: 200,
      }),

      columnHelper.accessor('sprintProgress', {
        cell: (info) => (
          <>
            <Flex
              direction="row"
              width="full"
              justify={'center'}
              align="center"
            >
              <Flex height="180px" width="240px" direction="column">
                <PieChartShared
                  showLegendOnBottom={false}
                  data={info.row.original?.sprintProgress?.tasks?.map(
                    (item: any, idx: number) => ({
                      ...item,
                      value:
                        item.value === 'null'
                          ? 0
                          : item.value === null
                          ? 0
                          : parseFloat(item.value),
                      fill: fillColors[idx],
                    })
                  )}
                />
              </Flex>
            </Flex>
          </>
        ),
        header: 'Current Status Dist',
        size: 200,
      }),
      columnHelper.accessor('velocity', {
        cell: (info) => (
          <>
            <Flex
              direction="column"
              width="full"
              justify={'center'}
              align="flex-start"
            >
              <Flex width="160px" height="160px">
                <SprintAreaChart
                  data={info.row.original?.sprintVelocity?.chart?.map(
                    (item: any) => ({
                      ...item,
                      completed: item.completed
                        ? parseFloat(item.completed)
                        : 0,
                    })
                  )}
                  chart="completed"
                />
              </Flex>
            </Flex>
          </>
        ),
        header: 'Velocity',
        size: 120,
      }),

      columnHelper.accessor('sprintTaskDist', {
        cell: (info) => (
          <>
            <Flex
              direction="row"
              width="full"
              justify={'center'}
              align="center"
              overflowY="auto"
            >
              <Flex
                height={
                  info.row.original?.sprintTaskDist?.tasks?.length > 16
                    ? '465px'
                    : info.row.original?.sprintTaskDist?.tasks?.length > 10
                    ? '345px'
                    : info.row.original?.sprintTaskDist?.tasks?.length > 8
                    ? '285px'
                    : info.row.original?.sprintTaskDist?.tasks?.length >= 6
                    ? '225px'
                    : '180px'
                }
                width="240px"
                direction="column"
              >
                <PieChartShared
                  showLegendOnBottom={false}
                  data={info.row.original?.sprintTaskDist?.tasks?.map(
                    (item: any, idx: number) => ({
                      ...item,
                      value:
                        item.value === 'null'
                          ? 0
                          : item.value === null
                          ? 0
                          : parseFloat(item.value),
                      fill: fillColorsTaskDist[idx % fillColorsTaskDist.length],
                    })
                  )}
                />
              </Flex>
            </Flex>
          </>
        ),
        header: 'Task Distribution',
        size: 200,
      }),
    ],
    [data?.reports]
  );

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );

  return (
    <Box>
      <DataGrid
        data={rows}
        columns={columns}
        showLoader={loading}
        maxH="550px"
        sticky="firstCol"
      />

      <Box marginLeft="-20px" my={'16px'}>
        <Paginate
          currentPage={data?.currentPage + 1}
          totalPageCount={data?.totalPages}
          pageSize={20}
          onPageChange={(page) => handlePageClick(page as number)}
        />
      </Box>
    </Box>
  );
};

export default SprintList;
