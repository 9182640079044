import { AuthActionTypes, getUser } from '@devd-client/devd/auth';
import styled from 'styled-components';
import {
  DateFilter,
  MetaTags,
  TeamMenu,
  useAiAssistantStore,
} from '@devd-client/devd/components';
import { Box, Flex } from '@chakra-ui/react';
import Summary from '../components/Summary';
import InsightCards from '../components/InsightCards';
import { Chatbox } from '../components/Chatbox';
import { useDevdHome } from '../hooks/useDevdHome';

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 24px;
`;

export function HomePage() {
  const { isOpen } = useAiAssistantStore();
  const { AppState, dispatch, selectedTeam, setSelectedTeam, selected } =
    useDevdHome();

  return (
    <Flex direction="column" width="100%" minH="100%">
      <MetaTags
        title="Overview | Dashboard | Devdynamics"
        description="Welcome to Devdynamics"
        keywords="home, devdynamics"
      />

      <HomePageContainer>
        <Flex justify="space-between" align="flex-end">
          <TeamMenu
            selectedTeam={selectedTeam}
            handleSelectedTeam={setSelectedTeam}
            AuthActionTypes={AuthActionTypes}
            getUser={getUser}
            appState={AppState}
            dispatch={dispatch}
          />

          <DateFilter
            getUser={getUser}
            appState={AppState}
            dispatch={dispatch}
            AuthActionTypes={AuthActionTypes}
          />
        </Flex>

        <Box w="full">
          <Summary team={selectedTeam.value} selected={selected} />
        </Box>

        <Box mt={4} w="full">
          <InsightCards team={selectedTeam.value} selected={selected} />
        </Box>

        {isOpen && <Chatbox />}

        {/* <Box
            mt={12}
            bg="white"
            position="fixed"
            top={0}
            height="100%"
            right={2}
            width="380px"
            zIndex="1"
            borderLeft="0.5px solid rgba(0, 0, 0, 0.1)"
          >
            <Insights />
          </Box> */}
      </HomePageContainer>
    </Flex>
  );
}

export default HomePage;
