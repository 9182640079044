import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useToastHook } from '../../appToast';
import Loader from '../../appLoader';
import { FC, useEffect, useState } from 'react';
import {
  AddBottomViewProps,
  azureBoardTabs,
  clickupTabs,
  jiraTabs,
  tabsWithoutFieldMap,
} from '../helpers/bottomView.types';
import {
  QUERY_KEYS,
  useAddUpdateBottomViewMutation,
  useBottomView,
} from '../api';
import JiraForm from './jiraForm';
import LinearForm from './linearForm';
import AzureBoardForm from './azureBoardForm';
import IssueForm from './issueForm';
import useQueryState from '../../hooks/useQueryState';
import ProjectTable from './projectTab';
import StatusMapForm from './StatusMapForm';
import StatusCategory from './StatusCategory';
import ProdBugs from './ProdBugs';
import { getKeyByValue } from '../helpers/integrationDetails.utils';

interface BottomViewProps {
  intDetailsData: any;
}

export const BottomView: FC<BottomViewProps> = ({ intDetailsData }) => {
  const integrationId = intDetailsData?.dto?.name;
  const showFieldMap = intDetailsData?.dto?.showFieldMap;

  const { data, isLoading } = useBottomView(integrationId);

  const [newToast] = useToastHook();
  const queryClient = useQueryClient();

  const [tab, setTab] = useQueryState('tab');
  const [selectedTab, setSelectedTab] = useState<any>('');

  const { mutate: addUpdateBottomView, isLoading: addMutationLoading } =
    useAddUpdateBottomViewMutation();

  const handleSaveClick = (
    data: AddBottomViewProps[],
    integrationId: string
  ) => {
    addUpdateBottomView(
      {
        data,
        integrationId,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEYS.bottomView]);
          newToast({
            message: `Field Mapping done.`,
            status: 'success',
          });
          handleNextTab();
        },
        onError: (err: any) => {
          newToast({
            message:
              `${err.message}` ?? 'Field mapping failed. please try again',
            status: 'error',
          });
        },
      }
    );
  };

  const handleNextTab = () => {
    const currentTabs = getCurrentTabs();
    if (!currentTabs || !selectedTab) return;

    const currentIndex = currentTabs[selectedTab];
    if (typeof currentIndex !== 'number') return;

    const maxIndex = Math.max(
      ...Object.values(currentTabs).filter(Number.isInteger)
    );

    if (currentIndex < maxIndex) {
      const nextTab = Object.keys(currentTabs).find(
        (key) => currentTabs[key] === currentIndex + 1
      );
      if (nextTab) setSelectedTab(nextTab);
    }
  };

  const handlePreviousTab = () => {
    const currentTabs = getCurrentTabs();
    if (!currentTabs || !selectedTab) return;

    const currentIndex = currentTabs[selectedTab];
    if (typeof currentIndex !== 'number') return;

    if (currentIndex > 0) {
      const previousTab = Object.keys(currentTabs).find(
        (key) => currentTabs[key] === currentIndex - 1
      );
      if (previousTab) setSelectedTab(previousTab);
    }
  };

  const getCurrentTabs = () => {
    if (!integrationId) return tabsWithoutFieldMap;

    if (showFieldMap) {
      switch (integrationId) {
        case 'Jira':
          return jiraTabs;
        case 'Clickup':
          return clickupTabs;
        case 'AzureBoards':
          return azureBoardTabs;
        default:
          return tabsWithoutFieldMap;
      }
    }
    return tabsWithoutFieldMap;
  };

  useEffect(() => {
    if (typeof selectedTab !== 'undefined') {
      setTab(selectedTab);
    }
  }, [selectedTab]);

  useEffect(() => {
    integrationId === 'Jira' && showFieldMap
      ? setSelectedTab(getKeyByValue(jiraTabs, 0))
      : integrationId === 'Clickup' && showFieldMap
      ? setSelectedTab(getKeyByValue(clickupTabs, 0))
      : integrationId === 'AzureBoards' && showFieldMap
      ? setSelectedTab(getKeyByValue(azureBoardTabs, 0))
      : (integrationId === 'Jira' && !showFieldMap) ||
        (integrationId === 'Clickup' && !showFieldMap) ||
        (integrationId === 'AzureBoards' && !showFieldMap)
      ? setSelectedTab(getKeyByValue(tabsWithoutFieldMap, 0))
      : setSelectedTab('');
  }, [integrationId]);

  useEffect(() => {
    tab && setSelectedTab(tab);
  }, []);

  const handleTabChange = (index: number, tabs: any) => {
    if (Number?.isInteger(index) && Object?.values(tabs).includes(index)) {
      setSelectedTab(Object?.keys(tabs)?.find((key) => tabs[key] === index));
    }
    return null;
  };

  return (
    <Box mt={6}>
      {integrationId === 'Linear' && (
        <Box p={6} border="0.5px solid rgba(0,0,0,0.1)" borderRadius="4px">
          <Text
            fontSize="sm"
            fontFamily="heading"
            fontWeight="semibold"
            color="text.primary"
            lineHeight="17px"
            mb={4}
          >
            Configure which {integrationId} fields we should use for reading the
            following information
          </Text>

          <LinearForm
            handleSaveClick={handleSaveClick}
            data={data}
            integrationId={integrationId}
          />
        </Box>
      )}

      {(integrationId === 'Jira' ||
        integrationId === 'Clickup' ||
        integrationId === 'AzureBoards') && (
        <Tabs
          isLazy
          index={
            integrationId === 'Jira' && showFieldMap
              ? jiraTabs[selectedTab]
              : integrationId === 'Clickup' && showFieldMap
              ? clickupTabs[selectedTab]
              : integrationId === 'AzureBoards' && showFieldMap
              ? azureBoardTabs[selectedTab]
              : (integrationId === 'Clickup' && !showFieldMap) ||
                (integrationId === 'AzureBoards' && !showFieldMap) ||
                (integrationId === 'Jira' && !showFieldMap)
              ? tabsWithoutFieldMap[selectedTab]
              : 0
          }
          onChange={(index) =>
            handleTabChange(
              index,
              integrationId === 'Jira' && showFieldMap
                ? jiraTabs
                : integrationId === 'Clickup' && showFieldMap
                ? clickupTabs
                : integrationId === 'AzureBoards' && showFieldMap
                ? azureBoardTabs
                : (integrationId === 'Clickup' && !showFieldMap) ||
                  (integrationId === 'AzureBoards' && !showFieldMap) ||
                  (integrationId === 'Jira' && !showFieldMap)
                ? tabsWithoutFieldMap
                : {}
            )
          }
        >
          <TabList>
            {showFieldMap && (
              <Tab
                color="text.secondary3"
                _selected={{
                  color: 'primary',
                  borderBottom: '2px solid',
                  borderColor: 'primary',
                }}
                fontSize="sm"
                fontFamily="heading"
                fontWeight="semibold"
                lineHeight="17px"
              >
                {integrationId} Field Map
              </Tab>
            )}

            <Tab
              fontSize="sm"
              fontFamily="heading"
              fontWeight="semibold"
              lineHeight="17px"
              color="text.secondary3"
              _selected={{
                color: 'primary',
                borderBottom: '2px solid',
                borderColor: 'primary',
              }}
            >
              Status Category Map
            </Tab>
            <Tab
              fontSize="sm"
              fontFamily="heading"
              fontWeight="semibold"
              color="text.secondary3"
              _selected={{
                color: 'primary',
                borderBottom: '2px solid',
                borderColor: 'primary',
              }}
              lineHeight="17px"
            >
              Stage Map
            </Tab>
            <Tab
              fontSize="sm"
              fontFamily="heading"
              fontWeight="semibold"
              color="text.secondary3"
              _selected={{
                color: 'primary',
                borderBottom: '2px solid',
                borderColor: 'primary',
              }}
              lineHeight="17px"
            >
              Issue Type Category Map
            </Tab>

            <Tab
              fontSize="sm"
              fontFamily="heading"
              fontWeight="semibold"
              color="text.secondary3"
              _selected={{
                color: 'primary',
                borderBottom: '2px solid',
                borderColor: 'primary',
              }}
              lineHeight="17px"
            >
              Projects
            </Tab>

            <Tab
              fontSize="sm"
              fontFamily="heading"
              fontWeight="semibold"
              color="text.secondary3"
              _selected={{
                color: 'primary',
                borderBottom: '2px solid',
                borderColor: 'primary',
              }}
              lineHeight="17px"
            >
              Prod Bugs
            </Tab>
          </TabList>
          <TabPanels
            p={6}
            border="0.5px solid rgba(0,0,0,0.1)"
            borderRadius="4px"
          >
            {showFieldMap && (
              <TabPanel>
                <Box>
                  <Text
                    fontSize="sm"
                    fontFamily="heading"
                    fontWeight="semibold"
                    color="text.primary"
                    lineHeight="17px"
                    mb={4}
                  >
                    Configure which {integrationId} fields we should use for
                    reading the following information
                  </Text>

                  {integrationId === 'AzureBoards' ? (
                    <AzureBoardForm
                      handleSaveClick={handleSaveClick}
                      data={data}
                      integrationId={integrationId}
                      onPrevious={handlePreviousTab}
                      isFirstTab={getCurrentTabs()[selectedTab] === 0}
                      isLastTab={
                        getCurrentTabs()[selectedTab] ===
                        Math.max(...Object.values(getCurrentTabs()))
                      }
                    />
                  ) : (
                    <JiraForm
                      handleSaveClick={handleSaveClick}
                      data={data}
                      integrationId={integrationId}
                      onPrevious={handlePreviousTab}
                      isFirstTab={getCurrentTabs()[selectedTab] === 0}
                      isLastTab={
                        getCurrentTabs()[selectedTab] ===
                        Math.max(...Object.values(getCurrentTabs()))
                      }
                    />
                  )}
                </Box>
              </TabPanel>
            )}

            <TabPanel>
              <StatusCategory
                integrationId={integrationId}
                onNext={handleNextTab}
                onPrevious={handlePreviousTab}
                isFirstTab={getCurrentTabs()[selectedTab] === 0}
                isLastTab={
                  getCurrentTabs()[selectedTab] ===
                  Math.max(...Object.values(getCurrentTabs()))
                }
              />
            </TabPanel>

            <TabPanel>
              <StatusMapForm
                integrationId={integrationId}
                onNext={handleNextTab}
                onPrevious={handlePreviousTab}
                isFirstTab={getCurrentTabs()[selectedTab] === 0}
                isLastTab={
                  getCurrentTabs()[selectedTab] ===
                  Math.max(...Object.values(getCurrentTabs()))
                }
              />
            </TabPanel>

            <TabPanel>
              <IssueForm
                integrationId={integrationId}
                onNext={handleNextTab}
                onPrevious={handlePreviousTab}
                isFirstTab={getCurrentTabs()[selectedTab] === 0}
                isLastTab={
                  getCurrentTabs()[selectedTab] ===
                  Math.max(...Object.values(getCurrentTabs()))
                }
              />
            </TabPanel>

            <TabPanel>
              <ProjectTable
                integrationId={integrationId}
                onNext={handleNextTab}
                onPrevious={handlePreviousTab}
                isFirstTab={getCurrentTabs()[selectedTab] === 0}
                isLastTab={
                  getCurrentTabs()[selectedTab] ===
                  Math.max(...Object.values(getCurrentTabs()))
                }
              />
            </TabPanel>
            <TabPanel>
              <ProdBugs
                integrationId={integrationId}
                onNext={handleNextTab}
                onPrevious={handlePreviousTab}
                isFirstTab={getCurrentTabs()[selectedTab] === 0}
                isLastTab={
                  getCurrentTabs()[selectedTab] ===
                  Math.max(...Object.values(getCurrentTabs()))
                }
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}

      {(addMutationLoading || isLoading) && <Loader />}
    </Box>
  );
};

export default BottomView;
