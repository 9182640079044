import {
  AuthorIdFilter,
  ISSUE_CHART_KEYS,
  QUALITY_CHART_KEYS,
  TeamFilter,
} from '../../shared/utils';

export const setQueryParams = ({
  tableType,
  metricHeading,
  currentChartMetaData,
  filterBy,
  payload,
}: {
  tableType: 'PR' | 'ISSUE';
  metricHeading: string;
  currentChartMetaData: any;
  filterBy: TeamFilter;
  payload: any;
}) => {
  const tableURL =
    tableType === 'PR'
      ? '/pr-details'
      : tableType === 'ISSUE'
      ? '/issue-details'
      : '';
  const heading = metricHeading;
  const chartKey = currentChartMetaData?.chartKey ?? 'PR_UPDATED';
  const metricKey =
    currentChartMetaData?.chartKey === 'PR_HEALTH_INDEXES'
      ? (filterBy.type as string)
      : '';
  const metric = getMetric(currentChartMetaData, payload) as string;
  const firstOption = getFirstOption(currentChartMetaData, filterBy);
  const secondOption = getSecondOption(currentChartMetaData, filterBy);
  const teamId = filterBy.teamId ?? '';
  const sprintId = filterBy.sprintId || '';
  const status = 'all';
  const ticketType = filterBy?.type ?? 'all';
  const finalURL = `${tableURL}?chartKey=${chartKey}&firstOption=${firstOption}&heading=${heading}&metric=${metric}&metricKey=${metricKey}&secondOption=${secondOption}&teamId=${teamId}&sprintId=${sprintId}&status=${status}&preDefinedOptions=true`;
  return tableType === 'PR'
    ? finalURL
    : tableType === 'ISSUE'
    ? finalURL + `&ticketType=${ticketType}`
    : '';
};

const getMetric = (currentChartMetaData: any, payload: any) => {
  return currentChartMetaData?.chartKey === 'TOP_REVIEWER_DISTRIBUTION'
    ? 'PR_REVIEWED'
    : currentChartMetaData?.chartKey === 'PR_CYCLE_TIME_BREAKDOWN'
    ? 'PR_CYCLE_TIME'
    : currentChartMetaData?.chartKey === 'DEPLOYMENT_FREQUENCY_DISTRIBUTION'
    ? 'DEPLOYMENT_FREQUENCY'
    : currentChartMetaData?.chartKey === 'LEAD_TIME_BREAKDOWN'
    ? 'LEAD_TIME_FOR_CHANGE'
    : currentChartMetaData?.chartKey === 'MEAN_TIME_TO_RECOVERY_DISTRIBUTION'
    ? 'MEAN_TIME_TO_RECOVERY'
    : currentChartMetaData?.chartKey === 'CHANGE_HEALTH'
    ? 'CHANGE_FAILURE_RATE'
    : currentChartMetaData?.chartKey === 'CUSTOM'
    ? currentChartMetaData?.chartTitle
    : payload?.name?.includes('New') &&
      QUALITY_CHART_KEYS?.includes(currentChartMetaData?.chartKey)
    ? `NEW_${currentChartMetaData?.chartKey}`
    : ISSUE_CHART_KEYS?.includes(currentChartMetaData?.chartKey) &&
      payload?.name === 'Total Issues' &&
      currentChartMetaData?.chartKey === 'BACKWARD_MOMENTUM'
    ? 'ISSUE_UPDATED'
    : ISSUE_CHART_KEYS?.includes(currentChartMetaData?.chartKey) &&
      payload?.name === 'Total Issues'
    ? 'TASK_CLOSE'
    : ISSUE_CHART_KEYS?.includes(currentChartMetaData?.chartKey) &&
      payload?.name === 'Reopened Issues'
    ? 'ISSUE_REOPENED'
    : currentChartMetaData?.chartKey;
};

const getFirstOption = (
  currentChartMetaData: any,
  filterBy: AuthorIdFilter | TeamFilter
) => {
  return ['TOP_REVIEWER_DISTRIBUTION', 'PR_REVIEW', 'PR_REVIEWED'].includes(
    currentChartMetaData?.chartKey
  ) && filterBy?.author
    ? 'reviewers'
    : currentChartMetaData?.chartKey === 'LOC_VS_PR_CYCLE_TIME'
    ? 'pr_size'
    : currentChartMetaData?.chartKey === 'PR_AGEING_DISTRIBUTION'
    ? 'pr_age'
    : [
        'PR_OPEN',
        'PR_MERGE',
        'PR_CYCLE_TIME',
        'PR_COMMENT',
        'PR_REVIEW',
      ].includes(currentChartMetaData?.chartKey) && filterBy?.author
    ? 'authors'
    : filterBy?.type === 'merged_without_review' ||
      currentChartMetaData?.chartKey === 'MERGE_WITHOUT_REVIEW'
    ? 'merged_wo_review'
    : filterBy?.type === 'merged_with_review'
    ? 'merged_with_review'
    : currentChartMetaData?.chartKey === 'PR_WITHOUT_ISSUE'
    ? 'prs_unlinked'
    : filterBy?.name === 'Review Responsiveness' ||
      filterBy?.name === 'Review Responsiveness Index'
    ? 'prReviewResponsivenessType'
    : filterBy?.name === 'Feedback Responsiveness' ||
      filterBy?.name === 'Feedback Responsiveness Index'
    ? 'prFeedbackResponsivenessType'
    : filterBy?.name === 'Review Completion'
    ? 'review_feedback'
    : currentChartMetaData?.chartKey === 'ISSUE_THROUGHPUT'
    ? 'inflow_outflow'
    : currentChartMetaData?.chartKey === 'ISSUE_CYCLE_TIME_DIST'
    ? 'cycleTimeSpread'
    : currentChartMetaData?.chartKey === 'ISSUE_PROGRESSION_RATE'
    ? 'issueStatus'
    : currentChartMetaData?.chartKey === 'CHANGE_AGING_DISTRIBUTION'
    ? 'issueAge'
    : currentChartMetaData?.chartKey === 'ISSUE_LIFECYCLE_DIST'
    ? 'stage'
    : [
        'BUG_OPEN',
        'BUG_IN_PROGRESS',
        'BUG_CLOSE',
        'TASK_OPEN',
        'TASK_CLOSE',
        'TASK_IN_PROGRESS',
      ].includes(currentChartMetaData?.chartKey) && filterBy?.author
    ? 'currentAssignee'
    : '';
};

const getSecondOption = (
  currentChartMetaData: any,
  filterBy: AuthorIdFilter | TeamFilter
) => {
  return ['TOP_REVIEWER_DISTRIBUTION', 'PR_REVIEW', 'PR_REVIEWED'].includes(
    currentChartMetaData?.chartKey
  ) && filterBy?.author
    ? filterBy?.author
    : currentChartMetaData?.chartKey === 'LOC_VS_PR_CYCLE_TIME'
    ? filterBy?.lowerBoundIncl === 0
      ? '0-200'
      : filterBy?.lowerBoundIncl === 200
      ? '200-400'
      : filterBy?.lowerBoundIncl === 400
      ? '400-600'
      : filterBy?.lowerBoundIncl === 600
      ? '600-800'
      : '800'
    : currentChartMetaData?.chartKey === 'PR_AGEING_DISTRIBUTION'
    ? filterBy?.age === 'More than 1 Day'
      ? 'MORE_THAN_1D'
      : filterBy?.age === 'More than 3 Days'
      ? 'MORE_THAN_3D'
      : filterBy?.age === 'More than 7 Days'
      ? 'MORE_THAN_7D'
      : filterBy?.age === 'More than 14 Days'
      ? 'MORE_THAN_14D'
      : 'ABOVE_1M'
    : [
        'PR_OPEN',
        'PR_MERGE',
        'PR_CYCLE_TIME',
        'PR_COMMENT',
        'PR_REVIEW',
      ].includes(currentChartMetaData?.chartKey) && filterBy?.author
    ? filterBy?.author
    : filterBy?.type === 'merged_without_review' ||
      currentChartMetaData?.chartKey === 'MERGE_WITHOUT_REVIEW'
    ? ''
    : filterBy?.type === 'merged_with_review'
    ? ''
    : currentChartMetaData?.chartKey === 'PR_WITHOUT_ISSUE'
    ? ''
    : filterBy?.name === 'Review Responsiveness' ||
      filterBy?.name === 'Review Responsiveness Index'
    ? 'LESS_THAN_24HRS'
    : filterBy?.name === 'Feedback Responsiveness' ||
      filterBy?.name === 'Feedback Responsiveness Index'
    ? 'LESS_THAN_24HRS'
    : filterBy?.name === 'Review Completion'
    ? 'review_with_comment'
    : currentChartMetaData?.chartKey === 'ISSUE_THROUGHPUT'
    ? filterBy.status
    : currentChartMetaData?.chartKey === 'ISSUE_CYCLE_TIME_DIST'
    ? filterBy.age
    : currentChartMetaData?.chartKey === 'ISSUE_PROGRESSION_RATE'
    ? filterBy.status
    : currentChartMetaData?.chartKey === 'CHANGE_AGING_DISTRIBUTION'
    ? filterBy.age === ''
      ? 'moreThreeMonth'
      : filterBy.age
    : currentChartMetaData?.chartKey === 'ISSUE_LIFECYCLE_DIST'
    ? filterBy.status
    : [
        'BUG_OPEN',
        'BUG_IN_PROGRESS',
        'BUG_CLOSE',
        'TASK_OPEN',
        'TASK_CLOSE',
        'TASK_IN_PROGRESS',
      ].includes(currentChartMetaData?.chartKey) && filterBy?.author
    ? filterBy?.author
    : '';
};

// http://localhost:4200/pr-details?chartKey=TOP_REVIEWER_DISTRIBUTION&duration=Custom&end=2024-11-15&firstOption=reviewers&heading=Reviewer%20Distribution&metric=PR_REVIEWED&metricKey=&secondOption=rishi%40devdynamics.ai&start=2024-10-16

export default setQueryParams;
