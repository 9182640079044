import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
  rectSwappingStrategy,
} from '@dnd-kit/sortable';

import { SortableItem } from './SortableItem';
import React, { useState } from 'react';
import { Item } from './Item';
// import { ContributorColumns } from '../../../../utils/metricTableTypes';
import { PermanentItem } from './PermanentItem';

interface sortableListTypes {
  permanentColumns?: string[];
  items: any[];
  metricList: metricListType[];
  setItems: React.Dispatch<React.SetStateAction<any[]>>;
  handleRemoveMetric: (metric: any) => void;
}
interface metricListType {
  metricKey: string;
  metricName: string;
  tabs: string[] | null;
}

export function SortableList({
  permanentColumns,
  items,
  metricList,
  setItems,
  handleRemoveMetric,
}: sortableListTypes) {
  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  function handleDragStart(event: any) {
    const { active } = event;

    setActiveId(active.id);
  }

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items: any[]) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }

  return (
    <>
      {permanentColumns?.map((column: any) => (
        <PermanentItem column={column} />
      ))}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((id: any) => (
            <SortableItem
              key={id}
              id={id}
              handleRemoveMetric={handleRemoveMetric}
              name={
                metricList.find(
                  (metric: metricListType) => metric.metricKey === id
                )?.metricName
              }
            />
          ))}
        </SortableContext>
      </DndContext>
    </>
  );
}

export default SortableList;
