export function UserMergeAlertIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="User-Alert-Bell--Streamline-Core" clip-path="url(#clip0_5267_76)">
        <g id="user-alert-bell--actions-alert-geometric-human-person-single-user">
          <path
            id="Vector"
            d="M5.9048 6.42825C7.35159 6.42825 8.52444 5.25539 8.52444 3.80861C8.52444 2.36182 7.35159 1.18896 5.9048 1.18896C4.45801 1.18896 3.28516 2.36182 3.28516 3.80861C3.28516 5.25539 4.45801 6.42825 5.9048 6.42825Z"
            stroke={props.color || '#FFAD00'}
            stroke-width="1.33"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_2"
            d="M13.7647 10.6196C13.7647 9.92487 13.4887 9.25855 12.9974 8.76727C12.5061 8.27599 11.8398 8 11.145 8C10.4503 8 9.78394 8.27599 9.29266 8.76727C8.80139 9.25855 8.52539 9.92487 8.52539 10.6196V13.7632H13.7647V10.6196Z"
            stroke={props.color || '#FFAD00'}
            stroke-width="1.33"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_3"
            d="M7.47656 13.7632H14.8116"
            stroke={props.color || '#FFAD00'}
            stroke-width="1.33"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_4"
            d="M11.1445 13.7632V14.811"
            stroke={props.color || '#FFAD00'}
            stroke-width="1.33"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Ellipse 1772"
            d="M5.14649 13.7632H1.18945V13.2393C1.18945 10.6351 3.30059 8.52393 5.90481 8.52393C6.15216 8.52393 6.39505 8.54297 6.63212 8.57967"
            stroke={props.color || '#FFAD00'}
            stroke-width="1.33"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5267_76">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UserMergeAlertIcon;
