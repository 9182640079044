import { lazy } from 'react';
import SuspenseWrapper from '../../utils/suspenseWrapper';

export const Home = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/home').then((module) => ({
      default: module.Home,
    }))
  )
);

export const HomeInsight = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/home').then((module) => ({
      default: module.HomeInsights,
    }))
  )
);

export const Ticket = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/home').then((module) => ({
      default: module.Ticket,
    }))
  )
);

export const Quality = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/home').then((module) => ({
      default: module.Quality,
    }))
  )
);

export const Git = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/home').then((module) => ({
      default: module.Git,
    }))
  )
);

export const Dora = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/home').then((module) => ({
      default: module.Dora,
    }))
  )
);

export const Sprint = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/home').then((module) => ({
      default: module.Sprint,
    }))
  )
);

export const Cicd = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/home').then((module) => ({
      default: module.Cicd,
    }))
  )
);

export const Copilot = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/home').then((module) => ({
      default: module.Copilot,
    }))
  )
);

export const CicdDetails = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/home').then((module) => ({
      default: module.CicdDetails,
    }))
  )
);

export const TestSuiteDetails = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/home').then((module) => ({
      default: module.TestSuiteDetails,
    }))
  )
);

export const TestSuite = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/home').then((module) => ({
      default: module.TestSuite,
    }))
  )
);

export const Initiatives = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/initiatives').then((module) => ({
      default: module.Initiatives,
    }))
  )
);

export const CreateInitiatives = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/initiatives').then((module) => ({
      default: module.CreateInitiative,
    }))
  )
);

export const EditInitiatives = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/initiatives').then((module) => ({
      default: module.EditInitiative,
    }))
  )
);

export const Investments = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/investments').then((module) => ({
      default: module.Investments,
    }))
  )
);

export const ActivityLog = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/activity-log').then((module) => ({
      default: module.ActivityLog,
    }))
  )
);

export const Goals = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/goals').then((module) => ({
      default: module.Goals,
    }))
  )
);
export const WorkflowAutomation = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/workflow-automation').then((module) => ({
      default: module.WorkflowAutomation,
    }))
  )
);

export const PrDetails = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/metric-details').then((module) => ({
      default: module.PrDetailsPage,
    }))
  )
);
export const IssueDetails = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/metric-details').then((module) => ({
      default: module.IssueDetailsPage,
    }))
  )
);

export const Teams = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/team-details').then((module) => ({
      default: module.TeamDetails,
    }))
  )
);

export const Feedback = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/feedback').then((module) => ({
      default: module.Feedback,
    }))
  )
);

export const Reports = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/report').then((module) => ({
      default: module.Report,
    }))
  )
);

export const ContributorDetails = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/contributor-details').then((module) => ({
      default: module.ContributorDetails,
    }))
  )
);

export const ContributorTable = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/contributor-table').then((module) => ({
      default: module.ContributorTable,
    }))
  )
);

export const Survey = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/survey').then((module) => ({
      default: module.Survey,
    }))
  )
);

export const SoftwareCapitalization = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/software-capitalization').then((module) => ({
      default: module.DevdSoftwareCapitalization,
    }))
  )
);

export const DeploymentDetails = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/components').then((module) => ({
      default: module.DeploymentDetails,
    }))
  )
);

export const SprintDetails = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/sprint').then((module) => ({
      default: module.SprintDetails,
    }))
  )
);

export const CustomDashboard = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/home').then((module) => ({
      default: module.CustomDashbaord,
    }))
  )
);

export const AddMetric = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/home').then((module) => ({
      default: module.AddMetric,
    }))
  )
);

export const TeamCommparison = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/team-details').then((module) => ({
      default: module.TeamComparison,
    }))
  )
);
