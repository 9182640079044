import React, { useEffect } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Item } from './Item';

export function SortableItem({
  id,
  key,
  handleRemoveMetric,
  name,
}: {
  key: any;
  id: any;
  name: any;
  handleRemoveMetric: (metric: any) => void;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Item
      ref={setNodeRef}
      style={style}
      attributes={attributes}
      listeners={listeners}
      handleRemoveMetric={handleRemoveMetric}
      id={id}
    >
      {name}
    </Item>
  );
}
