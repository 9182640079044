import { useCallback, useEffect, useState } from 'react';

import ActivityLogContent from '../../components/ActivityLogContent';
import ActivityLogToolbar from '../../components/ActivityLogToolbar';
import {
  Loader,
  PageHeader,
  PageToolbar,
  ISelect,
  useSetUrl,
  useQueryState,
  removeEmptyQueryParams,
} from '@devd-client/devd/components';
import { getCurrentWeek } from '../../helpers/ActivityLog.utils';
import moment from 'moment';
import styled from 'styled-components';
import { useActivityLogGql } from '../../graphql';
import WeekNavigator from '../../components/WeekNavigator';
import { Button, Text } from '@chakra-ui/react';
import { useAuth } from '@devd-client/devd/auth';
import { useContributorDetailGql } from '@devd-client/devd/home';
import { useInView } from 'react-intersection-observer';
import { useDashboardStore } from '@devd-client/devd/components';

const ActivityLogContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export function ActivityLogPage(data: any) {
  const [appState, ,] = useAuth();

  const [team, setTeam] = useQueryState('team');
  const [employee, setEmployee] = useQueryState('employee');
  const [show, setShow] = useQueryState('show');
  const [week, setWeek] = useQueryState('week');

  const [weekArray, setWeekArray] = useState<string[]>(
    getCurrentWeek(moment().subtract(7, 'days'))
  );

  const [workLogType, setWorkLogType] = useState<ISelect>({
    value: show
      ? show
      : appState?.user?.dto?.modules?.contributorSearch !== false
      ? 'Contributors'
      : 'Git',
    label: show
      ? show
      : appState?.user?.dto?.modules?.contributorSearch !== false
      ? 'Contributors'
      : 'Git',
  });
  const { selectedTeam, setSelectedTeam } = useDashboardStore();
  const role = localStorage.getItem('role');
  const email = appState?.user?.email;
  const [employeeId, setEmployeeId] = useState<any>(email);

  const { data: contributorData, isLoading: contributorLoading } =
    useContributorDetailGql(
      weekArray[0],
      weekArray[13],
      '',
      selectedTeam.value,
      role
    );

  const [contributorIds, setContributorIds] = useState<any>([]);
  const { ref, inView } = useInView();

  const {
    isLoading,
    isError,
    data: activityLogData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useActivityLogGql(
    workLogType.value,
    weekArray[0],
    weekArray[13],
    selectedTeam.value,
    role === 'EMPLOYEE' ? [email] : contributorIds,
    30,
    0
  );

  const tableData = activityLogData?.pages?.reduce(
    (acc: any, current: any) => {
      const obj: any = Object.values(current)[0];
      acc.itemMeta = obj?.itemMeta;
      acc.rows = acc.rows.concat(obj?.rows);
      return acc;
    },
    { itemMeta: [], rows: [] }
  );

  useEffect(() => {
    if (typeof selectedTeam !== 'undefined') {
      role === 'EMPLOYEE'
        ? removeEmptyQueryParams({ team: '' })
        : selectedTeam.label === 'Organisation'
        ? setTeam('Organisation')
        : setTeam(selectedTeam.value);
    }

    role !== 'EMPLOYEE'
      ? removeEmptyQueryParams({ employee: '' })
      : setEmployee(employeeId);

    if (typeof workLogType.value !== 'undefined') {
      setShow(workLogType.value);
    }

    if (typeof weekArray[0] !== 'undefined') {
      setWeek(weekArray[0]);
    }
  }, [selectedTeam.value, workLogType.value, weekArray[0], employeeId]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'Organisation'
          ? { value: '', label: 'Organisation' }
          : { value: team, label: team }
      );

    employee && setEmployeeId(employee);

    week && setWeekArray(getCurrentWeek(moment(week)));

    show &&
      setWorkLogType({
        value: show === 'Author' ? 'Contributors' : show,
        label: show === 'Author' ? 'Contributors' : show,
      });
  }, []);

  useEffect(() => {
    setContributorIds([]);
  }, [selectedTeam?.value]);

  const handleNextClick = useCallback(() => {
    setWeekArray(getCurrentWeek(moment(weekArray[0]).add(1, 'week')));
  }, [weekArray]);

  const handlePrevClick = useCallback(() => {
    setWeekArray(getCurrentWeek(moment(weekArray[0]).subtract(1, 'week')));
  }, [weekArray]);

  if (isError) {
    console.log('Load error');
    // return <div>Error</div>;
  }

  useEffect(() => {
    if (workLogType.value === 'Contributors') {
      if (inView) {
        if (hasNextPage) {
          fetchNextPage();
        }
      }
    }
  }, [inView, workLogType]);

  return (
    <>
      <ActivityLogContainer>
        <PageHeader headingText="Activity Log" />
        <Text
          fontFamily="heading"
          fontSize="sm"
          color="text.secondary"
          mb={'10px'}
        >
          {data?.data?.dto?.title}
        </Text>
        <PageToolbar
          leftComp={
            <ActivityLogToolbar
              selectedTeam={selectedTeam}
              handleSelectedTeam={setSelectedTeam}
            />
          }
          rightComp={
            <WeekNavigator
              weekArray={weekArray}
              handlePrevClick={handlePrevClick}
              handleNextClick={handleNextClick}
            />
          }
          mb={'20px'}
        />

        <ActivityLogContent
          workLogType={workLogType}
          weekArray={weekArray}
          handleOptionSelect={setWorkLogType}
          teamId={selectedTeam?.value}
          contributorData={contributorData}
          contributorIds={contributorIds}
          setContributorIds={setContributorIds}
          activityLogData={tableData}
        />
        {(isFetchingNextPage || isLoading) && <Loader />}
      </ActivityLogContainer>
      <Button ref={ref} visibility={'hidden'}>
        hidden
      </Button>
    </>
  );
}

export default ActivityLogPage;
