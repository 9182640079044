import { apiClient } from '@devd-client/api';

export const getInvestmentData = (): Promise<any> =>
  apiClient(
    `/v2/account/org/${localStorage.getItem('orgId')}/page/Investment`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getSprintList = (): Promise<any> =>
  apiClient(`/v1/org/${localStorage.getItem('orgId')}/sprints`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const getJobRoleList = (): Promise<any> =>
  apiClient(`/v1/org/${localStorage.getItem('orgId')}/employee/jobRoles`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });
