import { apiClient } from '@devd-client/api';

export const updateContributorNameAndJobRole = (params: any): Promise<any> =>
  apiClient(
    `/v1/org/${localStorage.getItem('orgId')}/employee/${params.email}/jobRole`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        firstName: params.firstName,
        lastName: params.lastName,
        jobRole: params.jobRole,
      }),
    }
  );
