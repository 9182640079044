import { create } from 'zustand';

interface SoftCapitalizationState {
  currentMonth: string;
  setCurrentMonth: (currentMonth: string) => void;
}

export const useSoftCapitalizationStore = create<SoftCapitalizationState>()(
  (set) => ({
    currentMonth: '',
    setCurrentMonth: (currentMonth: string) => set({ currentMonth }),
  })
);
