import { Box } from '@chakra-ui/react';
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Cell,
} from 'recharts';
import styled from 'styled-components';

const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  font-family: 'Manrope';
  background-color: #fff;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.15);
  border: 0.5px solid rgba(0, 0, 0, 0.1);
`;

const CustomTooltip = ({ payload }: any) => {
  return (
    <TooltipContainer>
      <Box
        fontFamily="heading"
        fontWeight="600"
        fontSize="12px"
        color="#999999"
      >
        {payload[0]?.payload?.dist} {payload[0]?.name} :
      </Box>
      <Box fontFamily="heading" fontWeight="600" fontSize="12px" ml="5px">
        {payload[0]?.value}
      </Box>
    </TooltipContainer>
  );
};

interface PieChartSharedProps {
  data: any;
  legendWidth?: number;
  pieSize?: number;
  showPercent?: boolean;
  showLegendOnBottom?: boolean;
  isAllZero?: boolean;
  setMetric?: (metric: string) => void;
  setHeading?: (heading: string) => void;
  setCount?: (count: string) => void;
  onOpen?: () => void;
  setContributor?: (contributor: string) => void;
  contributor?: string;
  type?: string;
}

export const PieChartShared: React.FC<PieChartSharedProps> = ({
  data,
  legendWidth,
  pieSize,
  showPercent,
  showLegendOnBottom = true,
  isAllZero = false,
  setMetric,
  setHeading,
  onOpen,
  setCount,
  setContributor,
  contributor,
  type,
}) => {
  const RADIAN = Math.PI / 180;

  const handlePieClick = (data: any, index: number) => {
    if (!setMetric || !setHeading || !setCount || !onOpen) return;
    if (data.name === 'Critical') {
      if (type === 'sub-parReviewer') {
        setMetric('SUB_PAR_REVIEWER_DATA_CRITICAL');
        setHeading('Sub-Par Reviewer');
        setCount(`${contributor} - ${data.name} (${data.value})`);
      } else if (type === 'bugCausedBy') {
        setMetric('BUG_CAUSED_BY_DATA_CRITICAL');
        setHeading('Bug Caused By');
        setCount(`${contributor} - ${data.name} (${data.value})`);
      }
    } else if (data.name === 'Other') {
      if (type === 'sub-parReviewer') {
        setMetric('SUB_PAR_REVIEWER_DATA_OTHER');
        setHeading('Sub-Par Reviewer');
        setCount(`${contributor} - ${data.name} (${data.value})`);
      } else if (type === 'bugCausedBy') {
        setMetric('BUG_CAUSED_BY_DATA_OTHER');
        setHeading('Bug Caused By');
        setCount(`${contributor} - ${data.name} (${data.value})`);
      }
    }
    if (setContributor && contributor) {
      setContributor(contributor);
    }

    onOpen();
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.15;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const count = data[index].value;

    return (
      <text
        x={x}
        y={y}
        fill="#778899"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontSize={10}
        fontWeight={500}
      >
        {!showPercent ? `${count}` : `${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <ResponsiveContainer height="100%" width="100%">
      <PieChart
        margin={{
          left: 20,
          bottom: 20,
        }}
      >
        {isAllZero ? (
          <Pie
            data={data}
            cx={'40%'}
            cy={'50%'}
            outerRadius={pieSize || 50}
            fill="#D3D3D3"
            paddingAngle={0}
            dataKey="value"
            label={false}
            style={{ outline: 'none', marginBottom: 10 }}
          >
            <Cell fill={'lightGrey'} />
          </Pie>
        ) : (
          <>
            <Pie
              data={data}
              cx={'40%'}
              cy={'50%'}
              outerRadius={pieSize || 50}
              fill="#8884d8"
              paddingAngle={0}
              dataKey="value"
              labelLine={false}
              label={renderCustomizedLabel}
              style={{ outline: 'none', marginBottom: 10 }}
              onClick={handlePieClick}
              cursor={onOpen ? 'pointer' : 'default'}
            />
            <Tooltip
              cursor={{ fill: 'transparent' }}
              content={<CustomTooltip />}
              wrapperStyle={{ outline: 'none' }}
            />
            {showLegendOnBottom && (
              <Legend
                layout="horizontal"
                iconType="circle"
                iconSize={10}
                wrapperStyle={{
                  fontSize: 10.5,
                }}
              />
            )}
            {!showLegendOnBottom && (
              <Legend
                layout="vertical"
                verticalAlign="middle"
                align="right"
                iconType="circle"
                iconSize={10}
                wrapperStyle={{
                  fontSize: 10.5,
                  maxHeight: 100,
                  overflow: 'auto',
                  width: 100,
                }}
              />
            )}
          </>
        )}
      </PieChart>
    </ResponsiveContainer>
  );
};
