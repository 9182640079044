import { useQuery } from '@tanstack/react-query';
import * as ENDPOINTS from './endpoints';
import { useCustomMutation } from '@devd-client/devd/components';
export const QUERY_KEYS = {
  teammenu: 'teammenu',
  favTeam: 'favTeam',
  contributorGql: 'contributorGql',
  orgIntDetails: 'orgIntDetails',
  allProjects: 'allProjects',
  contributorDetails: 'contributorDetails',
  issueTypePriority: 'issueTypePriority',
  prDetailsPage: 'prDetailsPage',
  issueDetailsPage: 'issueDetailsPage',
  ignorePrOrIssue: 'ignorePrOrIssue',
};

export const useFetchIntegrationDetails = () => {
  return useQuery(
    [QUERY_KEYS.orgIntDetails],
    () => ENDPOINTS.getIntegrationDetails(),
    {
      keepPreviousData: false,
    }
  );
};

export const useTeammenu = () => {
  return useQuery([QUERY_KEYS.teammenu], ENDPOINTS.getTeammenu);
};
export const useIgnorePrOrIssue = () =>
  useCustomMutation([QUERY_KEYS.ignorePrOrIssue], ENDPOINTS.IgnorePrOrIssue);

export const useFavTeam = () => {
  return useCustomMutation([QUERY_KEYS.favTeam], ENDPOINTS.postFavTeam);
};

export const useRemoveFavTeam = () => {
  return useCustomMutation([QUERY_KEYS.favTeam], ENDPOINTS.deleteFavTeam);
};

export const useFetchRepo = (source: string) => {
  return useQuery([QUERY_KEYS.allProjects, source], () =>
    ENDPOINTS.getAllRepos(source)
  );
};
export const useGetIssueTypePriorityOptions = () => {
  return useQuery(
    [QUERY_KEYS.issueTypePriority],
    ENDPOINTS.getIssueTypePriorityOptions
  );
};

export const useContributorDetails = (email: string) => {
  return useQuery(
    [QUERY_KEYS.contributorDetails, email],
    () => ENDPOINTS.getContributorDetails(email),
    {
      enabled: !!email,
    }
  );
};

// graphQL

export const useContributorDetailGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  role: string | null
) => {
  return useQuery(
    [QUERY_KEYS.contributorGql, startDate, endDate, sprintId, teamId],
    () => ENDPOINTS.getContributorGraphql(startDate, endDate, sprintId, teamId),
    {
      select: (data) => data?.ContributorDetails,
      enabled: !!startDate && !!endDate && role !== 'EMPLOYEE',
    }
  );
};

export const usePrDetailsPage = () => {
  return useQuery([QUERY_KEYS.prDetailsPage], ENDPOINTS.getPrDetailsPage);
};

export const useIssueDetailsPage = () => {
  return useQuery([QUERY_KEYS.issueDetailsPage], ENDPOINTS.getIssueDetailsPage);
};
