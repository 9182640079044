import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import {
  AppTooltip,
  LineChartRecharts,
  useDashboardStore,
  ViewSwitcher,
  viewSwitchObj,
} from '@devd-client/devd/components';
import { useCopilotLinesAccepted } from '../../graphql';
import { useState } from 'react';
import { CopilotDataCard } from './CopilotDataCard';
import { CopilotLoadingComponent } from './CopilotLoadingComponent';
import { Link, useNavigate } from 'react-router-dom';
import { MdInfoOutline } from 'react-icons/md';
import { useTheme } from '@chakra-ui/react';

export const CopilotLinesAccepted = () => {
  const { selectedTeam, selected } = useDashboardStore();
  const [viewToggle, setViewToggle] = useState('Week');
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const { colors } = useTheme();
  const {
    data: copilotLinesAcceptedData,
    isFetching: copilotLinesAcceptedFetching,
  } = useCopilotLinesAccepted(
    selectedTeam.value,
    selected.startDate,
    selected.endDate,
    selected.sprintId,
    viewToggle.toUpperCase(),
    ''
  );

  if (copilotLinesAcceptedFetching)
    return (
      <CopilotLoadingComponent title="Code Assitance Lines Accepted" height />
    );

  return (
    <Flex
      flexDirection={'column'}
      mt={4}
      p={4}
      bg="white"
      borderRadius="md"
      border="1px solid #E0E0E0"
    >
      <Flex align={'center'} gap={1} mb={4}>
        <Text fontSize="lg" fontWeight="semibold" color={'text.secondary'}>
          {copilotLinesAcceptedData?.chartMetadata?.chartTitle}
        </Text>
        {copilotLinesAcceptedData?.chartMetadata?.info && (
          <AppTooltip
            content={
              <Text
                fontSize="sm"
                color="text.secondary"
                fontWeight="normal"
                fontFamily="heading"
              >
                {copilotLinesAcceptedData?.chartMetadata?.info}
              </Text>
            }
            placement="bottom"
            trigger="hover"
          >
            <MdInfoOutline size={16} color={colors.text.secondary} />
          </AppTooltip>
        )}
      </Flex>
      <Flex>
        {copilotLinesAcceptedData?.chartDataState !== 'NO_INTEGRATION' && (
          <Flex direction="column" mr={8} w="250px" justifyContent={'center'}>
            <CopilotDataCard
              title="Avg. Copilot Lines Accepted"
              number={`${parseFloat(
                copilotLinesAcceptedData?.data?.avgCoPilotLineAccepted
              )?.toFixed(2)}`}
            />
            <CopilotDataCard
              title="Avg. Lines Added"
              number={`${parseFloat(
                copilotLinesAcceptedData?.data?.avgLineAdded
              )?.toFixed(2)}`}
            />
          </Flex>
        )}

        <Box
          w={
            copilotLinesAcceptedData?.chartDataState !== 'NO_INTEGRATION'
              ? '80%'
              : '100%'
          }
        >
          <Box w="fit-content" ml={'auto'} mb={8}>
            {copilotLinesAcceptedData?.chartDataState === 'NOT_CONFIGURED' ||
            copilotLinesAcceptedData?.chartDataState === 'NO_INTEGRATION' ||
            copilotLinesAcceptedData?.chartDataState === 'ERROR' ? (
              <AppTooltip
                trigger="hover"
                content={
                  <Flex>
                    <Text
                      fontFamily="heading"
                      fontSize="sm"
                      color="text.primary"
                      mr={2}
                    >
                      {copilotLinesAcceptedData?.chartError?.message}
                    </Text>
                    {copilotLinesAcceptedData?.chartError?.link && (
                      <Link
                        to={`${copilotLinesAcceptedData?.chartError?.link}`}
                      >
                        <Text
                          color="primary"
                          fontFamily="heading"
                          fontSize="sm"
                          textDecoration={'underline'}
                        >
                          Configure
                        </Text>
                      </Link>
                    )}
                  </Flex>
                }
              >
                <Box>
                  <Button
                    size={'sm'}
                    color={'error'}
                    border="1px"
                    borderColor={'error'}
                    backgroundColor={'white'}
                    fontFamily="heading"
                  >
                    {'why no data?'}
                  </Button>
                </Box>
              </AppTooltip>
            ) : (
              <ViewSwitcher
                tabList={viewSwitchObj}
                currentTab={viewToggle}
                handleSwitch={setViewToggle}
                height="25px"
              />
            )}
          </Box>
          {copilotLinesAcceptedData?.chartDataState === 'NO_INTEGRATION' ? (
            <Box
              w="full"
              height="300px"
              display="flex"
              justifyContent={'center'}
              alignItems="center"
              position="relative"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Image
                height="100%"
                src={copilotLinesAcceptedData?.chartMetadata?.zsImage || ''}
                style={{ position: 'relative' }}
              />
              {isHovered && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  backgroundColor="rgba(0, 0, 0, 0.4)"
                  borderRadius="4px"
                >
                  {copilotLinesAcceptedData?.chartMetadata?.source && (
                    <Button
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      onClick={() => navigate('/settings/integrations')}
                    >
                      Connect{' '}
                      {copilotLinesAcceptedData?.chartMetadata?.source ===
                      'ISSUE TRACKING'
                        ? 'Jira'
                        : copilotLinesAcceptedData?.chartMetadata?.source ===
                          'REPOSITORY'
                        ? 'Github'
                        : ''}
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          ) : (
            copilotLinesAcceptedData &&
            !copilotLinesAcceptedFetching && (
              <Box flex="1" height="350px">
                <LineChartRecharts
                  data={copilotLinesAcceptedData?.data?.data}
                  keys={copilotLinesAcceptedData?.keys}
                  chartMetadata={copilotLinesAcceptedData?.chartMetadata}
                  showLabel={true}
                  selectedTeam={selectedTeam.value}
                  selected={selected}
                  showYLabel={true}
                  hideBtn={true}
                  showCustomLegend={true}
                />
              </Box>
            )
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
