import { Box, Flex } from '@chakra-ui/react';
import {
  PageHeader,
  MetaTags,
  PageToolbar,
  TeamMenu,
  DateFilter,
} from '@devd-client/devd/components';
import { FC } from 'react';
import { AuthActionTypes, getUser } from '@devd-client/devd/auth';
import { AcceptanceByLanguage } from '../components/Copilot/AcceptanceByLanguage';
import { CopilotActiveUsers } from '../components/Copilot/CopilotActiveUsers';
import { CopilotLinesAccepted } from '../components/Copilot/CopilotLinesAccepted';
import { CopilotChatAcceptance } from '../components/Copilot/CopilotChatAcceptance';
import { useCopilotDashboard } from '../hooks/useCopilotDashboard';

export const Copilot: FC = () => {
  const { selectedTeam, setSelectedTeam, appState, dispatch } =
    useCopilotDashboard();
  return (
    <>
      <MetaTags title="Copilot | Dashboard | Devdynamics" />

      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        <Box>
          <PageHeader headingText={'Copilot'} mb={'10px'} />
          <PageToolbar
            leftComp={
              <Flex align="flex-end">
                <TeamMenu
                  selectedTeam={selectedTeam}
                  handleSelectedTeam={setSelectedTeam}
                  AuthActionTypes={AuthActionTypes}
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                />
              </Flex>
            }
            rightComp={
              <DateFilter
                getUser={getUser}
                appState={appState}
                dispatch={dispatch}
                AuthActionTypes={AuthActionTypes}
              />
            }
          />
          <AcceptanceByLanguage />

          <CopilotActiveUsers />
          <CopilotLinesAccepted />
          <CopilotChatAcceptance />
        </Box>
      </Box>
    </>
  );
};

export default Copilot;
