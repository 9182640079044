import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import CustomSelect from '../../customSelect';
import { QUERY_KEYS, useFetchIssueTypeMap, useIssueTpyeMutation } from '../api';
import { useToastHook } from '../../appToast';
import { useQueryClient } from '@tanstack/react-query';
import Loader from '../../appLoader';
import { SaveAndPreviousButton } from './SaveAndPreviousButton';

interface IssueFormProps {
  integrationId: string;
  onNext: () => void;
  onPrevious: () => void;
  isFirstTab: boolean;
  isLastTab: boolean;
}

const formatOptionLabel = ({ value, label }: any) => {
  if (value === '') {
    return <span>&nbsp;</span>;
  }
  return label;
};

const categoryOptions =
  localStorage.getItem('orgId') === 'OSAFSU'
    ? [
        { value: 'work', label: 'work' },
        { value: 'excluded', label: 'excluded' },
        { value: 'bug', label: 'bug' },
        { value: 'others', label: 'qa' },
      ]
    : [
        { value: 'work', label: 'work' },
        { value: 'excluded', label: 'excluded' },
        { value: 'bug', label: 'bug' },
      ];

const IssueForm: FC<IssueFormProps> = ({
  integrationId,
  onNext,
  onPrevious,
  isFirstTab,
  isLastTab,
}) => {
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();
  const { data: issueTypeData, isLoading } =
    useFetchIssueTypeMap(integrationId);
  const [selectedTypes, setSelectedTypes] = useState<{ [key: string]: string }>(
    {}
  );
  const [editMode, setEditMode] = useState(issueTypeData?.dto?.custom);
  const [custom, setCustom] = useState(issueTypeData?.dto?.custom);

  useEffect(() => {
    setSelectedTypes(issueTypeData?.dto?.issueTypesMap);
  }, [issueTypeData, isLoading]);

  const { mutate: handleIssueType, isLoading: addMutationLoading } =
    useIssueTpyeMutation();

  const onSubmit = (
    custom: boolean,
    selectedTypes: { [key: string]: string }
  ) => {
    const transformedTypes = Object.keys(selectedTypes).reduce(
      (acc, key) => ({
        ...acc,
        [key]: selectedTypes[key] === 'qa' ? 'others' : selectedTypes[key],
      }),
      {}
    );
    const params = {
      custom: custom,
      issueTypesMap: transformedTypes,
    };

    handleIssueType(params, {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.issueType]);
        newToast({
          message: `Field Mapping done.`,
          status: 'success',
        });
        setTimeout(
          () =>
            newToast({
              message: `Filter data will reflect in the next 5 minutes.`,
              status: 'info',
            }),
          2000
        );
        onNext();
      },
    });
  };

  const onEdit = () => {
    setEditMode(true);
    setCustom(true);
  };

  return (
    <Box px={4}>
      <Flex mb={2}>
        <Box fontFamily={'heading'}>
          <Text
            fontSize="md"
            fontFamily="heading"
            fontWeight="semibold"
            color="text.primary"
            lineHeight="17px"
          >
            Issue Type Inclusion
          </Text>
          <Text fontSize={'sm'}>
            {custom === true
              ? 'Mapping which DevDynamics will use in all metrics.'
              : 'Below is default mapping which DevDynamics will use in all metrics.'}
          </Text>
        </Box>
      </Flex>

      <Flex>
        <Box>
          <Divider my={2}></Divider>

          <Flex
            fontFamily={'heading'}
            fontSize={'14px'}
            fontWeight={'semibold'}
            justifyContent={'space-between'}
            mb={2}
          >
            <Text>ISSUE TYPE</Text>
            <Text mr="60px">CATEGORY</Text>
          </Flex>

          <Stack spacing={4} w="500px" height={'400px'} overflowY={'scroll'}>
            {issueTypeData?.dto?.issueTypesMap &&
              Object?.keys(issueTypeData?.dto?.issueTypesMap).map((type) => (
                <HStack key={type} justifyContent={'space-between'}>
                  <Text
                    fontSize="sm"
                    fontFamily="heading"
                    fontWeight="semibold"
                    color="text.secondary"
                    lineHeight="17px"
                    minW="100px"
                  >
                    {type}
                  </Text>
                  <CustomSelect
                    placeholder={`Select ${type}`}
                    onChange={(selected: string) =>
                      setSelectedTypes({ ...selectedTypes, [type]: selected })
                    }
                    formatOptionLabel={formatOptionLabel}
                    defaultItem={{
                      value: selectedTypes?.[type] ?? '',
                      label:
                        categoryOptions.find(
                          (el: any) => el.value === selectedTypes?.[type]
                        )?.label ?? '',
                    }}
                    isDisabled={editMode ? false : true}
                    options={categoryOptions}
                  />
                </HStack>
              ))}
          </Stack>

          <Flex mt={2}>
            {!editMode && (
              <Tooltip label="You can edit the form by clicking here" hasArrow>
                <Button w="120px" variant="primary" onClick={onEdit} mt={2}>
                  Edit Form
                </Button>
              </Tooltip>
            )}
          </Flex>
        </Box>

        <Box
          mx={4}
          ml={6}
          mt={2}
          lineHeight={'17px'}
          justifyContent={'center'}
          fontFamily={'inter'}
        >
          <Text mb={4}>
            <strong>Work</strong> - An issue or activity that needs to be
            completed as part of feature development or a customer request.
          </Text>
          <Text mb={4}>
            <strong>Bug</strong> - A defect in the software that needs to be
            fixed.
          </Text>
          <Text>
            <strong>Excluded</strong> - An issue which will be excluded
            (filtered) from all metrics.
          </Text>
        </Box>
      </Flex>
      <SaveAndPreviousButton
        isFirstTab={isFirstTab}
        onPrevious={onPrevious}
        onSubmit={() => onSubmit(custom, selectedTypes)}
        isLastTab={isLastTab}
        isDisabled={!editMode}
      />

      {(addMutationLoading || isLoading) && <Loader />}
    </Box>
  );
};

export default IssueForm;
