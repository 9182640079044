import { Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const GetTestSuiteDetails = ({
  keyOfTestSuite,
  value,
  link,
}: {
  keyOfTestSuite: string;
  value: any;
  link?: string;
}) => (
  <Flex align="flex-start" gap={2}>
    <Text
      fontFamily={'heading'}
      whiteSpace="nowrap"
      fontSize="sm"
      color="text.secondary"
    >
      {keyOfTestSuite} :{' '}
    </Text>
    {link ? (
      <Link to={link} target="_blank" rel="noopener">
        <Text
          fontFamily={'heading'}
          wordBreak={'break-all'}
          fontSize="sm"
          color="text.primary"
          textDecoration={'underline'}
        >
          {value}
        </Text>
      </Link>
    ) : (
      <Text
        fontFamily={'heading'}
        wordBreak={'break-all'}
        fontSize="sm"
        color="text.primary"
      >
        {value}
      </Text>
    )}
  </Flex>
);

export default GetTestSuiteDetails;
