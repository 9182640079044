import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useUpdateContributorNameAndJobRole } from './api';
import { useQueryClient } from '@tanstack/react-query';
import { useToastHook } from '../appToast';
import {
  NAME_MAX_LENGTH,
  ROLE_MAX_LENGTH,
  validateRoleInput,
  validateNameInput,
} from '../shared/utils';

interface EditContributorModalProps {
  isOpen: boolean;
  onClose: () => void;
  contributorEmail?: string;
  contributorFirstName?: string;
  contributorLastName?: string;
  contributorRole?: string;
}

export const EditContributorModal: FC<EditContributorModalProps> = ({
  isOpen,
  onClose,
  contributorEmail,
  contributorFirstName,
  contributorLastName,
  contributorRole,
}) => {
  const [newContributorFirstName, setNewContributorFirstName] = useState('');
  const [newContributorLastName, setNewContributorLastName] = useState('');
  const [newContributorRole, setNewContributorRole] = useState('');

  const {
    mutate: updateContributorJobRole,
    isLoading: updateContributorJobRoleIsInProgress,
  } = useUpdateContributorNameAndJobRole();

  const queryClient = useQueryClient();

  const [newToast] = useToastHook();

  const handleSave = async () => {
    updateContributorJobRole(
      {
        email: contributorEmail,
        firstName: newContributorFirstName,
        lastName: newContributorLastName,
        jobRole: newContributorRole,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            'contributorDetails',
            contributorEmail,
          ]);
          queryClient.invalidateQueries([
            'contributorJobRole',
            contributorEmail,
          ]);
          queryClient.invalidateQueries(['contributorList']);
          newToast({
            message: 'Contributor details updated successfully',
            status: 'success',
          });
          onClose();
        },
        onError: (error: any) => {
          newToast({
            message: 'Error updating contributor details',
            status: 'error',
          });
        },
      }
    );
  };

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    role: '',
  });

  useEffect(() => {
    setNewContributorFirstName(contributorFirstName || '');
    setNewContributorLastName(contributorLastName || '');
    setNewContributorRole(contributorRole || '');

    setErrors({
      firstName: validateNameInput(contributorFirstName || '')
        ? ''
        : 'Only letters, hyphens, and underscores are allowed',
      lastName: validateNameInput(contributorLastName || '')
        ? ''
        : 'Only letters, hyphens, and underscores are allowed',
      role: validateRoleInput(contributorRole || '')
        ? ''
        : 'Only letters, numbers, spaces, hyphens, and underscores are allowed',
    });
  }, [contributorFirstName, contributorLastName, contributorRole]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>,
    field: 'firstName' | 'lastName' | 'role'
  ) => {
    const value = e.target.value;
    setter(value);
    validateField(field, value);
  };

  const validateField = (
    field: 'firstName' | 'lastName' | 'role',
    value: string
  ) => {
    const isValid =
      field === 'role' ? validateRoleInput(value) : validateNameInput(value);
    const maxLength = field === 'role' ? ROLE_MAX_LENGTH : NAME_MAX_LENGTH;

    if (isValid && value.length <= maxLength) {
      setErrors((prev) => ({ ...prev, [field]: '' }));
    } else {
      setErrors((prev) => ({
        ...prev,
        [field]:
          value.length > maxLength
            ? `Maximum ${maxLength} characters allowed`
            : field === 'role'
            ? 'Only letters, numbers, spaces, hyphens, and underscores are allowed'
            : 'Only letters, hyphens, and underscores are allowed',
      }));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      motionPreset="slideInBottom"
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={'4px'}
        boxShadow="0px 0px 14px rgba(0, 0, 0, 0.1)"
        fontFamily={'inter'}
      >
        <ModalCloseButton onClick={onClose} />
        <ModalBody p={4} mt={3}>
          <Flex mb={4}>
            <FormControl mr={2} isInvalid={!!errors.firstName}>
              <FormLabel color="#626266" fontWeight="500">
                First Name
              </FormLabel>
              <Input
                placeholder={contributorFirstName}
                value={newContributorFirstName}
                onChange={(e) =>
                  handleInputChange(e, setNewContributorFirstName, 'firstName')
                }
              />
              {errors.firstName && (
                <FormErrorMessage fontSize={'12px'}>
                  {errors.firstName}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl ml={2} isInvalid={!!errors.lastName}>
              <FormLabel color="#626266" fontWeight="500">
                Last Name
              </FormLabel>
              <Input
                placeholder={contributorLastName}
                value={newContributorLastName}
                onChange={(e) =>
                  handleInputChange(e, setNewContributorLastName, 'lastName')
                }
              />
              {errors.lastName && (
                <FormErrorMessage fontSize={'12px'}>
                  {errors.lastName}
                </FormErrorMessage>
              )}
            </FormControl>
          </Flex>
          <FormControl isInvalid={!!errors.role}>
            <FormLabel color="#626266" fontWeight="500">
              Job Role
            </FormLabel>
            <Input
              placeholder="Software Engineer"
              value={newContributorRole}
              onChange={(e) =>
                handleInputChange(e, setNewContributorRole, 'role')
              }
              borderColor="primary"
            />
            {errors.role && (
              <FormErrorMessage fontSize={'12px'}>
                {errors.role}
              </FormErrorMessage>
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            color={'#626266'}
            p={'12px 16px'}
            bg={'transparent'}
            onClick={onClose}
            fontSize={'16px'}
            fontWeight={500}
            _hover={{ bg: 'white' }}
          >
            Cancel
          </Button>
          <Button
            ml={'8px'}
            color={'white'}
            p={'12px 16px'}
            bg={'#4D4DFF'}
            border={'1px solid #E0E0E0'}
            borderRadius={'4px'}
            fontSize={'16px'}
            fontWeight={500}
            _hover={{ bg: '#4D4DFF' }}
            w={20}
            onClick={() => handleSave()}
            isDisabled={
              !!errors.firstName ||
              !!errors.lastName ||
              !!errors.role ||
              updateContributorJobRoleIsInProgress
            }
          >
            {updateContributorJobRoleIsInProgress ? (
              <Spinner size="sm" />
            ) : (
              'Save'
            )}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditContributorModal;
