import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthContext, RequireAuth, useAuth } from '@devd-client/devd/auth';
import Settings from './screens/Settings/settings';
import MetricsPage from './screens/Metrics/metrics';
import { AsyncGitInstallation } from './screens/gitInstallationPage/asyncGitInstallation';
import { AsyncGmeetInstallation } from './screens/gMeet/asyncGMeet';
import { AsyncInvitation } from './screens/invitation/asynsInvitation';
import { AsyncRegister } from './screens/register/asyncRegister';
import AsyncLogin from './screens/Login/login';
import AsyncSignup from './screens/Signup';
import Dashboard from './screens/Dashboard/dashboard';
import { AppLayout } from '@devd-client/devd/components';
import { useContext, useRef } from 'react';
import { AsyncMsTeamsInstallation } from './screens/msTeamsInstallationPage/asyncMsTeamsInstallation';
import Fallback from './screens/Status/Fallback';
import NotFound from './screens/Status/404NotFound';
import AsyncResetPassword from './screens/resetPassword';
import Maintenance from './screens/Status/Maintenance';
import { AsyncGitLabInstallation } from './screens/gitLabInstallationPage/asyncGitLabInstallation';
import { AsyncClickupInstallation } from './screens/clickupInstallationPage/asyncClickupInstallation';
import { AsyncAsyncIntercomInstallation } from './screens/intercomInstallation/asyncIntercomInstallation';
import { useTheme, useToast } from '@chakra-ui/react';
import AsyncOnboarding from './screens/Onboarding';

const AppRoutes = () => {
  const [, , handleLogout] = useContext(AuthContext);
  const [appState] = useAuth();
  const toast = useToast();
  const { colors } = useTheme();

  // Turn this flag to true to show maintenance page
  const isUnderMaintenance = false;

  const email = appState?.user?.email;

  return (
    <Routes>
      {isUnderMaintenance ? (
        <Route path="/*" element={<Maintenance />} />
      ) : (
        <>
          <Route
            path="/"
            element={(() => {
              const returnUrl = sessionStorage.getItem('returnUrl');

              if (sessionStorage.getItem('role') === 'EMPLOYEE') {
                return <Navigate to={`/contributors/${email}`} />;
              }

              if (returnUrl) {
                return <Navigate to={returnUrl} />;
              }

              return <Navigate to="/home" />;
            })()}
          />
          <Route
            path="/*"
            element={
              <RequireAuth>
                <AppLayout appState={appState} handleLogout={handleLogout}>
                  <Dashboard appState={appState} />
                </AppLayout>
              </RequireAuth>
            }
          >
            <Route path=":appId" />
          </Route>
          <Route
            path="/metrics"
            element={
              <RequireAuth>
                <AppLayout appState={appState} handleLogout={handleLogout}>
                  <MetricsPage />
                </AppLayout>
              </RequireAuth>
            }
          >
            <Route path=":metricsId" />
          </Route>
          <Route
            path="/settings/*"
            element={
              <RequireAuth>
                <AppLayout appState={appState} handleLogout={handleLogout}>
                  <Settings />
                </AppLayout>
              </RequireAuth>
            }
          >
            <Route path=":settingsId" />
          </Route>

          <Route path="account">
            <Route path="login" element={<AsyncLogin />} />
            <Route path="signup" element={<AsyncSignup />} />
            <Route path="reset-password" element={<AsyncResetPassword />} />
            <Route index element={<Navigate to={'/account/login'} />} />
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route path="/onboarding" element={<AsyncOnboarding />} />

          <Route
            path="/github/installation"
            element={<AsyncGitInstallation />}
          />
          <Route
            path="/gitlab/installation"
            element={<AsyncGitLabInstallation />}
          />
          <Route
            path="/clickup/installation"
            element={<AsyncClickupInstallation />}
          />
          <Route
            path="/gmeet/installation"
            element={<AsyncGmeetInstallation />}
          />
          <Route
            path="/intercom/installation"
            element={<AsyncAsyncIntercomInstallation />}
          />
          <Route
            path="/msteams/installation"
            element={<AsyncMsTeamsInstallation />}
          />
          <Route
            path="/webex/installation"
            element={<AsyncMsTeamsInstallation />}
          />
          <Route
            path="/outlook/installation"
            element={<AsyncMsTeamsInstallation />}
          />
          <Route path="/invitation" element={<AsyncInvitation />} />
          <Route path="/register" element={<AsyncRegister />} />
          <Route path="/error" element={<Fallback />} />

          <Route path="*" element={<NotFound />} />
        </>
      )}
    </Routes>
  );
};

export default AppRoutes;
