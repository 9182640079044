import { useAuth } from '@devd-client/devd/auth';
import {
  durationData,
  removeEmptyQueryParams,
  useDashboardStore,
  useQueryState,
} from '@devd-client/devd/components';
import { useEffect, useState } from 'react';
import { useCicdPipelineGql } from '../graphql';
import { useFetchRepo } from '../api';
import moment from 'moment';

export const useCicdDashboard = () => {
  const [appState, dispatch] = useAuth();
  const { selectedTeam, selected, setSelectedTeam, setSelected } =
    useDashboardStore();
  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [team, setTeam] = useQueryState('team');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [filter, setFilter] = useQueryState('filter');
  const [repo, setRepo] = useQueryState('repo');
  const [pipeline, setPipeline] = useQueryState('pipeline');

  const [currentPage, setCurrentPage] = useState(0);

  const [periodOptions, setPeriodOptions] = useState<any>(durationData);
  const [isHovered, setIsHovered] = useState(false);
  const [repos, setRepos] = useState([]);
  const [pipelines, setPipelines] = useState([]);

  const [filterBy, setFilterBy] = useState<{
    value: string;
    label: string;
  }>({ value: 'pipeline', label: 'Pipeline' });

  const { data: repoList, isLoading: repoLoading } = useFetchRepo('git');
  const { data: pipelineList, isLoading: pipelineLoading } =
    useFetchRepo('cicd');

  const { data: cicdData, isFetching } = useCicdPipelineGql(
    selectedTeam.value,
    selected.startDate,
    selected.endDate,
    selected.sprintId,
    currentPage,
    20,
    filterBy.value === 'pipeline'
      ? pipelines?.map((el: any) => el.label)
      : repos?.map((el: any) => el.label),
    filterBy.value
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [
    selected.startDate,
    selected.endDate,
    selectedTeam.value,
    selected.duration,
  ]);

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'Organisation'
        ? setTeam('Organisation')
        : setTeam(selectedTeam.value);
    }

    if (repos && repos?.length < 1) {
      removeEmptyQueryParams({ repo: '' });
    } else {
      setRepo(repos?.map((repo: any) => repo.label).join(', '));
    }

    if (pipelines && pipelines?.length < 1) {
      removeEmptyQueryParams({ pipeline: '' });
    } else {
      setPipeline(pipelines?.map((repo: any) => repo.value).join(', '));
    }

    if (filterBy.value) {
      setFilter(filterBy.value);
    }
  }, [selected, selectedTeam.value, filterBy.value, repos, pipelines]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'Organisation'
          ? { value: '', label: 'Organisation' }
          : { value: team, label: team }
      );

    filter &&
      setFilterBy(
        filter === 'pipeline'
          ? { value: 'pipeline', label: 'Pipeline' }
          : filter === 'repo'
          ? { value: 'repo', label: 'Repo' }
          : { value: '', label: '' }
      );

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  useEffect(() => {
    if (!repoList?.dto) return;
    repo &&
      setRepos(
        repoList?.dto
          ?.filter((el: any) => repo?.split(', ')?.includes(el.name))
          ?.map((item: any) => ({ value: item.key, label: item.name }))
      );
  }, [repoList?.dto, repo]);

  useEffect(() => {
    if (!pipelineList?.dto) return;
    pipeline &&
      setPipelines(
        pipelineList?.dto
          ?.filter((el: any) => pipeline?.split(', ')?.includes(el.key))
          ?.map((item: any) => ({ value: item.key, label: item.name }))
      );
  }, [pipelineList?.dto, pipeline]);

  return {
    cicdData,
    isFetching,
    isHovered,
    setIsHovered,
    selectedTeam,
    setSelectedTeam,
    appState,
    dispatch,
    filterBy,
    setFilterBy,
    repos,
    setRepos,
    repoList,
    pipelines,
    setPipelines,
    pipelineList,
    currentPage,
    setCurrentPage,
  };
};
