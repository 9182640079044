import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import {
  PageHeader,
  MetaTags,
  PageToolbar,
  TeamMenu,
  Loader,
  AppTooltip,
  DateFilter,
} from '@devd-client/devd/components';
import { FC } from 'react';
import { AuthActionTypes, getUser } from '@devd-client/devd/auth';
import { Link, useNavigate } from 'react-router-dom';
import CicdList from '../components/CicdList';
import { Select } from 'chakra-react-select';
import { useCicdDashboard } from '../hooks/useCicdDashboard';

const filterOptions = [
  { value: 'pipeline', label: 'Pipeline' },
  { value: 'repo', label: 'Repo' },
];

const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
};

export const Cicd: FC = () => {
  const navigate = useNavigate();
  const {
    cicdData,
    isFetching,
    isHovered,
    setIsHovered,
    selectedTeam,
    setSelectedTeam,
    appState,
    dispatch,
    filterBy,
    setFilterBy,
    repos,
    setRepos,
    repoList,
    pipelines,
    setPipelines,
    pipelineList,
    currentPage,
    setCurrentPage,
  } = useCicdDashboard();

  return (
    <>
      <MetaTags title="CI/CD | Dashboard | Devdynamics" />

      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        {cicdData?.chartDataState === 'NO_INTEGRATION' ? (
          <Box>
            <PageHeader
              headingText={'Pipeline'}
              helpDocLink={'https://docs.devdynamics.ai/integrations/jenkins'}
              mb={'10px'}
            />
            <Flex mb={2} width="100%" justify="flex-end">
              <AppTooltip
                trigger="hover"
                content={
                  <Flex>
                    <Link
                      to={`${cicdData?.chartError?.link}`}
                      target="_blank"
                      rel="noopener"
                    >
                      <Text
                        color="primary"
                        fontFamily="heading"
                        fontSize="sm"
                        textDecoration={'underline'}
                      >
                        Configure
                      </Text>
                    </Link>
                    <Text
                      fontFamily="heading"
                      fontSize="sm"
                      color="text.primary"
                      ml={2}
                    >
                      {cicdData?.chartError?.message}
                    </Text>
                  </Flex>
                }
              >
                <Box>
                  <Button
                    size={'sm'}
                    color={'error'}
                    border="1px"
                    borderColor={'error'}
                    backgroundColor={'white'}
                    fontFamily="heading"
                  >
                    {'why no data?'}
                  </Button>
                </Box>
              </AppTooltip>
            </Flex>

            <Box
              w="full"
              height="400px"
              display="flex"
              justifyContent={'center'}
              alignItems="center"
              position="relative"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Image
                height="100%"
                src={cicdData?.zs || ''}
                style={{ position: 'relative' }}
              />

              {isHovered && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  backgroundColor="rgba(0, 0, 0, 0.4)"
                  borderRadius="4px"
                >
                  {cicdData?.chartError?.message && (
                    <Button
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      onClick={() => navigate('/settings/integrations')}
                    >
                      Connect{' '}
                      {cicdData?.chartError?.message === 'ISSUE TRACKING'
                        ? 'Jira'
                        : cicdData?.chartError?.message === 'REPOSITORY'
                        ? 'Github'
                        : ''}
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box>
            <PageHeader
              headingText={'CI/CD'}
              helpDocLink={'https://docs.devdynamics.ai/integrations/jenkins'}
              mb={'10px'}
            />
            <PageToolbar
              leftComp={
                <>
                  <Flex align="flex-end">
                    <TeamMenu
                      selectedTeam={selectedTeam}
                      handleSelectedTeam={setSelectedTeam}
                      AuthActionTypes={AuthActionTypes}
                      getUser={getUser}
                      appState={appState}
                      dispatch={dispatch}
                    />
                  </Flex>

                  <Box mx={3}>
                    <Text
                      fontSize="sm"
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="text.secondary"
                      lineHeight="17px"
                      mb={2}
                    >
                      Filter By
                    </Text>
                    <Select
                      chakraStyles={customStyles}
                      placeholder="Select"
                      useBasicStyles
                      onChange={(e: any) => {
                        setFilterBy(e);
                      }}
                      size="sm"
                      value={filterBy}
                      options={filterOptions}
                    />
                  </Box>

                  {filterBy.value === 'repo' && (
                    <Box mr={3} maxWidth="800px">
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                        mb={2}
                      >
                        Repository
                      </Text>
                      <Select
                        chakraStyles={customStyles}
                        placeholder="Select Repo"
                        isMulti={true}
                        useBasicStyles
                        onChange={(e: any) => {
                          setRepos(e);
                        }}
                        size="sm"
                        value={repos}
                        options={repoList?.dto?.map((repo: any) => ({
                          value: repo.key,
                          label: repo.name,
                        }))}
                      />
                    </Box>
                  )}

                  {filterBy.value === 'pipeline' && (
                    <Box mr={3} maxWidth="800px">
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                        mb={2}
                      >
                        Pipelines
                      </Text>
                      <Select
                        chakraStyles={customStyles}
                        placeholder="Select Pipeline"
                        isMulti={true}
                        useBasicStyles
                        onChange={(e: any) => {
                          setPipelines(e);
                        }}
                        size="sm"
                        value={pipelines}
                        options={pipelineList?.dto?.map((repo: any) => ({
                          value: repo.key,
                          label: repo.name,
                        }))}
                      />
                    </Box>
                  )}
                </>
              }
              rightComp={
                <DateFilter
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                  AuthActionTypes={AuthActionTypes}
                />
              }
            />

            <CicdList
              data={cicdData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              loading={isFetching}
            />
          </Box>
        )}
      </Box>
      {isFetching && <Loader />}
    </>
  );
};

export default Cicd;
