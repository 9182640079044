import { create } from 'zustand';
import { PeriodType } from '../shared/typings';
import { ISelect } from '../customSelect';

interface DashboardState {
  selectedTeam: ISelect;
  setSelectedTeam: (selectedTeam: ISelect) => void;
  selected: PeriodType;
  setSelected: (selected: PeriodType) => void;
  filterTitle: string;
  setFilterTitle: (title: string) => void;
  active: string;
  setActive: (active: string) => void;
}

interface DetailsPageState {
  selected: PeriodType;
  setSelected: (selected: PeriodType) => void;
  filterTitle: string;
  setFilterTitle: (title: string) => void;
  active: string;
  setActive: (active: string) => void;
}

export const useDashboardStore = create<DashboardState>()((set) => ({
  selectedTeam: {
    value: localStorage.getItem('team') || '',
    label: localStorage.getItem('team') || 'Organisation',
  },
  selected: {
    id: 0,
    startDate: '',
    endDate: '',
    duration: '',
    sprintId: '',
  },
  filterTitle: '',
  setSelected: (selected: PeriodType) => set({ selected }),
  setSelectedTeam: (selectedTeam: ISelect) => set({ selectedTeam }),
  setFilterTitle: (filterTitle: string) => set({ filterTitle }),
  active: '',
  setActive: (active: string) => set({ active }),
}));

export const useDetailsPageStore = create<DetailsPageState>()((set) => ({
  selected: {
    id: 0,
    startDate: '',
    endDate: '',
    duration: '',
    sprintId: '',
  },
  filterTitle: '',
  setSelected: (selected: PeriodType) => set({ selected }),
  setFilterTitle: (filterTitle: string) => set({ filterTitle }),
  active: '',
  setActive: (active: string) => set({ active }),
}));
