import { apiClient } from '@devd-client/api';

export const mergeContributors = (params: any): Promise<any> =>
  apiClient(`/v1/org/${localStorage.getItem('orgId')}/contributors/merge`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params),
  });
