import { FC, Fragment } from 'react';
import { Box, Divider, Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { SlideBar } from '../../../appSlideBar';
import { Paginate } from '../../../appPaginate';
import moment from 'moment';

interface DependencyVulnerabilitySidebarProps {
  openDrawer: boolean;
  handleDrawerClose: () => void;
  dependencyVulnerabilityData: any;
  heading: string;
  subHeading: string;
  isFetching: boolean;
  handlePageClick: any;
  startDate: string;
  endDate: string;
  filterBy: any;
}

const DependencyVulnerabilitySidebar: FC<
  DependencyVulnerabilitySidebarProps
> = ({
  openDrawer,
  handleDrawerClose,
  dependencyVulnerabilityData,
  heading,
  subHeading,
  isFetching,
  handlePageClick,
  endDate,
  filterBy,
  startDate,
}) => {
  return (
    <SlideBar
      width={'460px'}
      openDrawer={openDrawer}
      handleDrawerClose={handleDrawerClose}
      sideBarHeading={heading}
      startDate={startDate}
      endDate={endDate}
      filterBy={filterBy}
      currentChartMeta={{ chartKey: 'DEPENDENCY_VULNERABILITIES' }}
      sideBarSubHeading={
        <Text
          fontFamily="heading"
          fontSize="sm"
          color={'text.secondary'}
          fontWeight={'medium'}
        >
          {subHeading}
        </Text>
      }
      content={
        <Stack spacing={1}>
          {isFetching &&
            Array.from(Array(8).keys()).map((_: any, index: number) => (
              <Skeleton key={index} height={'100px'} width={'100%'} />
            ))}

          {dependencyVulnerabilityData?.data?.map(
            (item: any, idx: number, arr: any) => (
              <Fragment key={item?.id}>
                <Box p={2}>
                  <Stack spacing={1}>
                    <Box>
                      {item?.updated_at && (
                        <Text
                          fontFamily="heading"
                          fontSize="xs"
                          color="text.secondary"
                          fontWeight="semibold"
                        >
                          {moment(item?.updated_at).format('lll')}
                        </Text>
                      )}

                      <Link to={item?.link} target="_blank">
                        <Text
                          fontSize="md"
                          fontFamily="heading"
                          textDecoration="underline"
                          color="primary"
                          fontWeight="medium"
                        >
                          Package: {item?.package_name}
                        </Text>
                      </Link>
                    </Box>

                    <Flex align="center" flexWrap="wrap">
                      {Object.entries(item).map(([key, value]: any, idx) => {
                        if (
                          key === 'updated_at' ||
                          key === 'link' ||
                          key === 'id' ||
                          key === 'package_name'
                        ) {
                          return null;
                        }
                        return (
                          value && (
                            <Flex mr={3} key={`att-${idx}`} align="flex-start">
                              <Text
                                fontFamily="heading"
                                fontSize="sm"
                                fontWeight="semibold"
                                color="gray.600"
                                whiteSpace={'nowrap'}
                                mr={1}
                              >
                                {key}
                              </Text>{' '}
                              <Text mt={'-3px'}>:</Text>
                              <Text
                                ml={1}
                                fontFamily="heading"
                                fontSize="sm"
                                fontWeight="medium"
                                color="text.secondary"
                                wordBreak={'break-all'}
                              >
                                {value}
                              </Text>
                            </Flex>
                          )
                        );
                      })}
                    </Flex>
                  </Stack>
                </Box>
                {idx !== arr.length - 1 && <Divider />}
              </Fragment>
            )
          )}

          <Box marginLeft="-20px" my={'16px'}>
            {dependencyVulnerabilityData?.data?.length > 1 && (
              <Paginate
                currentPage={dependencyVulnerabilityData?.currentPage + 1}
                totalPageCount={dependencyVulnerabilityData?.totalPages}
                pageSize={20}
                onPageChange={(page) => handlePageClick(page as number)}
              />
            )}
          </Box>
        </Stack>
      }
    />
  );
};

export default DependencyVulnerabilitySidebar;
