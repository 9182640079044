import {
  Box,
  Button,
  Flex,
  ListItem,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { DataGrid, Paginate, PeriodType } from '@devd-client/devd/components';
import { createColumnHelper } from '@tanstack/react-table';
import { useFetchTeamCollaborationGql } from '../../../graphql';
import { useTeamDetailsStore } from '../../../store/teamDetail';
import { useCallback, useMemo, useState } from 'react';
import ShowPrSideDrawer from './ShowPrSideDrawer';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

interface TeamCollaborationProps {
  selected: PeriodType;
}

const TeamCollaboration = ({ selected }: TeamCollaborationProps) => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { pathname } = useLocation();

  const { data, isLoading, isFetching } = useFetchTeamCollaborationGql(
    selected?.startDate,
    selected?.endDate,
    selected?.sprintId,
    decodeURIComponent(pathname?.split('/')[2]),
    currentPage,
    20
  );

  const [submitter, setSubmitter] = useState<string>('');
  const [reviewer, setReviewer] = useState<string>('');
  const [count, setCount] = useState<string>('');

  const prSideDrawer = useDisclosure();

  const teamCollaborationData = data?.TeamCollaborationDetails?.collaborations;

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );

  const rows: any = useMemo(
    () => teamCollaborationData || [],
    [teamCollaborationData]
  );

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('reviewer', {
        cell: (info) => (
          <Flex w={'fit-content'} alignItems="center">
            <StyledLink to={`/contributors/${info?.getValue()?.name}`}>
              {info?.getValue()?.name}
            </StyledLink>

            <Button
              borderRadius={'full'}
              px="1"
              ml={1}
              w="fit-content"
              size={'xs'}
              backgroundColor="primary"
              _hover={{ backgroundColor: '#272780' }}
              color="white"
              onClick={(e) => {
                e.stopPropagation();
                setSubmitter('');
                setReviewer(`${info?.getValue()?.name}`);
                setCount(info?.getValue()?.value);
                prSideDrawer.onOpen();
              }}
            >
              {info?.getValue()?.value}
            </Button>
          </Flex>
        ),
        header: 'Reviewer',
      }),
      columnHelper.accessor('submitters', {
        cell: (info) => (
          <UnorderedList spacing={2}>
            {info.getValue()?.map((submitterDetail: any) => (
              <ListItem>
                <Flex w={'fit-content'} alignItems="center">
                  <StyledLink to={`/contributors/${submitterDetail?.name}`}>
                    {submitterDetail?.name}
                  </StyledLink>

                  <Button
                    borderRadius={'full'}
                    px="1"
                    ml={1}
                    w="fit-content"
                    size={'xs'}
                    backgroundColor="gray"
                    _hover={{ backgroundColor: 'gray.600' }}
                    color="white"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSubmitter(`${submitterDetail?.name}`);
                      setReviewer(`${info?.row?.original?.reviewer?.name}`);
                      setCount(submitterDetail?.value);
                      prSideDrawer?.onOpen();
                    }}
                  >
                    {submitterDetail?.value}
                  </Button>
                </Flex>
              </ListItem>
            ))}
          </UnorderedList>
        ),
        header: 'Submitters',
      }),
    ],
    [teamCollaborationData]
  );

  return (
    <Box>
      <DataGrid
        showLoader={isFetching}
        columns={columns}
        data={rows}
        maxH="24rem"
        sticky="firstCol"
      />

      <Box marginLeft="-20px">
        <Paginate
          currentPage={teamCollaborationData?.currentPage + 1}
          totalPageCount={teamCollaborationData?.totalPages}
          pageSize={10}
          onPageChange={(page) => handlePageClick(page as number)}
        />
      </Box>
      {prSideDrawer.isOpen && (
        <ShowPrSideDrawer
          openDrawer={prSideDrawer.isOpen}
          handleDrawerClose={prSideDrawer.onClose}
          heading={
            submitter === ''
              ? `PR reviewed by ${reviewer}`
              : `PR submitted by ${submitter}`
          }
          subHeading={`${moment(selected.startDate).format(
            'DD MMM'
          )} - ${moment(selected.endDate).format('DD MMM')}, ${
            submitter === '' ? 'PR reviewed' : 'PR submitted'
          } (${count})
          `}
          submitter={submitter}
          reviewer={reviewer}
          teamId={decodeURIComponent(pathname?.split('/')[2])}
          selected={selected}
        />
      )}
    </Box>
  );
};

export default TeamCollaboration;
