import { Box, Flex, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { QUALITY_CHART_KEYS, splitTime } from '../../../shared/utils';
import {
  StyledTooltipHeading,
  StyledToopTipText,
  TooltipContainer,
  Button,
  Unit,
} from './CustomTooltip.styled';
import { useEffect, useState } from 'react';
import { TbListDetails } from 'react-icons/tb';
import { apiClient } from '@devd-client/api';
import { useToastHook } from '../../../appToast';
import { getStartDateEndDate } from '../../utils/charts.utils';
import { Button as ChakraButton } from '@chakra-ui/react';

const indexes = [
  'Review Responsiveness Index',
  'Review Completion',
  'Feedback Responsiveness Index',
  'Requirement Changes',
  'PR Review Health',
  'PR Merge Health',
  'Change Failure Rate',
];

const issues = [
  'Inflow',
  'Outflow',
  'Cumulative Inflow',
  'Cumulative Outflow',
  'Less than 1M',
  'Less than 15D',
  'Less than 3M',
  'Above 3M',
];

export const CustomTooltip = ({
  total,
  active,
  payload,
  unit,
  label,
  setClickedData,
  handleModalOpen,
  onClickAction,
  hideBtn = false,
  onHoverChange,
  chartKey,
  hideTooltip,
  minW,
  keyName,
  showDownload,
}: any) => {
  const [pending, setPending] = useState(false);
  const [newToast] = useToastHook();
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);
  const tab = searchParams.get('tab');
  const breakdown = searchParams.get('breakdown');

  useEffect(() => {
    return () => onHoverChange?.(false);
  }, []);

  const selectedBar = getStartDateEndDate(label);

  const downloadCSV = async () => {
    setPending(true);
    apiClient(
      `/v2/org/${localStorage.getItem(
        'orgId'
      )}/download-software-capitalization-report?startDate=${
        selectedBar.startDate
      }&endDate=${selectedBar.endDate}`,
      {
        method: 'GET',
        headers: {
          authorization: `bearer ${localStorage?.getItem('token')}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res: any) => {
        setPending(false);
        newToast({ message: res?.dto, status: 'success' });
      })
      .catch((err) => {
        setPending(false);
        newToast({
          message: err.message || 'Something went wrong',
          status: 'error',
        });
      });
  };

  const isZeroValue =
    payload?.reduce(
      (acc: any, count: any) => parseFloat(count?.value) + acc,
      0
    ) === 0;

  if (active && payload && payload.length && !hideTooltip) {
    return (
      <TooltipContainer
        minW={minW}
        onMouseEnter={() => onHoverChange?.(true)}
        onMouseLeave={() => onHoverChange?.(false)}
      >
        <StyledTooltipHeading>
          {chartKey === 'PR_AGEING_DISTRIBUTION' ? ' ' : label}
        </StyledTooltipHeading>

        {payload
          .filter((item: any) => {
            return (
              item.dataKey !== 'yRollingAvg' &&
              item.dataKey !== 'timeFrame' &&
              item.dataKey !== 'YAXIS'
            );
          })
          .map((item: any, index: number) => {
            if (!total) {
              if (chartKey === 'WORK_BREAKDOWN') {
                total =
                  item.payload.helpOthers +
                  item.payload.refactor +
                  item.payload.newwork +
                  item.payload.rework;
              }
            }
            return (
              <Flex key={index} align="center" justify="space-between">
                <Flex align="center">
                  {keyName ? (
                    <></>
                  ) : [
                      'COPILOT_LINE_ACCEPTED',
                      'COPILOT_ACTIVE_USER',
                      'COPILOT_CHAT_ACCEPTED',
                    ].includes(item?.dataKey) ? (
                    <Box
                      mr={1}
                      height="2px"
                      width="15px"
                      bg={item.color || item?.payload?.color}
                    />
                  ) : [
                      'NEW_LINE_OF_CODE',
                      'CONTRIBUTOR_COUNT',
                      'COPILOT_CHAT_TURN',
                    ].includes(item?.dataKey) ? (
                    <Box
                      mr={1}
                      height="2px"
                      width="15px"
                      borderTop={`2px dotted ${
                        item.color || item?.payload?.color
                      }`}
                    />
                  ) : (
                    <Box
                      mr={1}
                      height="10.5px"
                      width="10.5px"
                      bg={item.color || item?.payload?.color}
                    />
                  )}

                  <StyledToopTipText key={index}>
                    {keyName ? (
                      <Text
                        as="span"
                        display="block"
                        color="text.secondary3"
                        fontWeight="medium"
                      >
                        {keyName}
                      </Text>
                    ) : (
                      `${item?.name} - `
                    )}
                    {chartKey === 'WORK_BREAKDOWN'
                      ? `${
                          item?.value >= 1000000
                            ? `${(item?.value / 1000000).toFixed(1)}M`
                            : item?.value >= 1000
                            ? `${(item?.value / 1000).toFixed(1)}K`
                            : item?.value
                        } (${((item.value / total) * 100).toFixed(
                          (item.value / total) * 100 < 1 ? 1 : 0
                        )}%)`
                      : item.dataKey === 'value' &&
                        chartKey === 'PR_AGEING_DISTRIBUTION'
                      ? `${item.value}(${((item.value / total) * 100).toFixed(
                          1
                        )}%)`
                      : item.dataKey === 'percentage_prs_reviewed' ||
                        item.name?.toLowerCase() === 'percent'
                      ? `${item.value} %`
                      : item.dataKey === 'pr_pickup_time' ||
                        item.dataKey === 'pr_merge_time' ||
                        item.dataKey === 'pr_cycle_time'
                      ? splitTime(item.value / 24)
                      : item.dataKey === 'avgValue' ||
                        item.dataKey === 'cycleTime' ||
                        item.name?.toLowerCase() === 'minutes'
                      ? splitTime(item.value / 60)
                      : item?.name === 'Hours' ||
                        item?.name === 'PR Pickup Time' ||
                        item?.name === 'PR Merge Time' ||
                        item?.name === 'Coding Time' ||
                        item?.name === 'Deploy Time' ||
                        item?.name === 'PR Cycle Time' ||
                        item?.name === 'Pickup Time' ||
                        item?.name === 'Merge Time' ||
                        unit === 'Hour'
                      ? splitTime(item.value)
                      : item?.name === 'Cycle Time'
                      ? `${item.value} Days`
                      : item?.name === 'Cost'
                      ? `$${item.value}`
                      : indexes.includes(item?.payload?.name) ||
                        indexes.includes(item?.name)
                      ? `${item.value} %`
                      : issues.includes(item?.name)
                      ? `${item.value} issues`
                      : [
                          'High',
                          'Low',
                          'Lowest',
                          'Medium',
                          'Critical',
                          'Highest',
                        ].includes(item?.name) &&
                        tab === 'MEAN_TIME_TO_RECOVERY_DISTRIBUTION'
                      ? splitTime(item.value)
                      : tab === 'ISSUE_PROGRESSION_RATE'
                      ? `${item.value}%`
                      : ['COPILOT_ACTIVE_USER', 'CONTRIBUTOR_COUNT'].includes(
                          item?.dataKey
                        )
                      ? item.value?.toFixed(2)
                      : item.value}{' '}
                    {breakdown && (
                      <Unit>
                        {unit === 'Count' ? '' : unit === 'Hour' ? '' : unit}
                      </Unit>
                    )}
                  </StyledToopTipText>
                </Flex>
                {(QUALITY_CHART_KEYS.includes(tab as string) ||
                  QUALITY_CHART_KEYS.includes(chartKey)) && (
                  <Box ml={1}>
                    <Tooltip hasArrow label={'View Data'}>
                      <IconButton
                        aria-label="View Data"
                        color="text.primary"
                        icon={<TbListDetails size={18} />}
                        background="transparent"
                        size="xs"
                        style={{ pointerEvents: 'all' }}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleModalOpen();
                          setClickedData(item);
                          onClickAction();
                        }}
                      />
                    </Tooltip>
                  </Box>
                )}
              </Flex>
            );
          })}

        {payload.length < 3 && chartKey === 'PR_AGEING_DISTRIBUTION' && (
          <Flex align="center" justify="space-between">
            <Flex align="center">
              <Box
                mr={1}
                height="10.5px"
                width="10.5px"
                bg={payload[0]?.color || payload[0]?.payload?.color}
              />
              <StyledToopTipText>{`PR Count (%) - 0(0.0%)`}</StyledToopTipText>
            </Flex>
          </Flex>
        )}

        {hideBtn && payload[0].name === 'Cost' && showDownload && (
          <ChakraButton
            variant="chart-download"
            onClick={() => downloadCSV()}
            isLoading={pending}
            loadingText={'Downloading'}
          >
            Download
          </ChakraButton>
        )}

        {!QUALITY_CHART_KEYS.includes((tab as string) || chartKey) && (
          <>
            {!hideBtn &&
              localStorage.getItem('role') !== 'EMPLOYEE' &&
              payload[0].name !== 'Met' &&
              payload?.some((item: any) => item.value !== 0) &&
              !isZeroValue && (
                <Button
                  style={{ pointerEvents: 'all' }}
                  onClick={() => {
                    handleModalOpen();
                    setClickedData(payload);
                    onClickAction();
                  }}
                >
                  View Data
                </Button>
              )}
          </>
        )}
      </TooltipContainer>
    );
  }

  return null;
};

export default CustomTooltip;
