import { Box, SimpleGrid } from '@chakra-ui/react';
import { AuthActionTypes, getUser } from '@devd-client/devd/auth';
import {
  MetricDetails,
  MetricsCard,
  PageHeader,
  PageToolbar,
  TeamMenu,
  DateFilter,
} from '@devd-client/devd/components';

import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCommonMetrics } from '../../hooks/useCommonMetrics';

interface ThroughputPageProps {
  metricsData: any;
}

const ThroughputPage: FC<ThroughputPageProps> = ({ metricsData }) => {
  const [searchParams] = useSearchParams();
  const metricType = searchParams.get('type');
  const helpDocLink = metricsData?.dto?.helpDoc;
  const throughputMetrics = metricsData?.dto?.metrics;

  const {
    selected,
    selectedTeam,
    setSelectedTeam,
    appState,
    dispatch,
    QUERY_KEYS,
  } = useCommonMetrics();

  return (
    <>
      {!metricType ? (
        <Box display={'flex'} flexDirection={'column'} width={'100%'}>
          <Box>
            <PageHeader
              headingText={metricsData?.dto?.name}
              mb={'10px'}
              helpDocLink={helpDocLink}
            />
            <PageToolbar
              leftComp={
                <TeamMenu
                  selectedTeam={selectedTeam}
                  handleSelectedTeam={setSelectedTeam}
                  AuthActionTypes={AuthActionTypes}
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                />
              }
              rightComp={
                <DateFilter
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                  AuthActionTypes={AuthActionTypes}
                />
              }
            />
          </Box>

          <SimpleGrid columns={{ base: 2, '2xl': 2 }} spacing={6} mt={6}>
            {throughputMetrics.map((type: string) => (
              <MetricsCard
                key={type}
                metricType={type}
                selected={selected}
                selectedTeam={selectedTeam.value}
                queryKey={QUERY_KEYS.throughputPage}
              />
            ))}
          </SimpleGrid>
        </Box>
      ) : (
        <MetricDetails />
      )}
    </>
  );
};

export default ThroughputPage;
