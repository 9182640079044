import { Box, Button, Flex, IconButton, Text } from '@chakra-ui/react';
import { forwardRef, ReactNode } from 'react';
import { CrossIcon, DragIcon } from '../../icons';
import { useTheme } from '@chakra-ui/react';
export const Item = forwardRef<any, any>(
  (
    { children, attributes, listeners, handleRemoveMetric, id, ...props },
    ref
  ) => {
    const { colors } = useTheme();

    return (
      <Flex
        px={2}
        py={1}
        bg={'#EAEAFB'}
        borderRadius={4}
        justify={'space-between'}
        outline={'1px solid #E2E2E7'}
        align={'center'}
        ref={ref}
        {...props}
      >
        <Flex gap={1} align={'center'} w={250} overflow={'hidden'}>
          <IconButton
            icon={<DragIcon fill={colors.primary} />}
            aria-label="drag-button"
            cursor={'grab'}
            size={'sm'}
            opacity={0.25}
            p={0}
            {...listeners}
          />

          <Text fontSize={'sm'}>{children}</Text>
        </Flex>
        <IconButton
          icon={
            <CrossIcon
              fill={colors.primary}
              color={colors.primary}
              height={20}
              width={14}
            />
          }
          aria-label="delete-metric"
          size={'sm'}
          p={0}
          opacity={0.75}
          background={'transparent'}
          color={'blue'}
          onClick={() => handleRemoveMetric(id)}
        />
      </Flex>
    );
  }
);
