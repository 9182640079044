import * as ENDPOINTS from './endpoints';
import { useQuery } from '@tanstack/react-query';

const QUERY_KEYS = {
  investmentData: 'investmentData',
  sprint: 'sprint',
  jobRole: 'jobRole',
};

export const useInvestmentData = () => {
  return useQuery([QUERY_KEYS.investmentData], ENDPOINTS.getInvestmentData);
};

export const useFetchSprint = () => {
  return useQuery([QUERY_KEYS.sprint], ENDPOINTS.getSprintList);
};

export const useFetchJobRole = () => {
  return useQuery([QUERY_KEYS.jobRole], ENDPOINTS.getJobRoleList);
};
