import { CustomSelect, ISelect } from '@devd-client/devd/components';
import { useFetchJobRole } from '../../api';
import { ChakraStylesConfig } from 'chakra-react-select';
import { Flex } from '@chakra-ui/react';

interface JobRoleFilterProps {
  selectedRole: ISelect;
  handleSelectedRole: (value: ISelect) => void;
  noDefault?: boolean;
}

export const JobRoleFilter = ({
  selectedRole,
  handleSelectedRole,
  noDefault = false,
}: JobRoleFilterProps) => {
  const { data, isLoading } = useFetchJobRole();
  const roleOptions =
    data?.dto?.map((role: string) => ({
      value: role,
      label: role,
      id: role,
    })) || [];
  const options = noDefault
    ? roleOptions
    : [{ value: '', label: 'All Roles', id: 'all' }].concat(roleOptions);
  const chakraStyles: ChakraStylesConfig = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };
  return (
    <Flex align="flex-end">
      <CustomSelect
        size="sm"
        placeholder="All Roles"
        label="Job Role"
        chakraStyles={chakraStyles}
        defaultItem={selectedRole}
        components={{ GroupHeading: () => <div></div> }}
        onChange={(selected: string) =>
          handleSelectedRole({
            value: selected,
            label: selected || 'All Roles',
          })
        }
        options={options}
        getOptionValue={(option: any) => `${option.id}`}
      />
    </Flex>
  );
};
