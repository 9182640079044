import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Input,
  Text,
  Heading,
  useDisclosure,
  Box,
} from '@chakra-ui/react';

import { FieldType } from '../../../helpers/teams.types';
import {
  eliminateHtmlTags,
  IssueFilterModal,
  useToastHook,
  ListSelect,
} from '@devd-client/devd/components';

import { useEffect, useState } from 'react';
import { QUERY_KEYS, useUpdateTeam } from '../../../apis';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

type Props = {
  gitRepo: any;
  jiraRepo: any;
  qualityRepo: any;
  pipelines: any;
  testSuite: any;
  incidentManagement: any;
  issueEpic: any;
  doraSettings: any;
  setWorkspaceRepo: any;
  workspaceRepo: any;
  setFilterDataByRepo: any;
  filterDataByRepo: any;
  setWorkspaceIssue: any;
  workspaceIssue: any;
  setFilterDataByIssueTracking: any;
  filterDataByIssueTracking: any;
  setQualityProjects: any;
  qualityProjects: any;
  cicdPipelines: any;
  setCicdPipelines: any;
  testSuiteProjects: any;
  setTestSuiteProjects: any;
  setCfLabels: any;
  cfLabels: any;
  setCfProjects: any;
  cfProjects: any;
  setCfEpics: any;
  cfEpics: any;
  setCfServicesList: any;
  cfServicesList: any;
  setCfRepos: any;
  cfRepos: any;
  setCfCustomField: any;
  cfCustomField: any;
  setDeploymentLabels: any;
  deploymentLabels: any;
  setDeploymentProjects: any;
  deploymentProjects: any;
  setDeploymentRepos: any;
  deploymentRepos: any;
  setDeploymentPattern: any;
  deploymentPattern: any;
  setDeploymentCustomField: any;
  deploymentCustomField: any;
  handleSubmit: any;
  loading: boolean;
  defaultData: any;
  setTriggerSubmit: (arg: boolean) => void;
};

const Workspace = ({
  setTriggerSubmit,
  gitRepo,
  jiraRepo,
  qualityRepo,
  pipelines,
  testSuite,
  incidentManagement,
  issueEpic,
  doraSettings,
  setWorkspaceRepo,
  workspaceRepo,
  setFilterDataByRepo,
  filterDataByRepo,
  setWorkspaceIssue,
  workspaceIssue,
  setFilterDataByIssueTracking,
  filterDataByIssueTracking,
  setQualityProjects,
  qualityProjects,
  setCicdPipelines,
  cicdPipelines,
  testSuiteProjects,
  setTestSuiteProjects,
  setCfLabels,
  cfLabels,
  setCfProjects,
  cfProjects,
  setCfEpics,
  cfEpics,
  setCfServicesList,
  cfServicesList,
  setCfRepos,
  cfRepos,
  setCfCustomField,
  cfCustomField,
  setDeploymentLabels,
  deploymentLabels,
  setDeploymentProjects,
  deploymentProjects,
  setDeploymentRepos,
  deploymentRepos,
  setDeploymentPattern,
  deploymentPattern,
  setDeploymentCustomField,
  deploymentCustomField,
  handleSubmit,
  defaultData,
  loading,
}: Props) => {
  const [newToast] = useToastHook();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const listSelectModal = useDisclosure();
  const issueFilterModal = useDisclosure();
  const [issueFilterConditions, setIssueFilterConditions] = useState<any>();
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>('');
  const { mutate: handleUpdateTeam, isLoading: handleUpdateTeamLoading } =
    useUpdateTeam();

  useEffect(() => {
    setIssueFilterConditions(defaultData?.workspaces?.issueFilterConditions);
  }, [defaultData]);

  return (
    <Flex gap="32px">
      <Box w={'50%'}>
        <Box mt={'8px'}>
          <Heading
            as="h6"
            size="md"
            fontWeight="semibold"
            color="text.primary"
            lineHeight="28px"
          >
            Repositories Filter
          </Heading>

          <Text
            fontSize="sm"
            fontFamily="heading"
            fontWeight="normal"
            color="text.secondary"
            lineHeight="20px"
            mt={2}
            mb={4}
          >
            Select Repositories from your version control system where your
            Teams store code, files and conduct other engineering activities.
          </Text>

          <Box my={4} display={'flex'} gap={4}>
            <Text
              fontSize="md"
              fontFamily="heading"
              fontWeight="medium"
              color="text.primary"
            >
              {workspaceRepo?.length || 0} Repositories Linked
            </Text>
            <Text
              fontSize="md"
              fontFamily="heading"
              fontWeight="semibold"
              color="primary"
              cursor="pointer"
              onClick={() => {
                listSelectModal.onOpen();
                setSelectedWorkspace('workspaceRepo');
              }}
            >
              {`Configure >`}
            </Text>
          </Box>
        </Box>

        <Divider orientation="horizontal" my="18px" />
        <Flex direction="column" gap={4}>
          <Heading
            as="h6"
            size="md"
            fontWeight="semibold"
            color="text.primary"
            lineHeight="28px"
          >
            Issue Filters
          </Heading>

          <Flex align="center" gap={4}>
            <Text
              fontSize="md"
              fontFamily="heading"
              fontWeight="medium"
              color="text.primary"
            >
              {defaultData?.workspaces?.issueFilterConditions?.conditions
                ?.length || 0}{' '}
              {`Issue Filter Conditio${
                defaultData?.workspaces?.issueFilterConditions?.conditions
                  ?.length > 1
                  ? `ns`
                  : 'n'
              } Linked`}
            </Text>

            <Text
              fontSize="md"
              fontFamily="heading"
              fontWeight="semibold"
              color="primary"
              cursor="pointer"
              onClick={() => {
                issueFilterModal.onOpen();
              }}
            >
              {`Configure >`}
            </Text>
          </Flex>
        </Flex>

        <Divider orientation="horizontal" my="18px" />

        <Box>
          <Heading
            as="h6"
            size="md"
            fontWeight="semibold"
            color="text.primary"
            lineHeight="28px"
          >
            Quality Projects Filter
          </Heading>
          <Text
            fontSize="sm"
            fontFamily="heading"
            fontWeight="normal"
            color="text.secondary"
            lineHeight="20px"
            mt={2}
            mb={4}
          >
            Select Projects from your issue management system where your Teams
            track Quality related issues like Bugs or Incidents.
          </Text>

          <Box my={4} display={'flex'} gap={4}>
            <Text
              fontSize="md"
              fontFamily="heading"
              fontWeight="medium"
              color="text.primary"
            >
              {qualityProjects?.length || 0} Projects Linked
            </Text>

            <Text
              fontSize="md"
              fontFamily="heading"
              fontWeight="semibold"
              color="primary"
              cursor="pointer"
              onClick={() => {
                listSelectModal.onOpen();
                setSelectedWorkspace('qualityProjects');
              }}
            >
              {`Configure >`}
            </Text>
          </Box>
        </Box>

        <Divider orientation="horizontal" my="18px" />

        <Box>
          <Heading
            as="h6"
            size="md"
            fontWeight="semibold"
            color="text.primary"
            lineHeight="28px"
          >
            CI/CD Pipeline Filter
          </Heading>
          {/* <Text
            fontSize="sm"
            fontFamily="heading"
            fontWeight="normal"
            color="text.secondary"
            lineHeight="20px"
            mt={2}
            mb={4}
          >
            Select Projects from your issue management system where your Teams
            track Quality related issues like Bugs or Incidents.
          </Text> */}

          <Box my={4} display={'flex'} gap={4}>
            <Text
              fontSize="md"
              fontFamily="heading"
              fontWeight="medium"
              color="text.primary"
            >
              {cicdPipelines?.length || 0} Pipelines Linked
            </Text>

            <Text
              fontSize="md"
              fontFamily="heading"
              fontWeight="semibold"
              color="primary"
              cursor="pointer"
              onClick={() => {
                listSelectModal.onOpen();
                setSelectedWorkspace('cicd');
              }}
            >
              {`Configure >`}
            </Text>
          </Box>
        </Box>

        <Divider orientation="horizontal" my="18px" />

        <Box>
          <Heading
            as="h6"
            size="md"
            fontWeight="semibold"
            color="text.primary"
            lineHeight="28px"
          >
            Test Suite Filter
          </Heading>

          <Box my={4} display={'flex'} gap={4}>
            <Text
              fontSize="md"
              fontFamily="heading"
              fontWeight="medium"
              color="text.primary"
            >
              {testSuiteProjects?.length || 0} Test Suite Linked
            </Text>

            <Text
              fontSize="md"
              fontFamily="heading"
              fontWeight="semibold"
              color="primary"
              cursor="pointer"
              onClick={() => {
                listSelectModal.onOpen();
                setSelectedWorkspace('testSuite');
              }}
            >
              {`Configure >`}
            </Text>
          </Box>
        </Box>
      </Box>

      <Box w={'50%'}>
        <Heading
          as="h6"
          mt={'8px'}
          size="md"
          fontWeight="semibold"
          color="text.primary"
          lineHeight="28px"
        >
          Change Failure Filter
        </Heading>

        {doraSettings?.dto?.cf_team_tracking_type === FieldType.label ? (
          <Box>
            <Text
              fontSize="sm"
              fontFamily="heading"
              fontWeight="semibold"
              color="text.secondary"
              lineHeight="17px"
              mt={2}
              mb={4}
            >
              Change Failure Label
            </Text>

            <Input
              width={'100%'}
              placeholder="Add comma separated label"
              value={cfLabels}
              onChange={(e) => {
                setCfLabels(eliminateHtmlTags(e.target.value));
              }}
            />

            <Button
              mt={4}
              variant="filled"
              size="sm"
              maxW={'max-content'}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Box>
        ) : doraSettings?.dto?.cf_team_tracking_type === FieldType.project ? (
          <Box>
            <Text
              fontSize="sm"
              fontFamily="heading"
              fontWeight="semibold"
              color="text.secondary"
              lineHeight="17px"
              mt={2}
              mb={4}
            >
              Issue Projects
            </Text>

            <Text
              fontSize="sm"
              fontFamily="heading"
              fontWeight="normal"
              color="text.secondary"
              lineHeight="20px"
              mt={2}
              mb={4}
            >
              If your Team tracks Change Failure using an issue management
              system, please select the projects where they are tracked.
            </Text>

            <Box my={4} display={'flex'} gap={4}>
              <Text
                fontSize="md"
                fontFamily="heading"
                fontWeight="medium"
                color="text.primary"
              >
                {cfProjects?.length || 0} Projects Linked
              </Text>

              <Text
                fontSize="md"
                fontFamily="heading"
                fontWeight="semibold"
                color="primary"
                cursor="pointer"
                onClick={() => {
                  listSelectModal.onOpen();
                  setSelectedWorkspace('cfProjects');
                }}
              >{`Select Projects >`}</Text>
            </Box>
          </Box>
        ) : doraSettings?.dto?.cf_team_tracking_type === FieldType.epic ? (
          <Box>
            <Text
              fontSize="sm"
              fontFamily="heading"
              fontWeight="semibold"
              color="text.secondary"
              lineHeight="17px"
              mt={2}
              mb={4}
            >
              Issue Epics
            </Text>
            <Text
              fontSize="sm"
              fontFamily="heading"
              fontWeight="normal"
              color="text.secondary"
              lineHeight="20px"
              mt={2}
              mb={4}
            >
              If your Team tracks Change Failure using an issue management
              system, please select the projects where they are tracked.
            </Text>
            <Box my={4} display={'flex'} gap={4}>
              <Text
                fontSize="md"
                fontFamily="heading"
                fontWeight="medium"
                color="text.primary"
              >
                {cfEpics?.length || 0} Epics Linked
              </Text>

              <Text
                fontSize="md"
                fontFamily="heading"
                fontWeight="semibold"
                color="primary"
                cursor="pointer"
                onClick={() => {
                  listSelectModal.onOpen();
                  setSelectedWorkspace('cfEpics');
                }}
              >{`Select Epics >`}</Text>
            </Box>
          </Box>
        ) : doraSettings?.dto?.cf_team_tracking_type === FieldType.service ? (
          <Box>
            <Text
              fontSize="sm"
              fontFamily="heading"
              fontWeight="semibold"
              color="text.secondary"
              lineHeight="17px"
              mt={2}
              mb={4}
            >
              Incident Service
            </Text>

            <Text
              fontSize="sm"
              fontFamily="heading"
              fontWeight="normal"
              color="text.secondary"
              lineHeight="20px"
              mt={2}
              mb={4}
            >
              If your Team tracks Change Failure using an issue management
              system, please select the projects where they are tracked.
            </Text>

            <Box my={4} display={'flex'} gap={4}>
              <Text
                fontSize="md"
                fontFamily="heading"
                fontWeight="medium"
                color="text.primary"
              >
                {cfServicesList?.length || 0} Incident Service Linked
              </Text>

              <Text
                fontSize="md"
                fontFamily="heading"
                fontWeight="semibold"
                color="primary"
                cursor="pointer"
                onClick={() => {
                  listSelectModal.onOpen();
                  setSelectedWorkspace('cfServicesList');
                }}
              >{`Select Incident Service >`}</Text>
            </Box>
          </Box>
        ) : doraSettings?.dto?.cf_team_tracking_type === FieldType.repo ? (
          <Box>
            <Text
              fontSize="sm"
              fontFamily="heading"
              fontWeight="semibold"
              color="text.secondary"
              lineHeight="17px"
              mt={2}
              mb={4}
            >
              GIT Repositories
            </Text>

            <Text
              fontSize="sm"
              fontFamily="heading"
              fontWeight="normal"
              color="text.secondary"
              lineHeight="20px"
              mt={2}
              mb={4}
            >
              If your Team tracks Change Failure using an issue management
              system, please select the projects where they are tracked.
            </Text>
            <Box my={4} display={'flex'} gap={4}>
              <Text
                fontSize="md"
                fontFamily="heading"
                fontWeight="medium"
                color="text.primary"
              >
                {cfRepos?.length || 0} Repositories Linked
              </Text>

              <Text
                fontSize="md"
                fontFamily="heading"
                fontWeight="semibold"
                color="primary"
                cursor="pointer"
                onClick={() => {
                  listSelectModal.onOpen();
                  setSelectedWorkspace('cfRepos');
                }}
              >{`Select Repositories >`}</Text>
            </Box>
          </Box>
        ) : doraSettings?.dto?.cf_team_tracking_type === FieldType.custom ? (
          <Box>
            <Text
              fontSize="sm"
              fontFamily="heading"
              fontWeight="semibold"
              color="text.secondary"
              lineHeight="17px"
              mt={2}
              mb={4}
            >
              {doraSettings?.dto?.cf_team_tracking_field}
            </Text>

            <Input
              width={'100%'}
              placeholder={doraSettings?.dto?.cf_team_tracking_field}
              value={cfCustomField}
              onChange={(e) => {
                setCfCustomField(eliminateHtmlTags(e.target.value));
              }}
            />

            <Button
              mt={4}
              variant="filled"
              size="sm"
              maxW={'max-content'}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Box>
        ) : (
          <></>
        )}

        <Divider orientation="horizontal" my="18px" />
        <>
          <Heading
            as="h6"
            size="md"
            fontWeight="semibold"
            color="text.primary"
            lineHeight="28px"
          >
            Deployment Filter
          </Heading>

          {doraSettings?.dto?.deployment_team_tracking_type ===
          FieldType.label ? (
            <Box>
              <Text
                fontSize="sm"
                fontFamily="heading"
                fontWeight="semibold"
                color="text.secondary"
                lineHeight="17px"
                mt={2}
                mb={4}
              >
                Deployment Labels
              </Text>

              <Input
                width={'100%'}
                placeholder="Add comma separated label"
                value={deploymentLabels}
                onChange={(e) => {
                  setDeploymentLabels(eliminateHtmlTags(e.target.value));
                }}
              />

              <Button
                mt={4}
                variant="filled"
                size="sm"
                maxW={'max-content'}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Box>
          ) : doraSettings?.dto?.deployment_team_tracking_type ===
            FieldType.project ? (
            <Box>
              <Text
                fontSize="sm"
                fontFamily="heading"
                fontWeight="semibold"
                color="text.secondary"
                lineHeight="17px"
                mt={2}
                mb={4}
              >
                Issue Project
              </Text>
              <Text
                fontSize="sm"
                fontFamily="heading"
                fontWeight="normal"
                color="text.secondary"
                lineHeight="20px"
                mt={2}
                mb={4}
              >
                If your Team tracks their Deployments using Version Control
                System, Please add the repository where they are tracked for
                your team.
              </Text>

              <Box my={4} display={'flex'} gap={4}>
                <Text
                  fontSize="md"
                  fontFamily="heading"
                  fontWeight="medium"
                  color="text.primary"
                >
                  {deploymentProjects?.length || 0} Projects Linked
                </Text>

                <Text
                  fontSize="md"
                  fontFamily="heading"
                  fontWeight="semibold"
                  color="primary"
                  cursor="pointer"
                  onClick={() => {
                    listSelectModal.onOpen();
                    setSelectedWorkspace('deploymentProjects');
                  }}
                >{`Select Projects >`}</Text>
              </Box>
            </Box>
          ) : doraSettings?.dto?.deployment_team_tracking_type ===
            FieldType.repo ? (
            <Box>
              <Text
                fontSize="sm"
                fontFamily="heading"
                fontWeight="semibold"
                color="text.secondary"
                lineHeight="17px"
                mt={2}
                mb={4}
              >
                GIT Repositories
              </Text>

              <Text
                fontSize="sm"
                fontFamily="heading"
                fontWeight="normal"
                color="text.secondary"
                lineHeight="20px"
                mt={2}
                mb={4}
              >
                If your Team tracks their Deployments using Version Control
                System, Please add the repository where they are tracked for
                your team.
              </Text>

              <Box my={4} display={'flex'} gap={4}>
                <Text
                  fontSize="md"
                  fontFamily="heading"
                  fontWeight="medium"
                  color="text.primary"
                >
                  {deploymentRepos?.length || 0} Repositories Linked
                </Text>

                <Text
                  fontSize="md"
                  fontFamily="heading"
                  fontWeight="semibold"
                  color="primary"
                  cursor="pointer"
                  onClick={() => {
                    listSelectModal.onOpen();
                    setSelectedWorkspace('deploymentRepos');
                  }}
                >{`Select Repositories >`}</Text>
              </Box>
            </Box>
          ) : doraSettings?.dto?.deployment_team_tracking_type ===
            FieldType.namePattern ? (
            <Box>
              <Text
                fontSize="sm"
                fontFamily="heading"
                fontWeight="semibold"
                color="text.secondary"
                lineHeight="17px"
                mt={2}
                mb={4}
              >
                Job Name Pattern
              </Text>

              <Input
                width={'100%'}
                placeholder="Enter job names/patterns using '*' as a wildcard, separated by commas."
                _placeholder={{
                  fontSize: 'xxs',
                }}
                value={deploymentPattern}
                onChange={(e) => {
                  setDeploymentPattern(eliminateHtmlTags(e.target.value));
                }}
              />

              <Button
                mt={4}
                variant="filled"
                size="sm"
                maxW={'max-content'}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Box>
          ) : doraSettings?.dto?.deployment_team_tracking_type ===
            FieldType.custom ? (
            <Box>
              <Text
                fontSize="sm"
                fontFamily="heading"
                fontWeight="semibold"
                color="text.secondary"
                lineHeight="17px"
                mt={2}
                mb={4}
              >
                {doraSettings?.dto?.deployment_team_tracking_field}
              </Text>

              <Input
                width={'100%'}
                placeholder={doraSettings?.dto?.deployment_team_tracking_field}
                value={deploymentCustomField}
                onChange={(e) => {
                  setDeploymentCustomField(eliminateHtmlTags(e.target.value));
                }}
              />
              <Button
                mt={4}
                variant="filled"
                size="sm"
                maxW={'max-content'}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </>
      </Box>

      {listSelectModal.isOpen && (
        <ListSelect
          isOpen={listSelectModal.isOpen}
          onClose={listSelectModal.onClose}
          loading={loading}
          initiallySelectedRows={
            selectedWorkspace === 'workspaceRepo'
              ? workspaceRepo || []
              : selectedWorkspace === 'workspaceIssue'
              ? workspaceIssue || []
              : selectedWorkspace === 'qualityProjects'
              ? qualityProjects || []
              : selectedWorkspace === 'cfProjects'
              ? cfProjects || []
              : selectedWorkspace === 'cfEpics'
              ? cfEpics || []
              : selectedWorkspace === 'cfServicesList'
              ? cfServicesList || []
              : selectedWorkspace === 'cfRepos'
              ? cfRepos || []
              : selectedWorkspace === 'deploymentProjects'
              ? deploymentProjects || []
              : selectedWorkspace === 'deploymentRepos'
              ? deploymentRepos || []
              : selectedWorkspace === 'cicd'
              ? cicdPipelines || []
              : selectedWorkspace === 'testSuite'
              ? testSuiteProjects || []
              : []
          }
          onSelectedRowsChange={
            selectedWorkspace === 'workspaceRepo'
              ? setWorkspaceRepo
              : selectedWorkspace === 'workspaceIssue'
              ? setWorkspaceIssue
              : selectedWorkspace === 'qualityProjects'
              ? setQualityProjects
              : selectedWorkspace === 'cfProjects'
              ? setCfProjects
              : selectedWorkspace === 'cfEpics'
              ? setCfEpics
              : selectedWorkspace === 'cfServicesList'
              ? setCfServicesList
              : selectedWorkspace === 'cfRepos'
              ? setCfRepos
              : selectedWorkspace === 'deploymentProjects'
              ? setDeploymentProjects
              : selectedWorkspace === 'deploymentRepos'
              ? setDeploymentRepos
              : selectedWorkspace === 'cicd'
              ? setCicdPipelines
              : selectedWorkspace === 'testSuite'
              ? setTestSuiteProjects
              : null
          }
          data={
            selectedWorkspace === 'workspaceRepo'
              ? gitRepo?.dto?.map((repo: any) => ({
                  label: repo.name,
                  value: repo.name,
                  url: repo.url,
                  key: repo.key,
                }))
              : selectedWorkspace === 'workspaceIssue'
              ? jiraRepo?.dto?.map((repo: any) => ({
                  label: repo.name,
                  value: repo.name,
                }))
              : selectedWorkspace === 'qualityProjects'
              ? qualityRepo?.dto?.map((repo: any) => ({
                  label: repo.name,
                  value: repo.name,
                }))
              : selectedWorkspace === 'cfProjects'
              ? jiraRepo?.dto?.map((repo: any) => ({
                  label: repo.name,
                  value: repo.name,
                }))
              : selectedWorkspace === 'cfEpics'
              ? issueEpic?.dto?.map((issue: any) => ({
                  label: issue,
                  value: issue,
                }))
              : selectedWorkspace === 'cfServicesList'
              ? incidentManagement?.dto?.map((repo: any) => ({
                  label: repo.name,
                  value: repo.name,
                }))
              : selectedWorkspace === 'cfRepos'
              ? gitRepo?.dto?.map((repo: any) => ({
                  label: repo.name,
                  value: repo.name,
                  url: repo.url,
                  key: repo.key,
                }))
              : selectedWorkspace === 'deploymentProjects'
              ? jiraRepo?.dto?.map((repo: any) => ({
                  label: repo.name,
                  value: repo.name,
                }))
              : selectedWorkspace === 'deploymentRepos'
              ? gitRepo?.dto?.map((repo: any) => ({
                  label: repo.name,
                  value: repo.name,
                  url: repo.url,
                  key: repo.key,
                }))
              : selectedWorkspace === 'cicd'
              ? pipelines?.dto?.map((pipeline: any) => ({
                  label: pipeline.name,
                  value: pipeline.name,
                  url: pipeline.url,
                  key: pipeline.key,
                }))
              : selectedWorkspace === 'testSuite'
              ? testSuite?.dto?.map((testSuite: any) => ({
                  label: testSuite.name,
                  value: testSuite.name,
                  url: testSuite.url,
                  key: testSuite.key,
                }))
              : []
          }
          label={
            selectedWorkspace === 'workspaceRepo'
              ? 'Repositories'
              : selectedWorkspace === 'workspaceIssue'
              ? 'Projects'
              : selectedWorkspace === 'qualityProjects'
              ? 'Projects'
              : selectedWorkspace === 'cfProjects'
              ? 'Projects'
              : selectedWorkspace === 'cfEpics'
              ? 'Epics'
              : selectedWorkspace === 'cfServicesList'
              ? 'Incident Services'
              : selectedWorkspace === 'cfRepos'
              ? 'Repositories'
              : selectedWorkspace === 'deploymentProjects'
              ? 'Projects'
              : selectedWorkspace === 'deploymentRepos'
              ? 'Repositories'
              : selectedWorkspace === 'cicd'
              ? 'CI/CI Pipelines'
              : selectedWorkspace === 'testSuite'
              ? 'Test Suite Projects'
              : ''
          }
          setTriggerSubmit={setTriggerSubmit}
        />
      )}

      {issueFilterModal.isOpen && (
        <IssueFilterModal
          isOpen={issueFilterModal.isOpen}
          onClose={issueFilterModal.onClose}
          issueFilterConditions={issueFilterConditions}
          loading={handleUpdateTeamLoading}
          onSubmitAction={(payload) => {
            handleUpdateTeam(
              {
                ...defaultData,
                workspaces: {
                  ...defaultData.workspaces,
                  issueFilterConditions: payload,
                  filterDataByIssueTracking: true,
                },
              },
              {
                onSuccess: () => {
                  newToast({
                    message: defaultData
                      ? 'Team has been updated successfully'
                      : 'Team has been added successfully',
                    status: 'success',
                  });
                  issueFilterModal.onClose();
                  queryClient.invalidateQueries([QUERY_KEYS.teamDetails]);
                },
                onError: (err: any) => {
                  setTriggerSubmit(false);
                  newToast({
                    message: err.message ? err.message : 'Something went wrong',
                    status: 'error',
                  });
                },
              }
            );
          }}
        />
      )}
    </Flex>
  );
};

export default Workspace;
