import * as ENDPOINTS from './endpoints';
import { useCustomMutation } from '../../hooks/useCustomMutation';

export const QUERY_KEYS = {
  contributors: 'contributors',
};

export const useContributorsMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.contributors],
    ENDPOINTS.mergeContributors
  );
};
