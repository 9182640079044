import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface ContributorTableState {
  selectedContributors: any;
  setSelectedContributors: (selectedContributors: any) => void;

  selectedMetrics: any;
  setSelectedMetrics: (selectedMetrics: any) => void;
}

export const useContributorTableStore = create<ContributorTableState>()(
  persist(
    (set) => ({
      selectedContributors: [],
      setSelectedContributors: (selectedContributors) =>
        set({ selectedContributors }),

      selectedMetrics: [],
      setSelectedMetrics: (selectedMetrics) => set({ selectedMetrics }),
    }),
    {
      name: 'contributor-table-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useContributorTableStore;
