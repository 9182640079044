import {
  ActivityLogIcon,
  FeedbackIcon,
  InitiativeIcon,
  InvestmentIcon,
  MetricsIcon,
  Reports,
  SettingIcon,
  SurveyIcon,
  TeamsIcon,
  WorkingAggIcon,
  DashboardIcon,
  cursorClick,
  SprintIcon,
  TeamCompareIcon,
  PrIcon,
  IssueIcon,
  ContributorsIcon,
} from '../icons';
import { Home } from '../icons/home';

export type NavItem = {
  id: string;
  Icon?: any;
  path: string;
  label: string;
  subMenuItems?: NavItem[];
};

export type CategoryItem = {
  id: string;
  Icon?: any;
  path: string;
  label: string;
  subMenuItems?: NavItem[];
};

export type Category = {
  categoryId: number;
  categoryTitle: string;
  categoryItem: CategoryItem[];
};

export type NavConfig = Category[];

export const navConfig: NavConfig = [
  {
    categoryId: 1,
    categoryTitle: '',
    categoryItem: [
      {
        id: 'Home',
        Icon: Home,
        path: 'home',
        label: 'Home',
        subMenuItems: [],
      },
      {
        id: 'Dashboard',
        Icon: DashboardIcon,
        path: 'dashboard',
        label: 'Dashboard',
        subMenuItems: [
          { id: 'overview', label: 'Overview', path: 'overview' },
          { id: 'Git', label: 'Git', path: 'git' },
          { id: 'Ticket', label: 'Ticket', path: 'ticket' },
          { id: 'Dora', label: 'DORA', path: 'dora' },
          { id: 'Cicd', label: 'CI/CD', path: 'cicd' },
          { id: 'Copilot', label: 'Copilot', path: 'copilot' },
          { id: 'testSuite', label: 'Test Suite', path: 'test-suite' },
          { id: 'Quality', label: 'Code Quality', path: 'quality' },
          {
            id: 'Custom Dashboard',
            label: 'Custom Dashboard',
            path: 'custom-dashboard',
          },
        ],
      },
      {
        id: 'Metrics',
        Icon: MetricsIcon,
        path: 'metrics',
        label: 'Metrics',
        subMenuItems: [
          {
            id: 'Favourite',
            label: 'Favourites',
            path: 'favourite',
          },
          {
            id: 'Productivity',
            label: 'Productivity',
            path: 'productivity',
          },
          {
            id: 'Velocity',
            label: 'Velocity',
            path: 'velocity',
          },
          {
            id: 'Quality',
            label: 'Quality',
            path: 'quality',
          },
          {
            id: 'Dora',
            label: 'DORA',
            path: 'dora',
          },
          {
            id: 'Throughput',
            label: 'Throughput',
            path: 'throughput',
          },
          {
            id: 'Cycle Time',
            label: 'Cycle Time',
            path: 'cycle-time',
          },
          {
            id: 'Flow',
            label: 'Flow',
            path: 'flow',
          },
          {
            id: 'Wellbeing',
            label: 'Well-being',
            path: 'wellbeing',
          },
        ],
      },
    ],
  },

  {
    categoryId: 2,
    categoryTitle: 'TEAMS',
    categoryItem: [
      {
        id: 'Teams',
        path: 'teams',
        Icon: TeamsIcon,
        label: 'Teams',
      },
      {
        id: 'Compare Teams',
        path: 'compare-teams',
        Icon: TeamCompareIcon,
        label: 'Compare Teams',
      },
      // {
      //   id: 'Team Compare',
      //   path: 'teams/comparison',
      //   Icon: TeamCompareIcon,
      //   label: 'Team Compare',
      // },
      {
        id: 'ActivityLog',
        path: 'activity-log',
        Icon: ActivityLogIcon,
        label: 'Activity Log',
      },
      {
        id: 'Contributors',
        path: 'contributors',
        Icon: ContributorsIcon,
        label: 'Contributors',
      },
      {
        id: 'Pull Requests',
        path: 'pr-details?chartKey=PR_UPDATED&firstOption=&heading=PR%20Details%20Table&metric=PR_UPDATED&metricKey=&preDefinedOptions=true&secondOption=&status=all&teamId=&back=hidden',
        Icon: PrIcon,
        label: 'Pull requests',
      },
      {
        id: 'Issues',
        path: 'issue-details?chartKey=ISSUE_UPDATED&firstOption=&heading=Issue%20Details%20Table&metric=ISSUE_UPDATED&preDefinedOptions=true&secondOption=&status=all&teamId=&ticketType=all&back=hidden',
        Icon: IssueIcon,
        label: 'Issues',
      },
    ],
  },
  {
    categoryId: 3,
    categoryTitle: 'PLANNING',
    categoryItem: [
      {
        id: 'Investments',
        path: 'investments',
        Icon: InvestmentIcon,
        label: 'Investments',
      },
      {
        id: 'Initiatives',
        path: 'initiatives',
        Icon: InitiativeIcon,
        label: 'Initiatives',
      },
      { id: 'Sprint', label: 'Sprint', Icon: SprintIcon, path: 'sprint' },
    ],
  },
  {
    categoryId: 4,
    categoryTitle: '',
    categoryItem: [
      {
        id: 'Reports',
        path: 'reports',
        Icon: Reports,
        label: 'Reports',
      },
      {
        id: 'Feedback',
        path: 'feedback',
        Icon: FeedbackIcon,
        label: 'Feedback',
      },
      {
        id: 'Goals',
        path: 'goals',
        Icon: WorkingAggIcon,
        label: 'Working Agreements',
      },
      {
        id: 'Automations',
        path: 'workflow-automation',
        Icon: cursorClick,
        label: 'Automations',
      },
      {
        id: 'Survey',
        path: 'survey',
        Icon: SurveyIcon,
        label: 'Survey',
      },
    ],
  },
  {
    categoryId: 5,
    categoryTitle: 'SETTINGS',
    categoryItem: [
      {
        id: 'Settings',
        Icon: SettingIcon,
        path: 'settings',
        label: 'Settings',
        subMenuItems: [
          {
            id: 'Integrations',
            label: 'Integrations',
            path: 'integrations',
          },
          {
            id: 'Investment Categories',
            label: 'Investment categories',
            path: 'investment-categories',
          },
          {
            id: 'Teams',
            label: 'Teams',
            path: 'teams',
          },
          {
            id: 'Members',
            label: 'Members',
            path: 'members',
          },
          {
            id: 'Dora',
            label: 'DORA',
            path: 'dora',
          },
          {
            id: 'Subscription and Billing',
            label: 'Subscription & Billing',
            path: 'subscription-billing',
          },
          {
            id: 'Organisation',
            label: 'Organisation',
            path: 'organisation',
          },
          {
            id: 'Contributors',
            label: 'Contributors',
            path: 'contributors',
          },
          {
            id: 'feedback',
            label: 'Feedback',
            path: 'feedback',
          },
          {
            id: 'notification',
            label: 'Notification',
            path: 'notification',
          },
          {
            id: 'survey',
            label: 'Survey',
            path: 'survey',
          },
          {
            id: 'custom-metrics',
            label: 'Custom metrics',
            path: 'custom-metrics',
          },
          {
            id: 'sdlc-map',
            label: 'SDLC Map',
            path: 'sdlc-map',
          },
        ],
      },
    ],
  },
];
