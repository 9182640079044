import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Loader,
  PageHeader,
  PageToolbar,
  durationData,
  PeriodType,
  ISelect,
  useQueryState,
  Investment,
  removeEmptyQueryParams,
  DateFilter,
} from '@devd-client/devd/components';
import { InvestmentsContext } from '../context/investmentContext';
import ChartCard from '../components/ChartCard';
import InfoCard from '../components/InfoCard';
import InvestmentsToolbar from '../components/InvestmentsToolbar';
import {
  useInvestmentReportOverallGql,
  useInvestmentReportWeekGql,
} from '../graphql';
import CountIcon from '../icons/CountIcon';
import EstimateIcon from '../icons/EstimateIcon';
import { useNavigate } from 'react-router-dom';
import { Button, Text, useTheme } from '@chakra-ui/react';
import TicketTypeIcon from '../icons/TicketTypeIcon';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';
import moment from 'moment';
import { useDashboardStore } from '@devd-client/devd/components';

const InvestmentsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const viewSwitchObj = [
  {
    tab: 'ticket-count',
    tabLabel: 'Ticket count',
    tooltipText: 'Ticket count',
    TabIcon: CountIcon,
  },
  {
    tab: 'ticket-time',
    tabLabel: 'Ticket time spent',
    tooltipText: 'Ticket time spent',
    TabIcon: EstimateIcon,
  },
  {
    tab: 'ticket-type',
    tabLabel: 'Ticket type',
    tooltipText: 'Ticket Type',
    TabIcon: TicketTypeIcon,
  },
];

export function InvestmentsPage(data: any) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { dispatch } = useContext(InvestmentsContext);
  const [appState, authDispatch] = useAuth();

  const [periodOptions, setPeriodOptions] = useState<any>(durationData);
  const [viewSwitch, setViewSwitch] = useState<string>(viewSwitchObj[0].tab);

  const [toggleTaskCalendar, setToggleTaskCalendar] = useState<string>('');
  const { selected, selectedTeam, setSelected, setSelectedTeam } =
    useDashboardStore();

  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [open, setOpen] = useQueryState('open');
  const [show, setShow] = useQueryState('show');
  const [team, setTeam] = useQueryState('team');

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'Organisation'
        ? setTeam('Organisation')
        : setTeam(selectedTeam.value);
    }

    if (typeof viewSwitch !== 'undefined') {
      setOpen(viewSwitch);
    }

    if (typeof toggleTaskCalendar !== 'undefined') {
      setShow(toggleTaskCalendar);
    }
  }, [selected, selectedTeam.value, viewSwitch, toggleTaskCalendar]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'Organisation'
          ? { value: '', label: 'Organisation' }
          : { value: team, label: team }
      );

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);

    open && setViewSwitch(open);

    show && setToggleTaskCalendar(show);
  }, []);

  const { isLoading, isFetching } = useInvestmentReportWeekGql(
    selected.startDate,
    selected.endDate,
    selected?.sprintId,
    'week',
    viewSwitch,
    selectedTeam.value,
    dispatch
  );

  const { isLoading: pieLoading, isFetching: pieFetching } =
    useInvestmentReportOverallGql(
      selected.startDate,
      selected.endDate,
      selected?.sprintId,
      '',
      viewSwitch,
      selectedTeam.value,
      dispatch
    );

  return (
    <>
      <InvestmentsContainer>
        <PageHeader
          headingText="Investments"
          component={
            <Button
              variant="primary"
              size="sm"
              leftIcon={<Investment color={theme['colors'].primary} />}
              onClick={() => navigate('/settings/investment-categories')}
            >
              Define Investment
            </Button>
          }
        />
        <Text
          fontFamily="heading"
          fontSize="sm"
          color="text.secondary"
          mb={'20px'}
        >
          {data?.data?.dto?.title}
        </Text>
        <PageToolbar
          mb={'20px'}
          leftComp={
            <InvestmentsToolbar
              selectedTeam={selectedTeam}
              handleSelectedTeam={setSelectedTeam}
              viewSwitch={viewSwitch}
              setViewSwitch={setViewSwitch}
            />
          }
          rightComp={
            <DateFilter
              getUser={getUser}
              appState={appState}
              dispatch={authDispatch}
              AuthActionTypes={AuthActionTypes}
            />
          }
        />

        <ChartCard />

        <InfoCard
          viewSwitch={viewSwitch}
          toggleTaskCalendar={toggleTaskCalendar}
          setToggleTaskCalendar={setToggleTaskCalendar}
          selectedTeam={selectedTeam.value}
          selected={selected}
        />
      </InvestmentsContainer>
      {(isLoading || isFetching || pieLoading || pieFetching) && <Loader />}
    </>
  );
}
