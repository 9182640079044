import { useCustomMutation } from '../../hooks/useCustomMutation';
import * as ENDPOINTS from './endpoints';

export const QUERY_KEYS = {
  contributorNameAndJobRole: 'contributorNameAndJobRole',
};

export const useUpdateContributorNameAndJobRole = () => {
  return useCustomMutation(
    [QUERY_KEYS.contributorNameAndJobRole],
    ENDPOINTS.updateContributorNameAndJobRole
  );
};
