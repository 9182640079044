export function MergeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="copy-07" clip-path="url(#clip0_5230_3748)">
        <path
          id="Icon"
          d="M7.00065 1.33536C6.55062 1.34146 6.28045 1.36751 6.062 1.47882C5.81111 1.60665 5.60714 1.81063 5.47931 2.06151C5.368 2.27996 5.34195 2.55013 5.33585 3.00016M13.0007 1.33536C13.4507 1.34146 13.7209 1.36751 13.9393 1.47882C14.1902 1.60665 14.3942 1.81063 14.522 2.06151C14.6333 2.27996 14.6594 2.55013 14.6655 3.00015M14.6655 9.00016C14.6594 9.45019 14.6333 9.72036 14.522 9.93882C14.3942 10.1897 14.1902 10.3937 13.9393 10.5215C13.7209 10.6328 13.4507 10.6589 13.0007 10.665M14.6673 5.33349V6.66683M9.33402 1.3335H10.6673M3.46732 14.6668H8.53398C9.28072 14.6668 9.65409 14.6668 9.93931 14.5215C10.1902 14.3937 10.3942 14.1897 10.522 13.9388C10.6673 13.6536 10.6673 13.2802 10.6673 12.5335V7.46683C10.6673 6.72009 10.6673 6.34672 10.522 6.06151C10.3942 5.81063 10.1902 5.60665 9.93931 5.47882C9.65409 5.3335 9.28072 5.3335 8.53398 5.3335H3.46732C2.72058 5.3335 2.34721 5.3335 2.062 5.47882C1.81111 5.60665 1.60714 5.81063 1.47931 6.06151C1.33398 6.34672 1.33398 6.72009 1.33398 7.46683V12.5335C1.33398 13.2802 1.33398 13.6536 1.47931 13.9388C1.60714 14.1897 1.81111 14.3937 2.062 14.5215C2.34721 14.6668 2.72058 14.6668 3.46732 14.6668Z"
          stroke="#626266"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5230_3748">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MergeIcon;
