import { Box, Flex, Progress, Text, Tooltip } from '@chakra-ui/react';
import { useState } from 'react';
import { getOrdinalSuffix } from '../shared/utils';

type Props = {
  color: string;
  contAvg: number;
};

export const ProgressBar = ({ color, contAvg }: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box
      position="relative"
      width="170px"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Progress
        value={contAvg}
        size="lg"
        background="#F4F4F5"
        height="20px"
        borderRadius="none"
        display="flex"
        alignItems="center"
        sx={{
          '& > div': {
            bg: color,
            height: '8px',
            borderRadius: 'none',
            position: 'relative',
          },
        }}
      >
        <Box
          position="absolute"
          right="0"
          top="26%"
          transform="translateY(-50%)"
          width="0px"
          height="0px"
          bg="transparent"
        >
          <Tooltip
            label={
              <Flex align="center" p={1}>
                <Box mr={1} height="14px" width="2px" bg={color} />
                <Text
                  fontFamily={'heading'}
                  fontWeight="normal"
                  color="text.primary"
                  mr={1}
                >
                  Contributor:
                </Text>
                <Text
                  fontFamily={'heading'}
                  fontWeight="semibold"
                  color="text.secondary2"
                >
                  {contAvg}
                  <sup>{getOrdinalSuffix(contAvg)}</sup>
                </Text>
              </Flex>
            }
            isOpen={isHovered}
            placement="bottom"
            hasArrow
            variant={'whiteBg'}
            sx={{
              '.chakra-tooltip__arrow-wrapper .chakra-tooltip__arrow': {
                top: '1px !important',
                borderLeft: '1px solid',
                borderLeftColor: '#E2E8F0',
                borderTop: '1px solid',
                borderTopColor: '#E2E8F0',
              },
            }}
          >
            <Box width="100%" height="100%" />
          </Tooltip>
        </Box>
      </Progress>

      <Box
        position="absolute"
        top="20px"
        bottom="0"
        left={`25%`}
        width="1px"
        height="2px"
        bg="#8F8F91"
        transform="translateX(-50%)"
      />

      <Text
        position="absolute"
        top="24px"
        bottom="0"
        left={`20%`}
        fontFamily={'heading'}
        fontSize="xxs"
        fontWeight="normal"
        color="#8F8F91"
      >
        25<sup>th</sup>
      </Text>

      <Box
        position="absolute"
        top="20px"
        bottom="0"
        left={`50%`}
        width="1px"
        height="2px"
        bg="#8F8F91"
        transform="translateX(-50%)"
      />

      <Text
        position="absolute"
        top="24px"
        bottom="0"
        left={`46%`}
        fontFamily={'heading'}
        fontSize="xxs"
        fontWeight="normal"
        color="#8F8F91"
      >
        50<sup>th</sup>
      </Text>

      <Box
        position="absolute"
        top="20px"
        bottom="0"
        left={`75%`}
        width="1px"
        height="2px"
        bg="#8F8F91"
        transform="translateX(-50%)"
      />

      <Text
        position="absolute"
        top="24px"
        bottom="0"
        left={`71%`}
        fontFamily={'heading'}
        fontSize="xxs"
        fontWeight="normal"
        color="#8F8F91"
      >
        75<sup>th</sup>
      </Text>
    </Box>
  );
};

export default ProgressBar;
