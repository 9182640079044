import { useMemo } from 'react';
import { DataGrid, Paginate } from '@devd-client/devd/components';
import { Box, useTheme } from '@chakra-ui/react';
import { columns } from './columns';
import { useNavigate } from 'react-router';

type Props = {
  testSuiteData: any;
  loading: boolean;
  handlePageClick: (page: number) => void;
};

export const TestSuiteList = ({
  testSuiteData,
  loading,
  handlePageClick,
}: Props) => {
  const navigate = useNavigate();
  const { colors } = useTheme();

  const rows: any[] = useMemo(
    () => testSuiteData?.testSuiteReports || [],
    [testSuiteData?.testSuiteReports]
  );

  const dataColumns = useMemo(
    () => columns(navigate, colors),
    [testSuiteData?.testSuiteReports]
  );

  return (
    <Box mt={4}>
      <DataGrid
        data={rows}
        columns={dataColumns}
        showLoader={loading}
        maxH="550px"
        sticky="firstCol"
      />

      <Box marginLeft="-20px" my={'16px'}>
        <Paginate
          currentPage={testSuiteData?.currentPage + 1}
          totalPageCount={testSuiteData?.totalPages}
          pageSize={20}
          onPageChange={(page) => handlePageClick(page as number)}
        />
      </Box>
    </Box>
  );
};

export default TestSuiteList;
