import { Box, SimpleGrid, Text, Skeleton } from '@chakra-ui/react';
import {
  MetricDetails,
  MetricsCard,
  PageHeader,
  PageToolbar,
  ProgressMultiColor,
  splitTime,
  TeamMenu,
  DateFilter,
} from '@devd-client/devd/components';
import { FC, useEffect, useState } from 'react';
import { useCycleTimeProgressGql } from '../../graphql';
import { useSearchParams } from 'react-router-dom';
import { AuthActionTypes, getUser } from '@devd-client/devd/auth';
import { useCommonMetrics } from '../../hooks/useCommonMetrics';

const readings = [
  { label: 'Waiting Time', content: 0, value: 0, color: '#4A5568' },
  { label: 'Coding Time', content: 0, value: 0, color: '#0396A6' },
  { label: 'PR Pickup Time', content: 0, value: 0, color: '#FF8D70' },
  { label: 'PR Merge Time', content: 0, value: 0, color: '#8CC0DE' },
  { label: 'Deploy Time', content: 0, value: 0, color: '#F1B813' },
];

interface CycleTimePageProps {
  metricsData: any;
}

const CycleTimePage: FC<CycleTimePageProps> = ({ metricsData }) => {
  const [searchParams] = useSearchParams();
  const metricType = searchParams.get('type');
  const helpDocLink = metricsData?.dto?.helpDoc;
  const cycleTimeMetrics = metricsData?.dto?.metrics;

  const {
    selected,
    selectedTeam,
    setSelectedTeam,
    appState,
    dispatch,
    QUERY_KEYS,
  } = useCommonMetrics();

  const [stepperData, setStepperData] =
    useState<
      { label: string; content: number; value: number; color: string }[]
    >(readings);

  const { data: cycleTimeProgress, isFetching } = useCycleTimeProgressGql(
    selected?.startDate,
    selected?.endDate,
    selectedTeam?.value
  );

  useEffect(() => {
    if (!cycleTimeProgress || !cycleTimeProgress?.data) {
      setStepperData(readings);
    } else {
      setStepperData(
        readings?.map((item: any) => {
          const findPercent = (value: number): number => {
            const backlogTime =
              Number(cycleTimeProgress?.data[0]?.backlog_time) || 0;
            const codingTime =
              Number(cycleTimeProgress?.data[0]?.coding_time) || 0;
            const mergeTime =
              Number(cycleTimeProgress?.data[0]?.merge_time) || 0;
            const pickupTime =
              Number(cycleTimeProgress?.data[0]?.pickup_time) || 0;
            const deployTime =
              Number(cycleTimeProgress?.data[0]?.deploy_time) || 0;

            const total =
              backlogTime + codingTime + mergeTime + pickupTime + deployTime;

            if (total === 0) {
              return 0;
            }
            return (value / total) * 100;
          };

          return {
            ...item,
            content:
              item.label === 'Waiting Time'
                ? findPercent(cycleTimeProgress?.data[0]?.backlog_time).toFixed(
                    4
                  )
                : item.label === 'Coding Time'
                ? findPercent(cycleTimeProgress?.data[0]?.coding_time).toFixed(
                    4
                  )
                : item.label === 'PR Merge Time'
                ? findPercent(cycleTimeProgress?.data[0]?.merge_time).toFixed(4)
                : item.label === 'PR Pickup Time'
                ? findPercent(cycleTimeProgress?.data[0]?.pickup_time).toFixed(
                    4
                  )
                : item.label === 'Deploy Time'
                ? findPercent(cycleTimeProgress?.data[0]?.deploy_time).toFixed(
                    4
                  )
                : 0,
            value:
              item.label === 'Waiting Time'
                ? cycleTimeProgress?.data[0]?.backlog_time
                : item.label === 'Coding Time'
                ? cycleTimeProgress?.data[0]?.coding_time
                : item.label === 'PR Merge Time'
                ? cycleTimeProgress?.data[0]?.merge_time
                : item.label === 'PR Pickup Time'
                ? cycleTimeProgress?.data[0]?.pickup_time
                : item.label === 'Deploy Time'
                ? cycleTimeProgress?.data[0]?.deploy_time
                : 0,
          };
        })
      );
    }
  }, [cycleTimeProgress?.data]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!metricType ? (
        <Box display={'flex'} flexDirection={'column'} width={'100%'}>
          <Box>
            <PageHeader
              headingText={metricsData?.dto?.name}
              mb={'10px'}
              helpDocLink={helpDocLink}
            />

            <PageToolbar
              leftComp={
                <TeamMenu
                  selectedTeam={selectedTeam}
                  handleSelectedTeam={setSelectedTeam}
                  AuthActionTypes={AuthActionTypes}
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                />
              }
              rightComp={
                <DateFilter
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                  AuthActionTypes={AuthActionTypes}
                />
              }
            />
          </Box>

          <Box
            w={'100%'}
            mt={6}
            height="160px"
            padding={'20px'}
            backgroundColor="#fff"
            border="0.5px solid rgba(0, 0, 0, 0.1)"
            borderRadius="2px"
          >
            <Text
              as="h1"
              fontSize="sm"
              color="text.primary"
              fontWeight="medium"
              fontFamily="heading"
            >
              Change Lifecycle (Breakdown)
            </Text>

            <Box display="flex" mt="8" w={'100%'}>
              {isFetching ? (
                <Skeleton height="10px" width="100%" />
              ) : (
                <ProgressMultiColor
                  // showScale
                  // showValues
                  unset
                  showLegend
                  readings={stepperData?.map((item: any) => ({
                    name: `${item?.label} - ${splitTime(
                      Number(item?.value) / 60
                    )}`,
                    value: Number(item?.content),
                    color: item?.color,
                  }))}
                />
              )}
            </Box>
          </Box>

          <SimpleGrid columns={{ base: 2, '2xl': 2 }} spacing={6} mt={6}>
            {cycleTimeMetrics?.map((type: string) => (
              <MetricsCard
                key={type}
                metricType={type}
                selected={selected}
                selectedTeam={selectedTeam?.value}
                queryKey={QUERY_KEYS.cycletimePage}
              />
            ))}
          </SimpleGrid>
        </Box>
      ) : (
        <MetricDetails />
      )}
    </>
  );
};

export default CycleTimePage;
